import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * A button to open RouteFilter dialog, with icon, and tooltip.
 * This should only be used by RouteFilter.js
 * @param props - passed on to button
 * @returns {JSX.Element}
 */
export default function RouteFilterButton(props) {
  return (
    <button
      {...props}
      className="py-1 px-4 rounded-md border font-semibold border-ao-blue hover:bg-ao-blue hover:text-white"
    >
      Filter Routes
    </button>
  );
}
