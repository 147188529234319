import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Popover from "@material-ui/core/Popover";
import { useRecoilState, useRecoilValue } from "recoil";
import { availableBunkersFilter, availableRoutesFilter,  availableRoutesFiltered, availableTCEsFiltered, userSettings } from "../../store/ui";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import RegionsLookup from "../../dataMocks/RegionsLookup.json";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import RouteFilterButton from "./RouteFilterButton";
import { validVesselTypes } from "../../store/api";
import { DATA_SOURECE_CATEGORY } from "../../utils/Constants";
import { DataSourceSelect } from "../DataSourceSelect/DataSourceSelect";

function allShipTypesSelected(_availableRoutesFilter) {
  let shipTypes = 0;
  for (let shipType of validVesselTypes) {
    if (_availableRoutesFilter[shipType]) {
      shipTypes += 1;
    }
  }

  return shipTypes === validVesselTypes.length;
}

function allRegionsSelected(_availableRoutesFilter) {
  let regions = 0;
  for (let region of RegionsLookup) {
    if (_availableRoutesFilter[region["codeName"]]) {
      regions += 1;
    }
  }

  return regions === RegionsLookup.length;
}

/**
 * A button and poover that allows users to select a filter on routes they wish to use
 * throughout the app
 */
export default function RouteFilter({tce, isSpotView = false}) {
  const [_availableRoutesFilter, setAvailableRoutesFilter] = useRecoilState(
    availableRoutesFilter
  );

  const [_availableBunkersFilter, setAvailableBunkersFilter] = useRecoilState(
    availableBunkersFilter
  );
  const _availableRoutes = useRecoilValue(
    tce ? availableTCEsFiltered : availableRoutesFiltered);
    const _userSettings = useRecoilValue(userSettings)
  const [_anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(_anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Set a particular value on the filter
  const setKeyOnRoutesFilter = (key, value) => {
        setAvailableRoutesFilter({
      ..._availableRoutesFilter,
      [key]: value,
    });
  };

  // Set a particular value on the filter
  const setKeyOnBunkersFilter = (key, value) => {
    setAvailableBunkersFilter({
      ..._availableBunkersFilter,
      [key]: value,
    });
  };

  // Set all ships on or off on filter
  const setAllShipsFilter = (value) => {
    let filter = {};
    for (let shipType of validVesselTypes) {
      filter[shipType] = value;
    }

    setAvailableRoutesFilter({
      ..._availableRoutesFilter,
      ...filter,
    });
  };

  // Set all regions on or off on filter
  const setAllRegionsFilter = (value) => {
    let filter = {};
    for (let region of RegionsLookup) {
      filter[region["codeName"]] = value;
    }

    setAvailableRoutesFilter({
      ..._availableRoutesFilter,
      ...filter,
    });
  };

  const handleRegionChange = (event) => {
    // Value is an array of selected short names
    const {
      target: { value },
    } = event;

    let filter = {};
    for (let region of RegionsLookup) {
      filter[region["codeName"]] = value.includes(region["codeName"]);
    }

    setAvailableRoutesFilter({
      ..._availableRoutesFilter,
      ...filter,
    });
  };

  React.useEffect(() => {
    setAvailableRoutesFilter({
      ..._availableBunkersFilter,
      routeDataSource: _userSettings.frightRatesSource,
      bunkerDataSource: _userSettings.bunkerPricesSource
    })
  }, [_userSettings]);

  // Get selected regions in array
  const selectedRegions = RegionsLookup.filter(
    (x) => _availableRoutesFilter[x["codeName"]]
  ).map((x) => x["codeName"]);

  const _allShipTypesSelected = allShipTypesSelected(_availableRoutesFilter);
  const _allRegionsSelected = allRegionsSelected(_availableRoutesFilter);

  return (
    <div>
      <RouteFilterButton onClick={handleClick} />
      <Popover
        id="selected-routes-menu"
        anchorEl={_anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="">
          <Grid container spacing={2} style={{ margin: 10 }}>
            <Grid item xs={4}>
              <FormLabel>Freight rates data source</FormLabel>
              <MenuItem>
              <DataSourceSelect 
                category={DATA_SOURECE_CATEGORY.FREIGHT_RATES}
                value={_availableRoutesFilter.routeDataSource}
                onChange={(e) => {
                    handleDataSourceChange(e.target.value)
                }} />
              </MenuItem>
              {!tce && !isSpotView && (<FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={_availableRoutesFilter.showConstructedRouteCurves}
                    onChange={(e) => {
                      setKeyOnRoutesFilter("showConstructedRouteCurves", e.target.checked);
                    }}
                  />
                }
                label="Show constructed curves"
              />)}
              <FormLabel style={{ marginTop: 20, display: 'block' }}>Clean or Dirty</FormLabel>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={_availableRoutesFilter.clean}
                      onChange={(e) => {
                        setKeyOnRoutesFilter("clean", e.target.checked);
                      }}
                    />
                  }
                  label="Clean"
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={_availableRoutesFilter.dirty}
                      onChange={(e) => {
                        setKeyOnRoutesFilter("dirty", e.target.checked);
                      }}
                    />
                  }
                  label="Dirty"
                />
              </MenuItem>
              <FormLabel style={{ marginTop: 32, color: "grey", width: 155 }}>
                {_availableRoutes.allRoutes.length} routes selected
              </FormLabel>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Vessel Type</FormLabel>

              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={_allShipTypesSelected}
                      onChange={(e) => {
                        setAllShipsFilter(e.target.checked);
                      }}
                    />
                  }
                  label="Any vessel"
                />
              </MenuItem>
              {validVesselTypes.map((vesselType, idx) => (
                <MenuItem key={idx}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={_availableRoutesFilter[vesselType]}
                        onChange={(e) => {
                          setKeyOnRoutesFilter(vesselType, e.target.checked);
                        }}
                      />
                    }
                    label={vesselType.toUpperCase()}
                  />
                </MenuItem>
              ))}
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Load / Discharge Region</FormLabel>

              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={_allRegionsSelected}
                      onChange={(e) => {
                        setAllRegionsFilter(e.target.checked);
                      }}
                    />
                  }
                  label="Any region"
                />
              </MenuItem>
              <Select
                multiple
                displayEmpty
                value={selectedRegions}
                onChange={handleRegionChange}
                input={<OutlinedInput label="Tag" />}
                style={{ minWidth: 170, maxWidth: 170 }}
                renderValue={(selected) => {
                  let regionTypes = [];
                  for (let region of RegionsLookup) {
                    if (selected.includes(region["codeName"])) {
                      regionTypes.push(region["shortName"]);
                    }
                  }

                  if (regionTypes.length === RegionsLookup.length) {
                    regionTypes = ["All regions"];
                  } else if (regionTypes.length > 2) {
                    regionTypes = ["Many selected..."];
                  } else if (regionTypes.length === 0) {
                    regionTypes = ["No regions!"];
                  }

                  return regionTypes.join("|");
                }}
              >
                {RegionsLookup.sort((a, b) =>
                  a["longName"] > b["longName"] ? 1 : -1
                ).map((region) => (
                  <MenuItem key={region["codeName"]} value={region["codeName"]}>
                    <Checkbox
                      color="primary"
                      checked={_availableRoutesFilter[region["codeName"]]}
                    />
                    <ListItemText primary={region["shortName"]} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <MenuItem onClick={handleClose}>Ok</MenuItem>
            </Grid>
          </Grid></div>
      </Popover>
    </div>
  );

  function handleDataSourceChange(dataSource) {
    setKeyOnRoutesFilter('routeDataSource', dataSource)
  }

  function handleBunkerDataSourceChange(dataSource) {
    setKeyOnBunkersFilter('bunkerDataSource', dataSource)
  }
}
