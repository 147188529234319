import React, { useState } from 'react'
import Optimizations from './components/Optimizations'

const VEOptimizations = () => {

    return (
      <div className="px-4">
          <Optimizations />
     </div>
    );
}

export default VEOptimizations;
