import { useField } from "formik";
import { FieldContainer } from "./FieldContainer";

export function SelectField({ name, label, options, className }) {
    const [field] = useField(name);

    return <FieldContainer id={name} label={label} className={className} {...field}>
        <select id={name} className="text-base text-gray-700 leading-6 bg-white -ml-[5px] outline-none py-[1px]" {...field}>
            {options.map((o) => <option key={o.value} value={o.value}>{o.label}</option>)}
        </select>
    </FieldContainer>
}