import React, { useState, useEffect } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  DiscreteColorLegend,
} from "react-vis";
import { useRecoilState } from "recoil";
import { availableBunkersFilter } from "../../store/ui";
import SPGCIDisclaimer from "../SPGCIDisclaimer";


const BunkerSpotChart = ({ data }) => {
  const [_availableBunkersFilter, setAvailableBunkerFilter] = useRecoilState(availableBunkersFilter)
  const seriesData = [];
  data.map((row) => {
    let series = {};
    series.x = row.port;
    series.y = { HSFO: row.fuels.HSFO.price, LSFO: row.fuels.LSFO.price, MGO: row.fuels.MGO.price };
    seriesData.push(series);
  });

  const legendItems = ["HSFO", "LSFO", "MGO"]; 
  
  return (
    <div>
      <XYPlot xType="ordinal" width={700} height={400} xDistance={100}>
        <XAxis />
        <YAxis />
        {legendItems.map((yKey, index) => (
          <VerticalBarSeries
            key={yKey}
            data={seriesData.map((d) => ({ x: d.x, y: d.y[yKey] }))}
          />
        ))}
      </XYPlot>
      <DiscreteColorLegend
        items={legendItems}
        orientation="horizontal"
        height={80}
      />
    </div>
  );
};

export default BunkerSpotChart;
