import React from "react"
import RouteSelect from "../../components/RouteSelect/RouteSelect"
import { PriceTable } from "./PriceTable"
import { useRecoilValue } from "recoil";
import { selectableDataSources } from "../../store/ui";
import { DATA_SOURCE, DATA_SOURECE_CATEGORY } from "../../utils/Constants";
import { DataAttribution } from "./DataAttribution";

export function RoutePriceTable({data}) {
    const dataSources = useRecoilValue(selectableDataSources(DATA_SOURECE_CATEGORY.FREIGHT_RATES));
    const hasPlattsSource = dataSources.findIndex(x => x.value === DATA_SOURCE.PLATTS.name) >= 0

    return (
        <div>
            <div className="grid grid-cols-5">
                <div>
                </div>
                <RouteSelect index={0}/>
                <RouteSelect index={1}/>
                <RouteSelect index={2}/>
                <RouteSelect index={3}/>
            </div>
            <PriceTable data={data} hasPlattsSource={hasPlattsSource}/>
            <DataAttribution data={data} isTCE={false} />
        </div>
    )
}