import React, {Fragment, useState} from "react"
import Table from "@material-ui/core/Table"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableToolbar from "./TableToolbar"
import TableHead from "./TableHead"
import TableBody from "./TableBody"

function handleSelectAll(rows, selected, { setSelected }) {
    return () => {
        if (selected.length > 0) {
            setSelected([])
            return
        }

        setSelected(rows.map(({ id }) => id))
    }
}

function handleSelect(selected, { setSelected }) {
    return ({ id }) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((_id) => _id !== id))
            return
        }

        const _selected = [...selected, id]
        setSelected(_selected)
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
    title,
    columns,
    rows,
    order,
    orderBy,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    actions,
    onRowClick,
    noSort,
    dense
}) => {
    const [selected, setSelected] = useState([])
    const [_order, setOrder] = useState(order || "asc")
    const [_orderBy, setOrderBy] = useState(orderBy)
    const [_page, setPage] = useState(page || 0)
    const [_rowsPerPage, setRowsPerPage] = useState(rowsPerPage || 15)

    return (
        <Fragment>
            {title && <TableToolbar title={title} />}
            <TableContainer style={{overflowX: "hidden", backgroundColor: '#e5e7eb'}}>
                <Table>
                    <TableHead
                        columns={columns}
                        rowCount={rows.length}
                        order={_order}
                        setOrder={setOrder}
                        orderBy={_orderBy}
                        setOrderBy={setOrderBy}
                        selected={selected}
                        setSelectedAll={actions && handleSelectAll(rows, selected, { setSelected })}
                        noSort={noSort}
                    />
                    <TableBody
                        columns={columns}
                        rows={rows}
                        order={_order}
                        orderBy={_orderBy}
                        page={_page}
                        rowsPerPage={_rowsPerPage}
                        selected={selected}
                        setSelected={actions && handleSelect(selected, { setSelected })}
                        onRowClick={onRowClick}
                        noSort={noSort}
                        dense={dense}
                    />
                </Table>
            </TableContainer>
            {rowsPerPage && rowsPerPageOptions && <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [5, 15, 30]}
                component="div"
                count={rows.length}
                rowsPerPage={_rowsPerPage}
                page={_page}
                onChangePage={(_, n) => setPage(n)}
                onChangeRowsPerPage={(_, { key }) => setRowsPerPage(Number(key))}
            />}
        </Fragment>
    )
}
