import React, { useState, useEffect} from "react";
import { dollarFormat, separatorFormat, percentageFormat, mtFormat, kgFormat } from "../../../utils/Helpers";



export default function TceRow({title, record, keys}){

    const [showDetails, setShowDetails] = useState(false);
    const variableKeys = Object.keys(record)

    const effectSum = variableKeys.reduce((sum, x) => record[x] + sum, 0)

    const handleClick = (e) => {
        e.preventDefault()
        setShowDetails(!showDetails)
    }

    const cell = (item, bold = false, last=false) => {
        return (
            <div className={`flex ${last ? "" : "border-r border-gray-150"} border-dotted`}>
                <div className={`w-full p-1 ml-2 ${bold ? "font-medium" : ""}`}>
                    {item}
                </div>
            </div>
        )
    }

    return(
        <div className="overflow-x-auto border border-gray-150 rounded bg-white p-2">
            <div className={`grid grid-cols-3 p-1 text-ao-figma-text-gray ${showDetails ? "border-b border-gray-150" : ""}`}>
                    {cell(title)}
                    {cell(dollarFormat(effectSum))}
                    <div className="flex p-1 ml-2 col-span-1">
                        <button onClick={handleClick}>{showDetails ? "Hide" : "Details"}</button>
                    </div>
            </div>
            {showDetails && (
                <div className="flex flex-row">
                <div className="flex flex-col p-1 w-full pt-10">
                    <div className="overflow-x-auto p-2">
                        <div className="grid grid-cols-3 p-1 border-b text-ao-figma-text-gray">
                                {cell("Variable", true, true)}
                                {cell("Contribution", true, true)}
                        </div>
                    </div>
                    {variableKeys.map((key) => {
                        return (
                            <div className="overflow-x-auto border-b border-gray-150 p-2">
                                <div className="grid grid-cols-3 p-1 text-ao-figma-text-gray">
                                        {cell(key === "emissions" ? "CO2" : key)}
                                        {cell(dollarFormat(record[key]))}
                                </div>
                            </div>
                        )
                    })}
                </div>
                </div>
            )}
        </div>
    )

}