import { useField } from "formik";
import { FieldContainer } from "./FieldContainer";

export function SwitchField({ name, value, className, children }) {
    const [field, meta] = useField(name);

    const checked = value ? meta.value?.includes(value) : meta.value

    return <FieldContainer className={`flex-col justify-center ${className}`}>
        <label className={`inline-flex items-center cursor-pointer align-middle`}>
            <span className="text-base leading-6 text-ao-blue mr-4">{children}</span>
            <input type="checkbox" className="sr-only peer" {...field} value={value} checked={checked} />
            <div className={`relative w-8 h-4 border border-neutral-300 rounded-full peer ${checked ? "after:start-[-2px]" : "after:end-[-3px]"} peer-checked:after:bg-ao-blue after:content-[''] after:absolute after:top-[-3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all`}></div>
        </label>
    </FieldContainer>
};
