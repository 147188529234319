import React, { useState, useEffect } from "react";
import EmissionChart from "../../components/Charts/EmissionChart";
import { selectedEmissions } from "../../store/ui";
import { useRecoilValue } from "recoil";
import { getAllEmissions } from "../../store/api";
import { getRecoilPromise } from "recoil-nexus";
import { userSettings } from "../../store/ui";
import { EmissionsPriceTable } from "../PricingEngine/EmissionsPriceTable";
import { spgciStatus } from "../../store/integrations";
import { ProviderStatus } from "../../utils/Constants";
import { NoProviders } from "../PricingEngine/NoProviders";

// eslint-disable-next-line import/no-anonymous-default-export
const PEEmissions = () => {
  const _userSettings = useRecoilValue(userSettings);
  const _spgciStatus = useRecoilValue(spgciStatus);
  const [_vol, setVol] = useState(_userSettings.volatility);
  const [_liq, setLiq] = useState(_userSettings.liquidity);
  const _selectedEmissions = useRecoilValue(selectedEmissions);
  const [data, setData] = useState([unavailablePriceData()]);

  useEffect(() => {
    (async function () {
      const processedData = [];
      const apiData = await getRecoilPromise(getAllEmissions)
      if (apiData) {
        processedData.push({
          spotPrice: apiData.spotPrice,
          spotDate: apiData.priceDate,
          constructedSpot: false,
          forwardCurveData: apiData.forwardCurveMonthly,
          constructedCurve: apiData.constructedCurveMonthly,
          provider: apiData.dataProvider,
          providerData: { spotSymbol: apiData.spotSymbol,
                          forwardCurveCode: "CN2HZ",
                          spotDescr: "EEX EUA Eur/MT",
                          forwardCurveName: "EEX EUA Eur/MT",
                          spotMdc: "EU",
                          forwardMdc: "EU"},
          status: "available",
        });
        setData(processedData);
      } else {
        setData([unavailablePriceData("unavailable")]);
      }
    })();
  }, [_selectedEmissions]);

  const spgciActive = _spgciStatus === ProviderStatus.ACTIVE
  if(!spgciActive){
    return <NoProviders
              spgciFailed={!spgciActive}
              page={"emission pricing data"}
            />
  }

  return (
    <div className="flex flex-col">
    <div className="flex flex-row">
      <div className="h-full flex flex-col gap-y-2  border-r-2 border-gray-300">
        <EmissionsPriceTable data={data} />
      </div>
      <div className="h-full w-1/2">
        <EmissionChart volatility={_vol} liquidity={_liq} data={data}/>
      </div>
    </div>
    </div>
  );
};

export default PEEmissions;

function unavailablePriceData(status) {
  return {
    spotPrice: null,
    spotDate: "N/A",
    constructedSpot: false,
    forwardCurveData: Array.from({ length: 12 }, (_) => ({})),
    constructedCurve: false,
    providerData: {},
    status,
  };
}
