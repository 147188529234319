import React from "react";
import Tooltip from "react-png-tooltip"

const SPGCIDisclaimer = ({ dataText = "SPGCI Data", flipX, flipY}) => {
  return (
    <div className="flex flex-row items-baseline">
      <Tooltip tooltip={<DisclaimerButton/>}>
      <span>
        S&P Global Commodity Insights, ©2024 by S&P Global Inc. Use of the S&P
        Global Commodity Insights (“SPGCI”) services constitutes acceptance of
        the terms and conditions contained in the SPGCI Master Subscription
        Agreement. Reproduction or retransmission of SPGCI Data
        in any form is prohibited except with the prior written permission of
        SPGCI. SPGCI does not guarantee the accuracy, adequacy, completeness or
        availability of any information and is not responsible for any errors or
        omissions, regardless of the cause or for the results obtained from the
        use of such information. SPGCI DISCLAIMS ANY AND ALL EXPRESS OR IMPLIED
        WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE. In no event
        shall SPGCI be liable for any direct, indirect, special or consequential
        damages, costs, expenses, legal fees, or losses (including lost income
        or lost profit and opportunity costs) in connection with subscriber’s or
        others’ use of SPGCI Data.
        <img 
          src="https://ao-general-public.s3.amazonaws.com/Spg+comm+insights+platts+horz+red+rev+rgb+c.svg" 
          alt="SPGCI Logo"
          className="w-1/5 h-1/5 bg-gray-800 mx-auto"
        />
      </span>
      </Tooltip>
    </div>
  );
};

export default SPGCIDisclaimer;

function DisclaimerButton({}){
  return (
    <button className="">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 -0.5 30 30"
        strokeWidth="1.5"
        stroke="gray"
        className="w-5 h-5 inline-block ml-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
        />
      </svg>
    </button>

  )
}