
import { MenuItem, Select } from "@material-ui/core"
import { useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { selectdVoyagePortfolio, selectdVoyagePortfolioId } from "../../../store/ui"
import { voyagePortFolios } from "../../../store/voyagePortfolios"
import { VoyagePortfolioManageDialog } from "./VoyagePortfolioManageDialog"

const manageOptionValue = "__manage__"
const allVOyagesOptionValue = ""

export function VoyagePortfolioSelect({ onSelect }) {
    const portfolios = useRecoilValue(voyagePortFolios)
    const [_selectedPortfolioId, setSelectedPortfolioId] = useRecoilState(selectdVoyagePortfolioId)

    const [_portfolioModalOpen, setPortfolioModalOpen] = useState(false)

    const onChangeHandler = (event) => {
        const value = event.target.value
        if (value === manageOptionValue) {
            setPortfolioModalOpen(true)
        } else {
            setSelectedPortfolioId(value === allVOyagesOptionValue ? null : value)
            onSelect(value === allVOyagesOptionValue ? null : getPortfolioByValue(value))
        }
    }
    return (
        <>
            <Select
                onChange={onChangeHandler}
                displayEmpty
                renderValue={(value) => value ? getPortfolioByValue(value).portfolioName : "All voyages"}
                value={_selectedPortfolioId ? _selectedPortfolioId : allVOyagesOptionValue}
            >
                <MenuItem key={"manage"} value={manageOptionValue}>Manage portfolios</MenuItem>
                <MenuItem key={"all"} value={allVOyagesOptionValue}>All voyages</MenuItem>
                {
                    portfolios.map((p) => {
                        return <MenuItem key={p.id} value={p.id}>{p.portfolioName}</MenuItem>
                    })
                }
            </Select>
            <VoyagePortfolioManageDialog open={_portfolioModalOpen} onClose={() => setPortfolioModalOpen(false)} voyagesToAdd={[]}></VoyagePortfolioManageDialog>
        </>
    )

    function getPortfolioByValue(selection) {
        return portfolios.find((p) => p.id === selection)
    }
}