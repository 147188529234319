
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Box, ThemeProvider, ToggleButton, ToggleButtonGroup } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import "flatpickr/dist/themes/light.css"
import { useFormik, yupToFormErrors } from "formik"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useState } from "react"
import Flatpickr from "react-flatpickr"
import { useRecoilState, useRecoilValue } from "recoil"
import { getRecoil, getRecoilPromise } from "recoil-nexus"
import * as Yup from "yup"
import { getPortListByShipType, getRouteParamsIn, getRouteParamsUnpriced } from "../../../store/api"
import { loadingIndicator, userSettings } from "../../../store/ui"
import { SHIP_TYPES, getSelectableVessels, userCustomisedVessels, userModelVessels } from "../../../store/vessels"
import { SmallerTextTheme as theme } from "../../../theme"
import { DATA_SOURCE, DATA_SOURECE_CATEGORY, FreightTypes } from "../../../utils/Constants"
import { usdFormatter, dollarFormat, ceilThousands } from "../../../utils/Helpers"
import { calculateOutputFieldsFromVoyage, getUpdatedVoyage, getBunkerPortsForShipType, getOpenPortsForShipType, getPortsForShipAndRegion, customRoutes } from "./VoyageEngineLogic"
import "./modal.css"
import { ModelVEsselOptionsPopup } from "../../../components/ModelVesselOptionsPopup/ModelVesselOptionsPopup"
import { ButtonBase, FormLabel } from "@material-ui/core"
import { DataAttribution } from "../../PricingEngine/DataAttribution"
import { DataSourceSelect } from "../../../components/DataSourceSelect/DataSourceSelect"


const ViewModes = {
    INITIAL: "INITIAL",
    CURRENT: "CURRENT"
}

const contentModes = {
    voyageSummary: "Voyage Summary",
    bunkerSummary: "Bunker Summary",
    financialSummary: "Financial Summary"
}

export const VoyageEngineCostingModal = withDefaultFields(({ voyage, modalOpen, closeModal, onSave, isEdit, defaultFields, validationSchema }) => {

    const [_ballastOnly, _setBallastOnly] = useState(false)
    const [_simpleLayout, _setSimpleLayout] = useState(false)
    const [_viewMode, setViewMode] = useState(isEdit ? ViewModes.CURRENT : ViewModes.INITIAL)
    const [validationWarningOpen, setValidationWarningOpen] = useState(false)
    const _userSettings = getRecoil(userSettings)
    const [_freightRatesSource, setFreightRatesSource] = useState(_userSettings.frightRatesSource)
    const [_bunkerPricesSource, setBunkerPricesSource] = useState(_userSettings.bunkerPricesSource)
    const [_emissionsPricesSource, setEmissionsPricesSource] = useState(_userSettings.emissionsPricesSource)

    const [fields, setFields] = useState(defaultFields)
    const [voyageOutputFields, setVoyageOutputFields] = useState({})
    const [bunkerOutputFields, setBunkerOutputFields] = useState({})
    const [_loading, setLoading] = useRecoilState(loadingIndicator)

    const [modelVesselOptionsOpen, setModelVesselOptionsOpen] = useState(false)
    const _modelVessels = useRecoilValue(userModelVessels)

    const _plattsRatesTickerSource = DATA_SOURCE.isPlatts(_freightRatesSource)
    const _plattsBunkerTickerSource = DATA_SOURCE.isPlatts(_bunkerPricesSource)
    const _plattsEmissionsTickerSource = DATA_SOURCE.isPlatts(_emissionsPricesSource)

    const formik = useFormik({
        initialValues: validationSchema.cast(voyage),
        validate: async values => {
            const outputFields = await calculateOutputFieldsFromVoyage(formik.values)

            return validationSchema
                .validate(values, { abortEarly: false, context: outputFields })
                .then(() => ({}))
                .catch(err => {
                    return yupToFormErrors(err)
                })
        },
        onSubmit: values => {
            onSave(values)
        }
    })

    const isAnyUntouched = fieldNames => fieldNames && fieldNames.length > 0 && fieldNames.some(fieldName => !formik.getFieldMeta(fieldName).touched)
    const getLabelSuffix = (rateFieldNames, bunkerFieldNames, emissionsFiledNames, rawValue = false) => {
        const cls = rawValue ? "text-SPGCI-color font-bold" : "text-SPGCI-constructed italic"
        let suffix = (_plattsRatesTickerSource && isAnyUntouched(rateFieldNames))
            || (_plattsBunkerTickerSource && isAnyUntouched(bunkerFieldNames)) ? <span className={cls}> {rawValue ? '*' : '**'}</span> : "";

        if (!suffix && _plattsEmissionsTickerSource && isAnyUntouched(emissionsFiledNames)) {
            suffix = rawValue || voyageOutputFields.emissionsExpenses !== 0 ? <span className={cls}> {rawValue ? '*' : '**'}</span> : "";
        }

        return suffix;
    }

    const recalculateOutputs = useCallback(async () => {
        return await calculateOutputs(formik.values, defaultFields, _viewMode)
    }, [formik.values, defaultFields, _viewMode])

    useEffect(() => {
        recalculateOutputs()
    }, [recalculateOutputs])


    async function setFieldValue(field, value, markAsTouched = true) {

        if (field.mapNewValueToVoyage) {
            const updatedVoyage = await field.mapNewValueToVoyage(formik.values, value, { freightRatesSource: _freightRatesSource, bunkerPricesSource: _bunkerPricesSource, emissionsPricesSource: _emissionsPricesSource })
            await formik.setValues(validationSchema.cast(updatedVoyage))
            await formik.validateForm()

            formik.setTouched({ ...formik.touched, [field.name]: markAsTouched })
            if (field.name === "routeShortName") {
                formik.setTouched({ ...formik.touched, freightRate: false, cargoQuantity: false })
            }
        } else {
            await formik.setFieldValue(field.name, value)

            formik.setTouched({ ...formik.touched, [field.name]: markAsTouched })
        }
    }

    function TextField(field, label) {
        const fieldName = field.name;
        const value = formik.values[fieldName];

        return (
            <div className=" p-1">
                <label className="text-sm text-ao-blue">
                    {label}
                </label>
                <div className="relative">
                    <input
                        className={`block w-full ${isEdit ? "w-[200px]" : "min-w-[245px]"} border-b border-r px-1 py-1 pr-8 focus:outline-none focus:ring focus:border-blue-300 bg-white`}
                        type="text"
                        size="small"
                        name={fieldName}
                        value={value == null ? "" : String(value)}
                        onChange={e => {
                            const value = e.target.value;
                            setFieldValue(field, value);
                        }}
                        placeholder="Client name"
                        readOnly={field.readOnly}
                    />
                </div>
                {formik.errors[fieldName] && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors[fieldName]}</p>
                )}
            </div>
        )
    }

    function PortConfirmCheckbox(field, label) {
        const fieldName = field.name;
        const value = formik.values[fieldName];

        return (
            <div className=" w-full min-h-full p-1 mb-[.66rem]">
                <label className="text-sm text-ao-blue">
                    {label}
                </label>
                <div className="relative flex flex-row justify-center">
                    <input
                        className="text-center w-full border-b border-r focus:outline-none bg-white"
                        type="checkbox"
                        size="small"
                        name={fieldName}
                        value={value == null ? "" : String(value)}
                        onChange={e => {
                            const value = e.target.value;
                            setFieldValue(field, value);
                        }}
                        readOnly={field.readOnly}
                    />
                </div>
                {formik.errors[fieldName] && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors[fieldName]}</p>
                )}
            </div>
        )
    }

    function MinMaxTextField(field, label, unit) {
        const disabled = field.ballastOnly && _ballastOnly;

        if (disabled) return <></>;

        const labelMin = field.min ? ` (Min: ${field.min})` : "";
        const fieldName = field.name;
        const value = formik.values[fieldName];

        return (
            <div className=" p-1">
                <label className="text-sm text-ao-blue">
                    {label}
                    {labelMin && <span className="text-gray-500">{labelMin}</span>}
                </label>
                <div className="relative">
                    <input
                        className={`block ${isEdit ? "min-w-[200px]" : "min-w-[245px]"} border-r border-b focus:outline-none focus:ring focus:border-blue-300 bg-white`}
                        type="number"
                        size="small"
                        name={fieldName}
                        value={value == null ? "" : String(value)}
                        onChange={e => {
                            const value = e.target.value;
                            setFieldValue(field, isNaN(value) ? null : Number(value));
                        }}
                        pattern="^\d*(\.\d{0,2})?$"
                        step="any"
                        readOnly={field.readOnly}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <span className="text-gray-400">{unit}</span>
                    </div>
                </div>
                {formik.errors[fieldName] && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors[fieldName]}</p>
                )}
            </div>
        )
    }

    function DateField(field, label) {
        const disabled = field.ballastOnly && _ballastOnly;
        if (disabled) return <></>;

        const fieldName = field.name;
        const fieldDate = formik.values[fieldName]

        const flatpickrConfig = {
            theme: "light",
            dateFormat: "d/m/y",
            defaultDate: field.readOnly ? fieldDate : "today",
        }


        return (
            <div className="bg-white p-1">
                <label className="text-sm text-ao-blue">{label}</label>
                <div className="bg-white">
                    <Flatpickr
                        options={flatpickrConfig}
                        onChange={d => {
                            const tempDate = new Date(d[0])
                            setFieldValue(field, tempDate)
                        }
                        }
                        className={`${isEdit ? "min-w-[200px]" : "min-w-[245px]"} border-b border-r ${formik.errors[fieldName] ? "text-red-500" : "text-ao-figma-text-gray"}`}
                        disabled={field.readOnly}
                    />
                </div>
            </div>
        )
    }

    function AutocompleteSelectField(field, label) {
        const disabled = field.ballastOnly && _ballastOnly;

        if (disabled) return <></>;

        const fieldName = field.name;
        const value = formik.values[fieldName]
        const selectValue = field.valueKey ? value[field.valueKey] : value
        const getCaption = field.getCaption || (option => field.captionKey ? option[field.captionKey] : option)

        let options = field.options
        if (label === "Route") {
            options = []
            const optionGroups = [...new Set(field.options.map(x => x.group))]
            for (const group of optionGroups) {
                const filtered = field.options.filter(x => x.group === group)
                options.push([group, filtered])
            }

        }

        return (
            <div className="p-1">
                <label className="text-sm text-ao-blue pl-1">{label}</label>
                <div className=" ">
                    <select
                        className={`block ${isEdit ? "w-[200px]" : "w-[245px]"} border-b border-r  focus:outline-none focus:ring focus:border-blue-300`}
                        onChange={ev => setFieldValue(field, ev.target.value)}
                        value={selectValue}
                        disabled={field.readOnly}
                        id={field.readOnly ? "dropdown-disabled" : ""}
                    >
                        {label === "Route" ?

                            (
                                options.map(optionGroup => {
                                    return <optgroup key={optionGroup[0]} label={optionGroup[0]}>
                                        {
                                            optionGroup[1].map(x => {
                                                const value = field.optionValueKey ? x[field.optionValueKey] : x
                                                const caption = getCaption(x)

                                                return (<option key={value} value={value}>
                                                    {caption}
                                                </option>)
                                            })
                                        }

                                    </optgroup>
                                })
                            ) : (

                                field.options.map(option => {
                                    const value = field.optionValueKey ? option[field.optionValueKey] : option
                                    const caption = getCaption(option)

                                    return (<option key={value} value={value}>
                                        {caption}
                                    </option>)
                                })
                            )

                        }

                    </select>
                </div>
                {formik.errors[fieldName] && (
                    <p className="text-red-500 text-sm ">{formik.errors[fieldName]}</p>
                )}
            </div>
        )
    }

    function LowCarbonSelectField(field, label) {
        const disabled = _ballastOnly;

        if (disabled) return <></>;

        const value = "None"

        const options = ["None", "LNG", "MeOH", "NH3", "H2", "Bio", "KwH"]

        return (
            <div className="p-1">
                <label className="text-sm text-ao-blue ">{label}</label>
                <div className="relative">
                    <select
                        className={`block ${isEdit ? "min-w-[200px]" : "min-w-[245px]"} border-b border-r w-full  focus:outline-none focus:ring focus:border-blue-300`}
                        defaultValue={value}
                        disabled={isEdit}
                        id={isEdit ? "dropdown-disabled" : ""}
                    >
                        {options.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }

    const outputFields = voyageOutputFields


    function OutputField(fieldKeyOrFn, label, unit, cost, inModal, options = {}) {
        const { ballastOnly = false, highlightChanges = true, valueFormatter = v => v, unChangedValue = undefined } = options;

        const disabled = ballastOnly && _ballastOnly;
        if (disabled) return null;

        const valueGetter = typeof fieldKeyOrFn === "function" ? fieldKeyOrFn : outputFields => outputFields[fieldKeyOrFn];

        const initialValue = valueGetter(voyageOutputFields);
        const value = valueGetter(outputFields);

        let bigger = highlightChanges && initialValue < value;
        let smaller = highlightChanges && initialValue > value;

        if (highlightChanges && typeof value === "string") {
            bigger = false;
            smaller = false;
        }

        let fontColor = "text-ao-figma-text-gray"
        let titleColor = "text-ao-blue"

        if (inModal) {
            fontColor = "text-gray-400"
            titleColor = "text-gray-400"
        } else if (cost) {
            fontColor = bigger ? "text-red-500" : (smaller ? "text-green-500" : "text-ao-figma-text-gray")
        } else {
            fontColor = bigger ? "text-green-500" : (smaller ? "text-red-500" : "text-ao-figma-text-gray")
        }

        return (
            <div className={`${isEdit ? "max-w-[200px]" : "max-w-[245px]"} w-full grow p-1`}>
                <label className={`text-sm ${titleColor}`}>
                    {label}
                </label>
                <div className="relative">
                    <div className=" w-full flex flex-row justify-between">
                        <span className={`${isEdit ? "min-w-[200px]" : "min-w-[245px]"}  ${fontColor} border-b ${inModal && "border-r"} `}>{valueFormatter(value)}</span>

                        <span className={`absolute right-0 text-gray-400`}>{unit}</span>

                    </div>
                </div>

            </div>
        )
    }


    const hasBunkeringDecisions = (
        bunkerOutputFields.purchasedQtyIFO > 0
        || bunkerOutputFields.purchasedQtyMGO > 0
        || bunkerOutputFields.purchasedQtyBIO > 0
        || bunkerOutputFields.purchasedQtyLNG > 0
        || bunkerOutputFields.purchasedQtyMEOH > 0
        || bunkerOutputFields.purchasedQtyNH3 > 0
        || bunkerOutputFields.bunkersNeededBIO > 0
        || bunkerOutputFields.bunkersNeededLNG > 0
        || bunkerOutputFields.bunkersNeededMEOH > 0
        || bunkerOutputFields.bunkersNeededNH3 > 0
        || (bunkerOutputFields.bunkerPorts && (
            bunkerOutputFields.bunkerPorts.length > 1 ||
            bunkerOutputFields.bunkerPorts[0] !== formik.values.bunkerPort))
    )

    const isCurrentView = _viewMode === ViewModes.CURRENT

    const hasBunkerData = Object.keys(bunkerOutputFields).length > 0
    Object.keys(fields).forEach(f => fields[f].readOnly = hasBunkerData)
    const isModelVessel = formik.values.vessel.modelVessel


    const row = (item1, item2, item3, item4) => {
        return (
            <>
                <div className="flex">
                    {item1}
                </div>
                <div className="flex">
                    {item2}
                </div>
                <div className="flex">
                    {item3}
                </div>
                <div className="flex">
                    {item4}
                </div>
            </>
        )
    }

    let fieldsLayout = <></>
    if (Object.keys(fields).length === 0) {
        fieldsLayout = <></>
    } else {
        fieldsLayout = (
            <div className="overflow-x-auto">
                {/* Grid */}
                <div className="grid grid-cols-4 gap-0">
                    {/* Row 0 */}
                    <div className="flex">
                        <div className="text-lg font-bold text-ao-blue border-l border-r border-gray-150 w-full p-1">Vessel</div>
                    </div>
                    <div className="flex">
                        <div className="text-lg font-bold text-ao-blue border-l border-r border-gray-150 w-full p-1">Routing</div>
                    </div>
                    <div className="flex">
                        <div className="text-lg font-bold text-ao-blue border-l border-r border-gray-150 w-full p-1">Timing</div>
                    </div>
                    <div className="flex">
                        <div className="text-lg font-bold text-ao-blue border-l border-r border-gray-150 w-full p-1">Bunkers & Emissions</div>
                    </div>

                    {/* Row 1 */}
                    <div className="flex">
                        <div className="flex-grow">{AutocompleteSelectField(fields.vessel, "Vessel")}</div>
                        {/* {isModelVessel && <div className="">
                            <ButtonBase onClick={() => setModelVesselOptionsOpen(true)}>
                                <MoreVertIcon />
                            </ButtonBase>
                            <ModelVEsselOptionsPopup open={modelVesselOptionsOpen} onClose={() => setModelVesselOptionsOpen(false)} defaultOptions={getModelVesselOptions(formik.vaues)} onOk={handleModelNewVesselOptions}></ModelVEsselOptionsPopup>
                        </div>} */}
                    </div>
                    <div className="flex">
                        {AutocompleteSelectField(fields.routeShortName, "Route")}
                    </div>
                    <div className="flex">
                        {DateField(fields.openDate, "Vessel Open Date")}
                    </div>
                    <div className="flex">
                        {AutocompleteSelectField(fields.bunkerPort, "Bunkering Port")}
                    </div>


                    {/* Row 2 */}
                    {row(
                        MinMaxTextField(fields.timeCharterCost, "Time Charter Cost", "$/day"),
                        AutocompleteSelectField(fields.loadingPort, "Load Port 1"),
                        AutocompleteSelectField(fields.openPort, "Vessel Open Port"),
                        MinMaxTextField(fields.ifoBunkerPrice, <div>{`IFO Price (${formik.values["ifoPricePort"]})`}{getLabelSuffix(null, ['ifoBunkerPrice'], null, true)}</div>, "$")
                    )}


                    {/* Row 3 */}
                    {row(
                        AutocompleteSelectField(fields.freightType, "Freight Type"),
                        AutocompleteSelectField(fields.dischargePort, "Discharge Port 1"),
                        AutocompleteSelectField(fields.canalConstraints, "Canal Constraints"),
                        MinMaxTextField(fields.mgoBunkerPrice, <div>{`MGO Price(${formik.values["mgoPricePort"]})`}{getLabelSuffix(null, ['mgoBunkerPrice'], null, true)}</div>, "$")
                    )}

                    {/* Row 4 */}
                    {row(
                        MinMaxTextField(fields.freightRate, <div>Freight Rate{getLabelSuffix(["freightRate"], null, null, true)}</div>, fields.freightRate.unitFunc(formik.values.freightType)),
                        MinMaxTextField(fields.portCosts, "Load Port Costs", "$"),
                        MinMaxTextField(fields.ballastSpeed, "Ballast Speed", "knots"),
                        MinMaxTextField(fields.co2Price, <div>CO2 Price{getLabelSuffix(null, null, ['co2Price'], true)}</div>, "$")
                    )}

                    {/* Row 5 */}
                    {row(
                        MinMaxTextField(fields.commission, "Commission", "%"),
                        MinMaxTextField(fields.portCosts2, "Discharge Port Costs", "$"),
                        MinMaxTextField(fields.ladenSpeed, "Laden Speed", "knots"),
                        OutputField("bunkersNeededIFO", "Bunkers Needed (IFO)", "MT", false, true, { valueFormatter: ceilThousands.format })
                    )}

                    {/* Row 6 */}
                    {row(
                        MinMaxTextField(fields.taxes, "Taxes & Misc.", "$"),
                        MinMaxTextField(fields.canalCostsAll, "Canal Costs", "$"),
                        MinMaxTextField(fields.seaMargin, "Sea Margin", "%"),
                        OutputField("bunkersNeededMGO", "Bunkers Needed (MGO)", "MT", false, true, { valueFormatter: ceilThousands.format })
                    )}

                    {/* Row 7 */}
                    {row(
                        AutocompleteSelectField(fields.cargo, "Cargo"),
                        MinMaxTextField(fields.loadDays, "Load Days", "days"),
                        DateField(fields.laycanStart, "Laycan Start"),
                        OutputField("co2Emissions", "C02 Emissions", "T", false, true)
                    )}

                    {/* Row 8 */}
                    {row(
                        MinMaxTextField(fields.cargoQuantity, <div>Quantity{getLabelSuffix(["cargoQuantity"], null, null, true)}</div>, "MT"),
                        MinMaxTextField(fields.dischargeDays, "Discharge Days", "days"),
                        DateField(fields.laycanEnd, "Laycan End"),
                        MinMaxTextField(fields.robIFO, "ROB at Open (IFO)", "MT")
                    )}


                    {/* Row 9 */}
                    {row(
                        MinMaxTextField(fields.cargoMinQuantity, "Min Quantity", "MT"),
                        MinMaxTextField(fields.waitingDays, "Waiting Days", "days"),
                        OutputField("loadETA", "ETA Load Port", "", false, true, { valueFormatter: v => DateTime.fromJSDate(v).toFormat("dd/MM/yyyy") }),
                        MinMaxTextField(fields.robMGO, "ROB at Open (MGO)", "MT")
                    )}


                    {/* Row 10 */}
                    {row(
                        MinMaxTextField(fields.cargoMaxQuantity, "Max Quantity", "MT"),
                        MinMaxTextField(fields.canalDays, "Canal Days", "days"),
                        OutputField("nextOpenDate", "Next Open Date", "", false, true, { valueFormatter: v => DateTime.fromJSDate(v).toFormat("dd/MM/yyyy") }),
                        LowCarbonSelectField("", "Low Carbon Fuels")
                    )}
                </div>
            </div>
        )
    }

    let outputLayout = <></>

    outputLayout = (

        <div className="overflow-x-auto border border-gray-150 rounded w-4/5 mx-auto p-2 bg-blue-50">
            {/* Grid */}
            <div className="grid grid-cols-1 gap-0">

                {/* Row 0 */}
                <div className="text-lg font-bold text-ao-blue  w-full p-1">
                    Voyage Calculations
                </div>

                {/* Row 1 */}
                <div className="flex">
                    {OutputField("cii", "CII", "", false, false)}
                </div>

                {/* Row 2 */}
                <div className="flex">
                    {OutputField("duration", "Voyage Length", "days", false, false, { valueFormatter: roundIT })}
                </div>

                {/* Row 3 */}
                <div className="flex">
                    {OutputField("totalRevenues", <div>Total Revenues{getLabelSuffix(["freightRate", "cargoQuantity"])}</div>, "", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 4 */}
                <div className="flex">
                    {OutputField("bunkerExpenses", <div>Bunker Costs{getLabelSuffix(null, ['ifoBunkerPrice', 'mgoBunkerPrice'])}</div>, "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 5 */}
                <div className="flex">
                    {OutputField("emissionsExpenses", <div>Emissions Costs{getLabelSuffix(null, null, ['co2Price'])}</div>, "", true, false, { valueFormatter: usdFormatter.format })}
                </div>
                {/* Row 6 */}
                <div className="flex">
                    {OutputField("portExpenses", "Port & Other Costs", "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 7 */}
                <div className="flex">
                    {OutputField("totalExpenses", <div>Total Costs{getLabelSuffix(null, ['ifoBunkerPrice', 'mgoBunkerPrice'], ['co2Price'])}</div>, "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 8 */}
                <div className="flex">
                    {OutputField("tce", <div>TCE{getLabelSuffix(["freightRate", "cargoQuantity"], ['ifoBunkerPrice', 'mgoBunkerPrice'], ['co2Price'])}</div>, "$/day", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 9 */}
                <div className="flex">
                    {OutputField("profitLossPerDay", <div>Profit & Loss (per day){getLabelSuffix(["freightRate", "cargoQuantity"], ['ifoBunkerPrice', 'mgoBunkerPrice'], ['co2Price'])}</div>, "$/day", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 10 */}
                <div className="flex">
                    {OutputField("profitLoss", <div>Profit & Loss (voyage){getLabelSuffix(["freightRate", "cargoQuantity"], ['ifoBunkerPrice', 'mgoBunkerPrice'], ['co2Price'])}</div>, "", false, false, { valueFormatter: usdFormatter.format })}
                </div>
            </div>
        </div>
    )

    let bunkersView = <></>

    if (hasBunkeringDecisions && isCurrentView) {
        bunkersView = (
            <div className="overflow-x-auto border border-gray-150 rounded">
                {/* Grid */}
                <div className="grid grid-cols-1 gap-0">
                    {/* Row 0 */}
                    <div className="text-lg font-bold text-ao-figma-text-gray  w-full bg-red-50 p-1">Bunker Decisions</div>
                    {/* Row 1 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededIFO > 0 && OutputField("averagePriceIFO", "Average price (IFO)", "$", true, false)}
                    </div>

                    {/* Row 2 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededMGO > 0 && OutputField("averagePriceMGO", "Average price (MGO)", "$", true, false)}
                    </div>

                    {/* Row 3 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededLNG > 0 && OutputField("averagePriceLNG", "Average price (LNG)", "$", true, false)}
                    </div>

                    {/* Row 4 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededMEOH > 0 && OutputField("averagePriceMEOH", "Average price (MeOH)", "$", true, false)}
                    </div>

                    {/* Row 5 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededNH3 > 0 && OutputField("averagePriceNH3", "Average price (NH3)", "$", true, false)}
                    </div>

                    {/* Row 6 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededBIO > 0 && OutputField("averagePriceBIO", "Average price (BIO)", "$", true, false)}
                    </div>

                    {/* Row 7 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededLNG > 0 && OutputField("bunkersNeededLNG", "Bunkers Needed (LNG)", "mt", true, false)}
                    </div>

                    {/* Row 8 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededMEOH > 0 && OutputField("bunkersNeededMEOH", "Bunkers Needed (MeOH)", "mt", true, false)}
                    </div>

                    {/* Row 9 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededNH3 > 0 && OutputField("bunkersNeededNH3", "Bunkers Needed (NH3)", "mt", true, false)}
                    </div>

                    {/* Row 10 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkersNeededBIO > 0 && OutputField("bunkersNeededBIO", "Bunkers Needed (BIO)", "mt", true, false)}
                    </div>

                    {/* Row 11 */}
                    <div className="flex bg-red-50">
                        {outputFields.robLNG > 0 && OutputField("robLNG", "ROB (LNG)", "mt", true, false)}
                    </div>

                    {/* Row 12 */}
                    <div className="flex bg-red-50">
                        {outputFields.robMEOH > 0 && OutputField("robMEOH", "ROB (MeOH)", "mt", true, false)}
                    </div>

                    {/* Row 13 */}
                    <div className="flex bg-red-50">
                        {outputFields.robNH3 > 0 && OutputField("robNH3", "ROB (NH3)", "mt", true, false)}
                    </div>

                    {/* Row 14 */}
                    <div className="flex bg-red-50">
                        {outputFields.robBIO > 0 && OutputField("robBIO", "ROB (BIO)", "mt", true, false)}
                    </div>

                    {/* Row 15 */}
                    <div className="flex bg-red-50">
                        {outputFields.bunkerPorts && outputFields.bunkerPorts.length > 1 && (
                            <>
                                {OutputField("bunkerPorts", "Additional Bunkering Ports", "", false, false, { valueFormatter: v => v?.slice(1).join(", ") || "" })}
                                {OutputField("distanceDifferencePc", "Additional Distance", "", true, false)}
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }


    const ticker = (
        <div className="overflow-x-auto w-full">
            {/* Grid */}
            <div className="grid grid-cols-4 gap-2 w-full">
                {/* Row 0 */}
                <div className="flex flex-col border border-gray-150 rounded">
                    <div className="text-ao-blue text-sm p-1">Freight rates</div>
                    <div className="grid grid-cols-5 gap-0 p-1">
                        <div className="text-ao-blue text-xs flex items-center justify-center">Source</div>
                        <DataSourceSelect
                            className="flex-none"
                            category={DATA_SOURECE_CATEGORY.FREIGHT_RATES}
                            value={_freightRatesSource}
                            onChange={e => {
                                const source = e.target.value;
                                setFreightRatesSource(source);
                                updateRoute(formik, source, fields.routeShortName, setFieldValue);
                            }} />
                    </div>
                    {tickerCapsule(formik.values["freightPriceData"], "Rate", false)}
                </div>
                <div className="col-span-2 flex flex-col border border-gray-150 rounded">
                    <div className="text-ao-blue text-sm p-1">Bunker prices</div>
                    <div className="grid grid-cols-2 gap-2 w-full">
                        <div className="grid grid-cols-5 gap-0 p-1">
                            <div className="text-ao-blue text-xs flex items-center justify-center">Source</div>
                            <DataSourceSelect
                                className="flex-none"
                                category={DATA_SOURECE_CATEGORY.BUNKER_PRICES}
                                value={_bunkerPricesSource}
                                onChange={async e => {
                                    setBunkerPricesSource(e.target.value)
                                    await updateBunkerPrices(formik, e.target.value, setFieldValue);
                                }} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 w-full">
                        <div className="flex">
                            {tickerCapsule(formik.values["ifoPriceData"], "IFO", true)}
                        </div>
                        <div className="flex">
                            {tickerCapsule(formik.values["mgoPriceData"], "MGO", true)}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col border border-gray-150 rounded">
                    <div className="text-ao-blue text-sm p-1">Emissions prices</div>
                    <div className="grid grid-cols-5 gap-0 p-1">
                        <div className="text-ao-blue text-xs flex items-center justify-center">Source</div>
                        <DataSourceSelect
                            className="flex-none"
                            category={DATA_SOURECE_CATEGORY.EMISSIONS_PRICES}
                            value={_emissionsPricesSource}
                            onChange={async e => {
                                const source = e.target.value;
                                setEmissionsPricesSource(source);
                                await updateEmissionsPrices(formik, source, setFieldValue);
                            }} />
                    </div>
                    {/* <div className="grid grid-cols-3 gap-2 w-full"> */}
                    <div className="flex">
                        {tickerCapsule(formik.values["emissionData"][DATA_SOURCE.isPlatts(_emissionsPricesSource) ? 'platts' : 'default'], "CO2", false)}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>

    )

    function tickerCapsule(allData, label, bunker, datasourceSelect) {

        let data

        if (bunker) {
            data = _plattsBunkerTickerSource ? allData.platts : allData.bunkerex
        } else {
            data = allData
        }

        const spotPrice = isNaN(data.spotPrice) ? "N/A" : dollarFormat(data.spotPrice, 2)
        const spotSymbol = `Spot - ${data.spotSymbol}`
        const futurePrice = isNaN(data.futurePrice) || data.missingForwardPrice ? "N/A" : dollarFormat(data.futurePrice, 2)
        const futureSymbol = data.missingForwardPrice ? 'Forward' : `Forward - ${data.futureSymbol}`

        const classFormatter = (showPlatts, isPlatts, isConstructed, missingPrice) => {
            if (missingPrice) {
                return "text-ao-figma-gray italic"
            }

            if (isPlatts) {
                if (isConstructed) {
                    return "text-SPGCI-constructed italic"
                }
                return "text-SPGCI-color font-bold"
            }

            if (isConstructed) {
                return "text-gray-400 italic"
            }

            return "text-ao-figma-gray font-bold"
        }

        const spotClass = classFormatter(_plattsBunkerTickerSource, (data.provider === "SPGCI"), data.constructedSpot)
        const futureClass = classFormatter(_plattsBunkerTickerSource, (data.provider === "SPGCI"), data.constructedCurve, data.missingForwardPrice)

        const column = (top, bottom, spotDesc, futureDesc, futureDate, price) => {
            return (
                <div className="grid grid-cols-1 gap-1 text-right w-full p-0.5 items-center">
                    <div className="flex border-b pb-1 items-center">
                        <span
                            className={`transititext-primary ${price ? spotClass : "text-ao-figma-text-gray"} text-primary transition duration-5 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700`}
                            data-te-toggle="tooltip"
                            title={`${spotDesc}`}
                        >
                            {top}
                        </span>
                    </div>
                    <div className="flex items-center">
                        <span
                            className={`transititext-primary ${price ? futureClass : "text-ao-figma-text-gray"} text-primary transition duration-5 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700`}
                            data-te-toggle="tooltip"
                            title={`${futureDesc ? futureDesc + ' - ' : ''}${futureDate}`}
                        >
                            {bottom}
                        </span>
                    </div>
                </div>
            )
        }

        return (
            <div className="overflow-x-auto text-xs w-full">
                <div className="grid grid-cols-5 gap-0 p-1">
                    <div className="flex justify-center items-center text-ao-blue">
                        {label}
                    </div>
                    <div className="flex flex-col justify-left col-span-3">
                        {datasourceSelect}
                        {column(spotSymbol, futureSymbol, data.spotDescription, data.futureDescription, data.futureDate, false)}
                    </div>
                    <div className="flex justify-center items-end text-right">
                        {column(spotPrice, futurePrice, data.spotDescription, data.futureDescription, data.futureDate, true)}
                    </div>
                </div>
            </div>
        )

    }

    const legendData = [formik.values["freightPriceData"],
    _plattsBunkerTickerSource ? formik.values["ifoPriceData"].platts : formik.values["ifoPriceData"].bunkerex,
    _plattsBunkerTickerSource ? formik.values["mgoPriceData"].platts : formik.values["mgoPriceData"].bunkerex,
    formik.values["emissionData"]]

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={modalOpen}
                onClose={closeModal}
                fullWidth={true}
                maxWidth="xl"
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-ao-blue font-bold text-4xl pt-6 pl-6 pb-2">
                        {isEdit ? "Voyage Overview" : "Voyage Setup"}
                    </div>
                    <DialogContent>
                        <Box sx={{ display: "flex", padding: "10px" }}>
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "left" }}>
                                {
                                    isEdit && <ToggleButtonGroup exclusive value={_viewMode} onChange={(e, vm) => setViewMode(vm)}>
                                        <ToggleButton value={ViewModes.INITIAL}>Initial Plan</ToggleButton>
                                        <ToggleButton value={ViewModes.CURRENT}>Current Plan</ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            </Box>
                        </Box>

                        <div className="pl-6 pr-6 pb-1 flex flex-col h-100">
                            {/* ticker */}
                            <div className="flex pr-10 pl-10 h-4/5">
                                <div className="pb-2" style={{ flex: 4, marginRight: "15px" }}>
                                    <div>{ticker}</div>
                                </div>
                                <div style={{ flex: 1, marginRight: "15px" }}>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div className="flex flex-col xl:pr-10 xl:pl-10 2xl:h-4/5 h-1/2">

                                    </div>
                                    <div style={{ flex: 1, marginRight: "15px" }}>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div className="flex flex-col xl:pr-10 xl:pl-10 2xl:h-4/5 h-1/2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="ml-10 -mt-20">
                                    <DataAttribution
                                        data={legendData}
                                        isTCE={false}
                                    />
                                </div>
                            </div>
                            <div className="pt-5" style={{ flex: 3 }}>
                                <div className="flex xl:pr-10 xl:pl-10 2xl:h-4/5 h-1/2" >
                                    <div style={{ flex: 4, marginRight: "15px" }}>{fieldsLayout}</div>
                                    <div style={{ flex: 1, marginRight: "15px" }}>{outputLayout}</div>
                                    <div style={{ flex: 1 }}>{bunkersView}</div>
                                </div>
                                {_plattsRatesTickerSource || _plattsBunkerTickerSource || _plattsEmissionsTickerSource ? <div className="xl:pl-10 text-gray-500">
                                    <div><span className="text-SPGCI-color text-bold">*</span> <span className="text-xs">SPGCI Data</span></div>
                                    <div><span className="text-SPGCI-constructed italic">**</span> <span className="text-xs">Values derived from SPGCI Data</span></div>
                                </div> : null}
                            </div>
                        </div>

                    </DialogContent>

                    <DialogActions>
                        <button type="button" onClick={closeModal}>Cancel</button>
                        <button type="submit" className="block rounded-md bg-ao-blue px-3 py-2 text-center  font-semibold text-white shadow-md hover:bg-white hover:text-ao-blue" onClick={() => handleFieldErrors()}>Save</button>
                    </DialogActions>
                </form>
                <ValidationWarning modalOpen={validationWarningOpen}
                    onClose={() => setValidationWarningOpen(false)}
                    onOK={() => setValidationWarningOpen(false)}>
                </ValidationWarning>
            </Dialog>
        </ThemeProvider>
    )

    function handleModelNewVesselOptions(options) {
        const vessel = formik.values.vessel

        if (vessel.ecoShip !== options.ecoShip || vessel.scrubber !== options.scrubber) {
            const matchingModelVessel = _modelVessels
                .find(v =>
                    v.shipType === vessel.shipType
                    && v.ecoShip === options.ecoShip
                    && v.scrubber === options.scrubber
                )

            if (matchingModelVessel) {
                setFieldValue(fields.vessel, matchingModelVessel.id)
            }
        }

        setModelVesselOptionsOpen(false)
    }


    async function calculateOutputs(voyage, defaultFields, viewMode) {
        const voyageOutputFields = await calculateOutputFieldsFromVoyage(voyage)

        setFields(await getUpdatedFieldsForVoyage(defaultFields, voyage, voyageOutputFields, _freightRatesSource))
        setVoyageOutputFields(voyageOutputFields)
        setBunkerOutputFields(bunkerOutputFields)
    }

    async function handleFieldErrors() {
        const errors = await formik.validateForm()
        if (Object.keys(errors).length > 0) {
            setValidationWarningOpen(true)
        }
    }

    function ValidationWarning({ modalOpen, onOK, onClose }) {
        return <Dialog open={modalOpen}
            onClose={onClose}
            fullWidth={true}
        >
            <DialogTitle>Validation Errors</DialogTitle>
            <DialogContent>
                <ul>
                    {buildValidationMessages(formik.errors)}
                </ul>
            </DialogContent>
            <DialogActions>
                <button onClick={() => onOK()}>OK</button>
            </DialogActions>
        </Dialog>
    }

    function buildValidationMessages(errors) {
        const messages = []
        if (errors.robIfo) {
            messages.push(<li key="robIfo">ROB (IFO) is too low to reach bunkering port.</li>)
        }
        if (errors.robMgo) {
            messages.push(<li key="robMgo">ROB (MGO) is too low to reach bunkering port.</li>)
        }
        if (errors.laycanEnd) {
            messages.push(<li key="laycanEnd">Laycan period is invalid or cannot be reached by the vessel on time.</li>)
        }
        if (messages.length === 0) {
            messages.push(<li key="others">Please check the input fields and correct the error(s)</li>)
        }

        return messages
    }

})


function withDefaultFields(Component) {
    return props => {
        const [defaultFields, setDefaultFields] = useState()
        const [validationSchema, setValidationSchema] = useState()

        useEffect(() => {
            (async function() {
                const fields = await getFields()
                setDefaultFields(fields)
                setValidationSchema(getValidationSchemaForFields(fields))
            })()
        }, [])

        if (!defaultFields) return <></>

        return <Component {...props} defaultFields={defaultFields} validationSchema={validationSchema} />
    }
}

/**
 * Specification for the inputFields
 */


const FreightRateUnits = {
    [FreightTypes.WORLDSCALE]: "WS",
    [FreightTypes.DOLLAR_PER_MT]: "$/mt",
    [FreightTypes.LUMP_SUM]: "$"
}

const inputFields = {
    timeCharterCost: intFieldMeta(),
    freightType: selectFieldMeta({
        ballastOnly: true,
        options: Object.values(FreightTypes)
    }),
    freightRate: intFieldMeta({
        ballastOnly: true,
        unitFunc: freightType => {
            return FreightRateUnits[freightType]
        }
    }),
    commission: decimalFieldMeta({
        ballastOnly: true,
        max: 100
    }),
    taxes: intFieldMeta({
        ballastOnly: true
    }),
    differentials: intFieldMeta({
        ballastOnly: true
    }),
    cargoMinQuantity: intFieldMeta({
        ballastOnly: true
    }),
    cargoMaxQuantity: intFieldMeta({
        ballastOnly: true
    }),
    portCosts: intFieldMeta({
        ballastOnly: true
    }),
    portCosts2: intFieldMeta({
        ballastOnly: true
    }),
    canalCostsAll: intFieldMeta({
        ballastOnly: true
    }),
    ballastSpeed: decimalFieldMeta({
        min: 11,
        max: 14.5,
        step: 0.5
    }),
    ladenSpeed: decimalFieldMeta({
        ballastOnly: true,
        min: 11,
        step: 0.5,
        max: 14.5
    }),
    co2Price: intFieldMeta(),
    seaMargin: intFieldMeta(),
    bunkersNeededIFO: intFieldMeta(),
    bunkersNeededMGO: intFieldMeta(),
    emissionsRegsEu: decimalFieldMeta({
        min: 0,
        step: 1,
        max: 100
    }),
    emissionsRegsNonEu: decimalFieldMeta({
        min: 0,
        step: 1,
        max: 100
    })
}

async function getFields() {
    const [_portListByShipType, _routeParamsIn] = await Promise.all([getRecoilPromise(getPortListByShipType), getRecoilPromise(getRouteParamsIn)])

    const portsNames = Object.keys(_portListByShipType)

    // VLCC is first default ship
    const bunkerPortListOptions = portsNames.filter(port => _portListByShipType[port].VLCC === 1 && _portListByShipType[port].bunkeringPort === 1)

    const defaultMapToVoyage = fieldKey => {
        return async (voyage, newValue, dataSourceSettings) => await getUpdatedVoyage(voyage, fieldKey, newValue, dataSourceSettings)
    }

    const allFields = {
        ...inputFields,
        vessel: selectFieldMeta({
            valueKey: "id",
            getOptionLabel: v => v.shipName,
            isOptionEqualToValue: (option, value) => option.id === value.id
        }),
        cargoQuantity: intFieldMeta({
            ballastOnly: true,
            mapNewValueToVoyage: defaultMapToVoyage("cargoQuantity"),
            schemaValidation: sch => sch.when(["cargoMinQuantity", "cargoMaxQuantity"], ([cargoMinQuantity, cargoMaxQuantity], sch) => {
                if (!cargoMinQuantity) {
                    return sch.min(0)
                }

                if (!cargoMaxQuantity) {
                    return sch.min(cargoMinQuantity)
                }

                return sch.min(cargoMinQuantity).max(cargoMaxQuantity)
            })
        }),
        routeShortName: selectFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("routeShortName")
        }),
        loadingPort: selectFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("loadingPort")
        }),
        dischargePort: selectFieldMeta({
            ballastOnly: true,
            mapNewValueToVoyage: defaultMapToVoyage("dischargePort")
        }),
        loadingPort2: selectFieldMeta({
            ballastOnly: true,
            nullable: true,
            mapNewValueToVoyage: defaultMapToVoyage("loadingPort2")
        }),
        dischargePort2: selectFieldMeta({
            ballastOnly: true,
            nullable: true,
            mapNewValueToVoyage: defaultMapToVoyage("dischargePort2")
        }),
        canalConstraints: selectFieldMeta({
            ballastOnly: false,
            mapNewValueToVoyage: defaultMapToVoyage("canalConstraints")
        }),
        openPort: selectFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("openPort")
        }),
        bunkerPort: selectFieldMeta({
            options: [...bunkerPortListOptions],
            mapNewValueToVoyage: defaultMapToVoyage("bunkerPort")
        }),
        openDate: dateFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("openDate")
        }),
        laycanStart: dateFieldMeta({
            ballastOnly: true,
            mapNewValueToVoyage: defaultMapToVoyage("laycanStart")
        }),
        laycanEnd: dateFieldMeta({
            ballastOnly: true,
            schemaValidation: sch => sch.when(["laycanStart", "$loadETA"], ([laycanStart, loadETA], sch) => {
                if (!loadETA) {
                    return laycanStart ? sch.min(laycanStart) : sch
                }

                if (!laycanStart) {
                    return loadETA ? sch.min(loadETA) : sch
                }

                return laycanStart.getTime() > loadETA.getTime() ? sch.min(laycanStart) : sch.min(loadETA)
            })
        }),
        cargo: selectFieldMeta({
            ballastOnly: true,
            mapNewValueToVoyage: defaultMapToVoyage("cargo")
        }),
        robIFO: intFieldMeta(),
        robMGO: intFieldMeta(),
        loadDays: intFieldMeta({
            ballastOnly: true,
            min: 0,
            step: 1,
            max: 100,
            mapNewValueToVoyage: defaultMapToVoyage("loadDays")
        }),
        canalDays: intFieldMeta({
            ballastOnly: true,
            min: 0,
            step: 1,
            max: 100,
            mapNewValueToVoyage: defaultMapToVoyage("canalDays")
        }),
        dischargeDays: intFieldMeta({
            ballastOnly: true,
            min: 0,
            step: 1,
            max: 100,
            mapNewValueToVoyage: defaultMapToVoyage("dischargeDays")
        }),
        waitingDays: intFieldMeta({
            ballastOnly: true,
            min: 0,
            step: 1,
            max: 100,
            mapNewValueToVoyage: defaultMapToVoyage("waitingDays")
        }),
        ifoBunkerPrice: decimalFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("ifoBunkerPrice")
        }),
        mgoBunkerPrice: decimalFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("mgoBunkerPrice")
        }),
        customer: textFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("customer")
        }),
        supplier: textFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("supplier")
        }),
        confirmedOpen: checkboxFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("confirmed_open")
        }),
        confirmedDischarge: checkboxFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("confirmed_discharge")
        }),
        confirmedLoad: checkboxFieldMeta({
            mapNewValueToVoyage: defaultMapToVoyage("confirmed_load")
        }),
    }

    return Object.keys(allFields)
        // add name property on each field
        .reduce((fields, f) => ({ ...fields, [f]: { name: f, ...allFields[f] } }), {})
}

const cargoOptionsByShipType = {
    [SHIP_TYPES.VLCC]: ["Crude", "Crude (Midland Grade WTI)", "Crude (Bonny Light)", "Crude (Qua Oboe, Forcados)", "Crude (Murban)", "Crude (Arab Light)", "Crude (Arab Heavy)"],
    [SHIP_TYPES.SUEZMAX]: ["Crude", "Crude (Midland Grade WTI)", "Crude (Bonny Light)", "Crude (Qua Oboe, Forcados)", "Crude (Murban)", "Crude (Arab Light)", "Crude (Arab Heavy)", "Fuel Oil"],
    [SHIP_TYPES.AFRAMAX_LR2]: ["CPP", "Crude", "Crude (Brent)", "Fuel Oil", "Condensates", "Gasoline", "Naphtha", "Jet Fuel", "Gasoil", "Diesel"],
    [SHIP_TYPES.PANAMAX_LR1]: ["CPP", "Crude", "Crude (Bakken)", "Crude (Eagle Ford 45)", "Crude (Eagle Ford 52)", "Fuel Oil", "Gasoline", "Naphtha", "Jet Fuel", "Gasoil", "Diesel"],
    [SHIP_TYPES.MR]: ["CPP", "Crude", "Fuel Oil", "Gasoline", "Naphtha", "Jet Fuel", "Gasoil", "Diesel", "Vegetable Oil", "Chemicals"],
    [SHIP_TYPES.HANDY]: ["CPP", "Crude", "Fuel Oil", "Gasoline", "Naphtha", "Jet Fuel", "Gasoil", "Diesel", "Vegetable Oil", "Chemicals", "Vehicles"]
}
const canalOptionsByShipType = {
    [SHIP_TYPES.VLCC]: ["Allow Suez Canal", "Exclude Canals", "Suez Laden Leg Only"],
    [SHIP_TYPES.SUEZMAX]: ["Allow Canals", "Exclude Canals", "Laden Leg Only"],
    [SHIP_TYPES.AFRAMAX_LR2]: ["Allow Canals", "Exclude Canals", "Laden Leg Only"],
    [SHIP_TYPES.PANAMAX_LR1]: ["Allow Canals", "Exclude Canals", "Laden Leg Only"],
    [SHIP_TYPES.MR]: ["Allow Canals", "Exclude Canals", "Laden Leg Only"],
    [SHIP_TYPES.HANDY]: ["Allow Canals", "Exclude Canals", "Laden Leg Only"]
}

async function getUpdatedFieldsForVoyage(fields, voyage, outputFields, freightRatesSource) {
    const [_portListByShipType, _routeParamsIn, _routeParamsUnpriced, /*_voyageEngineBunkerPorts,*/ _modelVessels, _userVessels] = await Promise.all(
        [getRecoilPromise(getPortListByShipType), getRecoilPromise(getRouteParamsIn), getRecoilPromise(getRouteParamsUnpriced), /*getRecoilPromise(voyageEngineBunkerPorts),*/ getRecoilPromise(userModelVessels), getRecoilPromise(userCustomisedVessels)]
    )
    const _userSettings = getRecoil(userSettings)

    const allVessels = [..._modelVessels, ..._userVessels]
    const vesselOptions = getSelectableVessels(getModelVesselOptions(voyage), _modelVessels, _userVessels)

    // get ship type and two regions to filter pport list
    const routeParamsIn = _routeParamsIn[voyage.routeId] // has .reg1long. reg2long, .region1 and .region2

    const shipType = voyage.vessel.shipType

    const isBalticSource = DATA_SOURCE.isBaltic(freightRatesSource)
    const isPlatssFRSource = DATA_SOURCE.isPlatts(freightRatesSource)
    const availableRoutes = Object.values(_routeParamsUnpriced).filter(x => x.shiptype === shipType)
        .filter(x => (x.routetype.toLowerCase() === 'baltic' ? isBalticSource : isPlatssFRSource) || (x.routetype.toLowerCase() === "unpriced"))
    availableRoutes.sort((m1, m2) => m1.routetype.toLowerCase() === 'platts' ? m2.routetype.toLowerCase() === 'platts' ? 0 : -1 : 1)
    const routeOptions = availableRoutes.map(x => {
        let displayName, groupName
        if (x.routetype === "Baltic") {
            displayName = x.description
            groupName = "Baltic Exchange"
        } else if (x.routetype === "Platts") {
            displayName = `${x.description} (${x.symbolMT})`
            groupName = "SPGCI"
        } else {
            displayName = x.longName
            groupName = "Custom Routes"
        }
        return { display: displayName, value: x.shortName, group: groupName }
    })

    let loadingListOptions, dischargingListOptions
    if (customRoutes.includes(voyage.routeId)) {
        loadingListOptions = getOpenPortsForShipType(_portListByShipType, shipType)
        dischargingListOptions = getOpenPortsForShipType(_portListByShipType, shipType, voyage.routeId)
    } else {
        loadingListOptions = getPortsForShipAndRegion(_portListByShipType, shipType, routeParamsIn["reg1long"])
        dischargingListOptions = getPortsForShipAndRegion(_portListByShipType, shipType, routeParamsIn["reg2long"])
    }

    const openPortListOptions = getOpenPortsForShipType(_portListByShipType, shipType)
    const _voyageEngineBunkerPorts = getBunkerPortsForShipType(_portListByShipType, shipType)

    // Create a fields object to reflect selected voyage
    return {
        ...fields,
        vessel: {
            ...fields.vessel,
            options: vesselOptions,
            optionValueKey: "id",
            captionKey: "shipName",
            mapNewValueToVoyage: async (voyage, vesselId, dataSourceSettings) => {
                const vessel = allVessels.find(v => String(v.id) === String(vesselId))
                const newVoyage = await getUpdatedVoyage(voyage, "vessel", vessel, dataSourceSettings)

                //Get a list of the valid routes for the new vessel
                const isBalticSource = DATA_SOURCE.isBaltic(dataSourceSettings.freightRatesSource)
                const isPlatssSource = DATA_SOURCE.isPlatts(dataSourceSettings.freightRatesSource)
                const availableRoutes = Object.values(_routeParamsUnpriced).filter(x => x.shiptype === vessel.shipType)
                    .filter(x => (x.routetype.toLowerCase() === 'baltic' ? isBalticSource : isPlatssSource) || (x.routetype.toLowerCase() === "unpriced"))
                    .map(x => (x.shortName))

                // If the selected route is not valid, switch to a new one
                if (!availableRoutes.includes(newVoyage.routeShortName)) {
                    const routeUpdatedVoyage = await getUpdatedVoyage(newVoyage, "routeShortName", availableRoutes[0], dataSourceSettings)
                    return routeUpdatedVoyage
                }

                return newVoyage
            }
        },
        routeShortName: {
            ...fields.routeShortName,
            options: [...routeOptions],
            optionValueKey: "value",
            captionKey: "display"
        },
        loadingPort: {
            ...fields.loadingPort,
            options: [...loadingListOptions]
        },
        dischargePort: {
            ...fields.dischargePort,
            options: [...dischargingListOptions]
        },
        loadingPort2: {
            ...fields.loadingPort2,
            options: ["", ...loadingListOptions]
        },
        dischargePort2: {
            ...fields.dischargePort2,
            options: ["", ...dischargingListOptions]
        },
        openPort: {
            ...fields.openPort,
            options: [...openPortListOptions]
        },
        bunkerPort: {
            ...fields.bunkerPort,
            options: [..._voyageEngineBunkerPorts]
        },
        cargo: {
            ...fields.cargo,
            options: cargoOptionsByShipType[shipType]
        },
        robIfo: {
            ...fields.robIfo,
            min: outputFields.minrobIfo
        },
        robMgo: {
            ...fields.robMgo,
            min: outputFields.minrobMgo
        },
        canalConstraints: {
            ...fields.canalConstraints,
            options: canalOptionsByShipType[shipType]
        },
        customer: { ...fields.customer },
        supplier: { ...fields.supplier },
        confirmed_discharge: { ...fields.confirmedDischarge },
        confirmed_open: { ...fields.confirmedOpen },
        confirmed_load: { ...fields.confirmedLoad }


    }
}

function getValidationSchemaForFields(fields) {
    return Yup.object().shape(
        Object.keys(fields).filter(f => !!fields[f].validationSchema)
            .reduce((schema, f) => ({ ...schema, [f]: fields[f].validationSchema }), {}))
}


function selectFieldMeta({ options, nullable, ...rest }) {
    return {
        ...rest,
        options: options || [],
        validationSchema: Yup.mixed().nullable(!!nullable)
    }
}

function textFieldMeta({ options, nullable, ...rest }) {
    return {
        ...rest,
        options: options || [],
        validationSchema: Yup.string().nullable(!!nullable)
    }
}
function checkboxFieldMeta({ options, nullable, ...rest }) {
    return {
        ...rest,
        options: options || [],
        validationSchema: Yup.string().nullable(!!nullable)
    }
}



function intFieldMeta(options) {
    const { min = 0, max = Number.MAX_SAFE_INTEGER } = options || {}
    const schemaValidation = options?.schemaValidation || (sch => sch)
    return {
        ...options,
        validationSchema: schemaValidation(Yup
            .number()
            .integer()
            .min(min)
            .max(max)
            .nullable(true)
        )
            // checking self-equality works for NaN, transforming it to null
            .transform((_, val) => val === Number(val) ? val : null)
            .round()
    }
}

function decimalFieldMeta(options) {
    const { min = 0, max = Number.MAX_SAFE_INTEGER } = options || {}
    return {
        ...options,
        validationSchema: Yup.number()
            .nullable()
            .min(min)
            .max(max)
            .test(v => /^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/.test(String(v)))
    }
}

function dateFieldMeta(options) {
    const { schemaValidation, ...rest } = options

    let validationSchema = Yup.date()
    if (schemaValidation) {
        validationSchema = schemaValidation(validationSchema)
    }

    return {
        ...rest,
        validationSchema
    }
}

function roundIT(value) {
    if (typeof value === "undefined") {
        return ""
    }
    return value.toFixed(1)
}

function getModelVesselOptions(voyage) {
    return voyage?.vessel.modelVessel ? { ecoShip: voyage.vessel.ecoShip, scrubber: voyage.vessel.scrubber } : { ecoShip: false, scrubber: false }
}

async function updateRoute(formik, source, field, setFieldValue) {
    const _routeParamsUnpriced = await getRecoilPromise(getRouteParamsUnpriced);
    const isBalticSource = DATA_SOURCE.isBaltic(source);
    const isPlatssSource = DATA_SOURCE.isPlatts(source);
    const availableRoutes = Object.values(_routeParamsUnpriced)
        .filter(x => x.shiptype === formik.values.vessel.shipType)
        .filter(
            x =>
                (x.routetype.toLowerCase() === "baltic"
                    ? isBalticSource
                    : isPlatssSource) || x.routetype.toLowerCase() === "unpriced"
        );
    availableRoutes.sort((m1, m2) =>
        m1.routetype.toLowerCase() === "platts"
            ? m2.routetype.toLowerCase() === "platts"
                ? 0
                : -1
            : 1
    );

    setFieldValue(field, availableRoutes[0].shortName);
}

async function updateBunkerPrices(formik, source, setFieldValue) {
    const isPlatssSource = DATA_SOURCE.isPlatts(source);

    if (formik.values["ifoPricePort"] !== "Quoted") {
        let ifoPriceData = formik.values["ifoPriceData"];
        ifoPriceData = isPlatssSource ? ifoPriceData.platts : ifoPriceData.bunkerex;

        await setFieldValue({ name: "ifoBunkerPrice" }, ifoPriceData.spotPrice, false);
    }

    if (formik.values["mgoPricePort"] !== "Quoted") {
        let mgoPriceData = formik.values["mgoPriceData"];
        mgoPriceData = isPlatssSource ? mgoPriceData.platts : mgoPriceData.bunkerex;

        await setFieldValue({ name: "mgoBunkerPrice" }, mgoPriceData.spotPrice, false);
    }
}

async function updateEmissionsPrices(formik, source, setFieldValue) {
    const isPlatssSource = DATA_SOURCE.isPlatts(source);

    if (!formik.getFieldMeta("co2Price").touched) {
        const priceData = formik.values.emissionData[isPlatssSource ? 'platts' : 'default']
        await setFieldValue({ name: "co2Price" }, priceData.spotPrice, false);
    }
}
