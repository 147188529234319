import { DateTime, Info } from "luxon"
import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { bunkerPricesPort } from "../../store/api"
import { selectedBunkers } from "../../store/ui"
import GenericLineChart from "./GenericLineChart"
import { getRecoilPromise } from "recoil-nexus"

const colors = ["blue", "red", "orange", "green"]


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ volatility, liquidity, data }) => {
    const Bunker = []
    const [_selectedBunkers] = useRecoilState(selectedBunkers)
    const [chartData, setChartData] = useState([])
    const liquidDict = { "low": 0.02, "med": 0.01, "high": 0.005 }

    useEffect(() => {
        (async function () {
            let items = []
            for (const [i, selectedBunker] of _selectedBunkers.entries()) {
                const bunkerItem = selectedBunker.item
                if (selectedBunker.selected) {
        
                    let title = "";
                    const data_mean = []
                    let data_upper_ci = []
                    let data_lower_ci = []
                    let data_area = []
        
                    // Build month rows
                    const currentMonth = DateTime.now().month - 1
                    const monthRows = []
                    for (let i = 0; i < 12; i++) {
                        monthRows.push(Info.months("short")[(currentMonth + i) % 12])
                    }
                    if (typeof bunkerItem === "string") {
                        title = bunkerItem
                        const bunkerMean = Bunker[bunkerItem]["mean_" + volatility]
                        const bunkerCI = Bunker[bunkerItem]["CI_" + volatility]
                        if (!bunkerMean || !bunkerCI) {
                            console.error("Missing mean/CI data for bunker: " + bunkerItem)
                            continue
                        }
        
                        try {
        
                            // Build data entries for charts
                            for (let monthIdx = 0; monthIdx < 12; monthIdx += 1) {
                                data_mean.push({ x: monthRows[monthIdx], y: bunkerMean["M" + monthIdx] })
                                data_upper_ci.push({ x: monthRows[monthIdx], y: bunkerCI["M" + monthIdx][1] })
                                data_lower_ci.push({ x: monthRows[monthIdx], y: bunkerCI["M" + monthIdx][0] })
                            }
                        } catch (error) {
                            console.error("Incorrect format mean/CI data for bunker: " + bunkerItem)
                            console.error(error)
                        }
                    } else {
                        let prices;
                        if (bunkerItem.isBunkerEx) {
                            prices = (await getRecoilPromise(bunkerPricesPort({provider: "BunkerEx", port: bunkerItem.port, fuels: bunkerItem.fuelType})))[0]
                        } else {
                            prices = (await getRecoilPromise(bunkerPricesPort({provider: "SPGCI", port: bunkerItem.port, fuels: bunkerItem.fuelType})))[0]
                        }

                        if(prices){
                            title = `${bunkerItem.port} ${bunkerItem.fuelType}`
            
                            let monthIdx = 0
            
                            data_mean.push({ x: monthRows[monthIdx], y: prices.spotPrice })
                            data_upper_ci.push({ x: monthRows[monthIdx], y: prices.spotPrice })
                            data_lower_ci.push({ x: monthRows[monthIdx], y: prices.spotPrice })
            
                            for (let i = 0; i < prices.forwardCurve.length && i < 11; i++) {
                                ++monthIdx
                                data_mean.push({ x: monthRows[monthIdx], y: prices.forwardCurve[i].price })
                                data_upper_ci.push({ x: monthRows[monthIdx], y: prices.forwardCurveCiLower[i].price })
                                data_lower_ci.push({ x: monthRows[monthIdx], y: prices.forwardCurveCiUpper[i].price })
                            }
                        }
                    }

                    if(data_mean.length > 0){
                        data_area = data_mean.map((mean, index) => ({ x: monthRows[index], y: mean["y"] + liquidDict[liquidity] * mean["y"], y0: mean["y"] - liquidDict[liquidity] * mean["y"] }))
                        items.push({ title, color: colors[i % colors.length], mean: data_mean, upper: data_upper_ci, lower: data_lower_ci, area: data_area })
                    }
                }
            }
            setChartData(items)
        })()
    }, [_selectedBunkers])

    return (
        <>
        <GenericLineChart
            type={"bunkers"} title="Term Structure & Volatility of Selected Bunker Fuels ($/mt)" items={chartData} />
        <div className="grid grid-cols-4">
            {data.map((bunker, i) => (
                <BunkerLegend
                    port={bunker.port}
                    grade={bunker.fuelType}
                    isBunkerEx={bunker.provider === "BunkerEx"}
                    constructedSpot={bunker.constructedSpot}
                    constructedCurve={bunker.constructedCurve}
                    partiallyConstructedCurve={!(bunker.forwardCurveData.reduce((acc, point) => acc && point.constructed, true))}
                    providerData={bunker.providerData}
                    color={colors[i]}
                    status={bunker.status}
                    key={`${bunker.port} ${i}`}
                />
            ))}
        </div>
        </>
    )
}

export function BunkerLegend({port, grade, isBunkerEx, constructedSpot, constructedCurve, partiallyConstructedCurve, providerData, color, status}){
    const meanLineStyle = {
        // width: '100px', 
        height: '3px',
        backgroundColor: `${color}`,
        marginTop: '10px',
    }

    let spotDesc, forwardDesc

    if (isBunkerEx && constructedSpot){
        spotDesc = "Derived from BunkerEx Data"
    } else if(!isBunkerEx && constructedSpot){
        spotDesc = "Derived from SPGCI Data"
    } else if (isBunkerEx && !constructedSpot){
        spotDesc = "BunkerEx"
    } else if (!isBunkerEx && !constructedSpot && (status === "available")){
        spotDesc = `SPGCI (${providerData.spotSymbol})`
    }

    if(isBunkerEx && partiallyConstructedCurve && constructedCurve){
        forwardDesc = "Extrapolated from BunkerEx" 
    } else if (!isBunkerEx && partiallyConstructedCurve && constructedCurve){
        forwardDesc = `Extrapolated from SPGCI (${providerData.forwardCurveCode})`
    }else if (isBunkerEx && constructedCurve){
        forwardDesc = "Derived from BunkerEx Data"
    } else if(!isBunkerEx && constructedCurve){
        forwardDesc = "Derived from SPGCI Data"
    } else if (isBunkerEx && !constructedCurve){
        forwardDesc = "BunkerEx"
    } else if (!isBunkerEx && !constructedCurve && (status === "available")){
        forwardDesc = `SPGCI (${providerData.forwardCurveCode})`
    }


    return (
        <div className="flex flex-col p-2">
            <div className="w-full" style={meanLineStyle}></div>
            <p className="text-xs">
                <span className="font-semibold">Port</span>: {port}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Grade</span>: {grade}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Spot</span>: {spotDesc}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Curve</span>: {forwardDesc}
            </p>
        </div>
    )
}