import {createTheme} from "@material-ui/core/styles"

const _defaultTheme = {
    // typography: {
    //     fontSize: 16,
    //     lineHeight: 1,
    //     heading: {
    //         secondary: {
    //             fontSize: "0.8rem",
    //             fontWeight: 600
    //         },
    //         primary: {
    //             fontSize: "1rem",
    //             fontWeight: 600
    //         }
    //     }
    // },
    // palette: {
    //     mode: "dark",
    //     type: "dark",
    //     primary: {
    //         main: "#00A0F4",
    //         heading: "#00A0F4"
    //     },
    //     secondary: {
    //         main: "#fff",
    //         heading: "#818486"
    //     },
    //     error: {
    //         main: "#f44336"
    //     },
    //     background: {
    //         default: "#04090D",
    //         paper: "#1E2226"
    //     }
    // },
    // overrides: {
    //     MuiPopover: {
    //         paper: {
    //             backgroundColor: "black"
    //         }
    //     },
    //     MuiInputBase: {
    //         input: {
    //             color: "#fff"
    //         }
    //     },
    //     MuiFormLabel: {
    //         root: {
    //             color: "#fff"
    //         }
    //     },
    //     MuiOutlinedInput: {
    //         notchedOutline: {
    //             borderColor: "#fff"
    //         }
    //     },
    //     MuiCssBaseline: {
    //         "@global": {
    //             body: {
    //                 overflowX: "hidden"
    //             }
    //         }
    //     }
    // }
}

const _smallerTextTheme = {
    // ..._defaultTheme,
    // typography: {
    //     ..._defaultTheme.typography,
    //     fontSize: 14
    // }
}

export const AFTColours = {
    bunkers: "#8f0000",
    emissions: "#008800",
    voyages: "#026fa6",
    bunkersTransparent: "rgba(143,0,0,0.5)",
    emissionsTransparent: "rgba(0,136,0,0.5)",
    voyagesTransparent: "rgba(2,111,166,0.5)",
    readonly: "rgba(44, 66, 112, 0.5)"
}

export const DefaultTheme = createTheme(_defaultTheme)
export const SmallerTextTheme = createTheme(_smallerTextTheme)