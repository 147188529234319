import { LoadStem } from "../../../store/api/services-api";
import React, {useEffect, useState} from "react";
import { recostVoyages } from "./VoyageEngineLogic";

export default function LoadStemButton({shipName, fuelType}) {

    const handleLoadClick = async () => {
        await LoadStem(shipName, fuelType)
        await recostVoyages(shipName)
    }

    return(
        <>
        <button className="bg-ao-blue border pl-2 pr-2 text-white rounded-lg text-sm hover:bg-white hover:text-ao-blue w-full"
        onClick={handleLoadClick}
        >
            Load Stem
        </button>
        </>
    )
}