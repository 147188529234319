import { DateTime, Info } from "luxon"
import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { getRecoilPromise } from "recoil-nexus"
import { getAllSimulations, getRouteLookup, getRouteTceComps } from "../../store/api"
import { availableBunkersFilter, selectedRoutes } from "../../store/ui"
import { customisedModelVesselForSelectedRoutes, emissionsRatesForSelectedRoutes } from "../../store/vessels"
import { replaceItemAtIndex } from "../../utils/Helpers"
import { BunkerMultiplier, JourneyTimeMultiplier } from "../Multipliers/Multipliers"
import GenericLineChart from "./GenericLineChart"
import { userSettings } from "../../store/ui"
import { DATA_SOURCE } from "../../utils/Constants"

const colors = ["blue", "red", "orange", "green"]

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ volatility, liquidity, excludeEmissionsCosts, data }) => {
    const multipliers = useRecoilValue(customisedModelVesselForSelectedRoutes)
    const _availableBunkersFilter = useRecoilValue(availableBunkersFilter)
    const [_selectedRoutes] = useRecoilState(selectedRoutes)
    const _selectedEmissionRates = useRecoilValue(emissionsRatesForSelectedRoutes)
    const Tanker = useRecoilValue(getAllSimulations)
    const routeLookup = useRecoilValue(getRouteLookup)
    const _userSettings = useRecoilValue(userSettings)
    const isPlatssSource = DATA_SOURCE.isPlatts(_userSettings.bunkerPricesSource)


    const [items, setItems] = useState([])


    useEffect(() => {
        buildItems();
        async function buildItems() {
            for (const [i, selectedRoute] of _selectedRoutes.entries()) {
                if(!_availableBunkersFilter.bunkerDataSource){
                    return;
                }
                const routeId = selectedRoute.item
                const bunkMult = BunkerMultiplier(routeId, multipliers[i])
                const rateMult = multipliers[i]["modelShipRatesMultiplier"]
                const emissMult = bunkMult * _selectedEmissionRates[i] / 100
                const timeMult = JourneyTimeMultiplier(routeId, multipliers[i])

                // const ci_unhedged = comps.map((t) => Object.values(Tanker.filter((routes) => routes.route.toLowerCase() === routeId.toLowerCase() && routes.volatility.toLowerCase() === volatility.toLowerCase() && routes.liquidity.toLowerCase() === liquidity.toLowerCase())[0]["CF" + t]["hedge0_CI"]).map((month) => month[0]))
                // let stdDev = ci_unhedged.map((t, tIndex) => t.map((m, mIndex) => (cfMean[tIndex][mIndex] - m) / 1.65))
                let shipWeights = [[bunkMult / timeMult, rateMult / timeMult, emissMult / timeMult]]
                let shipWeightsT = shipWeights[0].map((w) => [w])


                const data_mean = []
                const data_upper_ci = []
                const data_lower_ci = []
                const data_area = []

                // Build month rows
                const currentMonth = DateTime.now().month - 1
                const monthRows = []
                for (let i = 0; i < 12; i++) {
                    monthRows.push(Info.months("short")[(currentMonth + i) % 12])
                }

                getRecoilPromise(getRouteTceComps({route: routeId, vol: volatility, dataSource: (selectedRoute.balticPlatts === "Baltic") ? "BunkerEx" : "SPGCI", excludeEmissionsCosts})).then((response) => {
                    if(response){
                        const { cfBunker, cfBunkerHs, cfFreight, cfOther, cfEmissions } = response
                        const bunker = multipliers[i]["scrubber"] === 0 ? cfBunker : cfBunkerHs

                        // Build data entries for charts
                        for (let monthIdx = 0; monthIdx < 12; monthIdx += 1) {
                            // let cov_mult_unhedge = [[stdDev[0][monthIdx] ** 2, 0, 0], [0, stdDev[1][monthIdx] ** 2, 0], [0, 0, stdDev[2][monthIdx] ** 2]]
                            let divisor =  30
                            // let sigma = 1.65 * Math.sqrt(matrixDot(shipWeights, matrixDot(cov_mult_unhedge, shipWeightsT))) / divisor
                            let emissionsTerm = excludeEmissionsCosts ? 0 : (emissMult * cfEmissions[monthIdx])
                            let mean = ((bunkMult * bunker[monthIdx] + rateMult * cfFreight[monthIdx] + emissionsTerm + cfOther) / timeMult) / divisor
                            data_mean.push({ x: monthRows[monthIdx], y: mean })
                            // data_upper_ci.push({ x: monthRows[monthIdx], y: mean + sigma })
                            // data_lower_ci.push({ x: monthRows[monthIdx], y: mean - sigma })
                            data_area.push({ x: monthRows[monthIdx], y: mean, y0: mean })

                        }

                        setItems((items) => {
                            const item = {
                                title: routeLookup.find((r) => r.routeID === routeId).longName,
                                color: colors[i % colors.length],
                                mean: data_mean,
                                upper: data_upper_ci,
                                lower: data_lower_ci,
                                area: data_area,
                                routeIndex: i,
                                routeId: routeId
                            }

                            const itemIndex = items.findIndex((item) => item.routeIndex === i)
                            if ( itemIndex === -1) {
                                return [...items, item]
                            } else {
                                return replaceItemAtIndex(items, itemIndex, item)
                            }
                        })
                    }

                })
            }
        }
    }, [Tanker, multipliers, _selectedRoutes, _selectedEmissionRates, excludeEmissionsCosts, volatility, liquidity, _availableBunkersFilter])

    return (
        <>
        <GenericLineChart
            title="Term Structure & Volatility of TCEs on Selected Routes ($/day)" items={items} type={"tce"} margins={{left: 100}} isTCE={true}/>
        <div className="grid grid-cols-4">
            {data.map((tce, i) => (
                <TCELegend
                    routeDesc={tce.routeDesc}
                    isSPGCI={(tce.provider === "SPGCI")}
                    color = {colors[i]}
                />
            ))}
        </div>
        </>
    )
}


export function TCELegend({routeDesc, isSPGCI, color}){
    const meanLineStyle = {
        // width: '100px', 
        height: '3px',
        backgroundColor: `${color}`,
        marginTop: '10px',
    }

    let spotDesc = "Derived from Baltic Exchange and BunkerEx Data"
    if(isSPGCI){
        spotDesc = "Derived from SPGCI Data"
    }

    return (
        <div className="flex flex-col p-2">
            <div className="w-full" style={meanLineStyle}></div>
            <p className="text-xs">
                <span className="font-semibold">Route</span>: {routeDesc}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Data Source(s)</span>: {spotDesc}
            </p>
        </div>
    )
}