import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Spinner } from "reactstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  loadingIndicator,
  selectdVoyagePortfolio,
  selectdVoyagePortfolioId,
} from "../../../store/ui";
import {
  dollarFormat,
  generateUUID,
} from "../../../utils/Helpers";
import AddVoyageEngineButton from "./AddVoyageEngineButton";
import VoyageTableChart from "./VoyageFinancialsChart.js";
import VoyageFinancialsTable from "./VoyageFinancialsTable.js";
import { formatDateToDDMMYY } from "./BunkerTable";
import { Link } from "react-router-dom/cjs/react-router-dom";
import EditVoyageEngineButton from "./EditVoyageEngineButton";
import { useVoyagesMutations, voyages } from "../../../store/voyages";
import { useVoyagePortfolioMutations } from "../../../store/voyagePortfolios";
import { VoyagePortfolioSelect } from "./VoyagePortfolioSelect"
import { VoyagePortfolioManageDialog } from "./VoyagePortfolioManageDialog"

export default function VoyageTable() {
  const _voyages = useRecoilValue(voyages);
  const [_loading, setLoading] = useRecoilState(loadingIndicator);
  const [_selectedVoyages, setSelectedVoyages] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false)
  const [_groupingChoice, setGroupingChoice] = useState("byVessel")

  const _selectedPortfolioId = useRecoilValue(selectdVoyagePortfolioId)
  const _selectedPortfolio = useRecoilValue(selectdVoyagePortfolio)
  const { saveVoyagePortfolio } = useVoyagePortfolioMutations()
  const { saveVoyage, deleteVoyage } = useVoyagesMutations()
  const [_adToPortfolioVisible, setAddToPortfolioVisible] = useState(false)
  const [_portfolioModalOpen, setPortfolioModalOpen] = useState(false)
  const [_voyageData, setVoyageData] = useState([]);

  useLayoutEffect(() => {

    setChecked(
      _voyages.length > 0 &&
      _selectedVoyages.length > 0 &&
      _voyages.length === _selectedVoyages.length
    )

  }, [_selectedVoyages]);

  function toggleAll() {
    handleSelectionChange(checked ? [] : _voyages.map((x) => x.id));
    setChecked(!checked);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleDeleteClick = () => async () => {
    if (_selectedVoyages && _selectedVoyages.length > 0) {
      await Promise.all(_selectedVoyages.map((v) => deleteVoyage(v)))

    }
  };

  // TODO - copy all ??
  const handleCopyClick = () => async () => {
    const id = _selectedVoyages[0]
    const rowToDuplicate = _voyages.find((row) => row.id === id)
    const savedVoyage = await saveVoyage({ ...rowToDuplicate, id: undefined })

    // setVoyageEngineBunkerData((prevRows) => {
    //   const rowToDuplicate = prevRows.find((b) => b.id === id);
    //   const newVoyage = { ...rowToDuplicate, id: savedVoyage.id };
    //   return [...prevRows, newVoyage];
    // });
  };

  useEffect(() => {
    setAddToPortfolioVisible(_selectedPortfolioId == null && _selectedVoyages.length > 0)
  }, [_selectedPortfolioId, _selectedVoyages])

  useEffect(() => {
    if (_selectedPortfolio) {
      handleSelectPortfolio(_selectedPortfolio)
    }
  }, [])

  return (
    <>
      <div style={{ display: _loading ? "block" : "none" }}>
        <Spinner />
      </div>

      <div style={{ display: _loading ? "none" : "block" }}>
        <div>
          {/* Action buttons */}
          <div className="flex items-center justify-end gap-x-8 -mt-14">
            <button onClick={handleCopyClick()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#5C93C0"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                />
              </svg>
            </button>
            <button onClick={handleDeleteClick()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#5C93C0"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
            <AddVoyageEngineButton />
          </div>

          {/* Main Table */}
          <div className="mt-8 flow-root">
            <div className="flex space-between">
              {_adToPortfolioVisible &&
                <button className="rounded-md px-3 py-2 text-center font-semibold text-ao-blue shadow-md hover:bg-white hover:text-ao-blue" onClick={handleAddToPortfolio}>
                  Add to portfolio
                </button>
              }
              <div style={{ flex: "1 0" }}></div>
              <VoyagePortfolioSelect onSelect={handleSelectPortfolio} />
              <VoyagePortfolioManageDialog open={_portfolioModalOpen} onClose={() => setPortfolioModalOpen(false)} voyagesToAdd={_selectedVoyages} onOk={handleAddedToPortfolio}></VoyagePortfolioManageDialog>
            </div>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="relative px-7 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th
                          scope="col"
                          className=" py-3.5 pr-3 text-left text-sm font-semibold text-ao-blue"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Vessel
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Route
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Laycan End
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Cargo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          TCE Plan
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Dynamic TCE
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          ∆-TCE Bunkers
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          ∆-TCE Emissions
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          ∆-TCE Operations
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          CII
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Duration (days)
                        </th>
                      </tr>
                    </thead>
                    {_voyages.length > 0 && (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {_voyages.map((voyage) => (
                          <tr
                            key={voyage.id}
                            className={
                              _selectedVoyages.includes(voyage.id)
                                ? "bg-gray-50"
                                : undefined
                            }
                          >
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              {_selectedVoyages.includes(voyage.id) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-ao-blue" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                                checked={_selectedVoyages.includes(voyage.id)}
                                onChange={(e) => {
                                  handleSelectionChange(e.target.checked
                                    ? [..._selectedVoyages, voyage.id]
                                    : _selectedVoyages.filter(
                                      (s) => s !== voyage.id
                                    ))
                                }
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                "whitespace-nowrap py-4 pr-3 font-medium",
                                _selectedVoyages.includes(voyage.id)
                                  ? "text-ao-blue"
                                  : "text-ao-blue"
                              )}
                            >
                              {voyage.id}
                            </td>
                            {/* TODO - Link Vessel to Vessels - individual ship view */}
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {voyage.vessel.shipName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {voyage.routeLongName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {formatDateToDDMMYY(voyage.laycanEnd)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {voyage.cargo}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {dollarFormat(voyage?.initialCosting?.tce)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {dollarFormat(voyage.tce)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              -
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              -
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              -
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              B
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                              {Math.round(voyage.voyageLength)}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-rightfont-medium sm:pr-3">
                              <EditVoyageEngineButton voy={voyage} />
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-3">
                              <Link
                                to="/voyage-engine/optimizations"
                                className="underline"
                              >
                                Optimize
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {_voyages.length < 1 && (
                    <div className="w-1/2 pt-12 text-ao-figma-text-gray font-semibold text-lg mx-auto text-center">
                      {" "}
                      No Voyages Found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Grouping, Table and Charts */}
          {_selectedVoyages.length > 0 && (
            <>
              <div className="flex mt-4">
                <div className="flex items-center mr-4">
                  <input id="inline-radio-vessel" type="radio" value="byVessel" name="inline-radio-group" className="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVessel"} onChange={(ev) => setGroupingChoice(ev.target.value)}>
                  </input>
                  <label htmlFor="inline-radio-vessel" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Vessel</label>
                </div>
                <div className="flex items-center mr-4">
                  <input id="inline-radio-voyage" type="radio" value="byVoyage" name="inline-radio-group" className="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVoyage"} onChange={(ev) => setGroupingChoice(ev.target.value)}>
                  </input>
                  <label htmlFor="inline-radio-voyage" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Voyage</label>
                </div>
              </div>

            <div className="flex flex-row justify-between mt-16">
              <div className="w-1/2">
                <VoyageTableChart selectedVoyages={_voyageData} grouping={_groupingChoice} />
              </div>
              <div className="w-1/2">
                <VoyageFinancialsTable selectedVoyages={_voyageData} grouping={_groupingChoice} />
              </div>
            </div>
            </>
          )}
        </div>
      </div>
    </>
  );


  function handleSelectionChange(newSelection) {
    updateSelectedVoyages(newSelection)

    if (_selectedPortfolio != null) {
      saveVoyagePortfolio({ ..._selectedPortfolio, selectedVoyages: newSelection })
    }
  }

  function handleSelectPortfolio(portfolio) {
    updateSelectedVoyages(portfolio ? portfolio.selectedVoyages || [] : [])
  }

  function handleAddToPortfolio() {
    setPortfolioModalOpen(true)
  }

  function handleAddedToPortfolio(portfolio) {
    handleSelectPortfolio(portfolio)
  }

  function updateSelectedVoyages(newSelection) {
    setSelectedVoyages(newSelection)
    setVoyageData(_voyages.filter((v) => newSelection.includes(v.id)))
  }
}