import { MenuItem, Select } from "@material-ui/core"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { getRouteParamsIn } from "../../store/api"
import { availableRoutesFiltered, selectedRoutes, userSettings } from "../../store/ui"
import { replaceItemAtIndex } from "../../utils/Helpers"

export default function RouteSelect({index}) {
    const availableRoutes = useRecoilValue(availableRoutesFiltered)
    const [_selectedRoutes, setSelectedRoutes] = useRecoilState(selectedRoutes)
    console.log("avail routes in select", availableRoutes)
    const onChangeHandler = (event) => {
        const oldRoute = _selectedRoutes[index]
        const newRoute = availableRoutes.allRoutes.find((route) => route.routeID === event.target.value)
        
        // TODO: remember the vesselID associated with each route
        // Currently if we switch route, reset the vessel ID - to prevent having incompatible route/vessel
        const newSelectedRoutes = replaceItemAtIndex(_selectedRoutes, index, { ...oldRoute,
            item: newRoute.routeID, vesselID: null, balticPlatts:newRoute.balticPlatts, codeName: newRoute.codeName, shipType: newRoute.type})

        setSelectedRoutes(newSelectedRoutes)
    }

    const Routes = useRecoilValue(getRouteParamsIn)

    const getDisplayNameForSelectValue = (routeId) => {
        if (routeId in Routes) {
          return `${Routes[routeId]["shortName"]}`
        }
        return routeId
    }

    const getRouteSymbol = (routeId) => {
        if (routeId in Routes) {
            return Routes[routeId]["symbolMT"]
          }
        return ""
    }
  

    // If for some reason the selected route no longer exists, replace with empty string
    let selectedRouteForIndex = typeof _selectedRoutes[index] === "undefined" ? "" : _selectedRoutes[index].item
    
    // If the selected route is not in the filter, add it separately, and highlight
    // let selectedRouteIsNotInFilter = !availableRoutes.allRoutes.includes(selectedRouteForIndex)

    return (
        <div className="flex flex-col">
        <Select
            onChange={onChangeHandler}
            style={{fontSize:11, paddingLeft: 5}}
            value={selectedRouteForIndex}
            renderValue={(value) => getDisplayNameForSelectValue(value)}
            fullWidth
        >

            { availableRoutes?.plattsRoutes?.length > 0 &&<div className="border-b-2 p-1 border-gray-400 pointer-events-none font-semibold text-lg text-center"> SPGCI Routes </div>}
            { availableRoutes?.plattsRoutes?.length > 0 && 
                availableRoutes.plattsRoutes.map((route) => {
                    return <MenuItem key={route.routeID} value={route.routeID}>{`${route.description} (${getRouteSymbol(route.routeID)})`}</MenuItem>
                })  
            }

            { availableRoutes?.balticExRoutes?.length > 0 &&<div className="border-b-2 p-1 border-gray-400 pointer-events-none font-semibold text-lg text-center"> Baltic Exchange Routes </div>}
            { availableRoutes?.balticExRoutes?.length > 0 &&
                availableRoutes.balticExRoutes.map((route) => {
                    const routeId = route.routeID
                    return <MenuItem className="bg-ao-blue/50" key={routeId} value={routeId}>{route.description}</MenuItem>
                })
            }
        </Select>
        <div className="pl-1 text-xs text-left text-gray-400">
            {_selectedRoutes[index].shipType}
        </div>
        </div>
    )
}
