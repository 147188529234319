import React, { useState } from 'react';
import Select from 'react-select';
import ports from "../../dataMocks/port_list_by_shiptype_v3.json"

const PortConstraints = ({ updatePortConstraints, portConstraints, removePortConstraint }) => {
  const handleRemove = (option) => {
    removePortConstraint(option)
  }
  return (
    <div className='flex flex-col space-y-4'>
      <h4 className='text-lg font-semibold text-ao-figma-text-gray'>Allowed Bunker Ports</h4>

      <Select
        onChange={(e) => updatePortConstraints(e)}
        options={ports}
        getOptionLabel={(option) => `${option.portName}`}
        isSearchable
        placeholder="Select an option..."
      />

      {portConstraints.length === 0 ? 
        (
          <span className="text-ao-figma-text-gray text-sm pl-2">
            All ports allowed
          </span>
        )
       : (
        <div className="mt-2 w-full">
          <ul className='mb-2 list-disc pl-5 text-ao-figma-text-gray'>
            {portConstraints.map((value, index) => (
              <li className="relative" key={index}>
                {value}
                <button className="text-xs text-gray-400 absolute right-0" onClick={() => handleRemove(value)}> Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}

    </div>
  );
};

export default PortConstraints;