import React, {useEffect, useState} from "react"
import { allVessels } from "../../../store/vessels"
import { useRecoilValue} from "recoil"
import TanksFuelToggle from "./TanksFuelToggle"
import TankTimeline from "./TankTimeline"
import { Line } from 'rc-progress';
import TankLevel from "./TankTanksLevel"
import { getShipTankData } from "../../../store/api"
import { getRecoilPromise } from "recoil-nexus"
import AddStemButton from "./TanksAddStemButton"
import "./TankCss.css"

export default () => {
    const _realVessels = useRecoilValue(allVessels)
    const [_selectedShip, setSelectedShip] = useState(_realVessels[0])
    const [fuelType, setTankType] = useState("LSFO");
    const [tankData, setTankData] = useState([])
    const [fifoQueue, setFifoQueue] = useState([])
    const [stemQueue, setStemQueue] = useState([])
    const [overviewData, setOverviewData] = useState({})
    const [fuels, setFuels] = useState([])

    useEffect(() => {
        (async function () {
            const response = await getRecoilPromise(getShipTankData({shipName: _selectedShip.shipName, shipType: _selectedShip.shipType, id: _selectedShip.id}))
            console.log("tank response", response)
            const dataForFuelType = response[fuelType]
            setTankData(dataForFuelType["tankInfo"])
            setFifoQueue(dataForFuelType["fifoStems"])
            setStemQueue(dataForFuelType["stemQueue"])

            const fuelTypes = Object.keys(response)
            setFuels(fuelTypes)
            const overviewData = {}
            for (const type in fuelTypes){
                const fuel = fuelTypes[type]
                const tankData = response[fuel]["tankInfo"]
                let level = 0
                let capacity = 0
                for(const i in tankData){
                    const tank = tankData[i]
                    level += tank.level
                    capacity += tank.capacity
                }
                overviewData[fuel] = Math.round(level/capacity * 100)
            }
            setOverviewData(overviewData)
        })()
    }, [_selectedShip, fuelType])

    const headlineBox = (title, value) => {
        return (
            <div className="flex w-full">
                <div className="flex-1 p-2 pb-4 text-xl flex items-left">
                    <p className="text-ao-figma-text-gray text-left">{title}</p>
                </div>
                <div className="flex-1 p-2 pb-4 text-2xl flex items-center justify-end">
                    <p className="text-ao-figma-text-gray">{value}</p>
                </div>
            </div>
        )
    }

    const updateSelectedShip = (shipName) => {
        const selectedShip = _realVessels.filter((x) => x.shipName === shipName)[0]
        setSelectedShip(selectedShip)
    }

    const overview = (
        <div className="flex flex-col w-full pr-5 pb-5 pl-5 pt-2 border-r mr-3">
            <div className="mb-5 w-full"> 
                <select
                className="p-1 border border-1 rounded-md w-full"
                    onChange={(ev) => updateSelectedShip(ev.target.value)}
                >
                    {_realVessels.map((x) => (
                    <option key={x.shipName} value={x.shipName}>
                        {x.shipName}
                    </option>
                    ))}
                </select>
            </div>
            <div className="flex flex-col h-20 mt-5">
                {Object.keys(overviewData).map((x) => (
                    <div className="flex flex-col p-1">
                    <span className="text-ao-figma-text-gray font-semibold">
                        {x.toUpperCase()}
                    </span>
                    <div className="p-1 mb-2">
                        <Line percent={overviewData[x]} strokeWidth={1} strokeColor="#5C93C0" height={20} width={300}/>
                    </div>
                    </div>

                ))

                }
            </div>
        </div>
    )


    return (
        <div className="h-screen">
            <div className="grid grid-cols-4">
                <div className="flex col-span-1">
                   {overview}
                </div>
            <div className="flex col-span-3">
            {/* Fuel Toggle */}
                <div className="flex flex-col w-full">
                <div className="my-2 w-100 flex flex-row border-b-2 border-gray-300">
                            <TanksFuelToggle
                            setTankType={setTankType}
                            fuels={fuels}
                            />
                </div>
                <div className="flex flex-col">
                        <div className="text-xl mb-2 font-bold text-ao-figma-text-gray">
                            Tank Breakdown
                        </div>
                        <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                        <div className="w-full">
                            {tankData.length > 0 ? (
                                tankData.map((x) =>
                                        <TankLevel
                                        level={x.level}
                                        number={x.id}
                                        capacity={x.capacity}
                                        />
                                )
                            ): (
                                <p>
                                    No Data
                                </p>
                            )

                            }
                        </div>
                    </div>
                {/* Main */}
                <div className="grid grid-cols-2 gap-4 mt-10">
                    <div>
                    <div className="grid grid-cols-2 gap-2 items-center">
                            <div className="text-xl mb-2 font-bold text-ao-figma-text-gray">
                                Planned Stems
                            </div>
                            <AddStemButton vessel={_selectedShip} />
                        </div>
                        <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                        <div className="flex p-1" key="stemQueue">
                            <TankTimeline 
                            queue={stemQueue} 
                            isQueue={true}
                            shipName={_selectedShip}
                            fuelType={fuelType}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="text-xl mb-2 font-bold text-ao-figma-text-gray">
                            FIFO Queue
                        </div>
                        <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                        <div className="flex p-1" key="fifo">
                            <TankTimeline queue={fifoQueue}
                                            isQueue={false}
                                            shipName={_selectedShip}
                                            fuelType={fuelType}
                                            />
                        </div>
                    </div>
                </div>
                </div>

            </div>
            </div>

        </div>
    )

}