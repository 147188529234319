import { DateTime, Info } from "luxon"
import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { getRecoilPromise } from "recoil-nexus"
import { getAllSimulations, getRouteLookup, getRoutePrices } from "../../store/api"
import { selectedRoutes } from "../../store/ui"
import GenericLineChart from "./GenericLineChart"
import { buildForwardCurve, buildForwardCurveLower, buildForwardCurveUpper } from "../../routes/PricingEngine/routeHelper"

const colors = ["#d35400", "#f39c12", "#2ecc71", "#3498db", "#f1c40f", "#e6b0aa"]
const liquidDict = { "low": 0.02, "med": 0.01, "high": 0.005 }


export default function PricingsChart({ volatility, liquidity, data }) {

    const Tanker = useRecoilValue(getAllSimulations)
    const [_selectedRoutes] = useRecoilState(selectedRoutes)
    const [items, setItems] = useState([])
    const routeLookup = useRecoilValue(getRouteLookup)

    useEffect(() => {
        buildItems();
        async function buildItems() {
            setItems(await Promise.all(_selectedRoutes
                .filter((r) => r.selectedInPricingEngine)
                .map(async (selectedRoute, i) => {
                    const routeId = selectedRoute.item
                    const routePrices = await getRecoilPromise(getRoutePrices(routeId, volatility))
                                        const data_mean = []
                    const data_upper_ci = []
                    const data_lower_ci = []
                    const data_area = []
                    
                    // Build month rows
                    const currentMonth = DateTime.now().month - 1
                    const monthRows = []
                    for (let i = 0; i < 12; i++) {
                        monthRows.push(Info.months("short")[(currentMonth + i) % 12])
                    }

                    if(routePrices){
                        // Build data entries for charts
                        const forwardCurve = buildForwardCurve(routePrices)
                        const forwardCurveUpper = buildForwardCurveUpper(routePrices)
                        const forwardCurveLower = buildForwardCurveLower(routePrices)
                        for (let monthIdx = 0; monthIdx < 12; monthIdx += 1) {

                            data_mean.push({ x: monthRows[monthIdx], y:  forwardCurve[monthIdx]})
                            data_upper_ci.push({ x: monthRows[monthIdx], y: forwardCurveUpper[monthIdx] })
                            data_lower_ci.push({ x: monthRows[monthIdx], y: forwardCurveLower[monthIdx] })        

                            data_area.push({
                                x: monthRows[monthIdx],
                                y: data_mean[monthIdx].y + liquidDict[liquidity] * data_mean[monthIdx].y,
                                y0: data_mean[monthIdx].y - liquidDict[liquidity] * data_mean[monthIdx].y
                            })

                        }
                    }
                    
                    return {
                        title: routeLookup.find((r) => r.routeID === routeId).longName,
                        color: colors[i % colors.length],
                        mean: data_mean,
                        upper: data_upper_ci,
                        lower: data_lower_ci,
                        area: data_area,
                        isPlattsRoute: routePrices ? routePrices.plattsRoute : false,
                        routeId: routeId
                    }
                })))
        }
    }, [_selectedRoutes, liquidity, volatility, Tanker])
    
    return (
        <>
        <GenericLineChart
            type={"rates"} routes={_selectedRoutes} items={items} />
        <div className="grid grid-cols-4">
            {data.map((route, i) => (
                <FreightRateLegend
                    port={route.port}
                    grade={route.fuelType}
                    isSPGCI={route.provider === "SPGCI"}
                    constructedSpot={route.constructedSpot}
                    constructedCurve={route.constructedCurve}
                    partiallyConstructedCurve={!(route.forwardCurveData.reduce((acc, point) => acc && point.constructed, true))}
                    providerData={route.providerData}
                    color={colors[i]}
                    key={`${route.port} ${i}`}
                />
            ))}
        </div>
        </>
    )
}

function FreightRateLegend({isSPGCI, constructedSpot, constructedCurve, partiallyConstructedCurve, providerData, color}){

    const meanLineStyle = {
        // width: '100px', 
        height: '3px',
        backgroundColor: `${color}`,
        marginTop: '10px',
    }

    const ciLineStyle = {
        height: '1px', 
        border: `2px dashed ${color}`,
        marginTop: '6px',
    };

    let spotDesc, forwardDesc
    if(isSPGCI && !constructedSpot){
        spotDesc = `SPGCI (${providerData.spotSymbol})`
    } else if (isSPGCI && constructedSpot){
        spotDesc = "Derived from SPGCI Data"
    } else if (!isSPGCI && !constructedSpot){
        spotDesc = "Baltic Exchange"
    } else if (!isSPGCI && constructedSpot){
        spotDesc = "Derived from Baltic Exchange Data"
    }

    if(isSPGCI && partiallyConstructedCurve && constructedCurve){
        forwardDesc = `Extrapolated from SPGCI Data`
    } else if (!isSPGCI && partiallyConstructedCurve && constructedCurve){
        forwardDesc = "Extrapolated from Baltic Exchange Data" 
    }else if (isSPGCI && constructedCurve){
        forwardDesc = "Derived from SPGCI Data"
    } else if(!isSPGCI && constructedCurve){
        forwardDesc = "Derived from Baltic Exchange Data"
    } else if (isSPGCI && !constructedCurve){
        forwardDesc = "SPGCI" //needs to be updated to show forward curve symbol when db updated
    } else if (!isSPGCI && !constructedCurve){
        forwardDesc = "Baltic Exchange"
    }
   

    return (
        <div className="flex flex-col p-2">
            <div className="w-full" style={meanLineStyle}></div>
            <p className="text-xs">
                <span className="font-semibold">Route</span>: {providerData.spotDescr}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Spot</span>: {spotDesc}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Curve</span>: {forwardDesc}
            </p>
        </div>
    )


}