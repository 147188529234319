import {
  AreaSeries,
  ChartLabel,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import "../../../node_modules/react-vis/dist/style.css";
import {
  availableBunkersFilter, 
} from "../../store/ui";
import SPGCIDisclaimer from "../SPGCIDisclaimer";


const gridLines = {
  stroke: "#6b6b76",
};

const axis = {
  line: { stroke: "#6b6b76" },
  ticks: { stroke: "#6b6b76" },
  text: { stroke: "none", fill: "#6b6b76", fontWeight: 600 },
  title: { stroke: "none", fill: "#6b6b76", fontWeight: 600 },
};

const line = {
  strokeLinejoin: "round",
  strokeWidth: 2,
  fill: "none",
};
const lineLim = {
  strokeWidth: 4,
  fill: "none",
  strokeStyle: "dashed",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ routes, items, type, margins, isTCE = false }) => {
  const [_availableBunkersFilter, setAvailableBunkerFilter] = useRecoilState(availableBunkersFilter);

  let keyIndex = 0;
  const lineSeries = [];
  const areaSeries = [];

  for (const item of items) {
    lineSeries.push(
      <LineSeries
        color={item.color}
        data={item.mean}
        style={line}
        key={keyIndex++}
      />
    );
    lineSeries.push(
      <LineSeries
        color={item.color}
        data={item.upper}
        style={line}
        strokeStyle={"dashed"}
        key={keyIndex++}
      />
    );
    lineSeries.push(
      <LineSeries
        color={item.color}
        data={item.lower}
        style={line}
        strokeStyle={"dashed"}
        key={keyIndex++}
      />
    );
    areaSeries.push(
      <AreaSeries
        color={item.color}
        data={item.area}
        key={keyIndex++}
        opacity={0.5}
      />
    );
  }

  /*
   * The following code is designed to overcome a bug in react-vis
   * FlexibleWidthXYPlot, which was causing the plot to go to 0 sometimes
   * when changing the data.
   */
  const [_chartWidth, setChartWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    function handleResize() {
      const newWidth = ref.current ? ref.current.offsetWidth : 0;
      setChartWidth(newWidth);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="flex flex-col pl-1">
      <span className="font-bold text-ao-figma-text-gray text-l p-1">Forward Curves</span>
        <div ref={ref} className="h-100 f-100 pl-5 w-full">
          <XYPlot height={600} width={_chartWidth} xType="ordinal" margin={margins}>
            <VerticalGridLines style={gridLines} />
            <HorizontalGridLines style={gridLines} />
            <XAxis style={axis} tickLabelAngle={-90}/>
            <YAxis style={axis} />
            <ChartLabel
              text="$"
              className="alt-y-label"
              includeMargin={false}
              xPercent={-0.1}
              yPercent={0.6}
              style={{
                transform: "rotate(-90)",
                fill: "#6b6b76",
              }}
            />
            {lineSeries}
            {areaSeries}
          </XYPlot>
        </div>
        {!isTCE && <div className="flex flex-col">
          <p className="text-xs ml-2 text-gray-500">Note: 90% confidence intervals for future spot prices are plotted in dotted lines of the same color as the corresponding forward curve</p>
        </div>}
      </div>
    </>
  );
};
