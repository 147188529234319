import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { ManageSpgci } from "./ManageSpgci";
import { SettingsForm } from "./SettingsForm";

export function Settings() {
  let { path, url } = useRouteMatch();
  return (
    <div className="px-4">
      <Switch>
        <Route exact path={path}>
          <SettingsForm />
        </Route>
        <Route path={`${path}/spgci`}>
          <ManageSpgci />
        </Route>
      </Switch>
    </div>
  );
}
