import { React, useState } from 'react'
import SideNav from '../SideNav/SideNav'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import PageTitle from '../PageTitle/PageTitle'

export default function AppLayout({ routes, children }) {

  return (
    <>
      <div>
        <SideNav />
        {/* Main Application layout wrapper */}
        <div className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-12">
            <div className='flex flex-col gap-y-2 mb-4'>
              <BreadCrumbs routes={routes}/>
              <PageTitle routes={routes}/>
            </div>
             { children }  
          </div>
        </div>
      </div>
    </>
  )
}
