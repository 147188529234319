import React, { useState, useEffect } from "react";
import RouteFilter from "../../components/RouteFilter/RouteFilter";
import TCEChart from "../../components/Charts/TCEChart";
import { useRecoilValue } from "recoil";
import { availableRoutesFilter, userSettings } from "../../store/ui";
import { getRecoilPromise } from "recoil-nexus";
import { selectedRoutes } from "../../store/ui";
import { getAllSimulations, getRouteParamsIn } from "../../store/api";
import { customisedModelVesselForSelectedRoutes } from "../../store/vessels";
import { availableBunkersFilter } from "../../store/ui";
import { DATA_SOURCE, ProviderStatus } from "../../utils/Constants";
import { getRouteTceComps } from "../../store/api";
import {
  BunkerMultiplier,
  JourneyTimeMultiplier,
} from "../../components/Multipliers/Multipliers";
import { TCEPriceTable } from "../PricingEngine/TCEPriceTable";
import { replaceItemAtIndex } from "../../utils/Helpers";
import { spgciStatus, bunkerExStatus, balticStatus } from "../../store/integrations";
import { NoProviders } from "../PricingEngine/NoProviders";

// eslint-disable-next-line import/no-anonymous-default-export
const PETCEs = () => {
  const _userSettings = useRecoilValue(userSettings);
  const _selectedRoutes = useRecoilValue(selectedRoutes);
  const Tanker = useRecoilValue(getAllSimulations);
  const multipliers = useRecoilValue(customisedModelVesselForSelectedRoutes);
  const _availableRoutesFilter = useRecoilValue(availableRoutesFilter)
  const _availableBunkersFilter = useRecoilValue(availableBunkersFilter)
  const [_vol, setVol] = useState(_userSettings.volatility);
  const [_liq, setLiq] = useState(_userSettings.liquidity);
  const _spgciStatus = useRecoilValue(spgciStatus);
  const _bunkerExStatus = useRecoilValue(bunkerExStatus);
  const _balticStatus = useRecoilValue(balticStatus);
  const [data, setData] = useState([
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
  ]);
  const routeParams = useRecoilValue(getRouteParamsIn)

  useEffect(() => {
    (async function () {
      _selectedRoutes.forEach(async (route, index) => {
                const apiData = await getRecoilPromise(
          getRouteTceComps({
            route: route.item,
            vol: _vol,
            dataSource: (route.balticPlatts === "Baltic") ? "BunkerEx" : "SPGCI",
            excludeEmissionsCosts: true,
          })
        );

        let newData;
        if (apiData) {
          const { spot, curve, spotDate } = processData(
            apiData,
            route.item,
            index
          );
          let routeDesc = ""
          if(routeParams.hasOwnProperty(route.item)){
            routeDesc = routeParams[route.item].description
          }
          newData = {
            spotPrice: spot,
            spotDate: spotDate,
            constructedSpot: true,
            forwardCurveData: curve,
            constructedCurve: true,
            provider: route.balticPlatts === "Platts" ? "SPGCI" : "BunkerEx",
            providerData: {},
            routeDesc,
            status: "available",
          };
        } else {
          newData = unavailablePriceData("unavailable");
        }

        setData((d) => replaceItemAtIndex(d, index, newData));

        function processData(apiData, routeId, index) {
          const {
            cfBunker,
            cfBunkerHs,
            cfFreight,
            cfEmissions,
            cfOther,
            dates,
          } = apiData;
          let params = multipliers[index];
          const bunker = params["scrubber"] === 0 ? cfBunker : cfBunkerHs;
          let bunkerMult = BunkerMultiplier(routeId, params);
          let freightMult = multipliers[index]["modelShipRatesMultiplier"];
          let timeMult = JourneyTimeMultiplier(routeId, params);
          const curve = [];


          const spot =
            (bunkerMult * bunker[0] +
              freightMult * cfFreight[0] +
              // 0.0 * cfEmissions[0] +
              cfOther) /
            timeMult /
            30;

          for (let c = 1; c < 12; c++) {
            const tce =
              (bunkerMult * bunker[c] +
                freightMult * cfFreight[c] +
                // 0.0 * cfEmissions[c] +
                cfOther) /
              timeMult /
              30.0;
            curve.push({
              constructed: true,
              price: tce,
              yearMonth: dates[c],
            });
          }
          return { spot, curve, spotDate: dates[0] };
        }
      });
    })();
  }, [_selectedRoutes, _vol, _liq, Tanker, _availableRoutesFilter, _availableBunkersFilter]);

  const spgciActive = _spgciStatus === ProviderStatus.ACTIVE
  const bunkerExActive = _bunkerExStatus === ProviderStatus.ACTIVE
  const balticActive = _balticStatus === ProviderStatus.ACTIVE

  if(!spgciActive || !(bunkerExActive && balticActive)){
    return <NoProviders
              spgciFailed={!spgciActive}
              bunkerExFailed={!bunkerExActive}
              balticFailed={!balticActive}
              page={"TCE data"}
            />
  }


  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <div className="h-full flex flex-col gap-y-2  border-r-2 border-gray-300">
          <div className="flex flex-row gap-x-2 border-b-1 border-gray-300 align-middle">
            <RouteFilter tce />
            <button
              type="button"
              className="py-1 px-4 rounded-md border font-semibold border-ao-blue hover:bg-ao-blue hover:text-white"
            >
              Set Alerts
            </button>
          </div>
          <TCEPriceTable data={data} />
        </div>
        <div className="h-full flex-1 w-3/5">
          <TCEChart
            volatility={_vol}
            liquidity={_liq}
            excludeEmissionsCosts={true}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default PETCEs;

function unavailablePriceData(status) {
  return {
    spotPrice: null,
    spotDate: "N/A",
    constructedSpot: false,
    forwardCurveData: Array.from({ length: 12 }, (_) => ({})),
    constructedCurve: false,
    providerData: {},
    routeDesc: "N/A",
    status,
  };
}
