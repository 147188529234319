import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FUEL_TYPES_ARR } from "../../../utils/Constants"
import {
    XMarkIcon
  } from "@heroicons/react/24/outline";
import { string } from "yup";
import { convertToSentenceCase } from "../../../utils/Helpers";

  

export default function AddStemModal({
    stem,
    isEdit,
    vessel,
    modalOpen,
    closeModal,
    setStemData
}) {
    const initialValues = {
        quantity: stem?.quantity || 0,
        price: stem?.price ||0,
        shipName: vessel,
        fuelType: stem?.fuelType || "LSFO",
        supplier: stem?.supplier || "",
        stemDate: stem?.stemDate || "",
        recordedDate: stem?.recordedDate || "",
        port: stem?.port || "",
        id: stem?.id || ""
      };

          const validate = (values) => {
        const errors = {};
        if (values.quantity <= 0) {
          errors.quantity = 'Quantity must be greater than 0';
        }
        if (values.price <= 0) {
          errors.price = 'Price must be greater than 0';
        }
        return errors;
      };

      const onSubmit = (values, { resetForm }) => {
        values.shipName = convertToSentenceCase(vessel.shipName)
        values.load_order = null
        if(isEdit){
          values.id = stem.id
        }
        closeModal(values)
      };

      const QuantityPriceForm = (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="grid grid-cols-2">
            <div className="flex flex-col rounded-tl-lg gap-y-2 pb-2 border border-l-2 border-t-2 p-2 border-gray-300">
              <label className='text-ao-blue rounded text-lg text-semibold ' htmlFor="recordedDate">Recorded Date:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="date"
                id="recordedDate"
                name="recordedDate"
                placeholder="Enter Recorded Date"
              />
              <ErrorMessage name="recordedDate" component="div" />
            </div>
            <div className="flex flex-col rounded-tr-lg gap-y-2 border border-r-2 border-t-2 p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold' htmlFor="ship">Ship:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="text"
                id="shipName"
                name="shipName"
                readOnly={true}
                value={vessel.shipName}
                placeholder="Enter Ship"
              />
              <ErrorMessage name="shipName" component="div" />
            </div>
            <div className="flex flex-col gap-y-2 border border-l-2 p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold'  htmlFor="ship">Port:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="text"
                id="port"
                name="port"
                placeholder="Enter Port"
              />
              <ErrorMessage name="port" component="div" />
            </div>
            <div className="flex flex-col gap-y-2 border-r-2 border p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold'  htmlFor="stemDate">Stem Date:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="date"
                id="stemDate"
                name="stemDate"
                placeholder="Enter Stem Date"
              />
              <ErrorMessage name="stemDate" component="div" />
            </div>
            <div className="flex flex-col gap-y-2 border-l-2 border p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold'  htmlFor="supplier">Supplier:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="text"
                id="supplier"
                name="supplier"
                placeholder="Enter Supplier"
              />
              <ErrorMessage name="stemDate" component="div" />
            </div>
            <div className="flex flex-col gap-y-2 border-r-2 border p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold'  htmlFor="fuelType">Fuel Type:</label>
              <Field
                className='w-1/2 py-1 rounded-lg '
                as='select'
                id="fuelType"
                name="fuelType"
                placeholder="Enter Fuel Type"
              >
                {FUEL_TYPES_ARR.map((f) => 
                  <option value={f.name}>{f.caption}</option>
                )}
              </Field>
              <ErrorMessage name="stemDate" component="div" />
            </div>
            <div className="flex flex-col rounded-bl-lg gap-y-2 border-l-2 border-b-2  border p-2 border-gray-300">
              <label className={`${isEdit ? "text-gray-400" : "text-ao-blue"} text-lg text-semibold`}  htmlFor="quantity">Quantity:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="number"
                id="quantity"
                name="quantity"
                disabled={isEdit}
                placeholder="Enter quantity"
              />
              <ErrorMessage name="quantity" component="div" />
            </div>
            <div className="flex flex-col rounded-br-lg gap-y-2 border-r-2 border-b-2 border p-2 border-gray-300">
              <label className='text-ao-blue text-lg text-semibold'  htmlFor="price">Price:</label>
              <Field
                className='w-1/3 py-1 rounded-lg '
                type="number"
                id="price"
                name="price"
                placeholder="Enter price"
              />
              <ErrorMessage name="price" component="div" />
            </div>
            </div> <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="inline-flex w-1/4 mx-auto justify-center rounded-md bg-ao-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-ao-blue"
                // onClick={() => closeModal()}
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      );
    
    return(
        <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-1/2 p-6 overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                  {/* close button */}
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-ao-blue focus:ring-offset-2"
                      onClick={() =>{
                        
                        closeModal()}}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* modal content */}
                  <div className="flex flex-col">
                    <h2 className="text-2xl font-semibold text-ao-blue mb-4">
                      Add Stem:
                    </h2>
                    {QuantityPriceForm}
                  </div>

                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
}