import { formatDateToYYYYMMDD, getCanalBool, createDateFromFormat } from "../../../utils/Helpers"

export function formatVoyageRecosting(voyage){

    const reformatVoyage =  {"id": voyage.id,
    "open": voyage.openPort,
    "bunker_1": voyage.bunkerPort,
    "bunker_2": null,
    "load": voyage.loadingPort,
    "discharge": voyage.dischargePort,
    "discharge_date": formatDateToYYYYMMDD(voyage.dischargeDate),
    "open_date": formatDateToYYYYMMDD(voyage.openDate),
    "laycan_end": formatDateToYYYYMMDD(voyage.laycanEnd),
    "laden_speed": voyage.ladenSpeed ,
    "ballast_speed": voyage.ballastSpeed,
    "weather_adjustment": voyage.seaMargin/100,
    "allow_canals": getCanalBool(voyage.canalConstraints),
    "load_days": voyage.loadDays,
    "discharge_days": voyage.dischargeDays,
    "waiting_days": voyage.waitingDays,
    "canal_days": voyage.canalDays,
    "bunker_days": 0.5,
    "freight_rate_type": voyage.freightType,
    "freight_rate": voyage.freightRate,
    "cargo_quantity": voyage.cargoQuantity,
    "differentials": voyage.differentials,
    "commission_percent": voyage.commission,
    "load_port_charges": voyage.portCosts,
    "discharge_port_charges": voyage.portCosts2,
    "time_charter_cost": voyage.timeCharterCost,
    "quoted_co2_price": voyage.co2Price,
    "fo_type": voyage.vessel.scrubber ? "HSFO" : "LSFO",
    "emissions_percent_eu": voyage.emissionsRegsEu,
    "emissions_percent_non_eu": 0,
    "dwt": voyage.vessel.dwt,
    "flatrate": voyage.flatRate ? voyage.flatRate : 47.41,
    "taxes": voyage.taxes,
    "cargo": voyage.cargo}

    const ifoType = voyage.vessel.scrubber ? "HSFO" : "LSFO"
    reformatVoyage["initial_costing"] = convertKeys(voyage.initialCosting, INITIAL_COSTING_KEYS)

    if(voyage.fuels){
        const fuelsCurrent = {}
        for(const fuel of Object.keys(voyage.fuels)){
            fuelsCurrent[fuel] = convertKeys(voyage.fuels[fuel], FUEL_KEYS)
        }
        const fuelsInitial = {}
        for(const fuel of Object.keys(voyage.initialCosting.fuels)){
            fuelsInitial[fuel] = convertKeys(voyage.initialCosting.fuels[fuel], FUEL_KEYS)
        }

        reformatVoyage["fuels"] = convertFuel(fuelsCurrent, fuelMapping)
        reformatVoyage["initial_costing"]["fuels"] = convertFuel(fuelsInitial, fuelMapping)
        reformatVoyage["initial_costing"]["bunker_costs"] = convertFuel(voyage.initialCosting.bunkerCosts, fuelMapping)
        reformatVoyage["initial_costing"]["rob_costs"] = convertFuel(voyage.initialCosting.robCosts, fuelMapping)
    } else {
        reformatVoyage["fuels"] =  { ifoType: {
            "requirement": voyage.fuels[ifoType].requirement ? voyage.fuels[ifoType].requirement : 0,
            "quoted_price": 690,
            "burn_order": []
        },
        "MGO": {
            "requirement": voyage.fuels.MGO.requirement ? voyage.fuels.MGO.requirement : 0,
            "quoted_price": 950,
            "burn_order": []
        }}
    }
    return reformatVoyage
}

const FUEL_KEYS = [
    ["averagePrice", "average_price"],
    ["averagePriceMarket", "average_price_market"],
    ["burnOrder", "burn_order"],
    ["quotedPrice", "quoted_price"],
    ["requirement", "requirement"]
]

const INITIAL_COSTING_KEYS = [
    ["PortAndOtherCosts", "port_and_other_costs"],
    ["bunkerCost", "bunker_cost"],
    ["bunkerCosts", "bunker_costs"],
    ["carbonConsumption", "carbon_consumption"],
    ["dischargeDate", "discharge_date"],
    ["emissionsCost", "emissions_cost"],
    ["etaBunkerPort", "eta_bunker_port"],
    ["etaLoadPort", "eta_load_port"],
    ["pnlPerDay", "pnl_per_day"],
    ["pnlTotal", "pnl_total"],
    ["portAndOtherCosts", "port_and_other_costs"],
    ["robCosts", "rob_costs"],
    ["tce", "tce"],
    ["totalCost", "total_cost"],
    ["totalRevenues", "total_revenues"],
    ["voyageLength", "voyage_length"],
    ["id", "id"]
]

const fuelMapping = {
    lsfo: "LSFO",
    mgo: "MGO",
    hsfo: "HSFO"
}

function convertFuel(fuel, keys){
    const updatedFuel = {}
    for(const key of Object.keys(fuel)){
        updatedFuel[keys[key.toLowerCase()]] = fuel[key]
    }
    return updatedFuel
}

function convertKeys(item, keys) {

    const updatedObject = {}
    for(const pair of keys){
        updatedObject[pair[1]] = item[pair[0]]
    }
    return updatedObject

}


export function formatVoyage(voyage){
        return {"id": voyage.id,
        "open": voyage.openPort,
        "bunker_1": voyage.bunkerPort,
        "bunker_2": null,
        "load": voyage.loadingPort,
        "discharge": voyage.dischargePort,
        "dischargeDate": formatDateToYYYYMMDD(voyage.dischargeDate),
        "open_date": formatDateToYYYYMMDD(voyage.openDate),
        "laycan_end": formatDateToYYYYMMDD(voyage.laycanEnd),
        "laden_speed": voyage.ladenSpeed ,
        "ballast_speed": voyage.ballastSpeed,
        "weather_adjustment": voyage.seaMargin/100,
        "allow_canals": getCanalBool(voyage.canalConstraints),
        "load_days": voyage.loadDays,
        "discharge_days": voyage.dischargeDays,
        "waiting_days": voyage.waitingDays,
        "canal_days": voyage.canalDays,
        "bunker_days": 0.5,
        "freight_rate_type": voyage.freightType,
        "freight_rate": voyage.freightRate,
        "cargo_quantity": voyage.cargoQuantity,
        "differentials": voyage.differentials,
        "commission_percent": voyage.commission,
        "load_port_charges": voyage.portCosts,
        "discharge_port_charges": voyage.portCosts2,
        "time_charter_cost": voyage.timeCharterCost,
        "quoted_ifo_price": voyage.ifoBunkerPrice,
        "quoted_mgo_price": voyage.mgoBunkerPrice,
        "quoted_co2_price": voyage.co2Price,
        "fo_type": voyage.vessel.scrubber ? "HSFO" : "LSFO",
        "emissions_percent_eu": voyage.emissionsRegsEu,
        "emissions_percent_non_eu": 0,
        "dwt": voyage.dwt,
        "rob_ifo": voyage.robIFO,
        "rob_mgo": voyage.robMGO,
        "flatrate": voyage.flatRate ? voyage.flatRate : 47.41,
        "taxes": voyage.taxes,
        "cargo": voyage.cargo,
        "fuels": {
            "LSFO": {
                "requirement": 0,
                "quoted_price": voyage.ifoBunkerPrice,
                "burn_order": []
            },
            "MGO": {
                "requirement": 0,
                "quoted_price": voyage.mgoBunkerPrice,
                "burn_order": []
            }
        }}
}

export function updateServicesApiCalcs(originalVoyage, servicesApiCalcs, voyageCreation){
    const updatedVoyage = {...originalVoyage}
        
    // Logistics
    updatedVoyage["dischargeDate"] = createDateFromFormat(servicesApiCalcs.dischargeDate)
    updatedVoyage["voyageLength"] = servicesApiCalcs.voyageLength
    updatedVoyage["etaBunkerPort"] = createDateFromFormat(servicesApiCalcs.etaBunkerPort)
    updatedVoyage["etaLoadPort"] = createDateFromFormat(servicesApiCalcs.etaLoadPort)
    
    // Financials - Top Line
    updatedVoyage["totalRevenues"] = servicesApiCalcs.totalRevenues

    // Financials - Bottom Line
    updatedVoyage["portAndOtherCosts"] = servicesApiCalcs.portAndOtherCosts
    updatedVoyage["totalCost"] = servicesApiCalcs.totalCost

    // TCEs
    updatedVoyage["tce"] = servicesApiCalcs.tce
    updatedVoyage["pnlTotal"] = servicesApiCalcs.pnlTotal
    updatedVoyage["pnlPerDay"] = servicesApiCalcs.pnlPerDay
    
    // BUNKERS
    updatedVoyage["emissionsCost"] = servicesApiCalcs.emissionsCost
    updatedVoyage["bunkerCost"] = servicesApiCalcs.bunkerCost
    updatedVoyage["fuels"] = servicesApiCalcs.fuels
    updatedVoyage["carbonConsumption"] = servicesApiCalcs.carbonConsumption

    if(voyageCreation){
        updatedVoyage["initialCosting"] = {
            ...servicesApiCalcs,
            initialLoad: updatedVoyage.loadingPort,
            initialDischarge: updatedVoyage.dischargePort,
            initialBunkerPort: updatedVoyage.bunkerPort
        }
    } else{
        updatedVoyage["bunkerCostsWorstCase"] = servicesApiCalcs.bunkerCostsWorstCase
        updatedVoyage["bunkerCostsBestCase"] = servicesApiCalcs.bunkerCostsBestCase
        updatedVoyage["robCosts"] = servicesApiCalcs.robCosts
        updatedVoyage["robCostsWorstCase"] = servicesApiCalcs.robCostsWorstCase
        updatedVoyage["robCostsBestCase"] = servicesApiCalcs.robCostsBestCase
        updatedVoyage["tceEffects"] = servicesApiCalcs.tceEffects
        updatedVoyage["tceEffectsEmissions"] = servicesApiCalcs.tceEffectsEmissions
        updatedVoyage["deltaTce"] = servicesApiCalcs.deltaTce
        updatedVoyage["commissionCost"] = servicesApiCalcs.commission
        updatedVoyage["portCosts"] = servicesApiCalcs.portCosts
        updatedVoyage["uncoveredBunkers"] = servicesApiCalcs.uncoveredBunkers
        updatedVoyage["bunkerCosts"] = servicesApiCalcs.bunkerCosts
        updatedVoyage["soxConsumption"] = servicesApiCalcs.soxConsumption
        updatedVoyage["noxConsumption"] = servicesApiCalcs.noxConsumption
        updatedVoyage["mmbtuConsumption"] = servicesApiCalcs.mmbtuConsumption
    }
    return updatedVoyage
}