import {useEffect, useState} from "react"

import {findProfile, verifyToken} from "../../methods/users"

import {useTime} from "../"
import {useCookies} from "react-cookie"

const useAuth = () => {
    const [auth, setAuth] = useState({
        isAuthed: null,
        loading: true
    })
    const [cookie, , removeCookie] = useCookies([
        "aft_auth_username",
        "aft_auth_userId",
        "aft_auth_token"
    ])
    const now = useTime(1_800_000)

    useEffect(() => {

        if ( process.env.NODE_ENV === "development" ) {
            setAuth((s) => ({
                ...s,
                isAuthed: true,
                username: "aft-dev",
                userId: "zombie",
                token: "112358",
                loading: false
            }))
            return
        }
        if ( process.env.NODE_ENV === "production" ) {
            setAuth((s) => ({
                ...s,
                isAuthed: true,
                username: "aft-dev",
                userId: "zombie",
                token: "112358",
                loading: false
            }))
            return
        }
        if(!auth.isAuthed || !cookie.aft_auth_token || !cookie.aft_auth_userId) { return }
        setAuth((s) => ({ ...s, loading: true }))
        findProfile(cookie.aft_auth_userId, cookie.aft_auth_token).then((profile) => {
            setAuth((s) => ({
                ...s,
                profile,
                loading: false
            }))
        }, () => {
            removeCookie("aft_auth_userId")
            removeCookie("aft_auth_username")
            removeCookie("aft_auth_token")
            document.cookie = "aft_auth_userId="
            document.cookie = "aft_auth_username=;"
            document.cookie = "aft_auth_token=;"
        })
    }, [auth.isAuthed, cookie.aft_auth_username, cookie.aft_auth_userId, cookie.aft_auth_token, removeCookie])

    useEffect(() => {
        setAuth((s) => ({ ...s, loading: true }))
        if(!cookie.aft_auth_username) {
            setAuth((s) => ({ ...s, loading: false }))
            return
        }
        verifyToken(cookie.aft_auth_username, cookie.aft_auth_token).then((isAuthed) => {
            setAuth((s) => ({
                ...s,
                isAuthed,
                username: cookie.aft_auth_username,
                userId: cookie.aft_auth_userId,
                token: cookie.aft_auth_token,
                loading: false
            }))
        }, () => {
            removeCookie("aft_auth_userId")
            removeCookie("aft_auth_username")
            removeCookie("aft_auth_token")
            document.cookie = "aft_auth_userId="
            document.cookie = "aft_auth_username=;"
            document.cookie = "aft_auth_token=;"
            setAuth({ isAuthed: false, loading: false })
        })
    }, [now, cookie.aft_auth_username, cookie.aft_auth_userId, cookie.aft_auth_token, removeCookie])

    return auth
}

export default useAuth
