
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import { ThemeProvider } from "@mui/material"
import "flatpickr/dist/themes/light.css"
import React, { useState } from "react"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { getDischargingPorts } from "../../../store/api"
import { SmallerTextTheme as theme } from "../../../theme"
import { FreightTypes } from "../../../utils/Constants"
import { usdFormatter, dollarFormat, ceilThousands } from "../../../utils/Helpers"
import { recostVoyages, regions } from "./VoyageEngineLogic"
import "./modal.css"
import VoyageManagementTCE from "../../../components/Charts/VoyageManagementTCE"
import VoyageManagementRisk from "../../../components/Charts/VoyageManagementRisk"
import { getvoyageCalcs } from "./VoyageEngineLogic"
import { updateServicesApiCalcs } from "../../../store/api/helpers/services-api-helpers"
import { useRecoilState, useRecoilValue } from "recoil"
import TceRow from "./TceEffectsRow"
import { voyages } from "../../../store/voyages"



const ViewModes = {
    INITIAL: "INITIAL",
    CURRENT: "CURRENT"
}

const contentModes = {
    voyageSummary: "Voyage Summary",
    bunkerSummary: "Bunker Summary",
    financialSummary: "TCE Breakdown"
}

export function VoyageEngineManagementModal({ voyageInput, modalOpen, closeModal, onSave, isEdit, defaultFields, validationSchema }) {

    const [_viewMode, setViewMode] = useState(isEdit ? ViewModes.CURRENT : ViewModes.INITIAL)
    const [contentMode, setContentMode] = useState(contentModes.voyageSummary)
    const [voyage, setVoyage] = useState(voyageInput)
    const portList = useRecoilValue(getDischargingPorts({shipName: voyageInput.vessel, region: voyageInput.dischargePort}))
    const [_voyages, setVoyages] = useRecoilValue(voyages)
    

    const handleChange = async (port)=> {
        const temp = {...voyage, dischargePort: port}
        const servicesApiCalcs = await getvoyageCalcs(temp, false)
        let updatedVoyage = updateServicesApiCalcs(temp, servicesApiCalcs, false)
        setVoyage(updatedVoyage)
        await recostVoyages(updatedVoyage.vessel)
    }

    function TextField (value, label) {

        return (
            <div className=" p-1">
                <label className="text-sm text-ao-blue">
                    {label}
                </label>
                <div className="relative">
                    <input
                        className={`block w-full ${isEdit ? "w-[200px]":"min-w-[245px]"} border-b border-r px-1 py-1 pr-8 focus:outline-none focus:ring focus:border-blue-300 bg-white`}
                        type="text"
                        size="small"
                        name={label}
                        defaultValue={value == null ? "" : String(value)}
                        placeholder="Client name"
                        readOnly={true}
                    />
                </div>
            </div>
        )
    }

    function PortConfirmCheckbox (value, label) {

        return (
            <div className=" w-full min-h-full p-1 mb-[.66rem]">
                <label className="text-sm text-ao-blue">
                    {label}
                </label>
                <div className="relative flex flex-row justify-center">
                    <input
                        className="text-center w-full border-b border-r focus:outline-none bg-white"
                        type="checkbox"
                        size="small"
                        name={label}
                        defaultValue={value == null ? "" : String(value)}
                        onChange={(e) => {
                            const value = e.target.value;
                            console.log(`Clicked: ${value}`)
                        }}
                        readOnly={false}
                    />
                </div>
            </div>
        )
    }

    function MinMaxTextField(value, label, unit) {
        return (
            <div className=" p-1">
                <label className="text-sm text-ao-blue">
                    {label}
                </label>
                <div className="relative">
                    <input
                        className={`block ${isEdit ? "min-w-[200px]" : "min-w-[245px]"} border-r border-b focus:outline-none focus:ring focus:border-blue-300 bg-white text-gray-500`}
                        size="small"
                        name={label}
                        defaultValue={value}
                        readOnly={false}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <span className="text-gray-400">{unit}</span>
                    </div>
                </div>
            </div>
        )
    }

    function DateField(fieldDate, label) {

        const flatpickrConfig = {
            theme: "light",
            dateFormat: "d/m/y",
            defaultDate: fieldDate,
        }

        return (
            <div className="bg-white p-1">
                <label className="text-sm text-ao-blue">{label}</label>
                <div className="bg-white">
                    <Flatpickr
                        options={flatpickrConfig}
                        className={`${isEdit ? "min-w-[200px]":"min-w-[245px]"} bg-white border-b border-r ${false ? "text-red-500" : "text-gray-400"}`}
                        disabled={true}
                    />
                </div>
            </div>
        )
    }

    function DischargingPortOptions(region, label) {
        let dischargingListOptions
        if(regions.includes(region)){
            dischargingListOptions = portList.map((x) => x.portName)
        } else {
            dischargingListOptions = [region]
        }

        return (
            <div className="p-1">
                <label className="text-sm text-ao-blue pl-1">{label}</label>
                <div className=" ">
                    <select
                        className={`block ${isEdit ? "w-[200px]" : "w-[245px]"} border-b border-r  focus:outline-none focus:ring focus:border-blue-300`}
                        defaultValue={region}
                        disabled={false}
                        id={"dropdown_enabled"}
                        onChange={(event) => handleChange(event.target.value)}
                    >
                        {dischargingListOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        )
    }


    function OutputField(value, initialValue, label, unit, cost, inModal, options = {}) {

        
        return (
            <div className={`${isEdit ? "max-w-[200px]" : "max-w-[245px]"} w-full grow p-1`}>
                <label className={`text-sm text-ao-figma-text-gray`}>
                    {label}
                </label>
                <div className="relative">
                    <div className=" w-full flex flex-row justify-between">
                    <span className={`${isEdit ? "min-w-[200px]" : "min-w-[245px]"}  text-ao-figma-text-gray border-b ${inModal&& "border-r"} `}>{_viewMode === ViewModes.CURRENT ? value : initialValue}</span>
                    
                    <span className={`absolute right-0 text-gray-400`}>{unit}</span>
                    
                </div>
                </div>
                
            </div>
        )
    }
    
    let fieldsLayout = <></>
        fieldsLayout = (
            <div className="overflow-x-auto">
                {/* Grid */}
                <div className="flex flex-col gap-y-8 justify-center ">

                    {/* Route and Timing */}
                    <div className="flex flex-col gap-y-6 border-b-2 border-gray-300 justify-center">
                        <h2 className="text-2xl font-bold text-ao-blue w-full underline">Route & Timing</h2>
                        <div className="flex flex-row items-center gap-x-6 pb-10 border-b-2 border-gray-100">
                            <h4 className="text-md min-w-[150px] shrink-0 mr-4 font-semibold tracking-tight text-ao-blue">Route Details: </h4>
                            <div className="flex flex-col">
                                 <div className="flex flex-row items-end gap-x-2">
                                    {MinMaxTextField(voyage.vessel, "Vessel")}
                                    {MinMaxTextField(voyage.routeShortName, "Route", true)}
                                    {MinMaxTextField(voyage.ballastSpeed, "Ballast Speed", "knots")}
                                    {MinMaxTextField(voyage.ladenSpeed, "Laden Speed", "knots")}
                                    
                                </div>
                                <div className="flex flex-row items-end gap-x-2">
                                    {MinMaxTextField(voyage.canalConstraints, "Canal Constraints")}
                                    {MinMaxTextField(voyage.canalDays, "Canal Days", "days")}
                                </div>
                            </div>
                           
                        </div>
                        <div className="flex flex-row items-center gap-x-6 pb-10 border-b-2 border-gray-100">
                            <h4 className="text-md min-w-[150px] shrink-0 mr-4 font-semibold tracking-tight text-ao-blue">Open Details: </h4>
                            <div className="flex flex-row items-end gap-x-2 ">
                                { MinMaxTextField(voyage.openPort, "Vessel Open Port")}
                                {isEdit && PortConfirmCheckbox(voyage.confirmedOpen, "Confirm")}
                                {DateField(voyage.openDate, "Open Date")}

                                
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-x-6 pb-10 border-b-2 border-gray-100">
                            <h4 className="text-md min-w-[150px] shrink-0 mr-4 font-semibold tracking-tight text-ao-blue">Load Details: </h4>
                            
                            <div className="flex flex-col ">
                                <div className="flex flex-row gap-x-2">
                                    {MinMaxTextField(voyage.loadingPort, "Load Port")}
                                    {isEdit && PortConfirmCheckbox(voyage.confirmedLoad, "Confirm")}
                                    {MinMaxTextField(voyage.waitingDays, "Waiting Days", "days")}
                                    {MinMaxTextField(voyage.loadDays, "Load Days", "days")}
                                    {DateField(voyage.loadETA, "Load ETA")}
                                </div>
                                
                                <div className="flex flex-row gap-x-2 ">
                                    {MinMaxTextField(voyage.portCosts, "Load Port Costs", "$")}
                                    {DateField(voyage.laycanStart, "Laycan Start")}
                                    {DateField(voyage.laycanEnd, "Laycan End")}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-x-6 pb-10 border-gray-100">
                            <h4 className="text-md min-w-[150px] shrink-0 mr-4 font-semibold tracking-tight text-ao-blue">Discharge Details:</h4>
                            <div className="flex flex-row items-end gap-x-2">
                                {DischargingPortOptions(voyage.dischargePort, "Discharge Port 1")}
                                {isEdit && PortConfirmCheckbox(voyage.confirmedDischarge, "Confirm")}
                                {MinMaxTextField(voyage.dischargeDays, "Discharge Days", "days")}
                                {DateField(voyage.dischargeDate, "Next Open")}
                            </div>
                        </div>
                    </div>

                     {/* Cargo*/}
                     <div className="flex flex-col border-b-2 border-gray-300 justify-center ">
                        <h2 className="text-2xl font-bold text-ao-blue w-full mb-2 underline">Cargo</h2>
                        <div className="flex flex-row items-center gap-x-12 pb-10">
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-x-2 items-end">
                                    {TextField(voyage.customer, "Customer")}
                                    {MinMaxTextField(voyage.cargo, "Cargo")}
                                    {MinMaxTextField(voyage.cargoMinQuantity, "Min Quantity", "MT")}
                                    {MinMaxTextField(voyage.cargoMaxQuantity, "Max Quantity", "MT")}
                                
                                </div>
                                <div className="flex flex-row gap-x-2 items-end">
                                    {MinMaxTextField(voyage.cargoQuantity, "Quantity", "MT")}
                                    {MinMaxTextField(voyage.freightRate, "Freight Rate")}
                                    {MinMaxTextField(voyage.commission, "Commission", "%")}
                                </div>
                            </div>
                        </div>
                    </div>

                     {/* Bunkers*/}
                     <div className="flex flex-col gap-y-4 justify-center ">
                        <h2 className="text-2xl font-bold text-ao-blue w-full mb-2  underline">Bunkers</h2>
                        <div className="flex flex-row items-center gap-x-2 pb-10">
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-x-2 items-end">
                                    {MinMaxTextField(voyage.bunkerPort, "Bunkering Port")}   
                                    {MinMaxTextField(voyage.fuels.lsfO.requirement, "Bunkers Needed (IFO)", "MT", false, true, { valueFormatter: ceilThousands.format })}
                                    {MinMaxTextField(voyage.fuels.mgO.requirement, "Bunkers Needed (MGO)", "MT", false, true, { valueFormatter: ceilThousands.format })}
                                </div>
                                <div className="flex flex-row gap-x-2 items-end">
                                    {TextField(voyage.supplier, "Bunker Supplier")}
                                    {MinMaxTextField(voyage.ifoBunkerPrice, `IFO Price (${voyage.bunkerPort})`, "$")}
                                    {MinMaxTextField(voyage.mgoBunkerPrice, `MGO Price (${voyage.bunkerPort})`, "$")}
                                </div>
                                <div className="flex flex-row gap-x-2 items-end">
                                    {MinMaxTextField(voyage.co2Price, "CO2 Price", "$")}
                                    {MinMaxTextField(voyage.emissionsCost, "C02 Emissions", "MT", false, true)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    let outputLayout = <></>

    outputLayout = (

        <div className="overflow-x-auto border border-gray-150 rounded w-4/5 mx-auto p-2 bg-blue-50">
            {/* Grid */}
            <div className="flex flex-col">
                
                {/* Row 0 */}
                <div className="text-lg font-bold text-ao-blue  w-full p-1">
                    Voyage Calculations
                </div>
                
                {/* Row 1 */}
                <div className="flex">
                {/* OutputField(value, initialValue, label, unit, cost, inModal, options = {}) */}
                    {OutputField("B", "B","CII", "", false, false)}
                </div>

                {/* Row 2 */}
                <div className="flex">
                    {OutputField(voyage?.voyageLength, voyage?.initialCosting?.voyageLength, "Voyage Length", "days", false, false, { valueFormatter: roundIT })}
                </div>

                {/* Row 3 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.initialCosting?.totalRevenues), dollarFormat(voyage?.initialCosting?.totalRevenues),"Total Revenues", "", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 4 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.bunkerCost), dollarFormat(voyage?.initialCosting?.bunkerCost),"Bunker Costs", "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 5 */}
                <div className="flex">
                    {OutputField(dollarFormat(0), dollarFormat(0), "Emissions Costs", "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 6 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.portAndOtherCosts), dollarFormat(voyage?.initialCosting?.portAndOtherCosts),"Port & Other Costs", "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 7 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.totalCost), dollarFormat(voyage?.initialCosting?.totalCost),"Total Costs", "", true, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 8 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.tce), dollarFormat(voyage?.initialCosting?.tce), "TCE", "$/day", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 9 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.pnlPerDay), dollarFormat(voyage?.initialCosting?.pnlPerDay),"Profit & Loss (per day)", "$/day", false, false, { valueFormatter: usdFormatter.format })}
                </div>

                {/* Row 10 */}
                <div className="flex">
                    {OutputField(dollarFormat(voyage?.pnlTotal), dollarFormat(voyage?.initialCosting?.pnlTotal),"Profit & Loss (voyage)", "", false, false, { valueFormatter: usdFormatter.format })}
                </div>
            </div>
        </div>
    )

    function TceEffects(current) {
        const variableKeys = Object.keys(voyage.tceEffects)
        const priceEffectsCurrent = getTceEffect(voyage, variableKeys, current, "price")
        const quantityEffectsCurrent = getTceEffect(voyage, variableKeys, current, "quantity")
        const opsEffectsCurrent = getTceEffect(voyage, variableKeys, current, "ops")

        return (
            <div className="flex-col">
            <div className="font-semibold tracking-wide text-md text-ao-figma-text-gray">
                {current ? "Current Plan TCE Effects" : "Dynamic TCE Effects"}
            </div>
                <TceRow title={"Price Effects"} record={priceEffectsCurrent} keys={variableKeys}/>
                <TceRow title={"Quantity Effects"} record={quantityEffectsCurrent} keys={variableKeys}/>
                <TceRow title={"Days and Other Effects"} record={opsEffectsCurrent} keys={variableKeys}/>
            </div>
        )
      }

      function TceParts(){

        return (
            <div className="flex">
                <div className="w-1/2 p-4">
                    {TceEffects(true)}
                </div>
            
                <div className="w-1/2 p-4">
                    {TceEffects(false)}
                </div>
          </div>
        )

      }
      



    function buildModalHeader(){
        return(

            <div className={`flex flex-col ${!isEdit && "px-8"}`}>
                {isEdit ?
                <>
                <div className="flex flex-row gap-x-6" >
                   <span className="text-ao-blue font-bold text-4xl pt-6 pb-2">
                        Voyage Management: 
                    </span>
                    

                    
                        <div className="flex flex-row gap-x-12 items-end">
                            <span className="text-ao-blue font-bold text-2xl pt-6 pb-2" >
                                {voyage.loadingPort} 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-3 inline">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                                {voyage.dischargePort}
                            </span>
                    
                            <div className="flex flex-row gap-x-12 items-end mb-2">
                                <div className="font-semibold tracking-wide text-md text-ao-figma-text-gray">
                                    DYNAMIC TCE:
                                    <span className="text-ao-blue  ml-2 text-lg"> {voyage.tce ? usdFormatter.format(voyage.tce): 0}</span>
                                </div>
                                <div className="font-semibold tracking-wide text-md text-ao-figma-text-gray">
                                    Δ TCE: 
                                    <span className="text-ao-blue ml-2 text-lg">{voyage.deltaTce ? usdFormatter.format(voyage?.deltaTce) : usdFormatter.format(voyage?.tce - voyage?.initialCosting?.tce)} </span>
                                </div>
                                {/* <div className="font-semibold tracking-wide text-md text-ao-figma-text-gray">
                                    NEXT:
                                </div> */}
                            </div>
                        </div>
                </div>
                                 
                
                    <div className="flex flex-row gap-x-32 items-end border-b-2 border-gray-350">
                        <div className="flex flex-row gap-x-4">
                            {renderPlanToggle("Current")}
                            {renderPlanToggle("Initial")}
                        </div>
                    
                        <div className="flex flex-row gap-x-4 ">
                            {Object.keys(contentModes).map((key) => (
                                <div className="flex flex-row items-end gap-x-1">
                                    <svg height="10" width="10" className="mb-2">
                                    <circle
                                        cx="5"
                                        cy="5"
                                        r="4"
                                        stroke={contentModes[key] === contentMode ?  "#5C93C0" : "#C4CCCF"} 
                                        strokeWidth="1"
                                        fill={contentModes[key] === contentMode ?  "#5C93C0" : "#C4CCCF"}                              
                                    />
                                    </svg>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const val = contentModes[key]
                                            setContentMode(val)
                                        }}
                                        value={contentModes[key]}
                                        className={`text-lg disabled:text-gray-200 disabled:cursor-not-allowed ${
                                            contentModes[key] === contentMode
                                            ? "text-ao-blue underline hover:text-black"
                                            : "text-gray-500 hover:underline hover:text-ao-blue"
                                        } `}
                                    >
                                        {contentModes[key]}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    </> : 
                    <>
                        <span className="text-ao-blue font-bold text-4xl pt-6 pb-2 mb-4">
                            Voyage Setup
                        </span>
                        <div className="flex flex-col">

                                <div className="flex h-4/5">
                                        <div className="pb-2" style={{ flex: 4, marginRight: "15px" }}>
                                        <div className="flex flex-row items-center gap-x-4 pb-1">
                                                <div className="flex text-sm text-gray-400">
                                                    <p className="text-center pr-2">
                                                        SPGCI data values are displayed in <span className="text-ao-blue font-bold">blue</span>
                                                    </p>
                                                    <p className="text-center">
                                                        Constructed values are shown in <span className="italic font-bold">italics</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ flex: 1, marginRight: "15px" }}>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div className="flex flex-col xl:pr-10 xl:pl-10 2xl:h-4/5 h-1/2">
                                            </div>
                                        </div>
                                </div>  
                            </div>
                    </>

                }
            </div>
        )
    }

    function renderPlanToggle (label) {
        return ( 
        <div className="flex flex-row gap-x-1 items-center">
            <svg height="10" width="10" className="">
              <circle
                cx="5"
                cy="5"
                r="4"
                stroke={label.toUpperCase() === _viewMode ?  "#5C93C0" : "#C4CCCF"}
                strokeWidth="1"
                fill={label.toUpperCase() === _viewMode ?  "#5C93C0" : "#C4CCCF"}
              />
            </svg>
           <button
                onClick={(e) => {
                    e.preventDefault() 
                    setViewMode(label.toUpperCase())
                }}
              
                value={label}
                className={`text-lg disabled:text-gray-200 disabled:cursor-not-allowed ${
                    label.toUpperCase() === _viewMode
                    ? "text-ao-blue underline hover:text-black"
                    : "text-gray-500 hover:underline hover:text-ao-blue"
                } `}
                disabled={!isEdit && label === "Current"}
                >
                {label} Plan
         </button>
         </div>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={modalOpen}
                onClose={closeModal}
                fullWidth={true}
                maxWidth="xl"
                disableEnforceFocus={true}
                disableAutoFocus={true}
            >
                <form>
             
                    <DialogContent>
                        {buildModalHeader()}
                        <div className={`${!isEdit && "px-8"} my-4 flex flex-col`}>
                        
                            {/* main content */}
                            <div>
                                <div className="flex flex-row w-full gap-x-8" >
                                    {isEdit && 
                                        <div className="w-1/5 flex flex-col gap-y-4">
                                            {/* Map */}
                                            {/* <div className="w-full h-72 bg-gray-400 text-white  flex items-center justify-center"> A map will go here!</div> */}

                                            {/* Charts */}
                                            <VoyageManagementTCE />
                                            <VoyageManagementRisk />
                                            
                                        </div>
                                    }
                                    {contentMode === contentModes.voyageSummary ? (
                                        <div className="flex">
                                        <div className={`${isEdit ? "w-4/5": "w-5/6"}`}>{fieldsLayout}</div>
                                        <div className={`${isEdit ? "w-1/5": "w-1/6"}`}>{outputLayout}</div>
                                        </div>
                                    ) : ( contentMode === contentModes.bunkerSummary ? (
                                        <p>Bunker Summary</p>
                                    ) : (
                                        <div>
                                            {TceParts()}
                                        </div>
                                    )

                                    ) }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button type="button" onClick={closeModal}>Cancel</button>
                        <button type="submit" className="block rounded-md bg-ao-blue px-3 py-2 text-center  font-semibold text-white shadow-md hover:bg-white hover:text-ao-blue">Save</button>
                    </DialogActions>
                </form>
            </Dialog>
        </ThemeProvider>
    )



}


/**
 * Specification for the inputFields
 */


const FreightRateUnits = {
    [FreightTypes.WORLDSCALE]: "WS",
    [FreightTypes.DOLLAR_PER_MT]: "$/mt",
    [FreightTypes.LUMP_SUM]: "$"
}


function roundIT(value) {
    if (typeof value === "undefined") {
        return ""
    }
    return value.toFixed(1)
}

function getTceEffect(voyage, keys, current, effect){

    if(effect === "price"){
        return keys.reduce((acc, x) => {
            acc[x] = current ? voyage.tceEffects[x].currentTceEffects.priceEffect : voyage.tceEffects[x].dynamicTceEffects.priceEffect
            return acc;
          }, {});
    } else if (effect === "quantity"){
        return keys.reduce((acc, x) => {
            acc[x] = current ? voyage.tceEffects[x].currentTceEffects.quantityEffect : voyage.tceEffects[x].dynamicTceEffects.quantityEffect
            return acc;
          }, {});
    } else if (effect === "ops"){
        return keys.reduce((acc, x) => {
            acc[x] = current ? voyage.tceEffects[x].currentTceEffects.daysEffect + voyage.tceEffects[x].currentTceEffects.interactionEffect : voyage.tceEffects[x].dynamicTceEffects.daysEffect + voyage.tceEffects[x].dynamicTceEffects.quantityEffect
            return acc;
          }, {});
    }

}

