import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { selectableDataSources } from "../../store/ui";
import { DATA_SOURCE } from "../../utils/Constants";

export function DataSourceSelect({ category, value, onChange, className }) {
  const _selectableDataSources = useRecoilValue(
    selectableDataSources(category)
  );

  const unKnownsDataSourceSelected =
    _selectableDataSources.findIndex((x) => x.value === value) === -1;

  return (
    <Select
      value={value}
      onChange={onChange}
      input={<OutlinedInput label="Data Source" classes={{input: '!p-2'}} style={{padding: 0}}/>}
      style={{ minWidth: 170, maxWidth: 170 }}
      className={className}
    >
      {unKnownsDataSourceSelected && (
        <MenuItem
          style={{ color: "red" }}
          value={value}
          title="The data source is not available. Please check your configuration"
        >
          ⚠️{DATA_SOURCE[value].caption}
        </MenuItem>
      )}
      {_selectableDataSources.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
