import { useState } from "react";
import { useHistory } from "react-router-dom";

import { FieldArray } from "formik";
import { SimpleLineChart } from "../../../components/Charts/SimpleLineChart";
import { AoForm } from "../../../components/form/AoForm";
import { FieldContainer } from "../../../components/form/fields/FieldContainer";
import { InputField, SimpleInputField } from "../../../components/form/fields/InputField";
import { SliderField } from "../../../components/form/fields/SliderField";
import { SwitchField } from "../../../components/form/fields/SwitchField";
import { getVesselFuelConsumption, useUserCustomisedVesselsMutations } from "../../../store/vessels";
import { MeasureUnit } from "../../../utils/Constants";

const minVesselSpeed = 11
const vesselSpeedStep = 0.5
const maxVesselSpeed = 14.5

export function VesselForm({ defaultValues }) {
    let history = useHistory();
    const { saveVessel } = useUserCustomisedVesselsMutations()
    const [editConsumptionCurve, setEditConsumptionCurve] = useState(false)


    return (
        <AoForm initialValues={defaultValues} onSubmit={handleSave} onReset={backToVessels}>
            {({ Column, itemClassName, form }) => {
                const ladenConsumption = getVesselFuelConsumption(form.values, "laden", form.values.ladenSpeed)
                const ballastConsumption = getVesselFuelConsumption(form.values, "ballast", form.values.ballastSpeed)
                const customConsumptions = form.values.customConsumptions || { laden: [], ballast: [] }

                return (<>
                    <Column label="Vessel">
                        <InputField name="shipName" label="Vessel Name" className={itemClassName}></InputField>
                        <InputField name="shipType" label="Class" readOnly className={itemClassName}></InputField>
                        <InputField name="ladenCargo" label="Default Cargo Size" type="number" suffix={MeasureUnit.MT} className={itemClassName}></InputField>
                        <SwitchField name="scrubber" className={itemClassName}>Scrubber</SwitchField>
                        <SwitchField name="ecoShip" className={itemClassName}>Modern Eco</SwitchField>
                        <InputField name="yearBuilt" label="Year Built" type="number" className={itemClassName}></InputField>
                        <InputField name="imoNumber" label="IMO" className={itemClassName}></InputField>
                        <InputField name="dwt" label="DWT" type="number" suffix={MeasureUnit.MT} className={itemClassName}></InputField>
                    </Column>
                    <Column label="Speed & Consumption">
                        <SliderField name="ladenSpeed" label="Default Laden Speed" min={minVesselSpeed} max={maxVesselSpeed} step={vesselSpeedStep} suffix={" " + MeasureUnit.KNOTS} className={itemClassName}></SliderField>
                        <SliderField name="ballastSpeed" label="Default Ballast Speed" min={minVesselSpeed} max={maxVesselSpeed} step={vesselSpeedStep} suffix={" " + MeasureUnit.KNOTS} className={itemClassName}></SliderField>
                        <SimpleInputField id="ladenConsumption" label="Default Laden Consumption" type="number" suffix={MeasureUnit.MT_DAY} readOnly className={`${itemClassName} bg-ao-blue-light-background`} inputProps={{ value: ladenConsumption }}></SimpleInputField>
                        <SimpleInputField id="ballastConsumption" label="Default Ballast Consumption" type="number" suffix={MeasureUnit.MT_DAY} readOnly className={`${itemClassName} bg-ao-blue-light-background`} inputProps={{ value: ballastConsumption }}></SimpleInputField>
                        <InputField name="loadingConsumption" label="Loading Consumption" type="number" suffix={MeasureUnit.MT_DAY} className={itemClassName}></InputField>
                        <InputField name="dischargingConsumption" label="Discharging Consumption" type="number" suffix={MeasureUnit.MT_DAY} className={itemClassName}></InputField>
                        <InputField name="waitingConsumption" label="Waiting Consumption" type="number" suffix={MeasureUnit.MT_DAY} className={itemClassName}></InputField>
                        <InputField name="heatingConsumption" label="Heating Consumption" type="number" suffix={MeasureUnit.MT_DAY} className={itemClassName}></InputField>
                    </Column>
                    <Column label="Consumption Curve" className="grow-[2.5]" action={
                        <button type="button" className="underline mr-6" onClick={() => setEditConsumptionCurve(!editConsumptionCurve)}>{
                            editConsumptionCurve ? "View consumption curve" : "Edit consumption curve"}
                        </button>
                    }>
                        {editConsumptionCurve ?
                            <>
                                <ConsumptionCurveEditor id="ladenConsumptionCurve" label="Laden" name="customConsumptions.laden" className={`${itemClassName}`} defaultValue={customConsumptions.laden}></ConsumptionCurveEditor>
                                <ConsumptionCurveEditor id="ballastConsumptionCurve" label="Ballast" name="customConsumptions.ballast" className={`${itemClassName}`} defaultValue={customConsumptions.ballast}></ConsumptionCurveEditor>
                            </>
                            : <SimpleLineChart
                                height={300}
                                className="px-6 pl-4"
                                yAxisLabel={MeasureUnit.MT_DAY}
                                verticalGridLines={false}
                                data={[
                                    {
                                        color: "#CD3D64",
                                        data: customConsumptions.laden.map(c => ({ x: c.speed, y: c.consumption })),
                                        key: "laden",
                                        title: "Laden"
                                    }, {
                                        color: "#57B5CA",
                                        data: customConsumptions.ballast.map(c => ({ x: c.speed, y: c.consumption })),
                                        key: "ballast",
                                        title: "Ballast",
                                    }
                                ]}></SimpleLineChart>
                        }
                    </Column>
                </>)
            }
            }
        </AoForm>
    )

    async function handleSave(data) {
        await saveVessel(data);

        backToVessels()
    }

    function backToVessels() {
        history.push('/vessels')
    }
}

function ConsumptionCurveEditor({ id, label, className, name, defaultValue }) {
    return <FieldContainer id={id} label={label} className={`flex-1 pb-0 ${className}`} height={2}>
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div className="flex -mx-2">
                    {defaultValue.map((c, i) => (
                        <InputField key={i} name={`${name}.${i}.consumption`} label={`${c.speed} ${MeasureUnit.KNOTS}`} className="border-r last:border-r-0 border-t" />
                    ))
                    }
                </div>
            )}
        />
    </FieldContainer>
}