import { Spinner } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { DateTime } from "luxon";
import React, { useState, useRef, useLayoutEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  loadingIndicator,
  selectdVoyagePortfolio,
  selectdVoyagePortfolioId
} from "../../../store/ui";
import {
  dollarFormat
} from "../../../utils/Helpers";
import BunkerTableGroupedChart from "./BunkerExposuresChartGrouping";
import BunkerTableGroupedTable from "./BunkerExposuresTableGrouping";
import BunkerRiskIndicator from "./BunkerRiskIndicator";
import { voyages } from "../../../store/voyages";
import { useVoyagePortfolioMutations } from "../../../store/voyagePortfolios";
import { VoyagePortfolioSelect } from "./VoyagePortfolioSelect";
import { VoyagePortfolioManageDialog } from "./VoyagePortfolioManageDialog";
import BunkerTableGroupedChartFuel from "./BunkerExposuresChartFuel";

export default function BunkerTable() {
  const _voyages = useRecoilValue(voyages);

  const [_loading, setLoading] = useRecoilState(loadingIndicator);
  const [fuelFilter, setFuelFilter] = useState("all");
  const [_selectedVoyages, setSelectedVoyages] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [_groupingChoice, setGroupingChoice] = useState("byVessel")

  const _selectedPortfolioId = useRecoilValue(selectdVoyagePortfolioId)
  const _selectedPortfolio = useRecoilValue(selectdVoyagePortfolio)
  const { saveVoyagePortfolio } = useVoyagePortfolioMutations()
  const [_adToPortfolioVisible, setAddToPortfolioVisible] = useState(false)
  const [_portfolioModalOpen, setPortfolioModalOpen] = useState(false)


  useLayoutEffect(() => {
    setChecked(
      _voyages.length > 0 &&
      _selectedVoyages.length > 0 &&
      _voyages.length === _selectedVoyages.length
    );
  }, [_selectedVoyages]);

  function handleRadioChange(setting){
    setGroupingChoice(setting)
  }

  let _voyageDisplay = _voyages
    .filter((x) => {
      return fuelFilter === "all" || Object.keys(x.bunkers).includes(x); // second condition ??
    })
    .map((voy) => {
        let bunkerUsedFuelTypes = Object.keys(voy.fuels)
        let bunkerTimer = 0
        let bunkerUncovered = Object.values(voy.uncoveredBunkers).reduce((a, b) => a + b, 0)
        let dTCE = voy.deltaTce;
        let bunkerPort = voy.bunkerPort
        const meanCost = voy.bunkerCost
        const allUpperPrices = voy.bunkerCostsWorstCase.med ? Object.values(voy.bunkerCostsWorstCase.med).reduce((a,b) => a + b, 0) : 0
        const riskLevel = allUpperPrices/meanCost - 1
        let riskRating

        if (riskLevel < 0.1) {
          riskRating = "low"
        } else if (riskLevel < 0.2 && riskLevel >= 0.1) {
          riskRating = "med"
        } else {
          riskRating = "high"
        }

        return {
          ...voy,
          fuelTypeLabel: bunkerUsedFuelTypes,
          uncovered: Math.round(bunkerUncovered) + "mt",
          timer: Math.round(bunkerTimer),
          deltaTCEBunker: dollarFormat(dTCE),
          deltaTCEOps: 0,
          bunkerPort: bunkerPort,
          bunkerDate: "date",
          riskRating
        };

    })

  function toggleAll() {
    handleSelectionChange(checked ? [] : _voyageDisplay.map((x) => x.id));
    setChecked(!checked);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div style={{ display: _loading ? "block" : "none" }}>
        <Spinner />
      </div>

      <div style={{ display: _loading ? "none" : "block" }}>
        {/* Main Table */}
        <div className="mt-8 flow-root">
          <div className="flex space-between">
            {_adToPortfolioVisible &&
              <button className="rounded-md px-3 py-2 text-center font-semibold text-ao-blue shadow-md hover:bg-white hover:text-ao-blue" onClick={handleAddToPortfolio}>
                Add to portfolio
              </button>
            }
            <div style={{ flex: "1 0" }}></div>
            <VoyagePortfolioSelect onSelect={handleSelectPortfolio} />
            <VoyagePortfolioManageDialog open={_portfolioModalOpen} onClose={() => setPortfolioModalOpen(false)} voyagesToAdd={_selectedVoyages} onOk={handleAddedToPortfolio}></VoyagePortfolioManageDialog>
          </div>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className=" py-3.5 pr-3 text-left text-sm font-semibold text-ao-blue"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Vessel
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Route
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        First Stem
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Active Exposures
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Uncovered
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Δ-TCE Bunker
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        Risk
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                      >
                        <span className="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            className={`relative inline-flex items-center rounded-l-md ${fuelFilter === 'all' ? "bg-ao-blue" : "bg-white"} px-3 py-2 text-sm font-semibold ${fuelFilter === "all" ? "text-white" : "text-gray-900"} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                          // onClick={setFuelFilter("all")}
                          >
                            All Fuels
                          </button>
                          <button
                            type="button"
                            className={`relative inline-flex items-center ${fuelFilter === 'ifo' ? "bg-ao-blue" : "bg-white"} px-3 py-2 text-sm font-semibold ${fuelFilter === "ifo" ? "text-white" : "text-gray-900"} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                          // onClick={setFuelFilter("ifo")}

                          >
                            IFO
                          </button>
                          <button
                            type="button"
                            className={`relative inline-flex items-center ${fuelFilter === 'mgo' ? "bg-ao-blue" : "bg-white"} px-3 py-2 text-sm font-semibold ${fuelFilter === "mgo" ? "text-white" : "text-gray-900"} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                          // onClick={setFuelFilter("mgo")}

                          >
                            MGO
                          </button>
                          <button
                            type="button"
                            className={`relative inline-flex items-center rounded-r-md ${fuelFilter === 'lng' ? "bg-ao-blue" : "bg-white"} px-3 py-2 text-sm font-semibold ${fuelFilter === "lng" ? "text-white" : "text-gray-900"} ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                          // onClick={setFuelFilter("lng")}

                          >
                            LNG
                          </button>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  {_voyageDisplay.length > 0 && (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {_voyageDisplay.map((voyage) => (
                        <tr
                          key={voyage.id}
                          className={
                            _selectedVoyages.includes(voyage.id)
                              ? "bg-gray-50"
                              : undefined
                          }
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {_selectedVoyages.includes(voyage.id) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-ao-blue" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                              checked={_selectedVoyages.includes(voyage.id)}
                              onChange={(e) => {
                                handleSelectionChange(e.target.checked
                                  ? [..._selectedVoyages, voyage.id]
                                  : _selectedVoyages.filter(
                                    (s) => s !== voyage.id
                                  ))
                              }}
                            />
                          </td>
                          <td
                            className={classNames(
                              "whitespace-nowrap py-4 pr-3 font-medium",
                              _selectedVoyages.includes(voyage.id)
                                ? "text-ao-blue"
                                : "text-ao-blue"
                            )}
                          >
                            {voyage.id}
                          </td>
                          {/* TODO - Link Vessel to Vessels - individual ship view */}
                          <td className="whitespace-nowrap px-3 py-4 text-ao-blue underline cursor-pointer">
                            {voyage.vessel.shipName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            {voyage.routeShortName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            {voyage.bunkerPort}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            {voyage.fuelTypeLabel.join(", ").toUpperCase()}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            {voyage.uncovered}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            {voyage.deltaTCEBunker}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                            <BunkerRiskIndicator riskLevel={voyage.riskRating} />
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-3">
                            <Link
                              to="/voyage-engine/optimizations"
                              className="underline hover:text-ao-blue"
                            >
                              Optimize Fuel & Emissions
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {_voyageDisplay.length < 1 && (
                  <div className="w-1/2 pt-12 text-ao-figma-text-gray font-semibold text-lg mx-auto text-center">
                    {" "}
                    No Voyages Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Table and Charts */}
        {_selectedVoyages.length > 0 && (
          <>
            <div class="flex mt-4">
              <div class="flex items-center mr-4">
                <input id="inline-radio-vessel" type="radio" value="byVessel" name="inline-radio-group" class="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVessel"} onChange={(ev) => handleRadioChange(ev.target.value)}>
                </input>
                <label for="inline-radio-vessel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Vessel</label>
              </div>
              <div class="flex items-center mr-4">
                <input id="inline-radio-voyage" type="radio" value="byVoyage" name="inline-radio-group" class="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVoyage"} onChange={(ev) => handleRadioChange(ev.target.value)}>
                </input>
                <label for="inline-radio-voyage" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Voyage</label>
              </div>
              <div class="flex items-center mr-4">
                <input id="inline-radio-voyage" type="radio" value="byFuel" name="inline-radio-group" class="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byFuel"} onChange={(ev) => handleRadioChange(ev.target.value)}>
                </input>
                <label for="inline-radio-voyage" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Fuel</label>
              </div>
            </div>
          <div className="flex flex-row justify-between mt-16">
              <>
              {_groupingChoice === "byFuel" ? 
                <div className="w-1/2">
                      <BunkerTableGroupedChartFuel
                        selectedVoyages={_selectedVoyages}
                        grouping={_groupingChoice}
                      />
                </div>
               : 
               <div className="w-1/2">
                      <BunkerTableGroupedChart
                        selectedVoyages={_selectedVoyages}
                        grouping={_groupingChoice}
                      />
               </div>
               }
              <div className="w-1/2">
                <BunkerTableGroupedTable 
                  selectedVoyages={_selectedVoyages}
                  grouping={_groupingChoice}
                />
              </div>
              </>
          </div>
          </>
        )}
      </div>
    </>
  )
  
  function handleSelectionChange(newSelection) {
    updateSelectedVoyages(newSelection)
  
    if (_selectedPortfolio != null) {
      saveVoyagePortfolio({ ..._selectedPortfolio, selectedVoyages: newSelection })
    }
  }
  
  function handleSelectPortfolio(portfolio) {
    updateSelectedVoyages(portfolio ? portfolio.selectedVoyages || [] : [])
  }
  
  function handleAddToPortfolio() {
    setPortfolioModalOpen(true)
  }
  
  function handleAddedToPortfolio(portfolio) {
    handleSelectPortfolio(portfolio)
  }
  
  function updateSelectedVoyages(newSelection) {
    setSelectedVoyages(newSelection)
  }

}


export function formatDateToDDMMYY(date) {

  if (date instanceof Date){
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(-2)
    return `${day}/${month}/${year}`
  }

  const dateObj = date.toDate()
  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const year = String(dateObj.getFullYear()).slice(-2)
  return `${day}/${month}/${year}`
  
}
