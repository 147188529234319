import {XYPlot} from "react-vis"
import React, {useEffect, useRef, useState} from "react"

export default (props) => {

    /*
     * The following code is designed to overcome a bug in react-vis
     * FlexibleWidthXYPlot, which was causing the plot to go to 0 sometimes
     * when changing the data.
     */
    const [_chartWidth, setChartWidth] = useState(0)
    const ref = useRef(null)
    const hidden = props.visible !== true
    useEffect(() => {

        function handleResize() {
            const newWidth = ref.current ? ref.current.offsetWidth : 0
            // console.log("Flexible chart width:", newWidth)
            setChartWidth(newWidth)
        }

        // Add event listener
        window.addEventListener("resize", handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize)

    }, [hidden])

    return (
        <div ref={ref} style={{width: "100%", height: "100%"}}>
            <XYPlot {...props} width={_chartWidth}>
                {props.children}
            </XYPlot>
        </div>
    )
}