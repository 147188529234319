import OptimizationsTimelineCard from "./optimizations-timelineCard.js"
import { useEffect, useState } from "react"

export default function Timeline({ timelineData }) {

    if(timelineData[0]=== "error"){
        return (
            <div>
                <p> Error generating results</p>
            </div>
        )
    }

    return (
        <ol className="relative border-l border-gray-200 dark:border-gray-700">

            {timelineData.length > 0 ? (
                timelineData.map((event) => (
                <div className="ml-2" key={event.id}> {/* Add a unique key for each item */}
                <OptimizationsTimelineCard port={event} />
                </div>
            ))
            ) : (
            <p>No events to display.</p>
            )}
            </ol>
    )
}
