import React, { useState, useEffect } from "react";
import FlexibleChart from "../../../components/Charts/FlexibleChart";
import {
  DiscreteColorLegend,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DefaultAxisStyle } from "../../../utils/Constants";
import { getEmissionsChartDataForSelectedPeriod } from "./bunkerModalComponents/calculations/calcEmissionOutputs";
import { useRecoilValue } from "recoil";
import { voyages } from "../../../store/voyages";

const emissionsTypes = {
    co2: "CO2",
    sox: "SOx",
    nox: "NOx",
    co2Cost: "CO2 Cost",
    mmBTU: "mmBTU"
}

const emissionsUnits = {
    co2: "CO2/Mt",
    sox: "SOx/kg",
    nox: "NOx/kg",
    co2Cost: "$/Mt",
    mmBTU: "Energy/mmBTU"
}

function createDataSeries(data, emissionSelection) {
    return Object.entries(data).map(([x, emissions]) => {
        let y
        switch (emissionSelection) {
            case "co2":
                y = emissions.co2
                break
            case "sox":
                y = emissions.sox
                break
            case "nox":
                y = emissions.nox
                break
            case "co2Cost":
                y = emissions.emissionsExpenses
                break
            case "mmBTU":
                y = emissions.mmBTU
                break
            default:
                y = emissions.mmBTU
        }

        return {
            x: x,
            y: y
        }
    })

}

export default function EmissionsQuantityChart ({selectedVoyages, grouping}) {

    const [emissionsSelect, setEmissionsSelect] = useState("co2")
    const [_selectedPeriod, setSelectedPeriod] = useState("current")
    const [_emissionData, setEmissionData] = useState({})
    const _voyages = useRecoilValue(voyages)
    
    useEffect( () => {

        const getData = async () => {
            if (selectedVoyages.length > 0) {
                const relevantVoyages = _voyages.filter((v) => selectedVoyages.includes(v.id))
                const data = getEmissionsChartDataForSelectedPeriod(_selectedPeriod, relevantVoyages, grouping)
                setEmissionData(data)
            }
        }
        getData()
        .catch(console.error)

    }, [_selectedPeriod, emissionsSelect, selectedVoyages, grouping, _voyages])


    return <>
        <FormControl fullWidth style={{ marginTop: 15 }}>
            <InputLabel id="fuel-select-label">{"Emission"}</InputLabel>
            <Select
                value={emissionsSelect}
                label="Emission"
                onChange={(ev) => setEmissionsSelect(ev.target.value)}
                size="small"
            >
                {Object.keys(emissionsTypes).map((x) => (
                <MenuItem key={"emis" + x} value={x}>
                    {emissionsTypes[x]}
                </MenuItem>
                ))}
            </Select>
        </FormControl>

        <FormControl fullWidth style={{marginTop: 15}}>
            <InputLabel id="period-select-label">Period</InputLabel>
            <Select
                labelId="period-select-label"
                id="period-select"
                value={_selectedPeriod}
                label="Period"
                onChange={(ev) => setSelectedPeriod(ev.target.value)}
                size="small"
            >
                <MenuItem key="current" value="current">Current Month</MenuItem>
                <MenuItem key="quarter" value="quarter">Current Quarter</MenuItem>
                <MenuItem key="all" value="all">All</MenuItem>
                <MenuItem key="historical" value="historical">Historical</MenuItem>
            </Select>
        </FormControl>

        <FlexibleChart
            height={450}
            margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
            visible={true}
            xType="ordinal"
            >
            <XAxis style={DefaultAxisStyle} tickLabelAngle={-90} />
            <YAxis style={DefaultAxisStyle} title={emissionsUnits[emissionsSelect]} />
            <HorizontalGridLines style={DefaultAxisStyle} />
            <VerticalGridLines style={DefaultAxisStyle} />

            {selectedVoyages.length > 0 && (
                <VerticalBarSeries data={createDataSeries(_emissionData, emissionsSelect)} color="#5C93C0" barWidth={0.3} />
            )}
            </FlexibleChart>
    </>
}
