import {DateTime, Interval} from "luxon"







export const monthsDifference = (date1, date2) => {
    const dt1_MonthYear = DateTime.fromObject({month: date1.month, year: date1.year })
    const dt2_MonthYear = DateTime.fromObject({month: date2.month, year: date2.year })

    // Calculate the month difference
    // (negative number of days if dt2 is before dt1)
    const diff = dt2_MonthYear.diff(dt1_MonthYear, ["months"]).toObject()
    return diff.months
}


export const isDate1PrioToDate2Month = (date1, date2) => {
    const dt1_MonthYear = DateTime.fromObject({month: date1.month, year: date1.year })
    const dt2_MonthYear = DateTime.fromObject({month: date2.month, year: date2.year })
    const date1IsPriorToDate2Month = dt1_MonthYear < dt2_MonthYear

    return date1IsPriorToDate2Month
}


export const isDate1WithinDate2Month = (date1, date2) => {
    const dt1_MonthYear = DateTime.fromObject({month: date1.month, year: date1.year }).toFormat("MM-yyyy")
    const dt2_MonthYear = DateTime.fromObject({month: date2.month, year: date2.year }).toFormat("MM-yyyy")

    const date1IsWithinDate2Month = dt1_MonthYear === dt2_MonthYear

    return date1IsWithinDate2Month
}


export const isDate1WithinDate2Quarter = (date1, date2) => {

    const dt1_MonthYear = DateTime.fromObject({month: date1.month, year: date1.year })
    let dt2_MonthYearIntMinus = DateTime.fromObject({month: date2.month, year: date2.year })
    let dt2_MonthYearIntPlus = DateTime.fromObject({month: date2.month, year: date2.year })

    if (date2.month >= 1 && date2.month <=3) {
            dt2_MonthYearIntMinus = DateTime.fromObject({month: 1, year: date2.year })
            dt2_MonthYearIntPlus = DateTime.fromObject({month: 4, year: date2.year })
    } else 
    if (date2.month >= 4 && date2.month <=6) {
            dt2_MonthYearIntMinus = DateTime.fromObject({month: 4, year: date2.year })
            dt2_MonthYearIntPlus = DateTime.fromObject({month: 7, year: date2.year })
    } else 
    if (date2.month >= 7 && date2.month <=9) {
            dt2_MonthYearIntMinus = DateTime.fromObject({month: 7, year: date2.year })
            dt2_MonthYearIntPlus = DateTime.fromObject({month: 10, year: date2.year })
    } else 
    if (date2.month >= 10 && date2.month <=12) {
            dt2_MonthYearIntMinus = DateTime.fromObject({month: 10, year: date2.year })
            dt2_MonthYearIntPlus = DateTime.fromObject({month: 1, year: date2.year + 1 })
    }

    const date1IsWithinDate2Quarter = dt1_MonthYear >= dt2_MonthYearIntMinus && dt1_MonthYear < dt2_MonthYearIntPlus

    return date1IsWithinDate2Quarter
}


export const refFirstLastDates = (currentDate, dateRange) => {
    const refYr = currentDate.getFullYear()
    const refMonth = currentDate.getMonth()

    let firstDay, lastDay

    if (dateRange === "current"){
        firstDay = new Date(refYr, refMonth, 1)
        lastDay = new Date(refYr, refMonth + 1, 0)
    } else
    if (dateRange === "quarter") {
        switch (refMonth) {
            case 0:
            case 1:
            case 2:
                firstDay = new Date(refYr, 0, 1) // January 1st
                lastDay = new Date(refYr, 2, 31) // March 31st
                break
            case 3:
            case 4:
            case 5: 
                firstDay = new Date(refYr, 3, 1) // April 1st
                lastDay = new Date(refYr, 5, 30) // June 30th
                break
            case 6:
            case 7:
            case 8:
                firstDay = new Date(refYr, 6, 1) // July 1st
                lastDay = new Date(refYr, 8, 30) // September 30th
                break
            case 9:
            case 10:
            case 11:
                firstDay = new Date(refYr, 9, 1) // October 1st
                lastDay = new Date(refYr, 11, 31) // December 31st
                break
        }
    } else if (dateRange === "historical"){
        firstDay = new Date(1900, 1, 1)
        lastDay = getDayPrior(currentDate)
    } else if (dateRange === "future"){
        firstDay = new Date(refYr, refMonth, 1)
        lastDay = new Date(refYr+100, refMonth, 0)
    } else {
        firstDay = new Date(1900, 1, 1)
        lastDay = new Date(refYr+100, refMonth, 0)
    }

    return [firstDay, lastDay]
}

const getDayPrior = (currentDate) => {
    const refDate = new Date(currentDate)
    refDate.setDate(0)
    return refDate
}

export const voyageOverlapsWithDateRange = (voyageOpen, voyageClose, rangeOpen, rangeClose) => {

    if (voyageClose < rangeOpen || voyageOpen > rangeClose){
        return false
    }
    
    return true
}

export const daysInRange = (voyageOpen, voyageClose, rangeOpen, rangeClose) => {

    if (voyageClose < rangeOpen || voyageOpen > rangeClose){
        return 0
    }

    const startOverlap = voyageOpen > rangeOpen ? voyageOpen : rangeOpen
    const endOverlap = voyageClose < rangeClose ? voyageClose : rangeClose

    const daysInOverlap = daysBetweenDates(startOverlap, endOverlap)
    return daysInOverlap
}

export const daysBetweenDates = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000

    const daysInOverlap = (endDate - startDate) / oneDay + 1
    return daysInOverlap
  }
