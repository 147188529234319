import React from "react";
import { useLocation } from "react-router-dom";

/* NOTE
  this is hot fucking garbage, please refactor this asap
*/
export default function PageTitle({ routes }) {
  const location = useLocation();
  const getTitle = (routes) => {
    let title;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].subpaths && routes[i].subpaths.length > 0) {
        for (let j = 0; j < routes[i].subpaths.length; j++) {
          if (routes[i].subpaths[j].path === location.pathname) {
            return routes[i].subpaths[j].name;
          }
        }
      }

      if (routes[i].detailpaths && routes[i].detailpaths.length > 0) {
        for (let j = 0; j < routes[i].detailpaths.length; j++) {
          if (routes[i].detailpaths[j].path === location.pathname) {
            return routes[i].detailpaths[j].name;
          }
        }
      }

      if (routes[i].path === location.pathname) {
        return routes[i].name;
      }
    }
    return title;
  };

  return (
    <h1 className="text-ao-blue text-4xl font-semibold">{getTitle(routes)}</h1>
  );
}
