import React from "react"
import EmissionSelect from "../../components/EmissionSelect/EmissionSelect"
import { PriceTable } from "./PriceTable"
import { useRecoilValue } from "recoil";
import { selectableDataSources } from "../../store/ui";
import { DATA_SOURCE, DATA_SOURECE_CATEGORY } from "../../utils/Constants";
import { DataAttribution } from "./DataAttribution";

export function EmissionsPriceTable({data}) {
    const dataSources = useRecoilValue(selectableDataSources(DATA_SOURECE_CATEGORY.EMISSIONS_PRICES));
    const hasPlattsSource = dataSources.findIndex(x => x.value === DATA_SOURCE.PLATTS.name) >= 0

    return (
        <div>
            <div className="grid grid-cols-5 w-full">
                <div>
                </div>
                <EmissionSelect index={0}/>
            </div>
            <PriceTable data={data} hasPlattsSource={hasPlattsSource} isEuros={true}/>
            <DataAttribution data={data} isTCE={false}/>
        </div>
    )
}