import React, {useState} from "react";
import AddStemModal from "./TanksAddStem";
import { recostVoyages } from "./VoyageEngineLogic";
import { PostStem } from "../../../store/api/services-api";
import { allVessels } from "../../../store/vessels"
import { useRecoilValue } from "recoil";

export default function EditStemButton({stem, vessel}) {
    const _realVessels = useRecoilValue(allVessels)
    const selectedShip = _realVessels.filter((x) => x.shipName === vessel)[0]
    const [_modalOpen, setModalOpen] = useState(false);
    const [_stemData, setStemData] = useState({})
    const handleAddClick = () => {
        setModalOpen(true)
    }
    const closeModal = async (data) => {
        await PostStem(selectedShip.shipName, selectedShip.shipType, selectedShip.id, data, true)
        await recostVoyages(vessel)
        setModalOpen(false);
    };
    

    return (
        <>
        <button className="bg-white border pl-2 pr-2 text-ao-blue rounded-lg text-sm hover:bg-white hover:text-ao-blue w-full" onClick={handleAddClick}>
            Edit Stem
        </button>
        <AddStemModal
            stem={stem}
            isEdit={true}
            vessel= {vessel}
            modalOpen={_modalOpen}
            closeModal={closeModal}
            setStemData={setStemData}
            />
        </>
    )
}