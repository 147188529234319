import React from "react"
import BunkerSelect from "../../components/BunkerSelect/BunkerSelect"
import { PriceTable } from "./PriceTable"
import { useRecoilValue } from "recoil";
import { selectableDataSources } from "../../store/ui";
import { DATA_SOURCE, DATA_SOURECE_CATEGORY } from "../../utils/Constants";
import { DataAttribution } from "./DataAttribution";


export function BunkerPriceTable({data}){
    const dataSources = useRecoilValue(selectableDataSources(DATA_SOURECE_CATEGORY.BUNKER_PRICES));
    const hasPlattsSource = dataSources.findIndex(x => x.value === DATA_SOURCE.PLATTS.name) >= 0

    return (
        <div>
            <div className="grid grid-cols-5">
                <div>
                </div>
                <BunkerSelect index={0}/>
                <BunkerSelect index={1}/>
                <BunkerSelect index={2}/>
                <BunkerSelect index={3}/>
            </div>
            <PriceTable data={data} hasPlattsSource={hasPlattsSource}/>
            <DataAttribution data={data} isTCE={false} />
        </div>
    )

}