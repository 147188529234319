import { useField } from "formik";
import { FieldContainer } from "./FieldContainer";

export function EnumSliderField({ name, label, options, className }) {
  const [field, meta, helper] = useField(name);

  const optionIndex = options.findIndex(
    (o) => o.name === meta.value.toLowerCase()
  );

  return (
    <FieldContainer id={name} name={name} label={label} className={className}>
      <div className="flex flex-1 items-center">
        <input
          type="range"
          id={name}
          min="0"
          max={options.length - 1}
          step="1"
          className="w-full h-[2px] bg-ao-blue rounded-lg appearance-none cursor-pointer accent-ao-blue"
          {...field}
          value={optionIndex}
          onChange={(e) => {
            helper.setValue(options[e.target.value].name);
          }}
        />
        <span className="text-base text-sm ml-2">
          {options[optionIndex].caption}
        </span>
      </div>
    </FieldContainer>
  );
}
