import React from 'react'
import { Switch } from 'react-router-dom'
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom'
import CreateVessel from './CreateVessel'
import { EditVessel } from './EditVessel'
import VesselTable from './components/VesselsTable'

function Vessels() {
  let { path, url } = useRouteMatch();
  return (
    <div className="px-4">
      <Switch>
        <Route exact path={path}>
          <VesselTable />
        </Route>
        <Route path={`${path}/add/:type`}>
          <CreateVessel />
        </Route>
        <Route path={`${path}/:vesselId`}>
          <EditVessel />
        </Route>
      </Switch>
    </div>
  )
}

export default Vessels