
// Bengston et al. 2011
export const _IFOJOULES = 40.4
export const _MGOJOULES = 43
export const _LNGJOULES = 48

// Temp values
export const _WINJOULES = 40
export const _AMMJOULES = 40
export const _MTHJOULES = 40
export const _HYDJOULES = 40
export const _BIOJOULES = 40

export const energyVals = {
    ifo: _IFOJOULES,
    mgo: _MGOJOULES,
    lng: _LNGJOULES,
    win: _WINJOULES,          // Wind
    amm: _AMMJOULES,          // Ammonia (NH3)
    mth: _MTHJOULES,          // Methanol (MeOH)
    hyd: _HYDJOULES,          // Hydrogen
    bio: _BIOJOULES
}
export const _JOULES_TO_BTU = 0.947817


// Convertion values to be confirmed:
// (the following values are used int he bunker risk part of the voyage engine)
export const conversion_mt_to_mBTU = {
    IFO: 40,  // IFO
    LSFO: 40,  // LSFO
    HSFO: 40,  // HSFO
    MGO: 41.9,  // MGO
    LNG: 46.3,  // LNG
    MeOH: 40,  // MeOH
    NH3: 40,  // NH3
    H2: 40,  // H2
    Bio: 35,   // BIO
    KwH: 40   // Wind
}


export const DefaultAxisStyle = {
    color: "#334155",
    line: { stroke: "#334155" },
    ticks: { stroke: "#334155" },
    text: { stroke: "none", fill: "#334155", fontSize: 12 },
    title: { stroke: "none", fill: "#334155", fontSize: 12 }
}

export const fuelTypes = {
    LSFO: "LSFO",
    LSGO: "LSGO",
    HSFO: "HSFO",
    MGO: "MGO"
}
export const fuelTypeNames = {
    [fuelTypes.LSFO]: "Low-Sulphur Fuel Oil",
    [fuelTypes.LSGO]: "Low-Sulphur Marine Gasoil",
    [fuelTypes.HSFO]: "High-Sulphur Fuel Oil",
    [fuelTypes.MGO]: "Low-Sulphur Marine Gasoil"
}

export const oceanRegions = ["North Pacific", "South Pacific", "North Atlantic", "South Atlantic", "Indian/Southern Ocean"]

// t-CO2 / t-fuel   [!!! ALL VALUES TBC !!!]
export const ratio_mtCO2_to_mtFuel = {
    IFO: 3.151,
    LSFO: 3.151,
    HSFO: 3.151,
    MGO: 3.151,   // 3.206,
    LNG: 1,
    MeOH: 1,
    NH3: 1,
    H2: 0,
    Bio: 0,
    KwH: 0
}

export const ratio_mtCO2_to_mtFuel_scrubber = {
    IFO: 3.151,
    LSFO: 3.151,
    HSFO: 3.176,
    MGO: 3.151,   // 3.206,
    LNG: 1,
    MeOH: 1,
    NH3: 1,
    H2: 0,
    Bio: 0,
    KwH: 0
}
// source TBC: https://climate.ec.europa.eu/system/files/2017-07/02_guidance_monitoring_reporting_parameters_en.pdf

export const ratio_kgSOx_to_mtFuel = {
    IFO: 3.151,
    LSFO: 10.5,
    HSFO: 71.5,
    MGO: 2.1,
    LNG: 0.02,
    MeOH: 0,
    NH3: 0,
    H2: 0,
    Bio: 0,
    KwH: 0
}

export const ratio_kgSOx_to_mtFuel_scrubber = {
    IFO: 3.151,
    LSFO: 10.5,
    HSFO: 0.84,
    MGO: 2.1,
    LNG: 0.02,
    MeOH: 0,
    NH3: 0,
    H2: 0,
    Bio: 0,
    KwH: 0
}

export const ratio_kgNOx_to_mtFuel = {
    IFO: 0,
    LSFO: 51,
    HSFO: 51,
    MGO: 50.5,
    LNG: 8.4,
    MeOH: 26.10,
    NH3: 0,
    H2: 0,
    Bio: 0,
    KwH: 0
}

export const ratio_kgNOx_to_mtFuel_scrubber = {
    IFO: 0,
    LSFO: 51,
    HSFO: 0.51,
    MGO: 50.5,
    LNG: 8.4,
    MeOH: 26.10,
    NH3: 0,
    H2: 0,
    Bio: 0,
    KwH: 0
}

//source: https://www.researchgate.net/figure/Emissivity-indices-for-selected-marine-fuels_tbl5_348268549

export const DATA_SOURECE_CATEGORY = {
    FREIGHT_RATES: "FREIGHT_RATES",
    BUNKER_PRICES: "BUNKER_PRICES",
    EMISSIONS_PRICES: "EMISSIONS_PRICES",
}

export const DATA_SOURCE = createEnum([{
    name: "PLATTS",
    caption: "SPGCI",
    categories: [DATA_SOURECE_CATEGORY.FREIGHT_RATES, DATA_SOURECE_CATEGORY.BUNKER_PRICES, DATA_SOURECE_CATEGORY.EMISSIONS_PRICES],
}, {
    name: "BALTIC",
    caption: "Baltic Exchange",
    categories: [DATA_SOURECE_CATEGORY.FREIGHT_RATES]
}, {
    name: "BUNKEREX",
    caption: "BunkerEx",
    categories: [DATA_SOURECE_CATEGORY.BUNKER_PRICES]
}, {
    name: "PLATTS_AND_BALTIC",
    caption: "All data providers",
    categories: [DATA_SOURECE_CATEGORY.FREIGHT_RATES]
}, {
    name: "NONE",
    caption: "None",
    categories: [DATA_SOURECE_CATEGORY.EMISSIONS_PRICES]
}, /*
Disable this option for a while 
{
    name: "PLATTS_AND_BUNKERX",
    caption: "All data providers",
    categories: [DATA_SOURECE_CATEGORY.BUNKER_PRICES]

} */], (valueMap, values) => ({
    valuesAsSelectOptions(category) {
        return values
            .filter((v) => v.categories.includes(category))
            .map((v) => ({
                value: v.name, label: v.caption
            }))
    },
    isPlatts(dataSource) {
        return dataSource === valueMap.PLATTS.name || dataSource === valueMap.PLATTS_AND_BALTIC.name/*  || dataSource === valueMap.PLATTS_AND_BUNKERX.name */
    },
    isBaltic(dataSource) {
        return dataSource === valueMap.BALTIC.name || dataSource === valueMap.PLATTS_AND_BALTIC.name
    },
    isBunkerex(dataSource) {
        return dataSource === valueMap.BUNKEREX.name/*  || dataSource === valueMap.PLATTS_AND_BUNKERX.name */
    }
}))

export const VOLATILITY = createEnum([
    {
        name: "low",
        caption: "Low"
    },
    {
        name: "med",
        caption: "Medium"
    },
    {
        name: "high",
        caption: "High"
    }
])

export const LIQUIDITY = createEnum([
    {
        name: "low",
        caption: "Low"
    },
    {
        name: "med",
        caption: "Medium"
    },
    {
        name: "high",
        caption: "High"
    }
])

export const FUEL_TYPES = createEnum([
    {
        name: "LSFO",
        caption: "Low-Sulphur Fuel Oil",
    },
    {
        name: "HSFO",
        caption: "High-Sulphur Fuel Oil"
    },
    {
        name: "MGO",
        caption: "Low-Sulphur Marine Gasoil"
    },
    {
        name: "LNG",
        caption: "Liquefied Natural Gas"
    },
    {
        name: "MEOH",
        caption: "Methanol"
    },
    {
        name: "BIO",
        caption: "Biofuel"
    },
    {
        name: "NH3",
        caption: "Ammonia"
    },
    {
        name: "H2",
        caption: "Hydrogen"
    },
    {
        name: "KWH",
        caption: "Wind"
    }
])

export const ProviderStatus = createEnum([
    {
        name: "NOT_CONFIGURED",
        caption: "Not configured",
        desciption: "Data sources is not configured yet"
    },
    {
        name: "ACTIVE",
        caption: "Active",
        desciption: "Data source is configured and active"
    },
    {
        name: "ACTIVE_WITH_NO_RELEVANT_DATA",
        caption: "Active with no relevant data",
        desciption: "Data source is configured and active, but no relevant data is available"
    },
    {
        name: "INVALID",
        caption: "Invalid",
        desciption: "Data source configuration is invalid"
    },
    {
        name: "ERROR",
        caption: "Error",
        desciption: "Unable to fetch status"
    }
])

export const FUEL_TYPES_ARR = [
    {
        name: "LSFO",
        caption: "Low-Sulphur Fuel Oil",
    },
    {
        name: "HSFO",
        caption: "High-Sulphur Fuel Oil"
    },
    {
        name: "MGO",
        caption: "Low-Sulphur Marine Gasoil"
    },
    {
        name: "LNG",
        caption: "Liquefied Natural Gas"
    },
    {
        name: "MEOH",
        caption: "Methanol"
    },
    {
        name: "BIO",
        caption: "Biofuel"
    },
    {
        name: "NH3",
        caption: "Ammonia"
    },
    {
        name: "H2",
        caption: "Hydrogen"
    },
    {
        name: "KWH",
        caption: "Wind"
    }
]

export const FreightTypes = {
    WORLDSCALE: "worldscale",
    DOLLAR_PER_MT: "$/mt",
    LUMP_SUM: "lumpsum"
}

function createEnum(values, createExtesions = () => ({})) {
    const valueMap = values.reduce((acc, v) => ({
        ...acc, [v.name]: {
            ...v,
            matches(name) {
                return v.name.toUpperCase() === name.toUpperCase()
            }

        }
    }), {})

    return {
        ...valueMap,
        values() {
            return [...values]
        },
        indexOf(name) {
            return Object.keys(valueMap).findIndex((k) => valueMap[k].matches(name))
        },
        ...createExtesions(valueMap, values)
    }
}

export const MeasureUnit = {
    MT: 'mt',
    MT_DAY: 'mt/day',
    KNOTS: 'knots',
}
