import React, { useState, useEffect } from "react";
import GeneralTable from "../../../components/GeneralTable";
import {
  dollarFormat, mtFormat
} from "../../../utils/Helpers";
import { getBunkerDataForSelectedPeriod } from "./bunkerModalComponents/calculations/calcBunkerChart";
import { useRecoilValue } from "recoil";
import { voyages } from "../../../store/voyages";

// eslint-disable-next-line import/no-anonymous-default-export
export default function BunkerTableGroupedTable({ selectedVoyages, grouping }) {
  const [_selectedOption, setSelectedOption] = useState("all");
  const [_rows, setRows] = useState([]);
  const [_options, setOptions] = useState([])
  const _voyages = useRecoilValue(voyages)


  useEffect(() => {
    const getData = async () => {

      if (selectedVoyages.length > 0) {
        const relevantVoyages = _voyages.filter((v) => selectedVoyages.includes(v.id))
        const dataMonthAll = getBunkerDataForSelectedPeriod(
          "current",
          relevantVoyages,
          grouping
        );
        const dataMonth = dataMonthAll.bunkersByGroup;
        const dataQuarterAll = getBunkerDataForSelectedPeriod(
          "quarter",
          relevantVoyages,
          grouping
        );
        const dataQuarter = dataQuarterAll.bunkersByGroup;
        const dataFutureAll = getBunkerDataForSelectedPeriod(
          "future",
          relevantVoyages,
          grouping
        );

        const dataFuture = dataFutureAll.bunkersByGroup
        const keys = dataFutureAll.keys
        let rob, purchased, bunkersNeeded, bestCaseCosts, averageCosts, worstCaseCosts

        const optionsArray = [
          { key: "all", label: "All" },
          ...keys.map((x) => {
            return { key: x, label: x };
          }),
        ]
        setOptions(optionsArray)

        if (_selectedOption === "all" || !keys.includes(_selectedOption)) {
          let monthRob = 0
          let monthPurchased = 0
          let monthBunkersNeeded = 0
          let monthBestCaseCosts = 0
          let monthAverageCosts = 0
          let monthWorstCaseCosts = 0

          let quarterRob = 0
          let quarterPurchased = 0
          let quarterBunkersNeeded = 0
          let quarterBestCaseCosts = 0
          let quarterAverageCosts = 0
          let quarterWorstCaseCosts = 0

          let futureRob = 0
          let futurePurchased = 0
          let futureBunkersNeeded = 0
          let futureBestCaseCosts = 0
          let futureAverageCosts = 0
          let futureWorstCaseCosts = 0

          for (const key in dataMonth) {
            monthRob += dataMonth[key].robSum
            monthPurchased += dataMonth[key].purchasedSum
            monthBunkersNeeded += dataMonth[key].requiredBunkers
            monthBestCaseCosts += dataMonth[key].bestCaseSum
            monthAverageCosts += dataMonth[key].meanCostSum
            monthWorstCaseCosts += dataMonth[key].worstCaseSum
          }


          for (const key in dataQuarter) {
            quarterRob += dataQuarter[key].robSum
            quarterPurchased += dataQuarter[key].purchasedSum
            quarterBunkersNeeded += dataQuarter[key].requiredBunkers
            quarterBestCaseCosts += dataQuarter[key].bestCaseSum
            quarterAverageCosts += dataQuarter[key].meanCostSum
            quarterWorstCaseCosts += dataQuarter[key].worstCaseSum
          }


          for (const key in dataFuture) {
            futureRob += dataFuture[key].robSum
            futurePurchased += dataFuture[key].purchasedSum
            futureBunkersNeeded += dataFuture[key].requiredBunkers
            futureBestCaseCosts += dataFuture[key].bestCaseSum
            futureAverageCosts += dataFuture[key].meanCostSum
            futureWorstCaseCosts += dataFuture[key].worstCaseSum
          }

          bunkersNeeded = [
            "Required Bunkers",
            mtFormat(monthBunkersNeeded),
            mtFormat(quarterBunkersNeeded),
            mtFormat(futureBunkersNeeded),
          ];
          purchased = [
            "Purchased Bunkers",
            dollarFormat(monthPurchased),
            dollarFormat(quarterPurchased),
            dollarFormat(futurePurchased)
          ];
          bestCaseCosts = [
            "Best Case Costs",
            dollarFormat(monthBestCaseCosts),
            dollarFormat(quarterBestCaseCosts),
            dollarFormat(futureBestCaseCosts)
          ];
          averageCosts = [
            "Expected Costs",
            dollarFormat(monthAverageCosts),
            dollarFormat(quarterAverageCosts),
            dollarFormat(futureAverageCosts)
          ];

          worstCaseCosts = [
            "Worst Case Costs",
            dollarFormat(monthWorstCaseCosts),
            dollarFormat(quarterWorstCaseCosts),
            dollarFormat(futureWorstCaseCosts)
          ];

        } else {
          bunkersNeeded = [
            "Required Bunkers",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].requiredBunkers
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].requiredBunkers
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].requiredBunkers
                : 0
            )
          ];
          purchased = [
            "Purchased",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].purchasedSum
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].purchasedSum
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].purchasedSum
                : 0
            )
          ];
          bestCaseCosts = [
            "Best Case Costs",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].bestCaseSum
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].bestCaseSum
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].bestCaseSum
                : 0
            )
          ];
          averageCosts = [
            "Expected Costs",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].meanCostSum
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].meanCostSum
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].meanCostSum
                : 0
            )
          ];

          worstCaseCosts = [
            "Worst Case Costs",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].worstCaseSum
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].worstCaseSum
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].worstCaseSum
                : 0
            )
          ]
        }
        setRows([purchased, bunkersNeeded, bestCaseCosts, averageCosts, worstCaseCosts]);
      }
    };
    getData().catch(console.error);
  }, [selectedVoyages, _selectedOption, grouping, _voyages]);

  const cols = ["", "Current Month", "Current Quarter", "All Future Periods"];


  return (
    <>
      <select
        className="p-1 border border-1 rounded-md"
        onChange={(ev) => setSelectedOption(ev.target.value)}
      >
        {_options.map((option) => (
          <option key={"beum" + option.key} value={option.key}>
            {option.label}
          </option>
        ))}
      </select>

      {_rows.length > 0 && <>
        <GeneralTable rows={_rows} cols={cols} />
      </>}
    </>
  );
};
