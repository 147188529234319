import { selector, selectorFamily } from "recoil"
import { FetchCanalPassagesFrom, FetchCanalPassagesFromVLCC, FetchPortDistances, FetchPortDistancesVLCC, FetchPortDistancesFrom, FetchPortDistancesFromVLCC, FetchPortDistancesNoCanalsFrom, FetchPortECAsFrom, FetchPortECAsFromVLCC, FetchPortECAsNoCanalsFrom } from "./api/data"

/**
 * Returns nested dictionary with keys as ports, with distances
 */
export const portDistances = selector({
    key: "portDistances",
    get: async () => {

        const fetch = await FetchPortDistances()
        if (fetch.success) {
            return fetch.portDistances
        } else {
            return {}
        }
    }
})

export const portDistancesVLCC = selector({
    key: "portDistancesVLCC",
    get: async () => {

        const fetch = await FetchPortDistancesVLCC()
        if (fetch.success) {
            return fetch.portDistances
        } else {
            return {}
        }
    }
})

/**
 * Returns dictionary with keys as ports, with distances from given port
 */
export const portDistancesFrom = selectorFamily({
    key: "portDistancesFrom",
    get: (array) => async () => {
        const response = await FetchPortDistancesFrom(array[0], array[1])
        if (response.success) {
            return response.distances
        } else {
            throw response.error
        }
    }
})

export const portDistancesFromVLCC = selectorFamily({
    key: "portDistancesFromVLCC",
    get: (array) => async () => {
        const response = await FetchPortDistancesFromVLCC(array[0], array[1])
        if (response.success) {
            return response.distances
        } else {
            throw response.error
        }
    }
})

/**
 * Returns dictionary with keys as ports, with distances from given port
 */
export const portDistancesNoCanalsFrom = selectorFamily({
    key: "portDistancesNoCanals",
    get: (array) => async () => {

        const response = await FetchPortDistancesNoCanalsFrom(array[0], array[1])
        if (response.success) {
            return response.distances
        } else {
            throw response.error
        }
    }
})

export const portECAsFrom = selectorFamily({
    key: "ecasFrom",
    get: (input) => async () => {
        const response = await FetchPortECAsFrom(input[0], input[1])
        if (response.success) {
            return response.ecaPercentages
        } else {
            throw response.error
        }
    }
})

export const portECAsFromVLCC = selectorFamily({
    key: "ecasFromVLCC",
    get: (input) => async () => {
        const response = await FetchPortECAsFromVLCC(input[0], input[1])
        if (response.success) {
            return response.ecaPercentages
        } else {
            throw response.error
        }
    }
})

/**
 * Returns dictionary with keys as ports, with distances from given port
 */
export const portECAsNoCanalsFrom = selectorFamily({
    key: "ecasNoCanalsFrom",
    get: (input) => async () => {
        const response = await FetchPortECAsNoCanalsFrom(input[0], input[1])
        if (response.success) {
            return response.ecaPercentages
        } else {
            throw response.error
        }
    }
})

/**
 * Returns dictionary with keys as ports, with an enum value whether a canal passage is needed from given port
 * Possible values are: values of CANAL_PASSAGES
 */
export const canalPassagesFrom = selectorFamily({
    key: "canalPassagesFrom",
    get: (port) => async () => {
        const response = await FetchCanalPassagesFrom(port)
        if (response.success) {
            return response.canalPassages
        } else {
            throw response.error
        }
    }
})

export const canalPassagesFromVLCC = selectorFamily({
    key: "canalPassagesFromVLCC",
    get: (port) => async () => {
        const response = await FetchCanalPassagesFromVLCC(port)
        if (response.success) {
            return response.canalPassages
        } else {
            throw response.error
        }
    }
})

export const CANAL_PASSAGES = {
    PANAMA: "Panama",
    SUEZ: "Suez",
    BOTH: "Both",
    NEITHER: "Neither"
}