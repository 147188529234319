import React from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ cols, rows }) => {

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {cols.map((col, i) => (
                  <th
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    key={"col" + i}
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {rows.map((row, i) => (
                <tr key={"row" + i}>
                  {row.map((col, j) => (
                    <td className="whitespace-nowrap px-3 py-4 text-gray-500" key={"tc" + i + j}>{col}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
