import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import PrimaryHeading from "../Typography/PrimaryHeading"
import HelpIcon from "@mui/icons-material/Help"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.main,
        padding: "10px 15px"
    }
}))

export default ({title, help, children}) => {

    const classes = useStyles()

    return (
        <Paper className={classes.paper} style={{position: "relative"}}>
            {help !== undefined &&
            <IconButton size="small" style={{position: "absolute", top: 5, right: 5, backgroundColor: "transparent"}} color="primary">
                <Tooltip title={help}>
                    <HelpIcon fontSize="inherit"/>
                </Tooltip>
            </IconButton>
            }
            <PrimaryHeading>{title}</PrimaryHeading>

            {children}
        </Paper>
    )
}
