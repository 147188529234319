import { atom, selector, selectorFamily, useRecoilState } from "recoil"
import FuelConsumptionCurves from "../dataMocks/CustomFuelConsumption.json"
import ModelShipMultipliers from "../dataMocks/ModelShipMultipliers.json"
import { getRouteLookup, getRouteParamsIn } from "./api"
import { CreateVessel, DeleteVessel, FetchCustomVessels, FetchModelVessels, UpdateVessel } from "./api/aftapi.js"
import { modelVesselCustomisationForSelectedIndex, selectedEmissionRate, selectedRoutes } from "./ui"

/**
 * vessels.js
 * Recoil objects for users selected, setup vessels and selectors to access
 * Vessel info from api
 *
 * Vessels are many to many with routes:  They are assoicated with routes, as in each route
 * has a number of possible vessels associated with it and each vessel is only
 * applicable to certain routes.
 *
 * When querying vessels, they should always be filtered by a route as context. It is yet
 * to be defined exactly how custom ships will relate to routes.
 */


export const userModelVessels = selector({
    key: "userModelVessels",
    get: async () => {
        const fetch = await FetchModelVessels()
        if (fetch.success) {
            return fetch.data
        } else {
            return []
        }
    }
})

/**
 * List of user customised vessels.  This is a keyed list of actual custom vessels
 * which have been selected and added by the user
 *
 */
export const userCustomisedVessels = atom({
    key: "userCustomisedVessels",
    default: selector({
        key: "userCustomisedVesselsLoader",
        get: async () => {
            const fetch = await FetchCustomVessels()
            if (fetch.success) {
                return fetch.data
            } else {
                return []
            }
        }
    })
})

export function useUserCustomisedVesselsMutations() {
    const [customVessels, setCustomVessels] = useRecoilState(userCustomisedVessels)

    const saveVessel = async vesselData => {
        const update = vesselData.id != null
        const response = update ? await UpdateVessel(vesselData) : await CreateVessel(vesselData)

        if (response.success) {
            const savedVesselData = response.data
            if (update) {
                setCustomVessels(customVessels.map(v => String(v.id) === String(savedVesselData.id) ? savedVesselData : v))
            }
            else {
                setCustomVessels([...customVessels, savedVesselData])
            }

            return savedVesselData
        }
    }

    const deleteVessel = async id => {
        await DeleteVessel(id)

        setCustomVessels(customVessels.filter(v => String(v.id) !== String(id)))
    }

    return { saveVessel, deleteVessel }
}

/**
 * Returns userCustomisedVessels filtered by route
 */
export const vesselsByRoute = selectorFamily({
    key: "vesselsByRoute",
    get: (routeId) => async ({ get }) => {
        // Only return valid ids from _availableVessels
        const custom = await get(userCustomisedVessels)

        // Filter also by routeId
        const Routes = get(getRouteParamsIn)
        const info1 = Routes[routeId]
        const validShipTypes = [info1.shiptype.toLowerCase()]
        return custom.filter((v) =>
            validShipTypes.includes(v.shipType.toLowerCase())
        )
    }
})


/**
 * Returns userCustomisedVessels filtered by route
 */
export const vesselsByShipType = selectorFamily({
    key: "vesselsByShipType",
    get: (shipType) => async ({ get }) => {
        // Only return valid ids from _availableVessels
        const custom = await get(userCustomisedVessels)

        const validShipType = shipType.toLowerCase()
        return custom.filter((v) => validShipType === v.shipType.toLowerCase())
    }
})


export const SHIP_TYPES = {
    VLCC: "VLCC",
    SUEZMAX: "Suezmax",
    AFRAMAX_LR2: "Aframax/LR2",
    PANAMAX_LR1: "Panamax/LR1",
    MR: "MR",
    HANDY: "Handy"
}

export function getSelectableVessels(modelOptions, _modelVessels, _customVessels) {
    const selecatbleModelVessels = Object.values(SHIP_TYPES).filter((st) =>
        _modelVessels.findIndex((v) => v.shipType === st) >= -1
    ).map((st) => {
        const modelsByType = _modelVessels.filter((v) => v.shipType === st)
        let selectableModelVessel = modelsByType.find((v) => v.ecoShip === modelOptions.ecoShip && v.scrubber === modelOptions.scrubber)

        return { ...JSON.parse(JSON.stringify(selectableModelVessel)), shipName: `${selectableModelVessel.shipType} Model` }
    })

    return [..._customVessels, ...selecatbleModelVessels]
}

/**
 * The selected ship type, region and vessel for the AI calculators
 */
export const regionAndVesselForAI = atom({
    key: "regionAndVesselForAI",
    default: ["VLCC", "Caribbean", "default"]
})

/**
 * The model ship customisation for the AI calculators
 */
export const modelVesselCustomisationForAI = atom({
    key: "modelVesselCustomisationForAI",
    default: {
        eco: false,
        scrubber: false,
        slowSteam: false,
        halfLaden: false
    }
})

/**
 * The actual model ship used in the AI calculators
 */
export const customisedModelVesselForAI = selector({
    key: "customisedModelVesselForAI",
    get: ({ get }) => {

        const regionVesselAI = get(regionAndVesselForAI) // [shipType, region, shipUsed]
        const params = get(modelVesselCustomisationForAI)
        const vesselType = regionVesselAI[0]

        const modelShip = ModelShipMultipliers.find((v) => {
            return (
                v.vesselType === vesselType &&
                (v.ecoShip === 1) === params.eco &&
                (v.modelShipHalfLaden === 1) === params.halfLaden &&
                (v.scrubber === 1) === params.scrubber &&
                (v.modelShipSlowSteam === 1) === params.slowSteam
            )
        })

        if (!modelShip) {
            console.error("Ship not found for AI with parameters: " + params)
        }

        return modelShip
    }
})


/**
 * For an given route, looks up ui preferences and then looks up in model ship table
 * to get multipliers for pricing calculations
 * @type {(param: SerializableParam) => RecoilValueReadOnly<unknown>}
 */
export const customisedModelVesselForSelectedRoutes = selector({
    key: "customisedModelVesselForSelectedRoutes",
    get: ({ get }) => {

        const userSelectedRoutes = get(selectedRoutes)
        const shipParams = userSelectedRoutes.map((sr, index) => {
            const routeId = sr.item
            const params = get(modelVesselCustomisationForSelectedIndex(index))
            const Routes = get(getRouteParamsIn)
            const vesselType = Routes[routeId].shiptype

            const modelShip = ModelShipMultipliers.find((v) => {
                return (
                    v.vesselType === vesselType &&
                    (v.ecoShip === 1) === params.eco &&
                    (v.modelShipHalfLaden === 1) === params.halfLaden &&
                    (v.scrubber === 1) === params.scrubber &&
                    (v.modelShipSlowSteam === 1) === params.slowSteam
                )
            })

            if (!modelShip) {
                console.error("Ship not found for: " + routeId + " with parameters: " + JSON.stringify(params))
            }

            return modelShip
        })

        return shipParams

    }
})

/**
 * Provides an array with the correctly average emissions rate, dependent
 * on the routes region
 */
export const emissionsRatesForSelectedRoutes = selector({
    key: "emissionsRatesForSelectedRoutes",
    get: ({ get }) => {

        const userSelectedRoutes = get(selectedRoutes)
        const selectedEmissionRates = get(selectedEmissionRate)
        const routeLookup = get(getRouteLookup)

        const emissionsRates = userSelectedRoutes.map((sr, index) => {
            const routeId = sr.item

            const route = routeLookup.find((x) => x["routeID"] === routeId)


            const nonEU = 1 - route["EUEmissionsPercentage"]
            return route["EUEmissionsPercentage"] * selectedEmissionRates["EU"] +
                nonEU * selectedEmissionRates["nonEU"]
        })

        return emissionsRates

    }
})

/**
 * Returns array with custom vessels, selected real vessels, and model vessels,
 * all are options for the Bunker Engine
 */
export const allVessels = selector({
    key: "allVessels",
    get: async ({ get }) => {
        const _modelVessels = get(userModelVessels)
        const _customVessels = await get(userCustomisedVessels)

        return getSelectableVessels({ scrubber: false, ecoShip: false }, _modelVessels, _customVessels)
    }
})


/**
 * Get a particular custom fuel consumption
 * shipType: (E.g. VLCC)
 * scrubber: true/false
 * ecoShip: true/false
 * ballastLaden: "ballast" or "laden"
 * speed: 10-15 steps of 0.5
 */
export function getDefaultFuelConsumption(shipType, scrubber, ecoShip, ballastLaden, speed) {
    const _customFuelConsumption = FuelConsumptionCurves

    const shipTypeSearch = shipType === "Aframax/LR2" ? "Aframax" : (shipType === "Panamax/LR1" ? "LR1" : shipType)

    const fuelConsumption = _customFuelConsumption.find((x) => {
        return (
            x.shipType === shipTypeSearch &&
            x.scrubber === (scrubber ? "Yes" : "No") &&
            x.ecoShip === (ecoShip ? "Yes" : "No") &&
            x.ballastLaden === ballastLaden &&
            x.speed === speed)
    })

    return fuelConsumption["fuelCons"]
}

export function getDefaultFuelConsumptionCurve(shipType, scrubber, ecoShip, ballastLaden) {
    const _customFuelConsumption = FuelConsumptionCurves

    const shipTypeSearch = shipType === "Aframax/LR2" ? "Aframax" : (shipType === "Panamax/LR1" ? "LR1" : shipType)

    return _customFuelConsumption.filter((x) => {
        return (
            x.shipType === shipTypeSearch &&
            x.scrubber === (scrubber ? "Yes" : "No") &&
            x.ecoShip === (ecoShip ? "Yes" : "No") &&
            x.ballastLaden === ballastLaden)
    })
        .map((c) => ({ speed: c.speed, consumption: c.fuelCons }))
        .sort((a, b) => a.speed - b.speed)
}

export function getVesselFuelConsumption(vesselData, ballastLaden, speed) {
    const { customConsumptions, shipType, scrubber, ecoShip } = vesselData

    if (customConsumptions == null) {
        return getDefaultFuelConsumption(shipType, scrubber, ecoShip, ballastLaden, speed)
    }

    const consumptions = customConsumptions[ballastLaden]
    const consumptionWithSpeed = consumptions.find((c) => c.speed === speed)

    if (consumptionWithSpeed && consumptionWithSpeed.consumption != null) {
        return consumptionWithSpeed.consumption
    } else {
        let closestSpeed = Number.MAX_SAFE_INTEGER
        closestSpeed = consumptions.reduce((closest, c) => Math.abs(c.speed - speed) < Math.abs(closest - speed) ? c.speed : closest, closestSpeed)
        if (closestSpeed < Number.MAX_SAFE_INTEGER) {
            return consumptions.find((c) => c.speed === closestSpeed).consumption
        }
        else {
            return getDefaultFuelConsumption(shipType, scrubber, ecoShip, ballastLaden, speed)
        }
    }
}

export function getVesselConsumptionCurve(vesselData, ballastLaden) {
    const { customConsumptions, shipType, scrubber, ecoShip } = vesselData

    if (customConsumptions == null) {
        return getDefaultFuelConsumptionCurve(shipType, scrubber, ecoShip, ballastLaden)
    }

    return customConsumptions[ballastLaden]
}
