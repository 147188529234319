import React from "react"

export function NoProviders({spgciFailed, bunkerExFailed, balticFailed, page}){

    const message = 'Please check status of integrations with ';
    let providers = ""

    if (spgciFailed) {
      providers = 'SPGCI';
    }
    
    if (bunkerExFailed) {
      console.log("in bunkerex failed")
      const snippet = (spgciFailed) ? ", BunkerEx" : "BunkerEx";
      providers = providers + snippet;
    }

    if (balticFailed) {
      console.log("in baltic failed")
      const snippet = (spgciFailed || bunkerExFailed) ? ", Baltic Exchange" : "Baltic Exchange";
      providers = providers + snippet;
    }

    
    return (
              <div className="flex items-center">
                    <div className="text-xl text-ao-figma-text-gray">
                        {message} <span className="font-bold">{providers}</span> for {page}
                    </div>
                </div>
            )
}