import { atom, selector } from "recoil";
import {
  FetchProviderStatus
} from "./api/services-api";

export const spgciStatus = atom({
  key: "spgciStatus",
  default: selector({
    key: "spgciStatusLoader",
    get: async () => {
      const response = await FetchProviderStatus("SPGCI");
      return response.data;
    },
  }),
});

export const bunkerExStatus = atom({
  key: "bunkerExStatus",
  default: selector({
    key: "bunkerExLoader",
    get: async () => {
      const response = await FetchProviderStatus("BunkerEx");
      return response.data;
    },
  }),
});

export const balticStatus = atom({
  key: "balticStatus",
  default: selector({
    key: "balticLoader",
    get: async () => {
      const response = await FetchProviderStatus("Baltic Exchange");
      return response.data;
    },
  }),
});
