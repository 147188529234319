import PEBunkers from "./TwPricingEngine/PEBunkers"
import PEEmissions from "./TwPricingEngine/PEEmissions"
import PEFreightRates from "./TwPricingEngine/PEFreightRates"
import PETCEs from "./TwPricingEngine/PETCEs"
import Vessels from "./TwVessels/Vessels"
import {
    ChartBarSquareIcon,
    Cog8ToothIcon
} from '@heroicons/react/24/outline'
import ChartererTCEarnings from "./RiskEngine/ChartererTCEarnings"
import { Settings } from "./Settings/Settings"
import VEBunkers from "./TwVoyageEngine/VEBunkers"
import VEEmissions from "./TwVoyageEngine/VEEmissions"
import VEOptimizations from "./TwVoyageEngine/VEOptimizations"
import VEPortfolioRisks from "./TwVoyageEngine/VEPortfolioRisks"
import VETanks from "./TwVoyageEngine/VETanks"
import VEVoyages from "./TwVoyageEngine/VEVoyages"
import { ManageSpgci } from "./Settings/ManageSpgci"
import CreateVessel from "./TwVessels/CreateVessel"

const navRoutes = [

    {
        id: "vessels",
        path: "/vessels",
        Component: Vessels,
        name: "Vessels",
        detailpaths: [{
            id: "vessel-add",
            path: "/vessels/add/:type",
            Component: CreateVessel,
            name: "Create vessel",
        }]
    },
    {
        id: "pricing-engine",
        name: "Pricing Engine",
        subpaths: [
            {
                id: "bunkers",
                path: "/pricing-engine/bunkers",
                Component: PEBunkers,
                name: "Bunkers"
            },
            {
                id: "emissions",
                path: "/pricing-engine/emissions",
                Component: PEEmissions,
                name: "Emissions "
            },
            {
                id: "freight-rates",
                path: "/pricing-engine/freight-rates",
                Component: PEFreightRates,
                name: "Freight Rates"
            },
            // {
            //     id: "risk-adjusted-earnings",
            //     path: "/pricing-engine/risk-adjusted-earnings",
            //     Component: PEAdjustedEarnings,
            //     name: "Risk Adjusted Earnings"
            // },
            {
                id: "tce-earnings",
                path: "/pricing-engine/time-charter-equivalent-earnings",
                Component: PETCEs,
                name: "Time Charter Equivalent Earnings (TCE)"
            },
        ]
    },
    // {
    //     id: "time-charter-engine",
    //     name: "Time Charter Engine",
    //     subpaths: [
    //         // {
    //         //     id: "baskets",
    //         //     path: "/time-charter-engine/baskets",
    //         //     Component: PEFreightRates,
    //         //     name: "Baskets"
    //         // },
    //         {
    //             id: "charterer-tc-earnings",
    //             path: "/time-charter-engine/charterer-tc-earnings",
    //             Component: ChartererTCEarnings,
    //             name: "Charterer TC Earnings"
    //         },
    //         // {
    //         //     id: "owner-tc-pricing",
    //         //     path: "/time-charter-engine/owner-tc-pricing",
    //         //     Component: OwnerTCPricing,
    //         //     name: "Owner TC Pricing"
    //         // },
    //         // {
    //         //     id: "time-charters",
    //         //     path: "/time-charter-engine/time-charters",
    //         //     Component: PEFreightRates,
    //         //     name: "Time Charters"
    //         // },
    //         // {
    //         //     id: "trading-simulations",
    //         //     path: "/time-charter-engine/trading-simulations",
    //         //     Component: PEFreightRates,
    //         //     name: "Trading Simulations"
    //         // },
    //     ]
    // },
    {
        id: "voyage-engine",
        name: "Voyage Engine",
        subpaths: [
            {
                id: "voyages",
                path: "/voyage-engine/voyages",
                Component: VEVoyages,
                name: "Voyages"
            },
            {
                id: "bunkers",
                path: "/voyage-engine/bunkers",
                Component: VEBunkers,
                name: "Bunkers"
            },
            {
                id: "emissions",
                path: "/voyage-engine/emissions",
                Component: VEEmissions,
                name: "Emissions"
            },

            {
                id: "optimizations",
                path: "/voyage-engine/optimizations",
                Component: VEOptimizations,
                pageName: "Fuels & Emissions Optimizations",
                name: "Optimizations"
            },
            {
                id: "portfolio-risks",
                path: "/voyage-engine/portfolio-risks",
                Component: VEPortfolioRisks,
                name: "Portfolio Risks"
            },
            {
                id: "tanks",
                path: "/voyage-engine/tanks",
                Component: VETanks,
                name: "Tanks"
            },
        ]
    },
    // {
    //     id: "alpha-engine",
    //     name: "Alpha Engine",
    //     subpaths: [
    //         {
    //             id: "commodity-freight-causality",
    //             path: "/alpha-engine/commodity-freight-causality",
    //             Component: PEFreightRates,
    //             name: "Commodity-Freight Causality"
    //         },
    //         {
    //             id: "energy-intensity-indices",
    //             path: "/alpha-engine/energy-intensity-indices",
    //             Component: PEFreightRates,
    //             name: "Energy Intensity Indices"
    //         },
    //         {
    //             id: "fleet-movement",
    //             path: "/alpha-engine/fleet-movement",
    //             Component: PEFreightRates,
    //             name: "Fleet Movement"
    //         },
    //         {
    //             id: "freight-momentum",
    //             path: "/alpha-engine/freight-momentum",
    //             Component: PEFreightRates,
    //             name: "Freight Momentum"
    //         },
    //         {
    //             id: "regional-emissions-indices",
    //             path: "/alpha-engine/regional-emissions-indices",
    //             Component: PEFreightRates,
    //             name: "Regional Emissions Indices"
    //         }
    //     ]
    // },

    // {
    //     id: "heatmap",
    //     path: "/heatmap",
    //     Component: PEFreightRates,
    //     name: "Heatmap",
    // }
]

const userRoutes = [
    // {
    //     id: "my-reports",
    //     path: "/my-reports",
    //     Component: PEFreightRates,
    //     name: "My Reports",
    //     icon: ChartBarSquareIcon
    // },
    {
        id: "settings",
        path: "/settings",
        Component: Settings,
        name: "Settings",
        icon: Cog8ToothIcon,
        detailpaths: [{
            id: "spgci-settings",
            path: "/settings/spgci",
            Component: ManageSpgci,
            name: "SPGCI Settings",
        }]
    }
]

export { navRoutes, userRoutes }


// const navRoutes = [
//     // {
//     //     id: "global-overview-route",
//     //     path: "/", 
//     //     Component: GlobalOverview,
//     //     name: "Global Overview", 
//     // },
//     // {
//     //     id: "pricing-engine-route",
//     //     path: "/pricingengine", 
//     // Component: PricingEngine,
//     //     name: "Pricing Engine", 
//     //  
//     // },
//     {
//         id: "vessels",
//         path: "/vessels",
//         Component: PEFreightRates,
//         name: "Vessels",
//     },
//     {
//         id: "pricing-engine-route",
//         path: "/pricing-engine",
//         Component: PEFreightRates,
//         name: "Pricing Engine",
//         subpaths: [
//             {
//                 id: "freight-rates",
//                 path: "/freight-rates",
//                 Component: PEFreightRates,
//                 name: "Freight Rates"
//             },
//             {
//                 id: "bunkers",
//                 path: "/bunkers",
//                 Component: PEFreightRates,
//                 name: "Bunkers"
//             },
//             {
//                 id: "emissions",
//                 path: "/emissions",
//                 Component: PEFreightRates,
//                 name: "Emissions "
//             },
//             {
//                 id: "risk-adjusted-earnings",
//                 path: "/risk-adjusted-earnings",
//                 Component: PEFreightRates,
//                 name: "Risk Adjusted Earnings"
//             },
//             {
//                 id: "tce-earnings",
//                 path: "/time-charter-equivalent-earnings",
//                 Component: PEFreightRates,
//                 name: "Time Charter Equivalent Earnings (TCE)"
//             },
//         ]
//     },
//     {
//         id: "risk-engine-route",
//         path: "/riskengine",
//         Component: RiskEngine,
//         name: "Risk Engine",
//     },
//     {
//         id: "emissions-engine-route",
//         path: "/basicemissionsengine",
//         Component: BasicEmissionsEngine,
//         name: "Emissions Engine",
//     },
//     {
//         id: "bunker-engine-route",
//         path: "/bunker-engine",
//         Component: BunkerEngine,
//         name: "Bunker Engine",
//     },
//     {
//         id: "voyage-engine-route",
//         path: "/voyage-engine",
//         Component: VoyageEngine,
//         name: "Voyage Engine",
//     },
//     {
//         id: "alpha-engine-route",
//         path: "/alpha-engine",
//         Component: AlphaEngine,
//         name: "Alpha Engine",
//     },

//     {
//         id: "data-shopping-cart-route",
//         path: "/data-shop",
//         Component: DataShoppingCart,
//         name: "Data Shop",
//     },
// ]

// const userRoutes = [
//     {
//         id: "my-reports",
//         path: "/my-reports",
//         Component: PEFreightRates,
//         name: "My Reports",
//     },
//     {
//         id: "manage-alerts-route",
//         path: "/managealerts",
//         Component: ManageAlerts,
//         name: "Alerts",
//     },
// ]

// export { navRoutes }
