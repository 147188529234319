import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Checkbox from "@material-ui/core/Checkbox"

const useStyles = makeStyles({
    tableRow: {
        borderBottom: "1px solid #515151",
        cursor: "pointer",
        "&:last-child": {
            borderBottom: "none"
        },
        backgroundColor: '#e5e7eb'
    },
    firstCol: {
        fontWeight: 600,
        paddingLeft: 16,
        border: "none",
        fontSize: "0.9rem"
    },
    tableCell: {
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: "0.9rem",
        border: "none"
    }
})

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

export default ({
    columns,
    rows,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    setSelected,
    onRowClick,
    noSort,
    dense
}) => {
    const classes = useStyles()
    const data = noSort ? rows : stableSort(rows, getSorting(order, orderBy))
    return (
        <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const isItemSelected = selected.includes(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                        <TableRow
                            classes={{ root: classes.tableRow }}
                            onClick={() => onRowClick && onRowClick(row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                        >
                            {setSelected && <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                    onClick={(e) => {
                                        setSelected(row)
                                        e.stopPropagation()
                                    }}
                                />
                            </TableCell>}
                            {columns.map(({ id, align, style, tooltip, styleGenerator }, i) => {

                                if (i === 0) {
                                    return (
                                        <TableCell
                                            key={`tb-${i}`}
                                            align={align || "left"}
                                            classes={{ root: classes.firstCol }}
                                            style={{...(style || {}), ...(styleGenerator && styleGenerator(index, row) || {})}}
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            title={tooltip}
                                        >
                                            {row[id]}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell classes={{root: classes.tableCell}} key={`tb-${i}`} align="left">{row[id]}</TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            {rows.length === 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * 1 }}>
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>
    )
}
