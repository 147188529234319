import React from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

/* NOTE
  this is hot fucking garbage, please refactor this asap
*/

export default function BreadCrumbs({ routes }) {
  const location = useLocation();

  const getCrumbs = (routes) => {
    let rootCrumb;
    let trailingCrumbs = [];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === location.pathname) {
        rootCrumb = routes[i].name;
      } else if (routes[i].subpaths && routes[i].subpaths.length > 0) {
        for (let j = 0; j < routes[i].subpaths.length; j++) {
          if (routes[i].subpaths[j].path === location.pathname) {
            rootCrumb = routes[i].name;
            trailingCrumbs.push(routes[i].subpaths[j].name);
          }
        }
      } else if (routes[i].detailpaths && routes[i].detailpaths.length > 0) {
        for (let j = 0; j < routes[i].detailpaths.length; j++) {
          if (routes[i].detailpaths[j].path === location.pathname) {
            rootCrumb = routes[i].name;
            trailingCrumbs.push(routes[i].detailpaths[j].name);
          }
        }
      }
    }
    return (
      <div className="flex items-center">
        <span className=" text-sm font-medium text-gray-500 hover:text-gray-700">
          {rootCrumb}
        </span>

        {trailingCrumbs.length > 0 &&
          trailingCrumbs.map((crumb, i) => (
            <React.Fragment key={i}>
              <ChevronRightIcon
                className="h-4 w-4 flex-shrink-0 ml-1 text-gray-400"
                aria-hidden="true"
              />
              <span
                className={`${
                  trailingCrumbs.length > 0 && "ml-1"
                } text-sm font-medium text-gray-500 hover:text-gray-700`}
              >
                {crumb}
              </span>
            </React.Fragment>
          ))}
      </div>
    );
  };
  return (
    <nav className="flex" aria-label="Breadcrumb">
      {getCrumbs(routes)}
    </nav>
  );
}
