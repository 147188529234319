import { Tooltip } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Popover from "@material-ui/core/Popover"
import Select from "@material-ui/core/Select"
import MenuItem from "@mui/material/MenuItem"
import * as React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import RegionsLookup from "../../dataMocks/RegionsLookup.json"
import { availableBunkersFilter, bunkersFiltered, userSettings } from "../../store/ui"
import { DATA_SOURECE_CATEGORY, fuelTypeNames } from "../../utils/Constants"
import { DataSourceSelect } from "../DataSourceSelect/DataSourceSelect"

function allRegionsSelected(_availableRoutesFilter) {
    let regions = 0
    for (let region of RegionsLookup) {
                if (_availableRoutesFilter[region["codeName"]]) {
            regions += 1
        }
    }

    return regions === RegionsLookup.length
}

/**
 * A button and poover that allows users to select a filter on bunkers they wish to use
 * throughout the app
 */
export default function BunkerFilter({isSpotView = true}) {
    const [_availableBunkersFilter, setAvailableBunkerFilter] = useRecoilState(availableBunkersFilter)
    const _bunkersFiltered = useRecoilValue(bunkersFiltered)
    const _userSettings = useRecoilValue(userSettings)
    const [_anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(_anchorEl)
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // Set all regions on or off on filter
    const setAllRegionsFilter = (value) => {
        let filter = {}
        for (let region of RegionsLookup) {
            filter[region["codeName"]] = value
        }

        setAvailableBunkerFilter({
            ..._availableBunkersFilter,
            ...filter
        })
    }

    const handleRegionChange = (event) => {

        // Value is an array of selected short names
        const { target: { value } } = event

        let filter = {}
        for (let region of RegionsLookup) {
            filter[region["codeName"]] = value.includes(region["codeName"])
        }

        setAvailableBunkerFilter({
            ..._availableBunkersFilter,
            ...filter
        })
    }

    // Set a particular value on the filter
    const setKeyOnBunkersFilter = (key, value) => {
        setAvailableBunkerFilter({
            ..._availableBunkersFilter,
            [key]: value
        })
    }

    React.useEffect(() => {
        handleDataSourceChange(_userSettings.bunkerPricesSource)
    }, [_userSettings]);

    // Get selected regions in array
    const selectedRegions = RegionsLookup.filter((x) => _availableBunkersFilter[x["codeName"]])
        .map((x) => x["codeName"])


    const _allRegionsSelected = allRegionsSelected(_availableBunkersFilter)

    return (
        <div>
            <Tooltip title="Select which bunkers are available to select in the table">
                <button onClick={handleClick} className="py-1 px-4 rounded-md border font-semibold border-ao-blue hover:bg-ao-blue hover:text-white">
                    Filter Bunkers
                </button>
            </Tooltip>
            <Popover
                id="selected-routes-menu"
                anchorEl={_anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <Grid container spacing={2} style={{ margin: 10 }} >
                    <Grid item xs={6}>
                        <FormLabel>Data source</FormLabel>
                        <MenuItem>
                            <DataSourceSelect 
                                category={DATA_SOURECE_CATEGORY.BUNKER_PRICES}
                                value={_availableBunkersFilter.bunkerDataSource}
                                onChange={(e) => {
                                    handleDataSourceChange(e.target.value)
                                }}></DataSourceSelect>
                        </MenuItem>
                        {!isSpotView && (<FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={_availableBunkersFilter.showConstructedBunkerCurves}
                                    onChange={(e) => {
                                        setKeyOnBunkersFilter("showConstructedBunkerCurves", e.target.checked);
                                    }}
                                />
                            }
                            label="Show constructed curves"
                        />)}
                        <FormLabel className="block">Fuel Type</FormLabel>
                        <MenuItem>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    checked={_availableBunkersFilter.lsfo}
                                    onChange={(e) => {
                                        setKeyOnBunkersFilter("lsfo", e.target.checked)
                                    }} />} label={fuelTypeNames.LSFO} />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    checked={_availableBunkersFilter.hsfo}
                                    onChange={(e) => {
                                        setKeyOnBunkersFilter("hsfo", e.target.checked)
                                    }} />} label={fuelTypeNames.HSFO} />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    checked={_availableBunkersFilter.mgo}
                                    onChange={(e) => {
                                        setKeyOnBunkersFilter("mgo", e.target.checked)
                                    }} />} label={fuelTypeNames.MGO} />
                        </MenuItem>

                        <FormLabel style={{ marginTop: 32, color: "grey", width: 155 }}>
                            {_bunkersFiltered.platts.length + _bunkersFiltered.bunkerex.length} 
                            {" "}bunker prices selected
                        </FormLabel>
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>Region</FormLabel>

                        <MenuItem>
                            <FormControlLabel
                                control={<Checkbox
                                    size="small"
                                    checked={_allRegionsSelected}
                                    onChange={(e) => {
                                        setAllRegionsFilter(e.target.checked)
                                    }} />} label="Any region" />
                        </MenuItem>
                        <Select
                            multiple
                            displayEmpty
                            value={selectedRegions}
                            onChange={handleRegionChange}
                            input={<OutlinedInput label="Tag" />}
                            style={{ minWidth: 170, maxWidth: 170 }}
                            renderValue={(selected) => {

                                let regionTypes = []
                                for (let region of RegionsLookup) {
                                    if (selected.includes(region["codeName"])) {
                                        regionTypes.push(region["shortName"])
                                    }
                                }

                                if (regionTypes.length === RegionsLookup.length) {
                                    regionTypes = ["All regions"]
                                } else if (regionTypes.length > 2) {
                                    regionTypes = ["Many selected..."]
                                } else if (regionTypes.length === 0) {
                                    regionTypes = ["No regions!"]
                                }

                                return regionTypes.join("|")
                            }}
                        >
                            {RegionsLookup.sort((a, b) => (a["longName"] > b["longName"]) ? 1 : -1).map((region) => (
                                <MenuItem key={region["codeName"]} value={region["codeName"]}>
                                    <Checkbox checked={_availableBunkersFilter[region["codeName"]]} />
                                    <ListItemText primary={region["longName"]} />
                                </MenuItem>
                            ))}
                        </Select>


                    </Grid>
                    <Grid item xs={12}>
                        <MenuItem onClick={handleClose}>Ok</MenuItem>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    )

    function handleDataSourceChange(dataSource) {
        setKeyOnBunkersFilter('bunkerDataSource', dataSource)
    }
}
