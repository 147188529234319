import Keycloak from "keycloak-js"
import { useEffect, useState } from "react"

const initOptions = {
    url: process.env.REACT_APP_KEYCLOAK_URL, realm: process.env.REACT_APP_KEYCLOAK_REALM, clientId: process.env.REACT_APP_KEYCLOAK_CLIENT, onLoad: "login-required",
    clientSecret: "4GhvtaLfrAsFF6MlOYLDBhQDaQ1PCwPs"
}
export const keycloak = new Keycloak(initOptions)

export function useKeycloak() {
    const [status, setStatus] = useState("NONE")

    console.log("useKyecloak")

    if (status === "NONE") {
        console.log("init")

        keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
            console.log("initialised", auth)

            if (!auth) {
                window.location.reload()
            } else {
                setStatus("AUTHENTICATED")

                console.log(keycloak)
            }

            //Token Refresh
            setInterval(() => {
                keycloak.updateToken(70).then((refreshed) => {
                    // if (refreshed) {
                    //     console.log("Token refreshed" + refreshed)
                    // } else {
                    //     console.log("Token not refreshed, valid for "
                    //         + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + " seconds")
                    // }
                }).catch(() => {
                    setStatus("FAILED")
                })
            }, 6000)

        }).catch(() => {
            setStatus("FAILED")
        })

        setStatus("LOADING")
    }

    return {
        status
    }
}