import { getRecoil, getRecoilPromise } from "recoil-nexus"
import {
    bunkerPricesPort,
    emissionPrices,
    getPortListByShipType, getRouteParamsUnpriced, getRoutePrices
} from "../../../store/api"
import { CANAL_PASSAGES, canalPassagesFrom, canalPassagesFromVLCC, portDistancesFrom, portDistancesFromVLCC, portDistancesNoCanalsFrom, portECAsFrom, portECAsFromVLCC, portECAsNoCanalsFrom } from "../../../store/port"
import { availableEmissionPrices, userSettings } from "../../../store/ui"
import { SHIP_TYPES, getVesselFuelConsumption, userCustomisedVessels, userModelVessels } from "../../../store/vessels"
import { DATA_SOURCE, FreightTypes, FUEL_TYPES } from "../../../utils/Constants"
import {
    getJourneyTimeDays, formatDateToYYYYMM, addMonths, monthsBetweenDates, formatDateToYYYYMMDD,
    incrementDate, convertToSentenceCase, formatDateWithTime
} from "../../../utils/Helpers"
import axios from 'axios';
import { daysBetweenDates } from "../voyageutils"
import { formatVoyageRecosting, formatVoyage, updateServicesApiCalcs } from "../../../store/api/helpers/services-api-helpers"
import { voyages } from "../../../store/voyages"
import { keycloak } from "../../../utils/hooks/use-keycloak"

/**
 * Log of custom routes
 */

const servicesApiUrl = process.env.REACT_APP_SERVICES_API_URL

export const customRoutes = [
    "Custom_Route_VLCC(port)",
    "Custom_Route_Suez(port)",
    "Custom_Route_Afra(port)",
    "Custom_Route_LR1(port)",
    "Custom_Route_MR(port)",
    "Custom_Route_Handy(port)",
    "Custom_Route_VLCC(region)",
    "Custom_Route_Suez(region)",
    "Custom_Route_Afra(region)",
    "Custom_Route_LR1(region)",
    "Custom_Route_MR(region)",
    "Custom_Route_Handy(region)",
    "Custom_Route_VLCC(options)",
    "Custom_Route_Suez(options)",
    "Custom_Route_Afra(options)",
    "Custom_Route_LR1(options)",
    "Custom_Route_MR(options)",
    "Custom_Route_Handy(options)"]

export const customRoutesRegions = [
    "Custom_Route_VLCC(region)",
    "Custom_Route_Suez(region)",
    "Custom_Route_Afra(region)",
    "Custom_Route_LR1(region)",
    "Custom_Route_MR(region)",
    "Custom_Route_Handy(region)"]

export const regions = [
    "Baltic",
    "North Sea",
    "West Africa",
    "USWC",
    "South America",
    "Red Sea",
    "Med",
    "Middle East Gulf",
    "USGC",
    "UKC",
    "Caribbean",
    "Australia",
    "USAC",
    "SE Asia",
    "Black Sea",
    "China",
    "South Africa",
    "India",
    "South Korea",
    "EC Mexico",
    "Japan",
    "East Africa",
    "Pacific Russia",
    "North Atlantic"]

/**
 * Get the default vessel i.e. when user adds a new vessel
 * @returns {*}
 */

export const bunkerDaysAssumption = 0.5

/**
 * Create a new voyage to populate modal popup when user clicks "Add Voyage" button
 */
export const createNewVoyage = async () => {
    const _routeParamsInValues = Object.values(await getRecoil(getRouteParamsUnpriced))
    let vessel = (await getRecoil(userCustomisedVessels))[0]
    if (!vessel) {
        vessel = (await getRecoil(userModelVessels))[0]
    }
    const _userSettings = getRecoil(userSettings)
    const today = new Date()
    let openDate = today

    // set defaults for a new voyage
    let newVoyage = {
        vessel,
        timeCharterCost: 1000,
        freightRate: 0,
        freightType: FreightTypes.DOLLAR_PER_MT,
        commission: 3.75,
        taxes: 0,
        canalConstraints: CANAL_CONSTRAINTS.EXCLUDE,
        openDate: openDate,
        seaMargin: 5,
        laycanStart: incrementDate(openDate, 5),
        laycanEnd: incrementDate(openDate, 15),
        robIfo: 0,
        robMgo: 0,
        lngSub: 0,
        emissionsRegsEu: _userSettings.emissionsRegsEu,
        emissionsRegsNonEu: _userSettings.emissionsRegsNonEu,
        ballastSpeed: vessel.ballastSpeed,
        ladenSpeed: vessel.ladenSpeed,
        dischargeDays: 1,
        canalDays: 1,
        loadDays: 1,
        waitingDays: 1,
        flatRate: 0,
        bunkerOnVoyage: true
    }

    // Pick the first available route
    const isBalticSource = DATA_SOURCE.isBaltic(_userSettings.frightRatesSource)
    const isPlatssSource = DATA_SOURCE.isPlatts(_userSettings.frightRatesSource)
    const availableRoutes = Object.values(_routeParamsInValues).filter(x => x.shiptype === vessel.shipType)
        .filter(x => (x.routetype.toLowerCase() === 'baltic' ? isBalticSource : isPlatssSource) || (x.routetype.toLowerCase() === "unpriced"))
    availableRoutes.sort((m1, m2) => m1.routetype.toLowerCase() === 'platts' ? m2.routetype.toLowerCase() === 'platts' ? 0 : -1 : 1)
    // const routeParams = _routeParamsInValues.find((x) => x.shiptype === vessel.shipType)
    const routeParams = availableRoutes[0]

    newVoyage.canalConstraints = routeParams.canalChoice
    newVoyage.flatRate = routeParams.flatrate
    newVoyage = await setRouteOnVoyage(newVoyage, routeParams.shortName, _userSettings)
    newVoyage = await setVesselOnVoyage(newVoyage, vessel)
    newVoyage = updatePortDays(newVoyage)

    return newVoyage
}

/**
 * Updates the voyage when user changes a field, certain fields have knockon effects on other inputs
 */
export const getUpdatedVoyage = async (voyage, field, value, dataSourceSettings) => {

    if (field === "routeShortName") {
        setDefaultCanalChoice(voyage, value)    //canalConstraints property modified in-place
        const tempVoyage = await setRouteOnVoyage(voyage, value, dataSourceSettings)
        return await updatePortDays(tempVoyage)
    } else if (field === "openPort") {
        // get nearest bunker port when changing open port
        const nearestBunkerPort = await getBunkerPortNearest(value, voyage.canalConstraints, voyage.vesselData.shipType)
        let updatedVoyage = await setPortDistancesAndECAsOnVoyage({
            ...voyage,
            bunkerPort: nearestBunkerPort,
            [field]: value
        })
        updatedVoyage = await updateBunkerSpotPrices(updatedVoyage, dataSourceSettings)
        return updateCanalCostsAndDays(updatedVoyage)
    } else if (field === "loadingPort" || field === "dischargePort" || field === "loadingPort2" || field === "dischargePort2" || field === "canalConstraints") {
        const updatedVoyage = await setPortDistancesAndECAsOnVoyage({
            ...voyage,
            [field]: value
        })
        return updateCanalCostsAndDays(
            updatePortDays(updatedVoyage)
        )
    } else if (field === "vessel") {
        return setVesselOnVoyage(voyage, value)
    } else if (field === "bunkerPort") {
        // update bunker prices from bunkerPortChanges
        return updateCanalCostsAndDays(
            await updateBunkerSpotPrices(
                updatePortDays(
                    await setPortDistancesAndECAsOnVoyage({
                        ...voyage,
                        [field]: value
                    })
                ),
                dataSourceSettings
            )
        )
        // return updateCanalCostsAndDays(updatedVoyage)

    } else if (field === "loadDays"
        || field === "waitingDays"
        || field === "dischargeDays") {

        return updatePortDays({
            ...voyage,
            [field]: value
        })
    } else if (field === "canalDays") {

        return updateCanalCostsAndDays(
            updatePortDays({
                ...voyage,
                [field]: value
            })
        )
    }
    else if (field === "laycanStart") {

        return {
            ...voyage,
            laycanStart: value,
            laycanEnd: incrementDate(value, 5)
        }
    }
    else if (field === "cargoQuantity") {
        return updateCanalCostsAndDays({
            ...voyage,
            [field]: value
        })
    }
    else if (field === "openDate") {
        const updatedVoyage = await updateBunkerSpotPrices({
            ...voyage,
            openDate: value
        },
            dataSourceSettings
        )
        return updatedVoyage
    }
    else if (field === "ifoBunkerPrice") {
        return {
            ...voyage,
            [field]: value,
            ifoPricePort: "Quoted"
        }
    }
    else if (field === "mgoBunkerPrice") {
        return {
            ...voyage,
            [field]: value,
            mgoPricePort: "Quoted"
        }
    }
    else {
        return { ...voyage, [field]: value }
    }


}

export const getEUStatusForPorts = (...args) => {
    const portList = getRecoil(getPortListByShipType)

    return args.map(port => {
        if (!port) {
            // in the case where loadingPort2 and dischargePort2 are not used, return -1 for these ports (note : the result is not used in these case)
            return -1
        } else if (port in portList) {
            return portList[port].isEU
        } else {
            console.error("Port not found (in getEUStatusForPorts) - ", port, " returning false for EU Status.")
            return 0
        }
    })
}

export const getECAStatusForPorts = (...args) => {
    const portList = getRecoil(getPortListByShipType)
    return args.map(port => {
        if (!port) {
            // in the case where loadingPort2 and dischargePort2 are not used, return -1 for these ports (note : the result is not used in these case)
            return -1
        } else if (port in portList) {
            return portList[port].isECA
        } else {
            console.error("Port not found (in getECAStatusForPorts) - ", port, " returning false for ECA Status.")
            return 0
        }
    })
}

const FALLBACK_PORT = "London"

export async function safeGetDistance(portFrom, portTo, canalConstraints, laden) {


    const { basePort, destPort, isRegion } = getPortsRegions(portFrom, portTo)

    const distances = await getPortDistnacesFrom(basePort, canalConstraints, laden, isRegion)
    if (destPort in distances) {
        return distances[destPort]
    } else if (basePort === "" || destPort === "") {
        // in the case where loadingPort2 and dischargePort2 are not used, return distance = 0 
        return 0
    } else if (FALLBACK_PORT in distances) {
        console.error(`Port not found - ${destPort} using ${FALLBACK_PORT} instead.  Results will be crazy.`)
        return distances[FALLBACK_PORT]
    }
    throw new Error(`Port distance for [${destPort}] not found and distance for [${FALLBACK_PORT}] not found either.`)
}

export const CANAL_CONSTRAINTS = {
    ALLOW: "Allow Canals",
    EXCLUDE: "Exclude Canals",
    LADEN_ONLY: "Laden Leg Only",
    ALLOW_SUEZ: "Allow Suez Canal",
    SUEZ_LADEN_ONLY: "Suez Laden Leg Only"
}

async function getPortDistnacesFrom(port, canalConstraints, laden, isRegion = false) {
    const portDistancesNoCanals = getRecoilPromise(portDistancesNoCanalsFrom([port, isRegion]))

    if (canalConstraints === CANAL_CONSTRAINTS.ALLOW_SUEZ || canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY) {
        const portDistancesVLCC = getRecoilPromise(portDistancesFromVLCC([port, isRegion]))
        switch (canalConstraints) {
            case CANAL_CONSTRAINTS.ALLOW_SUEZ:
                return portDistancesVLCC
            default:
                return laden && canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY ? portDistancesVLCC : portDistancesNoCanals
        }
    } else {
        const portDistances = getRecoilPromise(portDistancesFrom([port, isRegion]))
        switch (canalConstraints) {
            case CANAL_CONSTRAINTS.EXCLUDE:
                return portDistancesNoCanals
            case CANAL_CONSTRAINTS.ALLOW:
                return portDistances
            default:
                return laden && canalConstraints === CANAL_CONSTRAINTS.LADEN_ONLY ? portDistances : portDistancesNoCanals
        }
    }
}

function getPortsRegions(portTo, portFrom) {
    let basePort = portFrom
    let destPort = portTo
    const isRegion = (regions && (regions.includes(portFrom) || regions.includes(portTo)))
    if (regions.includes(portFrom)) {
        basePort = portTo
        destPort = portFrom
    }
    return { basePort, destPort, isRegion }
}

export async function safeGetECA(portFrom, portTo, canalConstraints, laden) {

    if (portFrom === portTo) {
        return 0
    }

    const { basePort, destPort, isRegion } = getPortsRegions(portFrom, portTo)

    const ecaPercentages = await getPortECAsFrom(basePort, canalConstraints, laden, isRegion)

    if (destPort in ecaPercentages) {
        return ecaPercentages[destPort]
    } else {
        console.error(`Error ECAs not found for ${destPort}, in file for ${ecaPercentages}`)
    }

    return 0
}

async function getPortECAsFrom(port, canalConstraints, laden, isRegion) {

    const portECAsNoCanals = getRecoilPromise(portECAsNoCanalsFrom([port, isRegion]))

    if (canalConstraints === CANAL_CONSTRAINTS.ALLOW_SUEZ || canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY) {
        const portECAsVLCC = getRecoilPromise(portECAsFromVLCC([port, isRegion]))
        switch (canalConstraints) {
            case CANAL_CONSTRAINTS.ALLOW_SUEZ:
                return portECAsVLCC
            default:
                return laden && canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY ? portECAsVLCC : portECAsNoCanals
        }
    } else {
        const portECAs = getRecoilPromise(portECAsFrom([port, isRegion]))
        switch (canalConstraints) {
            case CANAL_CONSTRAINTS.EXCLUDE:
                return portECAsNoCanals
            case CANAL_CONSTRAINTS.ALLOW:
                return portECAs
            default:
                return laden && canalConstraints === CANAL_CONSTRAINTS.LADEON_ONLY ? portECAs : portECAsNoCanals
        }
    }

}

/**
 * Fetch the port distances from open, to bunker, to loading, to discharge and back to open
 */
const setPortDistancesAndECAsOnVoyage = async voyage => {
    const { bunkerPort, openPort, loadingPort, loadingPort2, dischargePort, dischargePort2, canalConstraints } = voyage
    const { timeOpenToBunker } = getNextOpen(voyage)

    let distanceOpenToBunker = 0
    let distanceBunkerToLoad = 0
    let distanceLoadToDischarge = 0
    let distanceLoadToLoad2 = 0
    let distanceLoad2ToDischarge = 0
    let distanceDischargeToDischarge2 = 0

    let ecaOpenToBunker = 0
    let ecaBunkerToLoad = 0
    let ecaLoadToDischarge = 0
    let ecaLoadToLoad2 = 0
    let ecaLoad2ToDischarge = 0
    let ecaDischargeToDischarge2 = 0
    let distanceMult = 1


    if (loadingPort2 === "" && dischargePort2 === "") {
        // Fetch halfway ports as e.g can use distances bunker to open and bunker to loading
        [distanceOpenToBunker, distanceBunkerToLoad, distanceLoadToDischarge,
            ecaOpenToBunker, ecaBunkerToLoad, ecaLoadToDischarge] = await Promise.all([
                safeGetDistance(openPort, bunkerPort, canalConstraints, false),
                safeGetDistance(bunkerPort, loadingPort, canalConstraints, false),
                safeGetDistance(loadingPort, dischargePort, canalConstraints, true),
                safeGetECA(openPort, bunkerPort, canalConstraints, false),
                safeGetECA(bunkerPort, loadingPort, canalConstraints, false),
                safeGetECA(loadingPort, dischargePort, canalConstraints, true)
            ])
        distanceMult = (distanceLoadToDischarge) / voyage.defaultDistance
    }
    else if (loadingPort2 !== "" && dischargePort2 === "") {
        [distanceOpenToBunker, distanceBunkerToLoad, distanceLoadToLoad2, distanceLoad2ToDischarge,
            ecaOpenToBunker, ecaBunkerToLoad, ecaLoadToLoad2, ecaLoad2ToDischarge] = await Promise.all([
                safeGetDistance(openPort, bunkerPort, canalConstraints, false),
                safeGetDistance(bunkerPort, loadingPort, canalConstraints, false),
                safeGetDistance(loadingPort, loadingPort2, canalConstraints, false),
                safeGetDistance(loadingPort2, dischargePort, canalConstraints, true),
                safeGetECA(openPort, bunkerPort, canalConstraints, false),
                safeGetECA(bunkerPort, loadingPort, canalConstraints, false),
                safeGetECA(loadingPort, loadingPort2, canalConstraints, false),
                safeGetECA(loadingPort2, dischargePort, canalConstraints, true)
            ])
        distanceMult = (distanceLoadToLoad2 + distanceLoad2ToDischarge) / voyage.defaultDistance
    }
    else if (loadingPort2 === "" && dischargePort2 !== "") {
        [distanceOpenToBunker, distanceBunkerToLoad, distanceLoadToDischarge, distanceDischargeToDischarge2,
            ecaOpenToBunker, ecaBunkerToLoad, ecaLoad2ToDischarge, ecaDischargeToDischarge2] = await Promise.all([
                safeGetDistance(openPort, bunkerPort, canalConstraints, false),
                safeGetDistance(bunkerPort, loadingPort, canalConstraints, false),
                safeGetDistance(loadingPort, dischargePort, canalConstraints, false),
                safeGetDistance(dischargePort, dischargePort2, canalConstraints, true),
                safeGetECA(openPort, bunkerPort, canalConstraints, false),
                safeGetECA(bunkerPort, loadingPort, canalConstraints, false),
                safeGetECA(loadingPort, dischargePort, canalConstraints, false),
                safeGetECA(dischargePort, dischargePort2, canalConstraints, true)
            ])
        distanceMult = (distanceLoadToDischarge + distanceDischargeToDischarge2) / voyage.defaultDistance
    }
    else {
        [distanceOpenToBunker, distanceBunkerToLoad, distanceLoadToLoad2, distanceLoad2ToDischarge, distanceDischargeToDischarge2,
            ecaOpenToBunker, ecaBunkerToLoad, ecaLoadToLoad2, ecaLoad2ToDischarge, ecaDischargeToDischarge2] = await Promise.all([
                safeGetDistance(openPort, bunkerPort, canalConstraints, false),
                safeGetDistance(bunkerPort, loadingPort, canalConstraints, false),
                safeGetDistance(loadingPort, loadingPort2, canalConstraints, false),
                safeGetDistance(loadingPort2, dischargePort, canalConstraints, true),
                safeGetDistance(dischargePort, dischargePort2, canalConstraints, true),
                safeGetECA(openPort, bunkerPort, canalConstraints, false),
                safeGetECA(bunkerPort, loadingPort, canalConstraints, false),
                safeGetECA(loadingPort, loadingPort2, canalConstraints, false),
                safeGetECA(loadingPort2, dischargePort, canalConstraints, true),
                safeGetECA(dischargePort, dischargePort2, canalConstraints, true)
            ])
        distanceMult = (distanceLoadToLoad2 + distanceLoad2ToDischarge + distanceDischargeToDischarge2) / voyage.defaultDistance
    }

    return {
        ...voyage,
        distanceOpenToBunker,
        distanceBunkerToLoad,
        distanceLoadToDischarge,
        distanceLoadToLoad2,
        distanceLoad2ToDischarge,
        distanceDischargeToDischarge2,
        ecaOpenToBunker,
        ecaBunkerToLoad,
        ecaLoadToDischarge,
        ecaLoadToLoad2,
        ecaLoad2ToDischarge,
        ecaDischargeToDischarge2,
        distanceMult: distanceMult // TODO: This may not be used in the future
    }
}

/**
 * Update the bunker spot prices i.e. when the bunker port changes
 */
const updateBunkerSpotPrices = async (voyage, dataSourceSettings) => {

    if (!voyage.bunkerPort) {
        return voyage
    }

    let vessel = voyage.vessel
    const _userSettings = getRecoil(userSettings)
    const usePlattsSpot = DATA_SOURCE.isPlatts(dataSourceSettings ? dataSourceSettings.bunkerPricesSource : _userSettings.bunkerPricesSource)
    const ifoFuel = vessel.scrubber ? FUEL_TYPES.HSFO : FUEL_TYPES.LSFO
    const _portListByShipType = getRecoil(getPortListByShipType)
    const proxy1 = _portListByShipType[voyage.bunkerPort].proxyPort1
    const proxy2 = _portListByShipType[voyage.bunkerPort].proxyPort2

    const plattsIFO = await getRecoilPromise(bunkerPricesPort({ provider: "SPGCI", port: voyage.bunkerPort, fuels: ifoFuel.name }))
    const plattsMGO = await getRecoilPromise(bunkerPricesPort({ provider: "SPGCI", port: voyage.bunkerPort, fuels: FUEL_TYPES.MGO.name }))
    const bunkerExIFO = await getRecoilPromise(bunkerPricesPort({ provider: "BunkerEx", port: voyage.bunkerPort, fuels: ifoFuel.name }))
    const bunkerExMGO = await getRecoilPromise(bunkerPricesPort({ provider: "BunkerEx", port: voyage.bunkerPort, fuels: FUEL_TYPES.MGO.name }))

    const { prices: plattsIFOPrices, port: ifoPlattsPort } = await filterPrices(plattsIFO, "SPGCI", voyage.bunkerPort, proxy1, proxy2, ifoFuel.name)
    const { prices: plattsMGOPrices, port: mgoPlattsPort } = await filterPrices(plattsMGO, "SPGCI", voyage.bunkerPort, proxy1, proxy2, FUEL_TYPES.MGO.name)
    const { prices: bunkerexIFOPrices, port: ifoBunkerexPort } = await filterPrices(bunkerExIFO, "BunkerEx", voyage.bunkerPort, proxy1, proxy2, ifoFuel.name)
    const { prices: bunkerexMGOPrices, port: mgoBunkerexPort } = await filterPrices(bunkerExMGO, "BunkerEx", voyage.bunkerPort, proxy1, proxy2, FUEL_TYPES.MGO.name)

    const _availableEmissionPrices = await getRecoilPromise(emissionPrices)

    const ifoPriceData = await getAllTickerBunkerData(voyage, plattsIFOPrices, bunkerexIFOPrices)
    const mgoPriceData = await getAllTickerBunkerData(voyage, plattsMGOPrices, bunkerexMGOPrices)
    const emissionData = getTickerEmissionData(voyage, _availableEmissionPrices)

    let ifoBunkerPrice, mgoBunkerPrice, ifoPricePort, mgoPricePort
    const ifoSpotData = await getSpotPrice((usePlattsSpot ? ifoPlattsPort : ifoBunkerexPort),
        proxy1,
        proxy2,
        (usePlattsSpot ? "SPGCI" : "BunkerEx"),
        ifoFuel.name,
        (usePlattsSpot ? ifoPriceData.platts.spotPrice : ifoPriceData.bunkerex.spotPrice.toFixed(2)),
        voyage.ifoBunkerPrice)

    ifoBunkerPrice = ifoSpotData.price
    ifoPricePort = ifoSpotData.port

    const mgoSpotData = await getSpotPrice((usePlattsSpot ? mgoPlattsPort : mgoBunkerexPort),
        proxy1,
        proxy2,
        (usePlattsSpot ? "SPGCI" : "BunkerEx"),
        FUEL_TYPES.MGO.name,
        (usePlattsSpot ? mgoPriceData.platts.spotPrice : mgoPriceData.bunkerex.spotPrice.toFixed(2)),
        voyage.mgoBunkerPrice)

    mgoBunkerPrice = mgoSpotData.price
    mgoPricePort = mgoSpotData.port
    const co2Price = DATA_SOURCE.isPlatts(dataSourceSettings ? dataSourceSettings.emissionsPricesSource : _userSettings.emissionsPricesSource) ? emissionData.platts.spotPrice : emissionData.default.spotPrice

    return {
        ...voyage,
        ifoBunkerPrice,
        mgoBunkerPrice,
        ifoPricePort,
        mgoPricePort,
        co2Price, // TODO: EU Allowance Spot price (EUA -> Spot) in emissionsparams JSON
        ifoPriceData,
        mgoPriceData,
        emissionData
    }

}

async function filterPrices(priceInput, dataSource, bunkerPort, proxy1, proxy2, fuelType) {

    let prices = priceInput
    let port = bunkerPort

    if (!prices || prices.length === 0) {
        prices = await getRecoilPromise(bunkerPricesPort({ provider: dataSource, port: proxy1, fuels: fuelType }))
        port = `Proxy: ${proxy1}`
    }

    if (!prices || prices.length === 0) {
        prices = await getRecoilPromise(bunkerPricesPort({ provider: dataSource, port: proxy2, fuels: fuelType }))
        port = `Proxy: ${proxy2}`
    }

    return { prices, port }
}

async function getSpotPrice(bunkerPort, proxy1, proxy2, dataSource, fuelType, livePrice, inputPrice) {
    if (!(isNaN(livePrice))) {
        return { price: Number(livePrice).toFixed(2), port: bunkerPort }
    }
    return await getProxySpotPrice(proxy1, proxy2, dataSource, fuelType, inputPrice)
}


async function getProxySpotPrice(proxy1, proxy2, dataSource, fuelType, inputPrice) {

    const proxy1PriceData = await getRecoilPromise(bunkerPricesPort({ provider: dataSource, port: proxy1, fuels: fuelType }))
    if (proxy1PriceData.length > 0) {
        return { price: proxy1PriceData[0].spotPrice.toFixed(2), port: `Proxy: ${proxy1}` }
    }

    const proxy2PriceData = (await getRecoilPromise(bunkerPricesPort({ provider: dataSource, port: proxy2, fuels: fuelType })) || [])
        .filter(x => (x.port === proxy2) && (x.fuelType === fuelType))
    if (proxy2PriceData.length > 0) {
        return { price: proxy2PriceData[0].spotPrice.toFixed(2), port: `Proxy: ${proxy2}` }
    }

    return { price: inputPrice, port: "N/A" }

}

async function getAllTickerBunkerData(voyage, plattsPrices, bunkerexPrices) {

    const naEntry = {
        spotPrice: "N/A",
        spotSymbol: "N/A",
        futurePrice: "N/A",
        futureSymbol: "N/A",
        futureDate: "N/A",
        spotDescription: "N/A",
        futureDescription: "N/A",
        constructuedSpot: true,
        constructedCurve: true
    }

    let plattsTickerData, bunkerexTickerData

    if (plattsPrices.length === 0) {
        plattsTickerData = naEntry
    } else {
        plattsTickerData = await getTickerBunkerData(voyage, plattsPrices[0], true)
    }

    if (bunkerexPrices.length === 0) {
        bunkerexTickerData = naEntry
    } else if (bunkerexPrices[0].futurePrices === null) {
        bunkerexTickerData = naEntry
    } else {
        bunkerexTickerData = await getTickerBunkerData(voyage, bunkerexPrices[0], false)
    }

    return {
        platts: plattsTickerData,
        bunkerex: bunkerexTickerData
    }
}





async function getTickerBunkerData(voyage, fuelPriceData, isPlattsData) {

    const futureDate = await getFutureStemDateYYMM(voyage)

    // Prices
    const spotPrice = fuelPriceData.spotPrice
    const forwardCurveData = fuelPriceData.forwardCurve.find(x => x.yearMonth === futureDate)
    const futurePrice = forwardCurveData ? forwardCurveData.price : 0

    let spotSymbol, spotPriceDate, futureSymbol, spotDescription, futureDescription
    if (isPlattsData) {
        spotSymbol = fuelPriceData.constructedSpot ? "Derived" : fuelPriceData.providerData.spotSymbol
        spotPriceDate = formatDateWithTime(fuelPriceData.priceDate)
        futureSymbol = forwardCurveData ? fuelPriceData.constructedCurve ? "Derived" : fuelPriceData.providerData.forwardCurveCode : '';
        spotDescription = fuelPriceData.constructedSpot ? fuelPriceData.fuelType : `SPGCI - ${fuelPriceData.providerData.spotDescr} - ${spotPriceDate}`
        futureDescription = forwardCurveData ? fuelPriceData.constructedCurve ? `Forward Price Derived from SPGCI Data` : `SPGCI - ${fuelPriceData.providerData.forwardCurveName}` : ''
    } else {
        spotSymbol = fuelPriceData.constructedSpot ? "Derived" : "BunkerEx"
        spotPriceDate = formatDateWithTime(fuelPriceData.priceDate)
        futureSymbol = fuelPriceData.constructedCurve ? "Derived" : "BunkerEx"
        spotDescription = fuelPriceData.constructedSpot ? fuelPriceData.fuelType : `BunkerEx - ${fuelPriceData.fuelType} - ${fuelPriceData.portName} - ${spotPriceDate}`
        futureDescription = fuelPriceData.constructedCurve ? "Forward Price Derived from BunkerEx Data" : `BunkerEx - ${fuelPriceData.fuelType} - Forward Price`
    }
    const tickerData = {
        spotPrice: Number(spotPrice.toFixed(2)),
        spotSymbol,
        spotPriceDate,
        futurePrice: Number(futurePrice.toFixed(2)),
        futureSymbol,
        futureDate,
        spotDescription,
        futureDescription,
        constructedSpot: fuelPriceData.constructedSpot,
        constructedCurve: fuelPriceData.constructedCurve,
        missingForwardPrice: !!!forwardCurveData,
        provider: isPlattsData ? "SPGCI" : "BunkerEx"
    }

    return tickerData
}

function getTickerEmissionData(voyage, emissionData) {
    const naEntry = {
        spotPrice: 0,
        forwardCurveMonthly: [],
        spotSymbol: "N/A",
        futurePrice: "0",
        futureSymbol: "N/A",
        futureDate: "N/A",
        spotDescription: "N/A",
        futureDescription: "N/A",
        constructedSpot: true,
        constructedCurve: true,
        provider: ""
    }

    if (!emissionData.platts) {
        return {
            default: naEntry,
            platts: null,
        }
    }

    const plattsEmissionData = emissionData.platts[0]

    let tickerData = naEntry
    if (plattsEmissionData) {
        const { nextOpenDate } = getNextOpen(voyage)
        const futureDate = getFutureYYMM(nextOpenDate)
        let forwardCurveDataPoint = null
        if (plattsEmissionData.forwardCurveMonthly.map(x => x.yearMonth).includes(futureDate)) {
            forwardCurveDataPoint = plattsEmissionData.forwardCurveMonthly.filter(x => x.yearMonth === futureDate)[0]
        } else {
            forwardCurveDataPoint = plattsEmissionData.forwardCurveMonthly[0]
        }

        const spotPrice = plattsEmissionData.spotPrice
        const spotSymbol = plattsEmissionData.spotSymbol
        const spotDescription = `SPGCI - EUAEA00 - ${formatDateWithTime(plattsEmissionData.priceDate)}`
        const constructedSpot = false
        const constructedCurve = forwardCurveDataPoint.constructed

        const futurePrice = forwardCurveDataPoint.price
        const futureSymbol = forwardCurveDataPoint.constructed ? "Derived" : "EUAEA00"
        const futureDescription = forwardCurveDataPoint.constructed ? "Extrapolated from EUAEA00 Quarterly Curve" : "SPGCI - EUAEA00 Quarterly Curve"

        tickerData = {
            spotPrice: Number(spotPrice.toFixed(2)),
            spotSymbol,
            futurePrice: Number(futurePrice.toFixed(2)),
            futureSymbol,
            futureDate,
            spotDescription,
            futureDescription,
            constructedSpot,
            constructedCurve,
            provider: "SPGCI"
        }
    }

    return {
        default: naEntry,
        platts: tickerData
    }
}

async function getFutureStemDateYYMM(voyage) {
    return getFutureYYMM(await getStemDate(voyage))
}


export async function getStemDate(voyage) {
    const seaMarginFactor = 1 + (voyage.seaMargin / 100)
    const distanceToBunker = voyage.distanceOpenToBunker ? voyage.distanceOpenToBunker : await safeGetDistance(voyage.openPort, voyage.bunkerPort, voyage.canalConstraints, false)
    const timeOpenToBunker = seaMarginFactor * getJourneyTimeDays(distanceToBunker, voyage.ballastSpeed)
    let stemETA = new Date(voyage.openDate)
    stemETA.setDate(stemETA.getDate() + timeOpenToBunker)
    return stemETA
}

function getFutureYYMM(date) {
    const openDateYYMM = formatDateToYYYYMM(date)
    const today = new Date()
    const todayYYMM = formatDateToYYYYMM(today)
    const oneMonthForwardDate = addMonths(today, 1)
    const oneMonthForwardDateYYMM = formatDateToYYYYMM(oneMonthForwardDate)

    // check if futureDate is still today
    const futureDateYYMM = openDateYYMM === todayYYMM ? oneMonthForwardDateYYMM : openDateYYMM
    return futureDateYYMM
}

async function getBunkerPortNearest(port, canalConstraints, shipType) {
    const [distancesFromPort, _portListByShipType] = await Promise.all([
        getPortDistnacesFrom(port, canalConstraints, false, false),
        getRecoilPromise(getPortListByShipType)
    ])

    const allBunkerPorts = getBunkerPortsForShipType(_portListByShipType, shipType)

    // To be edited to main bunker port for region
    const nearestBunkerPort = allBunkerPorts.reduce((prev, curr) => {
        return distancesFromPort[prev] <= distancesFromPort[curr] ? prev : curr
    }, allBunkerPorts[0])

    return nearestBunkerPort
}

const setDefaultCanalChoice = (voyage, routeShortName) => {
    const _routeParamsIn = getRecoil(getRouteParamsUnpriced)
    const routeParam = Object.values(_routeParamsIn).find(x => x.shortName === routeShortName)

    voyage.canalConstraints = routeParam.canalChoice
}

/**
 * Setting a route on a voyage determines which regions are involved, and which ports are involved
 */
const setRouteOnVoyage = async (voyage, routeShortName, dataSourceSettings) => {
    const _routeParamsIn = getRecoil(getRouteParamsUnpriced)
    const routeId = Object.keys(_routeParamsIn).find(x => _routeParamsIn[x].shortName === routeShortName && _routeParamsIn[x].shiptype === voyage.vessel.shipType)
    const routeValues = _routeParamsIn[routeId]
    const canalConstraints = voyage.canalConstraints

    let openPort, loadingPort, dischargePort
    let routePrices = {
        spotPrice: routeValues.spotPriceFeb,
        forwardCurve: Array(20).fill({ price: routeValues.spotPriceFeb })
    }

    if (customRoutesRegions.includes(routeId)) {
        openPort = "Singapore"
        loadingPort = "Fujairah"
        dischargePort = "China"

    } else if (customRoutes.includes(routeId)) {
        openPort = "Singapore"
        loadingPort = "Fujairah"
        dischargePort = "Singapore"
    } else {
        routePrices = await getRecoilPromise(getRoutePrices(routeId))
        openPort = routeValues.port2
        loadingPort = routeValues.port1
        dischargePort = routeValues.port2
    }

    const isPlattsRoute = routeValues.routetype === "Platts" ? true : false
    let spotPrice, futurePrice, description
    let freightSymbol = routeId

    const today = new Date()
    const openDateYYMM = formatDateToYYYYMM(voyage.openDate)
    const todayYYMM = formatDateToYYYYMM(today)
    const oneMonthForwardDate = addMonths(today, 1)
    const oneMonthForwardDateYYMM = formatDateToYYYYMM(oneMonthForwardDate)
    const futureDateYYMM = openDateYYMM === todayYYMM ? oneMonthForwardDateYYMM : openDateYYMM

    const hasWSPrice = routeValues.priceunits === "WS" ? true : false

    if (hasWSPrice && voyage.freightType === FreightTypes.LUMP_SUM) {
        voyage.freightType = FreightTypes.DOLLAR_PER_MT
    } else if (!hasWSPrice && voyage.freightType === FreightTypes.WORLDSCALE) {
        voyage.freightType = FreightTypes.DOLLAR_PER_MT
    }

    const monthsToOpen = monthsBetweenDates(voyage.openDate, today)

    if (routePrices != null) {
        switch (voyage.freightType) {
            case FreightTypes.DOLLAR_PER_MT:
                spotPrice = routePrices.spotPrice
                futurePrice = routePrices.forwardCurve[monthsToOpen].price
                break
            case FreightTypes.WORLDSCALE:
                spotPrice = routePrices.spotPrice / routeValues.flatrate * 100
                futurePrice = routePrices.forwardCurve[monthsToOpen].price / routeValues.flatrate * 100
                break
            case FreightTypes.LUMP_SUM:
                spotPrice = routePrices.spotPrice
                futurePrice = routePrices.forwardCurve[monthsToOpen].price
                break
            default:
                throw new Error("Freight Type [" + voyage.freightType + "]not recognised")
        }

        if (isPlattsRoute && routeValues.symbolMT.length > 0 && voyage.freightType === FreightTypes.DOLLAR_PER_MT) {
            freightSymbol = routeValues.symbolMT
        } else if (isPlattsRoute && routeValues.symbolWSLS.length > 0) {
            freightSymbol = routeValues.symbolWSLS
        }

        description = `${isPlattsRoute ? "SPGCI" : "Baltic Exchange"}: ${routeValues.description}`
    } else {
        spotPrice = null
        futurePrice = null
        description = "N/A"
    }

    const [defaultDistance, bunkerPort] = await Promise.all([
        safeGetDistance(loadingPort, dischargePort, canalConstraints, true),
        getBunkerPortNearest(openPort, canalConstraints, voyage.vessel.shipType)
    ])

    const freightPriceData = {
        spotPrice: spotPrice ? Number(spotPrice.toFixed(2)) : 'N/A',
        spotSymbol: freightSymbol,
        futurePrice: futurePrice ? Number(futurePrice.toFixed(2)) : 'N/A',
        futureSymbol: futurePrice ? "Derived" : 'N/A',
        futureDate: futurePrice ? futureDateYYMM : 'N/A',
        spotDescription: description,
        futureDescription: futurePrice ? "Derived Forward Price" : 'N/A',
        constructedSpot: false,
        constructedCurve: futurePrice ? true : false,
        provider: spotPrice ? isPlattsRoute ? "SPGCI" : "BALTIC" : "N/A",
        missingForwardPrice: futurePrice == null
    }
    // Set up defaults for a new voyage
    const newVoyage = {
        ...voyage,
        routeId: routeId,
        routeShortName: routeValues.shortName,
        routeLongName: routeValues.longName,
        cargo: routeValues.cargo,
        openPort,
        bunkerPort,
        loadingPort,
        loadingPort2: "",
        dischargePort,
        dischargePort2: "",
        differentials: routeValues.otherrevenues,
        portCosts: routeValues.loadPortCharges,
        portCosts2: routeValues.dischargePortCharges,
        canalConstraints,
        freightRate: spotPrice,
        cargoQuantity: routeValues.ladenCargo,
        cargoMinQuantity: 0.95 * routeValues.ladenCargo,
        cargoMaxQuantity: 1.05 * routeValues.ladenCargo,
        defaultDistance,
        freightPriceData,
        flatRate: routeValues.flatrate,
    }

    return updateBunkerSpotPrices(
        await setPortDistancesAndECAsOnVoyage(await updateCanalCostsAndDays(newVoyage)),
        dataSourceSettings
    )
}

function updatePortDays(voyage) {

    let loadPortDays = voyage.loadDays + voyage.waitingDays + bunkerDaysAssumption
    loadPortDays = voyage.hasOwnProperty("loadingPort2") ? (voyage.loadingPort2 === "" ? loadPortDays : loadPortDays * 2) : loadPortDays

    let dischargePortDays = voyage.dischargeDays
    dischargePortDays = voyage.hasOwnProperty("dischargePort2") ? (voyage.dischargePort2 === "" ? dischargePortDays : dischargePortDays * 2) : dischargePortDays
    voyage["portDays"] = loadPortDays + dischargePortDays

    return voyage

}

async function updateCanalCostsAndDays(voyage) {
    const { openPort, bunkerPort, loadingPort, loadingPort2, dischargePort, dischargePort2, canalConstraints, cargoQuantity, canalDays } = voyage

    let canalCostsOpenToBunker = 0
    let canalCostsBunkerToLoad = 0
    let canalCostsLoadToDischarge = 0
    let canalCostsLoadToLoad2 = 0
    let canalCostsLoad2ToDischarge = 0
    let canalCostsDischargeToDischarge2 = 0

    let canalDaysOpenToBunker = 0
    let canalDaysBunkerToLoad = 0
    let canalDaysLoadToDischarge = 0
    let canalDaysLoadToLoad2 = 0
    let canalDaysLoad2ToDischarge = 0
    let canalDaysDischargeToDischarge2 = 0


    if (loadingPort2 === "" && dischargePort2 === "") {
        [[canalCostsOpenToBunker, canalDaysOpenToBunker], [canalCostsBunkerToLoad, canalDaysBunkerToLoad], [canalCostsLoadToDischarge, canalDaysLoadToDischarge]] = await Promise.all([
            getCanalCosts(openPort, bunkerPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(bunkerPort, loadingPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(loadingPort, dischargePort, canalConstraints, cargoQuantity, true, canalDays)
        ])
    }
    else if (loadingPort2 !== "" && dischargePort2 === "") {
        [[canalCostsOpenToBunker, canalDaysOpenToBunker], [canalCostsBunkerToLoad, canalDaysBunkerToLoad], [canalCostsLoadToLoad2, canalDaysLoadToLoad2], [canalCostsLoad2ToDischarge, canalDaysLoad2ToDischarge]] = await Promise.all([
            getCanalCosts(openPort, bunkerPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(bunkerPort, loadingPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(loadingPort, loadingPort2, canalConstraints, cargoQuantity, true, canalDays),
            getCanalCosts(loadingPort2, dischargePort, canalConstraints, cargoQuantity, true, canalDays)
        ])
    }
    else if (loadingPort2 === "" && dischargePort2 !== "") {
        [[canalCostsOpenToBunker, canalDaysOpenToBunker], [canalCostsBunkerToLoad, canalDaysBunkerToLoad], [canalCostsLoadToDischarge, canalDaysLoadToDischarge], [canalCostsDischargeToDischarge2, canalDaysDischargeToDischarge2]] = await Promise.all([
            getCanalCosts(openPort, bunkerPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(bunkerPort, loadingPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(loadingPort, dischargePort, canalConstraints, cargoQuantity, true, canalDays),
            getCanalCosts(dischargePort, dischargePort2, canalConstraints, cargoQuantity, true, canalDays)
        ])
    }
    else {
        [[canalCostsOpenToBunker, canalDaysOpenToBunker], [canalCostsBunkerToLoad, canalDaysBunkerToLoad], [canalCostsLoadToLoad2, canalDaysLoadToLoad2], [canalCostsLoad2ToDischarge, canalDaysLoad2ToDischarge], [canalCostsDischargeToDischarge2, canalDaysDischargeToDischarge2]] = await Promise.all([
            getCanalCosts(openPort, bunkerPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(bunkerPort, loadingPort, canalConstraints, cargoQuantity, false, canalDays),
            getCanalCosts(loadingPort, loadingPort2, canalConstraints, cargoQuantity, true, canalDays),
            getCanalCosts(loadingPort2, dischargePort, canalConstraints, cargoQuantity, true, canalDays),
            getCanalCosts(dischargePort, dischargePort2, canalConstraints, cargoQuantity, true, canalDays)
        ])
    }

    return {
        ...voyage,
        canalCostsBallast: canalCostsOpenToBunker + canalCostsBunkerToLoad,
        canalCostsLaden: canalCostsLoadToLoad2 + canalCostsLoadToDischarge + canalCostsLoad2ToDischarge + canalCostsDischargeToDischarge2,
        canalCostsAll: canalCostsOpenToBunker + canalCostsBunkerToLoad + canalCostsLoadToDischarge + canalCostsLoadToLoad2 + canalCostsLoad2ToDischarge + canalCostsDischargeToDischarge2,
        canalDays: canalDaysOpenToBunker + canalDaysBunkerToLoad + canalDaysLoadToDischarge + canalDaysLoadToLoad2 + canalDaysLoad2ToDischarge + canalDaysDischargeToDischarge2,
        canalDaysOpenToLoad: canalDaysOpenToBunker + canalDaysBunkerToLoad
    }


}

export async function getCanalCosts(portFrom, portTo, canalConstraints, cargoQuantity, laden, canalDaysInput) {
    const canalPassage = await safeGetCanalPassage(portFrom, portTo, canalConstraints, laden)

    let canalCost, canalDays

    switch (canalPassage) {
        case CANAL_PASSAGES.NEITHER:
            canalCost = 0
            canalDays = 0   // waiting days
            break
        case CANAL_PASSAGES.SUEZ:
            canalCost = suesCost()
            canalDays = canalDaysInput   // waiting days
            break
        case CANAL_PASSAGES.PANAMA:
            canalCost = panamaCost()
            canalDays = canalDaysInput   // waiting days
            break
        case CANAL_PASSAGES.BOTH:
            canalCost = suesCost() + panamaCost()
            canalDays = canalDaysInput * 2   // waiting days
            break
        default:
            throw new Error("Unknown canal passage " + canalPassage)
    }

    const portList = getRecoil(getPortListByShipType)
    const blackSeaPort = Object.values(portList).find(({ portName, region }) => (portName === portFrom || portName === portTo) && region === "Black Sea")
    if (blackSeaPort) {
        canalCost += 30_000 // cancel cost for Bosporus
        canalDays += 1
    }

    return [canalCost, canalDays]   // NOTE: canalDays are waiting days here

    function suesCost() {
        return 175_000 + 1.5 * cargoQuantity
    }

    function panamaCost() {
        return 75_000 + cargoQuantity
    }
}

async function safeGetCanalPassage(portFrom, portTo, canalConstraints, laden) {
    if (canalConstraints === CANAL_CONSTRAINTS.EXCLUDE
        || portFrom === portTo
        || (!laden && canalConstraints === CANAL_CONSTRAINTS.LADEON_ONLY)
        || (!laden && canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY)
        || portFrom === ""
        || portTo === "") {
        return CANAL_PASSAGES.NEITHER
    }

    if (canalConstraints === CANAL_CONSTRAINTS.ALLOW) {

        const canalPassages = await getRecoilPromise(canalPassagesFrom(portFrom))
        if (portTo in canalPassages) {
            return canalPassages[portTo]
        } else if (FALLBACK_PORT in canalPassages) {
            console.error(`Port not found ${portTo} using ${FALLBACK_PORT} instead.  Results will be crazy.`)
            return canalPassages[FALLBACK_PORT]
        }
        return CANAL_PASSAGES.NEITHER

    } else if (canalConstraints === CANAL_CONSTRAINTS.ALLOW_SUEZ) {

        const canalPassagesVLCC = await getRecoilPromise(canalPassagesFromVLCC(portFrom))

        if (portTo in canalPassagesVLCC) {
            return canalPassagesVLCC[portTo]
        } else if (FALLBACK_PORT in canalPassagesVLCC) {
            console.error(`Port not found ${portTo} using ${FALLBACK_PORT} instead.  Results will be crazy.`)
            return canalPassagesVLCC[FALLBACK_PORT]
        }

        throw new Error(`Port Canal Passage for [${portTo}] not found and Canal Passage for [${FALLBACK_PORT}] not found either.`)
    } else if (canalConstraints === CANAL_CONSTRAINTS.LADEON_ONLY && laden) {

        const canalPassages = await getRecoilPromise(canalPassagesFrom(portFrom))

        if (portTo in canalPassages) {
            return canalPassages[portTo]
        } else if (FALLBACK_PORT in canalPassages) {
            console.error(`Port not found ${portTo} using ${FALLBACK_PORT} instead.  Results will be crazy.`)
            return canalPassages[FALLBACK_PORT]
        }
        return CANAL_PASSAGES.NEITHER

    } else if (canalConstraints === CANAL_CONSTRAINTS.SUEZ_LADEN_ONLY && laden) {

        const canalPassagesVLCC = await getRecoilPromise(canalPassagesFromVLCC(portFrom))

        if (portTo in canalPassagesVLCC) {
            return canalPassagesVLCC[portTo]
        } else if (FALLBACK_PORT in canalPassagesVLCC) {
            console.error(`Port not found ${portTo} using ${FALLBACK_PORT} instead.  Results will be crazy.`)
            return canalPassagesVLCC[FALLBACK_PORT]
        }
        return CANAL_PASSAGES.NEITHER
    }

}

/**
 * Set the vessel on a voyage
 */
export const setVesselOnVoyage = (voyage, vessel) => {

    const laden = voyage.ladenBallast === "Laden"

    // TODO - speed from voyage
    const fuelConsumption = getVesselFuelConsumption(vessel, laden ? "laden" : "ballast", vessel[(laden ? "ladenSpeed" : "ballastSpeed")])

    if (fuelConsumption != null) {
        const modelDaysLaden = 0.49 * voyage.daysOutToSea
        const modelDaysBallast = 0.51 * voyage.aysOutToSea
        const newDaysLaden = modelDaysLaden * 13 / vessel.ladenSpeed
        const newDaysBallast = modelDaysBallast * 12.5 / vessel.ballastSpeed
        const newConsumption = fuelConsumption.fuelCons * (laden ? newDaysLaden : newDaysBallast)
        const routeParamsIn = getRecoil(getRouteParamsUnpriced)[voyage.routeId]
        const modelConsumption = routeParamsIn[(laden ? "ladenConsumption" : "ballastConsumption")] * (laden ? modelDaysLaden : modelDaysBallast)

        const modelFuelTotal = (routeParamsIn.fuelconsFO + routeParamsIn.fuelconsGO) * (laden ? 0.55 : 0.45)
    }


    return updateCanalCostsAndDays({
        ...voyage,
        vessel: vessel,
        cargo: "Crude",
        cargoQuantity: Math.round(vessel.ladenCargo),
        cargoMinQuantity: Math.round(vessel.ladenCargo * 0.95),
        cargoMaxQuantity: Math.round(vessel.ladenCargo * 1.05),
        ballastSpeed: vessel.ballastSpeed,
        ladenSpeed: vessel.ladenSpeed,
        dischargeDays: vessel.dischargingDays,
        loadDays: vessel.loadingDays,
        waitingDays: vessel.waitingDays,
        laycanStart: incrementDate(voyage.openDate, 5),
        laycanEnd: incrementDate(voyage.openDate, 15)
    })
}

/**
 * Calculate all the output fields from a voyage, vessel and input fields
 */
export async function calculateOutputFieldsFromVoyage(voyageInput) {

    const {
        nextOpenDate,
        loadETA
    } = getNextOpen(voyageInput)

    const voyage = {
        ...voyageInput,
        dischargeDate: formatDateToYYYYMMDD(nextOpenDate),
        dwt: voyageInput.vessel.dwt
    }

    const servicesApiCalcs = await getvoyageCalcs(voyage)
    const routeParamsIn = getRecoil(getRouteParamsUnpriced)[voyage.routeID]

    let totalFreightRate
    if (voyage.freightType === "$/mt") {
        totalFreightRate = voyage.freightRate * voyage.cargoQuantity
    } else if (voyage.freightType === "Worldscale") {
        totalFreightRate = voyage.freightRate * routeParamsIn.flatrate * voyage.cargoQuantity / 100
    } else {
        totalFreightRate = voyage.freightRate // Lump sum
    }

    return {
        loadETA: loadETA,
        nextOpenDate: nextOpenDate,
        duration: servicesApiCalcs.voyageLength,
        minrobIFO: Math.ceil(0),
        minrobMGO: Math.ceil(0),
        bunkersNeededIFO: servicesApiCalcs.fuels.LSFO.requirement,
        bunkersNeededMGO: servicesApiCalcs.fuels.MGO.requirement,
        portExpenses: servicesApiCalcs.portAndOtherCosts,
        bunkerExpenses: servicesApiCalcs.bunkerCost,
        emissionsExpenses: servicesApiCalcs.emissionsCost,
        totalRevenues: servicesApiCalcs.totalRevenues,
        totalExpenses: servicesApiCalcs.totalCost,
        tce: servicesApiCalcs.tce,
        profitLossPerDay: servicesApiCalcs.pnlPerDay,
        profitLoss: servicesApiCalcs.pnlTotal,
        cii: "B",
        deltaTCE: 0,
        deltaTCEBunker: 0,
        deltaTCEOps: 0,
        co2Emissions: servicesApiCalcs.carbonConsumption,
        bunkerLessRob: servicesApiCalcs.bunkerCost - (voyage.robIFO * voyage.ifoBunkerPrice + voyage.robMGO * voyage.mgoBunkerPrice)
    }
}

export function getNextOpen(voyage) {
    const seaMarginFactor = 1 + (voyage.seaMargin / 100)

    // Get the durations for each journey leg, as well as waiting days (=portDays minus loadDays minus dischargeDays)
    // (these exclude any canal days)
    const timeOpenToBunker = seaMarginFactor * getJourneyTimeDays(voyage.distanceOpenToBunker, voyage.ballastSpeed)
    const timeBunkerToLoad = seaMarginFactor * getJourneyTimeDays(voyage.distanceBunkerToLoad, voyage.ballastSpeed)
    const timeLoadToDischarge = seaMarginFactor * getJourneyTimeDays(voyage.distanceLoadToDischarge, voyage.ladenSpeed)
    const timeLoadToLoad2 = seaMarginFactor * getJourneyTimeDays(voyage.distanceLoadToLoad2, voyage.ladenSpeed)
    const timeLoad2ToDischarge = seaMarginFactor * getJourneyTimeDays(voyage.distanceLoad2ToDischarge, voyage.ladenSpeed)
    const timeDischargeToDischarge2 = seaMarginFactor * getJourneyTimeDays(voyage.distanceDischargeToDischarge2, voyage.ladenSpeed)
    // Canal waiting days
    const timeCanalWaitOpenToLoad = voyage.canalDaysOpenToLoad
    const timeCanalWaitLoadToDischarge2 = voyage.canalDays - timeCanalWaitOpenToLoad
    const timeCanalWaitTotal = voyage.canalDays

    // Load, discharge and waiting days 
    const timeLoadPort1 = voyage.loadDays
    const timeLoadPort2 = voyage.loadingPort2 === "" ? 0 : voyage.loadDays
    const timeDischargePort1 = voyage.dischargeDays
    const timeDischargePort2 = voyage.dischargePort2 === "" ? 0 : voyage.dischargeDays
    let timeWaiting = voyage.waitingDays
    let timeWaitingLoadPort1 = voyage.waitingDays

    // total port days (includes all canal and port waiting days)
    let timePortDays = voyage.portDays + voyage.canalDays

    // total sea days (only fast moving days, ie excludes any waiting days)
    const timeSeaDays = timeOpenToBunker + timeBunkerToLoad + timeLoadToDischarge + timeLoadToLoad2 + timeLoad2ToDischarge + timeDischargeToDischarge2
    let timeTotalDays = timePortDays + timeSeaDays

    const timeWaitingLoadPort2 = voyage.loadingPort2 === "" ? 0 : voyage.waitingDays

    // Calculate the date at which vessel can load
    let loadETA = incrementDate(voyage.openDate, timeOpenToBunker + timeBunkerToLoad + timeCanalWaitOpenToLoad)
    let nextOpenDate = incrementDate(loadETA, timeWaitingLoadPort1 + timeLoadPort1 + timeLoadToLoad2 + timeWaitingLoadPort2 + timeLoadPort2 + timeLoadToDischarge + timeLoad2ToDischarge + timeDischargeToDischarge2 + timeDischargePort1 + timeDischargePort2 + timeCanalWaitLoadToDischarge2)

    // If load ETA date before laycan start date, increase wait time before load event, and update dates:
    const laycanStart = voyage.laycanStart
    const dtLoadStart_DMY = loadETA
    const dtLaycanStart_DMY = voyage.laycanStart

    // Calculate the days difference
    // (negative number of days if laycanStart is before loadETA)
    const diff = daysBetweenDates(dtLaycanStart_DMY, dtLoadStart_DMY)

    const daysAhead = diff.days - timeWaitingLoadPort1
    if (daysAhead > 0) {
        timeWaitingLoadPort1 += daysAhead
        timeWaiting += daysAhead
        timePortDays = timeCanalWaitTotal + timeWaiting + timeLoadPort1 + timeLoadPort2 + timeDischargePort1 + timeDischargePort2
        timeTotalDays = timePortDays + timeSeaDays
        nextOpenDate = incrementDate(loadETA, timeWaitingLoadPort1 + timeLoadPort1 + timeLoadToLoad2 + timeWaitingLoadPort2 + timeLoadPort2 + timeLoadToDischarge + timeLoad2ToDischarge + timeDischargeToDischarge2 + timeDischargePort1 + timeDischargePort2 + timeCanalWaitLoadToDischarge2)
    }

    return {
        nextOpenDate,
        loadETA,
        timeSeaDays,
        timeLoadPort1,
        timeWaitingLoadPort1,
        timeLoadPort2,
        timeWaitingLoadPort2,
        timeDischargePort1,
        timeDischargePort2,
        timeOpenToBunker,
        timeBunkerToLoad,
        timeLoadToDischarge,
        timeLoadToLoad2,
        timeLoad2ToDischarge,
        timeDischargeToDischarge2,
        timeTotalDays
    }

}

export function getBunkerPortsForShipType(portList, shipType) {

    const shipTypeSearch = shipType === SHIP_TYPES.AFRAMAX_LR2 ? "Aframax" : (shipType === SHIP_TYPES.PANAMAX_LR1 ? "Panamax" : shipType)
    const portsNames = Object.keys(portList)

    let allBunkerPorts
    switch (shipTypeSearch) {
        case "VLCC":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        case "Suezmax":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        case "Aframax":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        case "Panamax":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        case "MR":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        case "Handy":
            allBunkerPorts = portsNames.filter(port => (portList[port].largestShipType === "Handy"
                || portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
        default:
            allBunkerPorts = portsNames.filter(port => (portList[port].VLCC === 1
                || portList[port].largestShipType === "VLCC")
                && portList[port].bunkeringPort === 1)
            break
    }
    return allBunkerPorts
}
export function getOpenPortsForShipType(portList, shipType, route) {
    const shipTypeSearch = shipType === SHIP_TYPES.AFRAMAX_LR2 ? "Aframax" : (shipType === SHIP_TYPES.PANAMAX_LR1 ? "Panamax" : shipType)
    const portsNames = Object.keys(portList)

    if (customRoutesRegions.includes(route)) {
        return [...new Set(portsNames.map(port => portList[port].region))]
    }

    let allOpenPorts
    switch (shipTypeSearch) {
        case "VLCC":
            allOpenPorts = portsNames.filter(port => portList[port].VLCC === 1
                || portList[port].largestShipType === "VLCC")
            break
        case "Suezmax":
            allOpenPorts = portsNames.filter(port => portList[port].Suezmax === 1
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
            break
        case "Aframax":
            allOpenPorts = portsNames.filter(port => portList[port].Aframax === 1
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
            break
        case "Panamax":
            allOpenPorts = portsNames.filter(port => portList[port].Panamax === 1
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
            break
        case "MR":
            allOpenPorts = portsNames.filter(port => portList[port].MR === 1
                || portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
            break
        case "Handy":
            allOpenPorts = portsNames.filter(port => portList[port].Handy === 1
                || portList[port].largestShipType === "Handy"
                || portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
            break
        default:
            allOpenPorts = portsNames.filter(port => portList[port].VLCC === 1
                || portList[port].largestShipType === "VLCC")
            break
    }
    return allOpenPorts
}

export function getPortsForShipAndRegion(portList, shipType, region) {

    const shipTypeSearch = shipType === SHIP_TYPES.AFRAMAX_LR2 ? "Aframax" : (shipType === SHIP_TYPES.PANAMAX_LR1 ? "Panamax" : shipType)
    const portsNames = Object.keys(portList)

    let allRegionPorts
    switch (shipTypeSearch) {
        case "VLCC":
            allRegionPorts = portsNames.filter(port => (portList[port].VLCC === 1
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        case "Suezmax":
            allRegionPorts = portsNames.filter(port => (portList[port].Suezmax === 1
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        case "Aframax":
            allRegionPorts = portsNames.filter(port => (portList[port].Aframax === 1
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        case "Panamax":
            allRegionPorts = portsNames.filter(port => (portList[port].Panamax === 1
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        case "MR":
            allRegionPorts = portsNames.filter(port => (portList[port].MR === 1
                || portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        case "Handy":
            allRegionPorts = portsNames.filter(port => (portList[port].Handy === 1
                || portList[port].largestShipType === "Handy"
                || portList[port].largestShipType === "MR"
                || portList[port].largestShipType === "Panamax"
                || portList[port].largestShipType === "Aframax"
                || portList[port].largestShipType === "Suezmax"
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
        default:
            allRegionPorts = portsNames.filter(port => (portList[port].VLCC === 1
                || portList[port].largestShipType === "VLCC")
                && portList[port].region === region)
            break
    }
    return allRegionPorts
}



export async function getvoyageCalcs(voyage, appendVoyages = true) {

    const _voyages = await getRecoil(voyages)
    const shipName = voyage.vessel.shipName
    const formattedVoyages = []
    for (const voy of _voyages) {
        if (voy.vessel.shipName === shipName) {
            const formattedVoyage = formatVoyageRecosting(voy)
            formattedVoyages.push(formattedVoyage)
        }
    }

    const formattedVoyageForCosting = formatVoyage(voyage)
    const req = {
        ship_name: convertToSentenceCase(shipName),
        ship_type: voyage.vessel.shipType,
        ship_id: voyage.vessel.id,
        voyage: formattedVoyageForCosting,
        voyages: appendVoyages ? formattedVoyages : []
    }

    const jsonRequest = JSON.stringify(req)
    const axiosConfig = {
        method: "POST",
        url: `${servicesApiUrl}/v1/voyage_engine/voyage_costing`,
        data: jsonRequest,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${keycloak.token}`
        }
    }

    return axios(axiosConfig)
        .then(response => {
            return response.data.costing_output
        })
        .catch(error => {

        })
}

export async function recostVoyages(shipInput, shipType, shipId, voyagesInput) {

    let shipVoyages, unrelatedVoyages

    if (!voyagesInput) {
        const _voyages = await getRecoil(voyages)
        shipVoyages = _voyages.filter(x => x.vessel.shipName.toLowerCase() === shipInput.toLowerCase())
        unrelatedVoyages = _voyages.filter(x => x.vessel.shipName.toLowerCase() !== shipInput.toLowerCase())
    } else {
        shipVoyages = voyagesInput.filter(x => x.vessel.shipName.toLowerCase() === shipInput.toLowerCase())
        unrelatedVoyages = voyagesInput.filter(x => x.vessel.shipName.toLowerCase() !== shipInput.toLowerCase())
    }

    if (shipVoyages.length === 0) {
        return []
    }

    const shipName = convertToSentenceCase(shipInput)
    const voyagesForRequest = shipVoyages.map(formatVoyageRecosting)

    const jsonRequest = JSON.stringify({
        ship_name: shipName,
        ship_type: shipType,
        ship_id: shipId,
        voyages: voyagesForRequest
    })

    const axiosConfig = {
        method: "POST",
        url: `${servicesApiUrl}/v1/voyage_engine/recost_voyages`,
        data: jsonRequest,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${keycloak.token}`
        }
    }
    try {
        const response = await axios(axiosConfig)
        const recostedVoyages = response.data
        const updatedVoyages = await Promise.all(
            recostedVoyages.map(async voy => {
                const originalVoyage = shipVoyages.find(x => x.id === voy.id)
                const updatedVoyage = updateServicesApiCalcs(originalVoyage, voy, false)
                return updatedVoyage
            })
        )
        return updatedVoyages
    } catch (error) {
        console.error(error)
    }
}
