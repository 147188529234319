import { useField } from "formik";
import { FieldContainer } from "./FieldContainer";

export function SliderField({ name, label, min, max, step = 1, suffix = "", className }) {
    const [field] = useField(name);

    return <FieldContainer id={name} name={name} label={label} className={className}>
        <div className="flex flex-1 items-center">
            <input type="range" id={name} min={min} max={max} step={step}
                className="flex-1 h-[2px] bg-ao-blue rounded-lg appearance-none cursor-pointer accent-ao-blue"
                {...field}
                title={field.value}
            />
            <span className="text-base text-sm ml-2">{field.value + suffix}</span>
        </div>
    </FieldContainer>
}