import { atom, selector, useRecoilState } from "recoil"
import { replaceItemAtIndex } from "../utils/Helpers"
import { CreateVoyage, DeleteVoyage, FetchVoyages, SaveVoyage } from "./api/aftapi"

/**
 * The voyages for voyage engine
 */
export const voyages = atom({
    key: "voyages",
    default: selector({
        key: "voyagesLoader",
        get: loadVoyages
    })
})

export function useVoyagesMutations() {
    const [_voyages, setVoyages] = useRecoilState(voyages)

    const saveVoyage = async (voyage) => {
        const existingIndex = _voyages.findIndex((l) => l.id === voyage.id)
        const response = await (existingIndex >= 0 ? SaveVoyage(voyage) : CreateVoyage(voyage))

        if (response.success) {
            const savedVoyage = response.data

            setVoyages(existingIndex > -1
                ? replaceItemAtIndex(_voyages, existingIndex, savedVoyage)
                : [..._voyages, savedVoyage])

            return savedVoyage
        } else {
            throw new Error("Couldn't save voyage")
        }
    }

    const deleteVoyage = async (id) => {
        const response = await DeleteVoyage(id)

        if (response.success) {
            setVoyages(_voyages.filter((l) => l.id !== id))
        }
    }

    const resetVoyages = async () => {
        setVoyages(await loadVoyages())
    }

    return { saveVoyage, deleteVoyage, resetVoyages }
}

async function loadVoyages() {
    const voyages = await FetchVoyages()

    if (voyages.success) {
        return voyages.data
    }

    return []
}