import { useState, useEffect } from "react"
import { separatorFormat, dollarFormat } from "../../../utils/Helpers"

export default function OptimizationsBunkerTable({ required, purchased, prices, scrubber }){
    
    const [rowData, setRowData] = useState([])

    const fuels = [scrubber ? "HSFO" : "LSFO", "MGO", "LNG", "MeOH", "NH3", "H2", "Bio", "KwH"]

    useEffect(() => {
        (function () {
            const record = []
            for (let i = 0; i < 8; i++){

                if (required[i] !== 0 || purchased[i] !== 0){
                    const temp = {
                        grade: fuels[i],
                        required: required[i],
                        purchased: purchased[i],
                        price: prices[i]
                    }
                    record.push(temp)
                }
            }
            setRowData(record)

        })()
        }, [required, purchased, prices])

    return (
        <div class="relative overflow-x-auto mt-10">
            <table id="BunkerTable" class="w-3/4 text-sm text-center text-ao-figma-text-gray border-collapse">
                <thead class="text-center text-ao-figma-text-gray border-b">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-m border-r w-48">
                            Grade
                        </th>
                        <th scope="col" class="px-6 py-3 text-m border-r">
                            Req.
                        </th>
                        <th scope="col" class="px-6 py-3 text-m border-r">
                            Pur.
                        </th>
                        <th scope="col" class="px-6 py-3 text-m border-l">
                            $/T
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rowData.map((item, i) => (
                        <tr className= {`${i === rowData.length - 1 ? "" : "border-b"}`}>
                            <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap border-r">
                                {item.grade}
                            </th>
                            <td class="px-6 py-4 border-r">
                                {separatorFormat(item.required)}
                            </td>
                            <td class="px-6 py-4 border-r">
                                {separatorFormat(item.purchased)}
                            </td>
                            <td class="px-6 py-4 border-l">
                                {dollarFormat(item.price, 0)}
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
    )
}
