import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AoForm } from "../../components/form/AoForm";
import { CheckBoxField } from "../../components/form/fields/CheckboxField";
import { EnumSliderField } from "../../components/form/fields/EnumSliderField";
import {
  FIELD_CONTAINER_HEIGHTS,
  FieldContainer,
} from "../../components/form/fields/FieldContainer";
import { SelectField } from "../../components/form/fields/SelectField";
import { SliderField } from "../../components/form/fields/SliderField";
import { userSettings } from "../../store/ui";
import {
  DATA_SOURCE,
  DATA_SOURECE_CATEGORY,
  FUEL_TYPES,
  LIQUIDITY,
  VOLATILITY,
} from "../../utils/Constants";
import { spgciStatus } from "../../store/integrations";

export function SettingsForm() {
  const [_userSettings, setUserSettings] = useRecoilState(userSettings);
  const _spgciStatus = useRecoilValue(spgciStatus);

  return (
    <AoForm
      className="max-w-4xl"
      initialValues={_userSettings}
      onSubmit={(values, actions) => {
        setUserSettings({ ..._userSettings, ...values });
        actions.setSubmitting(false);
      }}
    >
      {({ Column, itemClassName, form }) => {
        return (
          <>
            <Column label="Default Data Sources">
              <SelectField
                name="frightRatesSource"
                label="Freight Rates"
                options={DATA_SOURCE.valuesAsSelectOptions(
                  DATA_SOURECE_CATEGORY.FREIGHT_RATES
                )}
                className={itemClassName}
              ></SelectField>
              <SelectField
                name="bunkerPricesSource"
                label="Bunker Prices"
                options={DATA_SOURCE.valuesAsSelectOptions(
                  DATA_SOURECE_CATEGORY.BUNKER_PRICES
                )}
                className={itemClassName}
              ></SelectField>
              <FieldContainer
                id="newFielsCheckboxes"
                label="New fuels"
                className={itemClassName}
                height={3}
              >
                <CheckBoxField name="newFuels" value={FUEL_TYPES.LNG.name}>
                  {FUEL_TYPES.LNG.caption}
                </CheckBoxField>
                <CheckBoxField name="newFuels" value={FUEL_TYPES.MEOH.name}>
                  {FUEL_TYPES.MEOH.caption}
                </CheckBoxField>
                <CheckBoxField name="newFuels" value={FUEL_TYPES.BIO.name}>
                  {FUEL_TYPES.BIO.caption}
                </CheckBoxField>
                <CheckBoxField name="newFuels" value={FUEL_TYPES.NH3.name}>
                  {FUEL_TYPES.NH3.caption}
                </CheckBoxField>
                <CheckBoxField name="newFuels" value={FUEL_TYPES.H2.name}>
                  {FUEL_TYPES.H2.caption}
                </CheckBoxField>
              </FieldContainer>
              <SelectField
                name="emissionsPricesSource"
                label="Emission Prices"
                options={[
                  ...DATA_SOURCE.valuesAsSelectOptions(
                    DATA_SOURECE_CATEGORY.EMISSIONS_PRICES
                  ),
                ]}
                className={itemClassName}
              ></SelectField>
            </Column>
            <Column label="Model Parameters">
              <SliderField
                name="emissionsRegsEu"
                label="Emissions Regulations (EU)"
                min={0}
                max={100}
                valueSuffix="%"
                className={itemClassName}
              ></SliderField>
              <SliderField
                name="emissionsRegsNonEu"
                label="Emissions Regulations (Non-EU)"
                min={0}
                max={100}
                valueSuffix="%"
                className={itemClassName}
              ></SliderField>
              <EnumSliderField
                name="volatility"
                label="Volatility"
                options={VOLATILITY.values()}
                className={itemClassName}
              ></EnumSliderField>
              <EnumSliderField
                name="liquidity"
                label="Liquidity"
                options={LIQUIDITY.values()}
                className={itemClassName}
              ></EnumSliderField>
            </Column>
            <Column label="Data Providers">
              <>
                <DataSourceContainer
                  name={DATA_SOURCE.BALTIC.name}
                  className={itemClassName}
                  dataSourceCaption={DATA_SOURCE.BALTIC.caption}
                  active
                  statusText="Active"
                ></DataSourceContainer>
                <DataSourceContainer
                  name={DATA_SOURCE.BUNKEREX.name}
                  className={itemClassName}
                  dataSourceCaption={DATA_SOURCE.BUNKEREX.caption}
                  active
                  statusText="Active"
                ></DataSourceContainer>
                <DataSourceContainer
                  name={DATA_SOURCE.PLATTS.name}
                  className={itemClassName}
                  dataSourceCaption={DATA_SOURCE.PLATTS.caption}
                  configuraionPath="settings/spgci"
                  active={_spgciStatus.name === "ACTIVE"}
                  error={
                    _spgciStatus.name === "ERROR" ||
                    _spgciStatus.name === "INVALID" ||
                    _spgciStatus.name === "ACTIVE_WITH_NO_RELEVANT_DATA"
                  }
                  statusText={_spgciStatus.description}
                ></DataSourceContainer>
              </>
            </Column>
          </>
        );
      }}
    </AoForm>
  );
}

function DataSourceContainer({
  className,
  dataSourceCaption,
  active,
  error,
  configuraionPath,
  statusText,
}) {
  return (
    <FieldContainer className={className}>
      <div
        className={`flex items-center justify-between ${FIELD_CONTAINER_HEIGHTS[1]}`}
      >
        <p className="">{dataSourceCaption}</p>
        <div className="flex">
          {active ? (
            <CheckCircleIcon
              className="h-5 mx-2 text-ao-green"
              title={statusText}
            />
          ) : error ? (
            <XCircleIcon className="h-5 mx-2 text-ao-red" title={statusText} />
          ) : (
            <CheckCircleIcon
              className="h-5 mx-2 text-gray-500"
              title={statusText}
            />
          )}
          {configuraionPath ? (
            <NavLink
              to={configuraionPath}
              className="text-ao-blue text-sm underline"
            >
              Manage
            </NavLink>
          ) : (
            <span className="text-sm underline text-gray-500 opacity-50">
              Manage
            </span>
          )}
        </div>
      </div>
    </FieldContainer>
  );
}
