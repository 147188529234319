import React, { useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { bunkersFiltered, availableBunkersFilter, bunkersFilteredSpot } from "../../store/ui";
import BunkerSpotChart from "../../components/Charts/BunkerSpotChart";
import { DATA_SOURCE, FUEL_TYPES } from "../../utils/Constants";
import { dollarFormat, formatDateWithTime, getDashForZero } from "../../utils/Helpers";
import { DataAttribution } from "./DataAttribution";
import { buildDescription, Cell } from "./PriceTable";

const BunkerPricingSpotTable = () => {
  const _bunkersFiltered = useRecoilValue(bunkersFilteredSpot);
  const [rowData, setRowData] = useState([]);
  const [pagination, setPagination] = useState(0);
  const _filter = useRecoilValue(availableBunkersFilter)
  const isBunkerEx = DATA_SOURCE.isBunkerex(_filter.bunkerDataSource)
  const [filteredPorts, setFilteredPorts] = useState(0)

  useEffect(() => {
    const uniquePlattsPorts = [
      ...new Set(_bunkersFiltered.platts.map((bunker) => bunker.portName)),
    ];
    const uniqueBunkerExPorts = [
      ...new Set(_bunkersFiltered.bunkerex.map((bunker) => bunker.portName)),
    ]
    const bunkerArray = isBunkerEx ? _bunkersFiltered.bunkerex : _bunkersFiltered.platts
    const portSet = isBunkerEx ?  uniqueBunkerExPorts : uniquePlattsPorts
    const provider = isBunkerEx ? "BunkerEx" : "SPGCI"
    setFilteredPorts(portSet.length)
    const rowDataArray = [];
    for (const port of portSet) {
      let rowData = {port: port, fuels: {HSFO: BLANK_ENTRY, LSFO: BLANK_ENTRY, MGO: BLANK_ENTRY}};
        bunkerArray.map((bunker) => {
        if (bunker.portName === port && !bunker.constructedSpot) {
          rowData.fuels[bunker.fuelType] = {
            price: bunker.spotPrice,
            constructedSpot: bunker.constructedSpot,
            constructedCurve: false,
            isBunkerEx: isBunkerEx,
            provider,
            providerData: bunker.providerData,
            title: buildDescription(true, bunker.constructedSpot, provider, bunker.providerData, formatDateWithTime(bunker.priceDate))
          }
        }
      });
      rowDataArray.push(rowData);
    }
    const paginated = rowDataArray.slice(
      pagination * 10,
      pagination * 10 + 10
    )
    setRowData(paginated);
  }, [_bunkersFiltered, pagination]);

  const handlePagination = (action) => {
    if (action === "increase") {
      setPagination(pagination + 1);
    } else if (action === "decrease" && pagination !=0) {
        setPagination(pagination - 1); 
    }
  }
  
  return (
    <div className="flex flex-col">
      <div className="flex flex-row w-full">
        <div className="px-4 sm:px-6 lg:px-8 w-1/2">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="flex flex-col w-full">
                  <TitleRow/>
                  {rowData.map((row, i) => (
                  <SpotRow row={row} key={`${i} ${row.price}`}/>
                ))}
                </div>
                <div
                  className="flex items-center mt-2 w-full justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{pagination === 0 ? 1 : pagination * 10 }</span> to{" "}
                      <span className="font-medium">{pagination*10+10 > filteredPorts ? filteredPorts : pagination === 0 ? 10 : pagination * 10 + 10}</span> of{" "}
                      <span className="font-medium">{filteredPorts}</span> results
                    </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                    <a
                      onClick={() => handlePagination("decrease")}
                      href="#"
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                      Previous
                    </a>
                    <a
                      onClick={() => handlePagination("increase")}
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                      Next
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <DataAttribution data={(rowData.map((x) => Object.values(x.fuels))).flat()} isTCE={false} />
          </div>
        </div>
        <div className="w-1/2">
          <BunkerSpotChart data={rowData}  />
        </div>
      </div>
    </div>
  );
};

export default BunkerPricingSpotTable;


function SpotRow({row}){

  return(
    <div className="grid grid-cols-4 border-t w-full">
      <div className="flex items-center text-ao-figma-text-gray">
        <span>{row.port}</span>
      </div>
      <div className="flex items-center">
        <Cell
          value={getDashForZero(row.fuels.HSFO.price)}
          isConstructed={row.fuels.HSFO.constructedSpot}
          title={row.fuels.HSFO.title}
          isSpgci={!row.fuels.HSFO.isBunkerEx}
          topBorder={false}
          />
      </div>
      <div className="flex">
        <Cell
        value={getDashForZero(row.fuels.LSFO.price)}
        isConstructed={row.fuels.LSFO.constructedSpot}
        title={row.fuels.LSFO.title} 
        isSpgci={!row.fuels.LSFO.isBunkerEx}
        topBorder={false}
        />
      </div>
      <div className="flex">
        <Cell
          value={getDashForZero(row.fuels.MGO.price)}
          isConstructed={row.fuels.MGO.constructedSpot}
          title={row.fuels.MGO.title}
          isSpgci={!row.fuels.MGO.isBunkerEx}
          topBorder={false}
          />
      </div>

    </div>
  )
}

function TitleRow({}){

  return(
    <div className="grid grid-cols-4 w-full text-ao-figma-text-gray">
      <div className="flex">
        <p className="font-bold py-2">PORT</p>
      </div>
      <div className="flex justify-items-center align-middle">
        <p className="font-bold p-2">HSFO</p>
      </div>
      <div className="flex">
        <p className="font-bold p-2">LSFO</p>
      </div>
      <div className="flex">
        <p className="font-bold p-2">MGO</p>
      </div>
    </div>
  )
}

const BLANK_ENTRY = {
  price: 0,
  constructedSpot: false,
  constructedCurve: false,
  isBunkerEx: true,
  providerData: {},
  provider: "",
  title: buildDescription(true, false, true, {})
}