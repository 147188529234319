import { useState, useEffect } from "react"
import { separatorFormat, dollarFormat } from "../../../utils/Helpers"


export default function OptimizationsHeaderTable({ bunkerCost, TCE, optimized}){
    
    return (
        <div className="relative overflow-x-auto mt-2 mb-5">
            <table id="HeaderTable" className="w-full text-sm text-center text-ao-figma-text-gray border-collapse">
                <thead className="text-center text-ao-figma-text-gray border-b">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-m border-r">
                            {optimized? "∆-TCE" : "TCE"}
                        </th>
                        <th scope="col" className="px-6 py-3 text-m">
                            Voyage Bunker Costs
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className=" ">
                            <td className="px-6 py-4 border-r">
                                {optimized ? `+${dollarFormat(TCE)}` : dollarFormat(TCE)}
                            </td>
                            <td className="px-6 py-4">
                                {dollarFormat(bunkerCost)}
                            </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
