import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { VoyageEngineCostingModal } from "./VoyageEngineCostingModal"
import { createNewVoyage, getvoyageCalcs, recostVoyages, getNextOpen } from "./VoyageEngineLogic";
import { formatDateToYYYYMMDD } from "../../../utils/Helpers";
import { updateServicesApiCalcs } from "../../../store/api/helpers/services-api-helpers";
import { useVoyagesMutations, voyages } from "../../../store/voyages";

export default function AddVoyageEngineButton() {
  const { saveVoyage } = useVoyagesMutations()
  const _voyages = useRecoilValue(voyages)
  const [_newVoyage, setNewVoyage] = useState(null);
  const [_modalOpen, setModalOpen] = useState(false);

  const handleAddClick = () => {
    createNewVoyage().then((result) => {
      setNewVoyage(() => result);
      setModalOpen(true);
    });
  };

  const handleSaveVoyage = async (newVoyage) => {

    const {nextOpenDate} = getNextOpen(newVoyage)
    newVoyage["dischargeDate"] = formatDateToYYYYMMDD(nextOpenDate)
    newVoyage["dwt"] = newVoyage.vessel.dwt
    const servicesApiCalcs = await getvoyageCalcs(newVoyage)
    let updatedVoyage = updateServicesApiCalcs(newVoyage, servicesApiCalcs, true)

    const propertiesToDelete = [
      "emissionData",
      "freightPriceData",
      "mgoPriceData",
      "ifoPriceData",
      "bunkMult",
      "canalCostsAll",
      "canalCostsBallast",
      "canalCostsLaden",
      "canalDaysOpenToLoad",
      "defaultDistance",
      "distanceDischargeToDischarge2",
      "distanceLoad2ToDischarge",
      "distanceLoadToDischarge",
      "distanceLoadToLoad2",
      "distanceOpenToBunker",
      "distanceBunkerToLoad",
      "distanceMult",
      "ecaBunkerToLoad",
      "ecaDischargeToDischarge2",
      "ecaLoad2ToDischarge",
      "ecaLoadToDischarge",
      "ecaLoadToLoad2",
      "ecaOpenToBunker",
      "ifoBunkerPrice",
      "ifoPricePort",
      "mgoBunkerPrice",
      "mgoPricePort",
      "multipliers",
      "LNGSub",
      "robIFO",
      "robMGO",
      "emissionsPrices",
          ]
    
    for(const key of propertiesToDelete){
      delete updatedVoyage[key]
    }
    
    updatedVoyage["id"] = -1

    const recostedVoyages = await recostVoyages(updatedVoyage.vessel.shipName, updatedVoyage.vessel.shipType, updatedVoyage.vessel.id, [..._voyages, updatedVoyage])
    await Promise.all(
      recostedVoyages.map(async (voy) => {
        if (voy.id === -1) {
          delete voy["id"]
        }
        await saveVoyage(voy)
      })
    )
    setModalOpen(false);
  }

  return (
    <>
      <button
        className="block rounded-md bg-ao-blue px-3 py-2 text-center  font-semibold text-white shadow-md hover:bg-white hover:text-ao-blue"
        onClick={handleAddClick}
      >
        Add
      </button>
      {_modalOpen && (
        <VoyageEngineCostingModal
          modalOpen={_modalOpen}
          closeModal={() => setModalOpen(false)}
          voyage={_newVoyage}
          onSave={handleSaveVoyage}
          isEdit={false}
        />
      )}
    </>
  );
}
