import React, { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Spinner } from "reactstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { DropdownButton } from "../../../components/Buttons/DropdownButton";
import { AoModal } from "../../../components/Dialogs/AoModal";
import { loadingIndicator } from "../../../store/ui";
import { SHIP_TYPES, useUserCustomisedVesselsMutations, userCustomisedVessels } from "../../../store/vessels";
import { voyages } from "../../../store/voyages";
import { separatorFormat } from "../../../utils/Helpers";

const customVesselOptions = Object.keys(SHIP_TYPES).map(type => {
  return { text: SHIP_TYPES[type], linkTo: `/vessels/add/${type}` }
})

export default function VesselTable() {

  const [_loading, setLoading] = useRecoilState(loadingIndicator);
  const [_selectedVessels, setSelectedVessels] = useState([]);
  const [_deleteModaleOpen, setDeleteModalOpen] = useState(false);
  const _vessels = useRecoilValue(userCustomisedVessels)
  const { deleteVessel } = useUserCustomisedVesselsMutations()
  const _voyages = useRecoilValue(voyages);

  const checkbox = useRef();
  const [checked, setChecked] = useState(true);

  useLayoutEffect(() => {
    setChecked(_selectedVessels.length === _vessels.length);
  }, [_selectedVessels]);

  function toggleAll() {
    setSelectedVessels(checked ? [] : _vessels)
    setChecked(!checked)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div style={{ display: _loading ? "block" : "none" }}>
        <Spinner />
      </div>

      <div style={{ display: _loading ? "none" : "block" }}>
        <div>
          {/* Action buttons */}
          <div className="flex items-center justify-end gap-x-8 -mt-14">
            {/* </button> */}
            <button onClick={handleDeleteClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#5C93C0"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </button>
            <DropdownButton text="Add" options={customVesselOptions}></DropdownButton>
          </div>

          {/* Main Table */}
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="relative px-7 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th
                          scope="col"
                          className=" py-3.5 pr-3 text-left text-sm font-semibold text-ao-blue"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Vessel
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Class
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          IMO
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Year Built
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          DWT (mt)
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Usage
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                        >
                          Current Status
                        </th>
                      </tr>
                    </thead>
                    {_vessels.length > 0 && (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {_vessels.map((vessel) => (
                          <tr
                            key={vessel.id}
                            className={
                              _selectedVessels.includes(vessel)
                                ? "bg-gray-50"
                                : undefined
                            }
                          >
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              {_selectedVessels.includes(vessel) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-ao-blue" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                                value={vessel.email}
                                checked={_selectedVessels.includes(vessel)}
                                onChange={(e) =>
                                  setSelectedVessels(
                                    e.target.checked
                                      ? [..._selectedVessels, vessel]
                                      : _selectedVessels.filter(
                                        (p) => p !== vessel
                                      )
                                  )
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                _selectedVessels.includes(vessel)
                                  ? "text-ao-blue"
                                  : "text-ao-blue"
                              )}
                            >
                              {vessel.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vessel.shipName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vessel.shipType}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vessel.imoNumber}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vessel.yearBuilt}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {separatorFormat(vessel.dwt)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Spot
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vesselOnVoyage(vessel, _voyages)}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                              <Link
                                to={`/vessels/${vessel.id}`}
                                className="underline pr-4"
                              >
                                Overview
                              </Link>
                              <Link
                                to="/vessels"
                                className="underline"
                              >
                                View Voyage History
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {_vessels.length === 0 && (
                    <div className="w-1/2 pt-12 text-ao-figma-text-gray font-semibold text-lg mx-auto text-center"> No Vessels Found </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AoModal open={_deleteModaleOpen} header="Are you sure?" message="Do you really want to delete the selected vessel(s)?"
        onDiscard={() => setDeleteModalOpen(false)} onCommit={handleDeleteSelectedVessels}></AoModal>
    </>
  );

  function handleDeleteClick() {
    if (_selectedVessels.length > 0) {
      setDeleteModalOpen(true)
    }
  }

  async function handleDeleteSelectedVessels() {
    await Promise.all(_selectedVessels.map(vessel => deleteVessel(vessel.id)))
    setSelectedVessels([])

    setDeleteModalOpen(false)
  }
}

function vesselOnVoyage(vessel, voyages) {
  const currentDate = new Date()
  const isInUse = voyages.some((voyage) => {
    if (voyage.vessel === vessel.shipName &&
      currentDate >= new Date(voyage.laycanStart) &&
      currentDate <= new Date(voyage.laycanEnd)) {
      return true
    }
    return false
  })

  const status = isInUse ? "On Voyage" : "Open"

  return status
}