import OptimizationsBunkerTable from "./Optimizations-card-table"

export default function OptimizationsTimelineCard({ port }){

  let bodyText = ""
  let svg
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const formattedDate = port.date.toLocaleDateString('en-US', options)
  let subtitle = port.eventDisplay   + " | " + formattedDate

  switch (port.event) {
      case "open":
        bodyText = (
          <div>
            Cargo: {port.cargo}
          </div>
        )
        svg = (
            <span className="absolute flex items-center justify-center w-6 h-6 bg-ao-figma-text-gray rounded-full -left-3 ring-8 ring-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-3 h-3">
                <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
              </svg>
            </span>
          )
        break
      case "bunker":
        bodyText = (
          <OptimizationsBunkerTable
            required={port.displayReq}
            purchased={port.purchased}
            prices={port.prices}
            scrubber={port.vesselScrubber}
          />
        )
        svg = (
            <span className="absolute flex items-center justify-center w-6 h-6 bg-ao-blue rounded-full -left-3 ring-8 ring-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-3 h-3">
                <path d="M11.983 1.907a.75.75 0 00-1.292-.657l-8.5 9.5A.75.75 0 002.75 12h6.572l-1.305 6.093a.75.75 0 001.292.657l8.5-9.5A.75.75 0 0017.25 8h-6.572l1.305-6.093z" />
              </svg>
            </span>
        )
        break
      case "discharge":
        // bodyText = (
        //             <div className="mb-1">
        //               {`ROB | IFO: ${port.robIFO}, MGO: ${port.robMGO}`}
        //             </div>
        //             )
        svg = (
          <span className="absolute flex items-center justify-center w-6 h-6 bg-ao-figma-text-gray rounded-full -left-3 ring-8 ring-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-3 h-3">
              <path d="M5.25 3A2.25 2.25 0 003 5.25v9.5A2.25 2.25 0 005.25 17h9.5A2.25 2.25 0 0017 14.75v-9.5A2.25 2.25 0 0014.75 3h-9.5z" />
            </svg>
          </span>
        )
        break
      case "load":
        bodyText = (
          <div className="mb-1">
            <p></p>
          </div>
          )
          svg = (
          <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-500 rounded-full -left-3 ring-8 ring-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="white" className="w-3 h-3">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
            </svg>
          </span>
          )
          break

  }


    return(
        <li className="mb-10 ml-6">
        {svg}
        <h3 className="mb-1 text-lg font-semibold text-gray-900"> {port.port}</h3>
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400">
          {subtitle}
        </time>
        <p className="text-base font-normal text-gray-500">{bodyText}</p>
        </li>
    )
}