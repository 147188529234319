import {MenuItem, Select} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useRecoilState, useRecoilValue} from "recoil"
import {selectedEmissions} from "../../store/ui"
import {replaceItemAtIndex} from "../../utils/Helpers"
import {getAllEmissions} from "../../store/api"

export default ({index}) => {
    const Emissions = useRecoilValue(getAllEmissions)
    const [_availableEmissions, setAvailableEmissions] = useState([])
    const [_selectedEmissions, setSelectedEmissions] = useRecoilState(selectedEmissions)

    const onChangeHandler = (event) => {
        const oldEmission = _selectedEmissions[index]
        const newEmissionID = _availableEmissions.find((emission) => emission === event.target.value)
        const newSelectedEmissions = replaceItemAtIndex(_selectedEmissions, index, { ...oldEmission, item: newEmissionID})
        setSelectedEmissions(newSelectedEmissions)
    }

    useEffect(() => {
        const fetchEmissions = async() => {
            setAvailableEmissions( Emissions ? [Emissions.spotSymbol] : [])
        }
        fetchEmissions()
    }, [Emissions])

    // If for some reason the selected emission no longer exists, replace with empty string
    let selectedEmissionForIndex = typeof _selectedEmissions[index] === "undefined" ? "" : _selectedEmissions[index].item
    
    return (
        <Select
            onChange={onChangeHandler}
            style={{"fontSize":11, paddingLeft: 5}}
            value={selectedEmissionForIndex}
            fullWidth={true}
        >
            {
                _availableEmissions.map((emission) => {
                    return <MenuItem value={emission}>{`EEX EUA Eur/MT (${emission})`}</MenuItem>
                })
            }
        </Select>
    )
}

