import { DateTime, Info } from "luxon"

/*
 * Global function for formatting numbers (e.g. adding , on thousands etc)
 */
export function formatNumber(num) {
    return num.toLocaleString()
}

export function replaceItemAtIndex(arr, index, newValue) {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

export function removeItemAtIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

/**
 * Given an array of objects all with key, get the unique values of this key
 * @param arr - input array of objects
 * @param key - the key on all objects in array to extract
 * @returns {*}
 */
export function getUniqueValuesForKey(arr, key) {
    return arr.map((value) => value[key])
        .filter((value, index, _arr) => _arr.indexOf(value) === index)
}


export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1)
}

export function getLabelForFuel(x) {
    switch (x) {
        case "win":
            return "Wind"
        case "amm":
            return "NH3"
        case "mth":
            return "MeOH"
        case "hyd":
            return "H2"
        default:
            return x.length === 3 ? x.toUpperCase() : x
    }

}


/**
 * This generates a unique UUID for new objects e.g. vessels
 * TODO: Move anything referencing this to server, and give it proper id from DB!
 * @returns {string}
 */
export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime()//Timestamp
    var d2 = ((typeof performance !== "undefined") && performance.now && (performance.now() * 1000)) || 0//Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0
            d = Math.floor(d / 16)
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0
            d2 = Math.floor(d2 / 16)
        }
        // eslint-disable-next-line no-mixed-operators
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16)
    })
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const daySuffix = (date) => {
    const day = date.getDate()
    const j = day % 10,
        k = day % 100
    if (j === 1 && k !== 11) {
        return day + "st"
    }
    if (j === 2 && k !== 12) {
        return day + "nd"
    }
    if (j === 3 && k !== 13) {
        return day + "rd"
    }
    return day + "th"
}

const shortMonth = (date) => {
    return monthNames[date.getMonth()]
}


export { shortMonth, daySuffix }

export const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
})

export const ceilThousands = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
    roundingMode: "ceil"
});

export function mtFormat(x) {
    const formattedNumber = Math.round(x).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return formattedNumber + " mt"
}

export function kgFormat(x) {
    const formattedNumber = Math.round(x).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return formattedNumber + " kg"
}

export function tonFormat(x) {
    const formattedNumber = Math.round(x).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return formattedNumber + " t"
}

export function btuFormat(x) {
    const formattedNumber = Math.round(x).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return formattedNumber + " mmBTU"
}

export function removeRottSing(x) {
    return x.replace("Sing", "").replace("Rott", "").replace("LSGO", "MGO")
}

export function getYearInMonthLabels() {
    const currentMonth = DateTime.now().month - 1
    const monthRows = []
    for (let i = 0; i < 12; i++) {
        monthRows.push(Info.months("short")[(currentMonth + i) % 12])
    }
    return monthRows
}

export function getJourneyTimeDays(distanceKm, speedKnots) {
    return (distanceKm / (speedKnots * 1.852)) / 24
}

export function dollarFormat(x, decimalPlaces = 0) {
    const roundedNumber = Math.round(x * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    const formattedNumber = roundedNumber.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
    return "$" + formattedNumber;
}

export function euroFormat(x, decimalPlaces = 0) {
    const roundedNumber = Math.round(x * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    const formattedNumber = roundedNumber.toLocaleString(undefined, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
    return "€" + formattedNumber;
}

export function separatorFormat(x) {
    const formattedNumber = Math.round(x).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return formattedNumber
}

export function asNumber(number, decimalPlaces = 2) {
    return isNaN(number) ? null : Number(number).toFixed(decimalPlaces)
}

export function percentageFormat(x, decimalPlaces = 2) {
    const formattedPercentage = (x * 100).toFixed(decimalPlaces) + '%'
    return formattedPercentage
}

export function unique(arr, props) {
    return arr.filter((v, i, a) => a.findIndex((v2) => props.every((k) => v2[k] === v[k])) === i)
}

export function formatDateToYYYYMMDD(date) {
    if (typeof date === "string"){
        return date
    }
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    var d = date.getDate()
    return  `${y}-${m}-${d}`
  }

export function formatDateWithTime(dateString) {
    const originalDate = new Date(dateString);
    return formatDateToYYYYMMDD(originalDate)
  }

export function flatpickrFormatDate(date) {
    const year = String(date.getFullYear()).slice(-2); // Extract the last two digits of the year
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
}

export function formatDateToYYYYMM(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");

    return `${year}-${month}`;
}

export function addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

export function monthsBetweenDates(date1, date2) {
    if (date1 > date2) {
      [date1, date2] = [date2, date1];
    }

    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();
  
    if (date2.getDate() < date1.getDate()) {
      monthDiff -= 1;
    }
    
    return yearDiff * 12 + monthDiff;
  }

export const DATE_FORMAT_MUI = "DD/MM/YYYY"
export const DATE_FORMAT = "dd/MM/yyyy"
export function formatDate(date) {
    return DateTime.fromJSDate(date).toFormat(DATE_FORMAT)
}

export function getCanalBool(constraint){
    if (constraint === "Exclude Canals"){
        return false
    }
    return true
  }

export  function incrementDate(originalDate, daysToAdd) {
    let newDate = new Date(originalDate);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }


export function createDateFromFormat(dateString) {
    let dateComponents = dateString.split("-")
    return new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2]);
}

export function convertToSentenceCase(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getDashForZero(val){
    if(val === 0){
      return "-"
    }
    return dollarFormat(val, 2)
  }