import {apiCall} from "../utils"

export const login = (username, password) => new Promise((resolve, reject) => {
    apiCall({

        // apiUrl: `${process.env.REACT_APP_API_URL}/v1/users/login`,
        apiUrl: "http://localhost:3000/v1/users/login",
        body: { username, password },
        callback: (err, res) => {
            err ? reject(err) : resolve(res)
        },
        method: "POST"
    })
})

export const verifyToken = (username, token) => new Promise((resolve, reject) => {
    apiCall({
    // apiUrl: `${process.env.REACT_APP_API_URL}/v1/users/verify`,
        apiUrl: "http://localhost:3000/v1/users/verify",
        body: { username, token },
        callback: (err, res) => {
            err ? reject(err) : resolve(res)
        },
        method: "POST"
    })
})

export const logout = ( username, token ) => new Promise((resolve, reject) => {
    apiCall({

        // apiUrl: `${process.env.REACT_APP_API_URL}/v1/users/logout`,
        apiUrl: "http://localhost:3000/v1/users/logout",
        body: { username, token },
        callback: (err, res) => {
            err ? reject(err) : resolve(res)
        },
        method: "POST"
    })
})

export const findProfile = (userId, token) => new Promise((resolve, reject) => {
    apiCall({

        // apiUrl: `${process.env.REACT_APP_API_URL}/v1/users/findProfile`,
        apiUrl: "http://localhost:3000/v1/users/findProfile",
        body: { userId, token },
        callback: (err, res) => {
            err ? reject(err) : resolve(res)
        },
        method: "POST"
    })
})
