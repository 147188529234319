import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SHIP_TYPES, getVesselConsumptionCurve } from '../../store/vessels';
import { VesselForm } from './components/VesselForm';
import { customVesselDefaults } from './components/custmVesselDefaults';

export function CreateVessel() {
    const { type } = useParams()

    const defaultValues = useMemo(() => {
        const defaults = JSON.parse(JSON.stringify(customVesselDefaults[SHIP_TYPES[type]] || {}))
        const ladenConsumptionCurve = getVesselConsumptionCurve(defaults, "laden")
        const ballastConsumptionCurve = getVesselConsumptionCurve(defaults, "ballast")
        defaults.customConsumptions = { laden: ladenConsumptionCurve, ballast: ballastConsumptionCurve }

        return defaults;
    }, [type])

    return (
        <VesselForm defaultValues={defaultValues}></VesselForm>
    );
};

export default CreateVessel;
