import { Form, Formik } from "formik";

const formUtils = {
    Column({ label, children, className, action }) {
        return <div className={`flex-1 ${className || ''}`}>
            <div className="flex border-r border-b ao-light-gray-border">
                <label className="grow text-base text-ao-blue font-bold leading-6 pt-3 pb-[1px] px-2">{label}</label>
                {action}
            </div>
            {children}
        </div>
    },
    itemClassName: 'border-r border-b ao-light-gray-border'
}

export function AoForm({ initialValues, onSubmit, children, className, onReset = () => { } }) {
    return <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values, actions) => {
            const newValue = await onSubmit({ ...initialValues, ...values })
            if(newValue){
                actions.setValues(newValue);
            }
            actions.setSubmitting(false);
        }}
    >
        {props => (
            <Form className={`border border-ao-dark-gray-border rounded-xl pt-2 px-[2px] pb-3 ${className || ''}`}>
                <div className="flex">
                    {children({ ...formUtils, form: props })}
                </div>
                <div className="flex direction-col justify-end mt-4 pr-3">
                    <button type="reset" className="flex-none text-ao-dark-gray-border underline text-base font-bold leading-6 px-4 py-2" onClick={onReset}>Cancel</button>
                    <button type="submit" className="flex-none bg-ao-blue text-white text-base font-bold leading-6 px-4 py-2 rounded-lg ml-2">Save</button>
                </div>

            </Form>)
        }
    </Formik >

}