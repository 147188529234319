export function AoModal({ open, header, message, onDiscard, onCommit }) {
    if (!open) return null

    return <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">

        <div className="fixed inset-0 bg-gray-50 bg-opacity-25 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden border border-ao-dark-gray-border rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{header}</h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">{message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" className="bg-ao-blue text-white text-base font-bold leading-6 px-4 py-2 rounded-lg ml-2" onClick={onCommit}>Yes</button>
                        <button type="button" className="underline text-ao-dark-gray-border text-base font-bold leading-6 px-4 py-2" onClick={onDiscard}>No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}