import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"

export function ConfirmationDialog({ open, message, onYes, onClose }) {
    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>
            Are you sure?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={onClose}>
                No
            </Button>
            <Button onClick={() => {
                onYes()
                onClose()
            }}>Yes</Button>
        </DialogActions>
    </Dialog>
}