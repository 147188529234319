import { useField } from "formik";
import React from "react";
import { FieldContainer } from "./FieldContainer";

export function InputField({ name }) {
  const [field] = useField(name);

  return (
    <SimpleInputField
      id={name}
      {...arguments[0]}
      inputProps={field}
    ></SimpleInputField>
  );
}

export function SimpleInputField({
  id,
  name,
  label,
  className,
  type = "text",
  readOnly = false,
  suffix = false,
  inputProps = {},
  placeholder = "",
  inputClassname = "",
  required,
}) {
  inputProps.onChange = inputProps.onChange || (() => {});
  if (inputProps.value === null) {
    inputProps.value = "";
  }

  return (
    <FieldContainer id={id} label={label} className={className}>
      <div className="flex">
        <input
          id={name}
          type={type}
          readOnly={readOnly}
          className={`w-full flex-1 text-base text-gray-700 leading-6 bg-inherit outline-none ${inputClassname}`}
          {...inputProps}
          placeholder={placeholder}
          required={required}
        ></input>
        {suffix && <span className="flex-none ml-2">{suffix}</span>}
      </div>
    </FieldContainer>
  );
}
