import { refFirstLastDates, voyageOverlapsWithDateRange, daysInRange } from "../../../voyageutils"


export function getBunkerDataForSelectedPeriod(selectedPeriod, voyageData, grouping) {
    const currentDate = new Date()
    const [rangeOpen, rangeClose] = refFirstLastDates(currentDate, selectedPeriod)
    let bunkersByGroup = {}
    let keys = new Set([])

    for (const voyage of voyageData) {
        const isVoyageInDateRange = voyageOverlapsWithDateRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
    
        if (isVoyageInDateRange) {
            const displayName = "ID: " + voyage.id + " - " + voyage.routeShortName
            const key = grouping === "byVessel" ? voyage.vessel.shipName : displayName
            keys.add(key)
            const voyageDuration = voyage.voyageLength
            const daysInPeriod = Math.round(daysInRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose))
            const loadFactorRaw = daysInPeriod/voyageDuration
            const loadFactor = loadFactorRaw >= 1 ? 1 : loadFactorRaw

            if (!bunkersByGroup[key]) {
                bunkersByGroup[key] = {
                    vessel: voyage.vessel.shipName,
                    displayName: displayName,
                    totalRevenues: 0,
                    voyagesInRange: 0,
                    days: 0,
                    purchasedSum: 0,
                    meanCostSum: 0,
                    robSum: 0,
                    voyagesInRange: 0,
                    bestCaseSum:0,
                    worstCaseSum:0,
                    worstCaseStub: 0,
                    requiredBunkers: 0
                }
            }

            bunkersByGroup[key].voyagesInRange += 1
            bunkersByGroup[key].totalRevenues += voyage.totalRevenues * loadFactor
            bunkersByGroup[key].tceSum += voyage.tce
            bunkersByGroup[key].days += voyageDuration * loadFactor
            
            const robKeys = voyage.robCosts ? Object.keys(voyage.robCosts) : []
            let robSum = 0
            for(const fuelType of robKeys){
                robSum += voyage.robCosts[fuelType]["cost"]
            }
            bunkersByGroup[key].robSum += robSum
            
            // Voyage Cost Calculations
            const worstCaseBunkerCost = Object.values(voyage.bunkerCostsWorstCase.med).reduce((a, b) => a + b, 0)
            const bestCaseBunkerCost = Object.values(voyage.bunkerCostsBestCase.med).reduce((a, b) => a + b, 0)
            bunkersByGroup[key].meanCostSum += voyage.bunkerCost * loadFactor
            bunkersByGroup[key].purchasedSum += 0 * loadFactor
            bunkersByGroup[key].worstCaseStub += (worstCaseBunkerCost - bestCaseBunkerCost) * loadFactor
            bunkersByGroup[key].worstCaseSum += worstCaseBunkerCost * loadFactor
            bunkersByGroup[key].bestCaseSum += bestCaseBunkerCost * loadFactor
            bunkersByGroup[key].requiredBunkers += Object.values(voyage.uncoveredBunkers).reduce((a, b) => a + b, 0)
        }
    }

    const keysArray = [...keys]
    
    return  {
        bunkersByGroup: bunkersByGroup,
        keys: keysArray
    }

}

export function getBunkerDataForSelectedPeriodByFuel(selectedPeriod, voyageData) {
    const currentDate = new Date()
    const [rangeOpen, rangeClose] = refFirstLastDates(currentDate, selectedPeriod)
    let bunkersByGroup = {}
    let keys = new Set([])

    for (const voyage of voyageData) {
        const isVoyageInDateRange = voyageOverlapsWithDateRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
    
        if (isVoyageInDateRange) {
            const voyageDuration = voyage.voyageLength
            const daysInPeriod = Math.round(daysInRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose))
            const loadFactorRaw = daysInPeriod/voyageDuration
            const loadFactor = loadFactorRaw >= 1 ? 1 : loadFactorRaw
            for(const fuel of Object.keys(voyage.fuels)){
                keys.add(fuel)
                if (!bunkersByGroup[fuel]) {
                    bunkersByGroup[fuel] = {
                        bestCaseSum:0,
                        meanCostSum: 0,
                        worstCaseSum:0,
                        worstCaseStub: 0,
                    }
                }

                bunkersByGroup[fuel].bestCaseSum += voyage.bunkerCostsBestCase.med[fuel] * loadFactor
                bunkersByGroup[fuel].meanCostSum += voyage.bunkerCosts[fuel] * loadFactor
                bunkersByGroup[fuel].worstCaseSum += voyage.bunkerCostsWorstCase.med[fuel] * loadFactor
                bunkersByGroup[fuel].worstCaseStub += (voyage.bunkerCostsWorstCase.med[fuel] - voyage.bunkerCostsBestCase.med[fuel]) * loadFactor
            }
        }
    }

    const keysArray = [...keys]
    return {
        bunkersByGroup: bunkersByGroup,
        keys: keysArray
    }
}
