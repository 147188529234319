import React from "react"
import { PriceTable } from "./PriceTable"
import RouteSelect from "../../components/RouteSelect/RouteSelect"
import { DataAttribution } from "./DataAttribution"


export function TCEPriceTable({data}){
    return (
        <div>
            <div className="grid grid-cols-5">
                <div>
                </div>
                <RouteSelect index={0}/>
                <RouteSelect index={1}/>
                <RouteSelect index={2}/>
                <RouteSelect index={3}/>
            </div>
            <PriceTable data={data}/>
            <DataAttribution data={data} isTCE={true}/>
        </div>
    )

}