import { dollarFormat } from "../../../utils/Helpers"
import LoadStemButton from "./TanksLoadStemButton"
import EditStemButton from "./EditStemButton"
import React from "react"


export default function TankPurchaseCard({purchase, isFirstStem, shipName, fuelType}){

        return(
            <>
            <ul className="divide-y divide-gray-200 w-full">
                <li className="py-3 sm:py-4">
                    <div className="flex items-center space-x-4 max-w-full">
                    <div className="flex-1">
                        <p className="truncate text-sm font-medium text-gray-900">Port: {purchase.port}</p>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-400">Supplier: {purchase.supplier}</p>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-400">Stem Date: {purchase.stemDate}</p>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-400">Quantity: {purchase.quantity}</p>
                        <p className="truncate text-sm text-gray-500 dark:text-gray-400">Price: {purchase.price}</p>
                    </div>
                        <div className="flex flex-col">
                            <div className="inline-flex items-center text-base font-semibold text-gray-900">{dollarFormat(purchase.quantity * purchase.price)}</div>
                            {isFirstStem && (<LoadStemButton
                                    shipName={shipName}
                                    fuelType={fuelType}
                                />)}
                            <EditStemButton vessel={shipName} fuelType={fuelType} stem={purchase} />
                        </div>
                    </div>
                </li>
            </ul>
    
            </>
        )
}