/**
 * A wrapper around asynchronous methods to fetch data from the api
 * Calls should return object with success boolean, and error message in error
 * if not successful.
 *
 * **This is the place to put calls to the data api!**
 */

import { Bunker as MockBunkers } from "../../dataMocks/Bunker"
import BunkersLookup from "../../dataMocks/BunkersLookup.json"
import { Emissions as MockEmissions } from "../../dataMocks/Emissions"
import bunkerParamsTemp from "../../dataMocks/bunkerparamsTemp.json"
import { pathways as MockPathways } from "../../dataMocks/pathways"
import RouteParamsInVSAB from "../../dataMocks/routeparamsoutFeb2023H.json"
import RouteParamsInUnpriced from "../../dataMocks/routeparamsin2023unpriced.json"
// import TankerOutResults from "../../dataMocks/Tanker_out_Feb2023-21-06-2023.json"
import FuelConsumptionCurves from "../../dataMocks/CustomFuelConsumption.json"
import port_list from "../../dataMocks/port_list.json"

export async function FetchRouteParamsIn() {
    return { success: true, data: RouteParamsInVSAB }
}

export async function FetchRouteParamsUnpriced() {
    return { success: true, data: RouteParamsInUnpriced }
}

export function FetchPortListByShipType_v3() {
    return { success: true, ports: port_list }
}

export async function FetchBunkerParams() {
    return { success: true, data: bunkerParamsTemp }
}

/*
export async function FetchSimulations() {
    return {success: true, tankers: TankerOutResults}
}*/

export async function FetchCustomFuelConsumption() {
    return { success: true, data: FuelConsumptionCurves }
}

/*
export async function FetchRouteParamsIn() {
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/datamocks/routeparamsinVSAB.json")

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        throw new Error(message)
    }

    const json = await response.json()
    return {success: true, data: json}
}
*/

export async function FetchSimulations() {

    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/datamocks/Tanker_out_Feb2023-21-06-2023.json")

    if (!response.ok) {
        const message = `An error has occurred fetching simulations: ${response.status}`
        throw new Error(message)
    }

    const json = await response.json()
    return { success: true, tankers: json }
}

export async function FetchSimulation(tankerID) {

    return { success: true, tanker: null }
}

export async function FetchBunkers_v1() {
    // The bunkers should ideally contain the short, long and code name,
    // this is a temporary fix
    for (let bunker in MockBunkers) {
        MockBunkers[bunker].codeName = BunkersLookup[bunker].codeName
        MockBunkers[bunker].longName = BunkersLookup[bunker].longName
    }

    return { success: true, bunkers: MockBunkers }
}

export async function FetchBunkers_v5() {
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/datamocks/bunkerparamsoutJan2022_v5.json")

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        throw new Error(message)
    }

    const json = await response.json()
    return { success: true, bunkers: json }
}

export async function FetchEmissions() {
    return { success: true, emissions: MockEmissions }
}

export async function FetchEmission(emissionsID) {
    return { success: true, emission: MockEmissions[emissionsID] }
}

export async function FetchPathways() {
    return { success: true, pathways: MockPathways }
}

export async function FetchPortDistances() {
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/datamocks/port_distances.json")

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        throw new Error(message)
    }

    const json = await response.json()
    return { success: true, portDistances: json }
}

export async function FetchPortDistancesVLCC() {
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/datamocks/port_distances_VLCC.json")

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        throw new Error(message)
    }

    const json = await response.json()
    return { success: true, portDistances: json }
}

const portDistancesCache = {} // Cache to memorize portDistances dictionaries
const portDistancesCacheVLCC = {} // Cache to memorize VLCCportDistances dictionaries

export async function FetchPortDistancesFrom(port, isRegion) {

    const portName = port.replace(/ /g, "_") + ".json"
    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/port_distances/" + portName
    if(isRegion){
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_port_distances/" + portName
    }

    const response = await fetch(link)
    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port distances for London - but in future need to figure out
        // why some ports are missing
        return await FetchPortDistancesFromVLCC("London")
    }
    const json = await response.json()
    let distances = { success: true, distances: json.distances }
    if(isRegion){
        distances = { success: true, distances: json }
    }

    portDistancesCacheVLCC[port] = distances
    return distances
}

export async function FetchPortDistancesFromVLCC(port, isRegion) {

    const portName = port.replace(/ /g, "_") + ".json"

    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/port_distances_VLCC/" + portName

    if(isRegion){
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_port_distances_VLCC/" + portName
    }

    const response = await fetch(link)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port distances for London - but in future need to figure out
        // why some ports are missing
        return await FetchPortDistancesFromVLCC("London")
    }

    const json = await response.json()
    let distances = { success: true, distances: json.distances }
    if(isRegion){
        distances = { success: true, distances: json }
    }

    portDistancesCacheVLCC[port] = distances
    return distances
}

const ecaPercentagesCache = {}
const ecaPercentagesCacheVLCC = {}

export async function FetchPortECAsFrom(port, isRegion) {

    let portName = port.replace(/ /g, "_") + "_eca.json"
    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/eca_percentages/" + portName

    if(isRegion){
        const portNameNoEca = port.replace(/ /g, "_") + ".json"
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_eca_percentages/" + portNameNoEca
    }
    const response = await fetch(link)
    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port eca percentage for London - but in future need to figure out
        // why some ports are missing
        // return await FetchPortECAsNoCanalsFrom("London", false)
    }

    const json = await response.json()
    const ecaPercentages = { success: true, ecaPercentages: json }

    return ecaPercentages
}

export async function FetchPortECAsFromVLCC(port, isRegion) {

    const portName = port.replace(/ /g, "_") + "_eca.json"
    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/eca_percentages_VLCC/" + portName

    if(isRegion){
        const portNameNoEca = port.replace(/ /g, "_") + ".json"
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_eca_percentages_VLCC/" + portNameNoEca
    }
    const response = await fetch(link)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port eca percentage for London - but in future need to figure out
        // why some ports are missing
        // return await FetchPortECAsNoCanalsFrom("London")
    }

    const json = await response.json()
    const ecaPercentagesVLCC = { success: true, ecaPercentages: json }

    return ecaPercentagesVLCC
}



const portDistancesNoCanalsCache = {}

export async function FetchPortDistancesNoCanalsFrom(port, isRegion) {

    // if (portDistancesNoCanalsCache[port]) {
    //     return portDistancesNoCanalsCache[port]
    // }

    const portName = port.replace(/ /g, "_") + ".json"
    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/port_distances_nocanals/" + portName
    if(isRegion){
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_port_distances_nocanals/" + portName
        const response = await fetch(link)
    
        if (!response.ok) {
            const message = `An error has occurred fetching: ${response.status}`
            console.error(message)
            return await FetchPortDistancesNoCanalsFrom("London", false)
        }
        const json = await response.json()
        const distances = { success: true, distances: json }

        return distances
    }

    const response = await fetch(link)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        return await FetchPortDistancesNoCanalsFrom("London")
    }

    const json = await response.json()
    const distances = { success: true, distances: json.distances }
    // portDistancesNoCanalsCache[port] = distances
    return distances
}

const ecaPercentagesNoCanalsCache = {}

export async function FetchPortECAsNoCanalsFrom(port, isRegion) {
    const portName = port.replace(/ /g, "_") + "_eca.json"
    let link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/eca_percentages_nocanals/" + portName

    if(isRegion){
        const portNameNoEca = port.replace(/ /g, "_") + ".json"
        link = "https://aft-demo-assets.s3.eu-west-2.amazonaws.com/region_eca_percentages_nocanals/" + portNameNoEca
    }
    const response = await fetch(link)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port eca percentage for London - but in future need to figure out
        // why some ports are missing
        // return await FetchPortECAsNoCanalsFrom("London")
    }

    const json = await response.json()
    const ecaPercentages = { success: true, ecaPercentages: json }
    ecaPercentagesNoCanalsCache[port] = ecaPercentages

    return ecaPercentages
}

const canalPassagesCache = {}
const canalPassagesCacheVLCC = {}

export async function FetchCanalPassagesFrom(port) {

    const portName = port.replace(/ /g, "_") + "_canal_passages.json"
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/canal_passages/" + portName)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port canal passages for London - but in future need to figure out
        // why some ports are missing
        return await FetchCanalPassagesFrom("London")
    }

    const json = await response.json()

    const canalPassages = { success: true, canalPassages: json }
    canalPassagesCache[port] = canalPassages
    return canalPassages
}

export async function FetchCanalPassagesFromVLCC(port) {

    const portName = port.replace(/ /g, "_") + "_canal_passages.json"
    const response = await fetch("https://aft-demo-assets.s3.eu-west-2.amazonaws.com/canal_passages_VLCC/" + portName)

    if (!response.ok) {
        const message = `An error has occurred fetching: ${response.status}`
        console.error(message)
        // To avoid crashes, just return port canal passages for London - but in future need to figure out
        // why some ports are missing
        return await FetchCanalPassagesFromVLCC("London")
    }

    const json = await response.json()

    const canalPassages = { success: true, canalPassages: json }
    canalPassagesCacheVLCC[port] = canalPassages
    return canalPassages
}

