import React, { useState, useEffect } from "react"
import GeneralTable from "../../../components/GeneralTable"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { mtFormat, kgFormat, btuFormat, dollarFormat } from "../../../utils/Helpers"
import { getEmissionsDataForSelectedPeriod } from "./bunkerModalComponents/calculations/calcEmissionOutputs"
import { useRecoilValue } from "recoil"
import { voyages } from "../../../store/voyages"

export default ({ selectedVoyages, grouping }) => {

    const [_selectedRoute, setSelectedRoute] = useState("all")
    const [_rows, setRows] = useState([])
    const [_options, setOptions] = useState([])
    const _voyages = useRecoilValue(voyages)


    useEffect(() => {

        const getData = () => {
            if (selectedVoyages.length > 0) {
                const relevantVoyages = _voyages.filter((v) => selectedVoyages.includes(v.id))
                const dataMonthAll = getEmissionsDataForSelectedPeriod("current", relevantVoyages, grouping)
                const dataQuarterAll = getEmissionsDataForSelectedPeriod("quarter", relevantVoyages, grouping)
                const dataFutureAll = getEmissionsDataForSelectedPeriod("future", relevantVoyages, grouping)
                const dataMonth = dataMonthAll.emissionsRecord
                const dataQuarter = dataQuarterAll.emissionsRecord
                const dataFuture = dataFutureAll.emissionsRecord
                const keys = dataFutureAll.keys
                const optionsArray = [
                    { key: "all", label: "All" },
                    ...keys.map((x) => {
                        return { key: x, label: x };
                    }),
                ]
                setOptions(optionsArray)
                let expenses, mmBTU, co2, sox, nox

                if (_selectedRoute === "all" || !keys.includes(_selectedRoute)) {
                    let monthExpenses = 0
                    let monthMMBTU = 0
                    let monthCO2 = 0
                    let monthSOx = 0
                    let monthNOx = 0
                    let quarterExpenses = 0
                    let quarterMMBTU = 0
                    let quarterCO2 = 0
                    let quarterSOx = 0
                    let quarterNOx = 0
                    let futureExpenses = 0
                    let futureMMBTU = 0
                    let futureCO2 = 0
                    let futureSOx = 0
                    let futureNOx = 0

                    for (const key in dataMonth) {
                        monthExpenses += dataMonth[key].emissionsExpenses
                        monthMMBTU += dataMonth[key].mmBTU
                        monthCO2 += dataMonth[key].co2
                        monthSOx += dataMonth[key].sox
                        monthNOx += dataMonth[key].nox
                    }

                    for (const key in dataQuarter) {
                        quarterExpenses += dataQuarter[key].emissionsExpenses
                        quarterMMBTU += dataQuarter[key].mmBTU
                        quarterCO2 += dataQuarter[key].co2
                        quarterSOx += dataQuarter[key].sox
                        quarterNOx += dataQuarter[key].nox
                    }

                    for (const key in dataFuture) {
                        futureExpenses += dataFuture[key].emissionsExpenses
                        futureMMBTU += dataFuture[key].mmBTU
                        futureCO2 += dataFuture[key].co2
                        futureSOx += dataFuture[key].sox
                        futureNOx += dataFuture[key].nox
                    }

                    expenses = [
                        "CO2 Cost",
                        dollarFormat(monthExpenses),
                        dollarFormat(quarterExpenses),
                        dollarFormat(futureExpenses)
                    ]
                    mmBTU = [
                        "mmBTU",
                        btuFormat(monthMMBTU),
                        btuFormat(quarterMMBTU),
                        btuFormat(futureMMBTU)
                    ]
                    co2 = [
                        "CO2",
                        mtFormat(monthCO2),
                        mtFormat(quarterCO2),
                        mtFormat(futureCO2),
                    ]
                    sox = [
                        "SOx",
                        kgFormat(monthSOx),
                        kgFormat(quarterSOx),
                        kgFormat(futureSOx)
                    ]
                    nox = [
                        "NOx",
                        kgFormat(monthNOx),
                        kgFormat(quarterNOx),
                        kgFormat(futureNOx)
                    ]
                } else {
                    expenses = [
                        "CO2 Cost",
                        dollarFormat(dataMonth[_selectedRoute] ? dataMonth[_selectedRoute].emissionsExpenses : 0),
                        dollarFormat(dataQuarter[_selectedRoute] ? dataQuarter[_selectedRoute].emissionsExpenses : 0),
                        dollarFormat(dataFuture[_selectedRoute] ? dataFuture[_selectedRoute].emissionsExpenses : 0)
                    ]
                    mmBTU = [
                        "mmBTU",
                        btuFormat(dataMonth[_selectedRoute] ? dataMonth[_selectedRoute].mmBTU : 0),
                        btuFormat(dataQuarter[_selectedRoute] ? dataQuarter[_selectedRoute].mmBTU : 0),
                        btuFormat(dataFuture[_selectedRoute] ? dataFuture[_selectedRoute].mmBTU : 0)
                    ]
                    co2 = [
                        "CO2",
                        mtFormat(dataMonth[_selectedRoute] ? dataMonth[_selectedRoute].co2 : 0),
                        mtFormat(dataQuarter[_selectedRoute] ? dataQuarter[_selectedRoute].co2 : 0),
                        mtFormat(dataFuture[_selectedRoute] ? dataFuture[_selectedRoute].co2 : 0)
                    ]
                    sox = [
                        "SOx",
                        kgFormat(dataMonth[_selectedRoute] ? dataMonth[_selectedRoute].sox : 0),
                        kgFormat(dataQuarter[_selectedRoute] ? dataQuarter[_selectedRoute].sox : 0),
                        kgFormat(dataFuture[_selectedRoute] ? dataFuture[_selectedRoute].sox : 0)
                    ]
                    nox = [
                        "NOx",
                        kgFormat(dataMonth[_selectedRoute] ? dataMonth[_selectedRoute].nox : 0),
                        kgFormat(dataQuarter[_selectedRoute] ? dataQuarter[_selectedRoute].nox : 0),
                        kgFormat(dataFuture[_selectedRoute] ? dataFuture[_selectedRoute].nox : 0)
                    ]
                }

                setRows([expenses, co2, sox, nox, mmBTU])
            }
        }
        getData()
    }, [selectedVoyages, _selectedRoute, grouping, _voyages])

    const cols = ["", "Current Month", "Current Quarter", "All Future Periods"]


    return (
        <>
            <FormControl fullWidth style={{ marginTop: 15, marginBottom: 20 }}>
                <InputLabel id="route-select-label">Route</InputLabel>
                <Select
                    labelId="route-select-label"
                    id="route-select"
                    value={_selectedRoute}
                    label="Route"
                    onChange={(ev) => setSelectedRoute(ev.target.value)}
                    size="small"
                >
                    {_options.map((x) => (
                        <MenuItem key={"beum" + x.key} value={x.key}>{x.label}</MenuItem>))}
                </Select>
            </FormControl>
            {_rows.length > 0 &&
                <>
                    <GeneralTable
                        rows={_rows}
                        cols={cols}
                    />
                </>
            }

        </>)
}