import React from "react"
import {useRecoilState, useRecoilValue} from "recoil"
import {selectedEmissions} from "../../store/ui"
import GenericLineChart from "./GenericLineChart"
import {getAllEmissions} from "../../store/api"
import {DateTime, Info} from "luxon"

const colors = ["green"]


export default ({volatility, liquidity, data}) => {
    const Emissions = useRecoilValue(getAllEmissions)
    const [_selectedEmissions] = useRecoilState(selectedEmissions)
    const liquidDict={"low":0.02,"med":0.01,"high":0.005}

    let items = []

    for(const [i, selectedEmission] of _selectedEmissions.entries()) {
        const emissionID = selectedEmission.item
        if (selectedEmission.selected && Emissions) {
            const emissionMean = Emissions["forwardCurveMonthly"]
            const emissionCIUpper = Emissions["forwardCurveMonthlyCiUpper"]
            const emissionCILower = Emissions["forwardCurveMonthlyCiLower"]
            if (!emissionMean || !emissionCIUpper || !emissionCILower) {
                console.error("Missing mean/CI data for emission: " + emissionID)
                continue
            }

            const data_mean = []
            const data_upper_ci = []
            const data_lower_ci = []
            const data_area=[]

            // Build month rows
            const currentMonth = DateTime.now().month - 1
            const monthRows = []
            for (let i = 0; i < 12; i++) {
                monthRows.push(Info.months("short")[(currentMonth + i) % 12])
            }

            try {
                data_mean.push({x: monthRows[0], y: emissionMean[0]["price"]})
                data_upper_ci.push({x: monthRows[0], y: emissionMean[0]["price"]})
                data_lower_ci.push({x: monthRows[0], y: emissionMean[0]["price"]})
                data_area.push({x: monthRows[0],y:data_mean[0]["y"]+liquidDict[liquidity]*data_mean[0]["y"],y0:data_mean[0]["y"]-liquidDict[liquidity]*data_mean[0]["y"]})
                // Build data entries for charts
                for (let monthIdx = 1; monthIdx < 12; monthIdx += 1) {
                    data_mean.push({x: monthRows[monthIdx], y: emissionMean[monthIdx]["price"]})
                    data_upper_ci.push({x: monthRows[monthIdx], y: emissionCIUpper[monthIdx]["price"]})
                    data_lower_ci.push({x: monthRows[monthIdx], y: emissionCILower[monthIdx]["price"]})
                    data_area.push({x: monthRows[monthIdx],y:data_mean[monthIdx]["y"]+liquidDict[liquidity]*data_mean[monthIdx]["y"],y0:data_mean[monthIdx]["y"]-liquidDict[liquidity]*data_mean[monthIdx]["y"]})

                }

                items.push({title:emissionID, color:colors[i % colors.length], mean: data_mean, upper: data_upper_ci, lower: data_lower_ci,area:data_area})
            } catch (error) {
                console.error("Incorrect format mean/CI data for emission: " + emissionID

                )
                console.error(error)
            }
        }
    }



    return (
        <>
        <GenericLineChart
            type ={"emissions"} title="Term Structure & Volatility of Selected Emissions Prices ($/mt)" items={items}/>
        <div className="grid grid-cols-4">
        {data.map((series, i) => (
            <EmissionsLegend
                providerData={series.providerData}
                color={colors[0]}
                key={0}
            />
        ))}
        </div>
        </>
    )
}

export function EmissionsLegend({providerData, color}){
    const meanLineStyle = {
        // width: '100px', 
        height: '3px',
        backgroundColor: `${color}`,
        marginTop: '10px',
    }

    let spotDesc = `SPGCI (${providerData.spotSymbol})`
    let forwardDesc = `Extrapolated from SPGCI (${providerData.forwardCurveCode})`


    return (
        <div className="flex flex-col p-2">
            <div className="w-full" style={meanLineStyle}></div>
            <p className="text-xs">
                <span className="font-semibold">EEX EUA Eur/MT</span>
            </p>
            <p className="text-xs">
                <span className="font-semibold">Spot</span>: {spotDesc}
            </p>
            <p className="text-xs">
                <span className="font-semibold">Curve</span>: {forwardDesc}
            </p>
        </div>
    )
}
