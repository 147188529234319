import React, { useState, useEffect } from "react";
import FlexibleChart from "../../../components/Charts/FlexibleChart";
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  DiscreteColorLegend,
} from "react-vis";
import { DefaultAxisStyle } from "../../../utils/Constants";
import { getVoyageFinancials } from "./bunkerModalComponents/calculations/calcVoyageFinancialsPeriod";
import { useRecoilValue } from "recoil"

import {userSettings } from "../../../store/ui"


const metrics = {
  revenueCost: "Revenues vs. Cost",
  costBreakdown: "Cost Breakdown",
  tce: "Initial vs. Current TCE",
};

const chartUnits = {
  revenueCost: "$",
  costBreakdown: "$",
  tce: "$TCE",
};

const legendRev = [
  { title: "Revenue", color: "green" },
  { title: "Costs", color: "red" },
];

const legendCostBreakDown = [
  { title: "Ports, Canals, Emissions", color: "gray" },
  { title: "ROB Bunkers", color: "blue" },
  { title: "Purchased Bunkers", color: "#ebb434" },
  { title: "Best Case Bunkers", color: "green" },
  { title: "Worst Case Bunkers", color: "red" },
];

const legendTCE = [
  { title: "Initial TCE", color: "gray" },
  { title: "Current TCE", color: "blue" },
];

function makeSeries(data, groupingKey, dataPoint) {

  const record = {}
  const entryKeys = []
  const dataKeys = Object.keys(data)

  for (const item of dataKeys){

    const voy = data[item]
    const key = voy[groupingKey]

    if(record[key]){
      record[key].y += voy[dataPoint]
    } else{
      
      record[key] = {
        x: key,
        y: voy[dataPoint]
      }
      entryKeys.push(key)
    }
  }

  const series = Object.values(record)
    return series
}


function makeRevCosts(data, groupingKey, dataPoint) {
  const record = {}
  const entryKeys = []
  const dataKeys = Object.keys(data)

  for (const item of dataKeys) {
    const voyage = data[item]
    const key = voyage[groupingKey]

    let value = 0

    if (dataPoint==="revenue"){
      value = voyage.revenue + 0.0005 * (entryKeys.length + 1)
    } else{
      value = voyage.totalCost+ 0.0001 *(entryKeys.length + 1)
    }

    if (record[key]) {
      record[key].y += value
    } else {
      record[key] = {
        x: key,
        y: value,
      }
      entryKeys.push(key)
    }
  }

  const series = Object.values(record)
  return series
}

function makeTCE(data, groupingKey, dataPoint) {
  const record = {}
  const entryKeys = []
  const dataKeys = Object.keys(data)

  for (const item of dataKeys) {
    const voyage = data[item]
    const key = voyage[groupingKey]

    let expenses


    if (dataPoint==="current"){
      expenses = voyage.totalCost * (entryKeys.length + 1)
    } else{
      expenses = voyage.initialExpenses + 0.0005 * (entryKeys.length + 1)
    }

    if (record[key]) {
      record[key].expenses += expenses
      record[key].revenue += voyage.revenue
      record[key].duration += voyage.duration
    } else {
      record[key] = {
        x: key,
        y: 0,
        duration:voyage.duration,
        expenses:expenses,
        revenue: voyage.revenue
      }
      entryKeys.push(key)
    }
  }

  const series = Object.values(record)

  // average tce
  for (const entry of series){
    entry.y = (entry.revenue - entry.expenses)/entry.duration
  }

  return series
}


export default function VoyageTableChart({ selectedVoyages, grouping }) {
  const [metricSelect, setMetricSelect] = useState("revenueCost");
  const [_voyageFinancialsData, setVoyageFinancialsData] = useState({});
  const _userSettings = useRecoilValue(userSettings)
  

  useEffect(() => {

    const getData = () => {
      if (selectedVoyages.length > 0) {
        const data = getVoyageFinancials(selectedVoyages)
        setVoyageFinancialsData(data);
      }
    };
    getData()
  }, [metricSelect, selectedVoyages, grouping]);


  return (
    <>

      <select
      className="p-1 border border-1 rounded-md"
        onChange={(ev) => setMetricSelect(ev.target.value)}
      >
        {Object.keys(metrics).map((x) => (
          <option key={x} value={x}>
            {metrics[x]}
          </option>
        ))}
      </select>

      <div className="mt-2">
        {selectedVoyages.length > 0 &&
        Object.keys(_voyageFinancialsData).length > 0 ? (
          renderChart(_voyageFinancialsData, metricSelect, grouping, _userSettings)
        ) : (
          <p>No data available for selected voyages.</p>
        )}
      </div>
    </>
  );
}

function renderChart(_voyageFinancialsData, metricSelect, grouping, userSettings) {

  const groupingKey = grouping==="byVessel" ? "vessel" : "displayName"
  

  switch (metricSelect) {
    case "costBreakdown":
      return (
        <>
          <FlexibleChart
            height={450}
            margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
            xType="ordinal"
            stackBy="y"
          >
            <XAxis style={DefaultAxisStyle}  tickLabelAngle={-90}/>
            <YAxis style={DefaultAxisStyle} title={chartUnits[metricSelect]} />
            <HorizontalGridLines style={DefaultAxisStyle} />
            <VerticalGridLines style={DefaultAxisStyle} />
            <VerticalBarSeries
              data={makeSeries(_voyageFinancialsData, groupingKey, "otherCosts")}
              color="gray"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeSeries(_voyageFinancialsData, groupingKey, "robSum")}
              color="blue"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeSeries(_voyageFinancialsData, groupingKey, "bestCaseSum")}
              color="green"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeSeries(_voyageFinancialsData, groupingKey, "meanCostStub")}
              color="#ebb434"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeSeries(_voyageFinancialsData, groupingKey, "worstCaseStub")}
              color="red"
              barWidth={0.3}
            />
          </FlexibleChart>
          <DiscreteColorLegend
            items={legendCostBreakDown}
            style={DefaultAxisStyle}
          />
        </>
      );
    case "revenueCost":
      return (
        <>
          <FlexibleChart
            height={450}
            margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
            stackBy="x"
            xType="ordinal"
            visible={true}
          >
            <XAxis style={DefaultAxisStyle} tickLabelAngle={-90} />
            <YAxis style={DefaultAxisStyle} title={chartUnits[metricSelect]} />
            <HorizontalGridLines style={DefaultAxisStyle} />
            <VerticalGridLines style={DefaultAxisStyle} />
            <VerticalBarSeries
              data={makeRevCosts(_voyageFinancialsData, groupingKey, "revenue")}
              color="green"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeRevCosts(_voyageFinancialsData, groupingKey, "costs")}
              color="red"
              barWidth={0.3}
            />
          </FlexibleChart>
          <DiscreteColorLegend items={legendRev} style={DefaultAxisStyle} />
        </>
      );
    default:
      return (
        <>
          <FlexibleChart
            height={450}
            margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
            xType="ordinal"
            stackBy="x"
          >
            <XAxis style={DefaultAxisStyle}  tickLabelAngle={-90} />
            <YAxis style={DefaultAxisStyle} title={chartUnits[metricSelect]} />
            <HorizontalGridLines style={DefaultAxisStyle} />
            <VerticalGridLines style={DefaultAxisStyle} />
            <VerticalBarSeries
              data={makeTCE(_voyageFinancialsData, groupingKey, "initial")}
              color="gray"
              barWidth={0.3}
            />
            <VerticalBarSeries
              data={makeTCE(_voyageFinancialsData, groupingKey, "current")}
              color="#5C93C0"
              barWidth={0.3}
            />
          </FlexibleChart>
          <DiscreteColorLegend items={legendTCE} style={DefaultAxisStyle} />
        </>
      );
  }
}
