import React, {useState} from "react";
import AddStemModal from "./TanksAddStem";
import { recostVoyages } from "./VoyageEngineLogic";
import { PostStem } from "../../../store/api/services-api";

export default function AddStemButton({vessel}) {
    const [_modalOpen, setModalOpen] = useState(false);
    const [_stemData, setStemData] = useState({})
    const handleAddClick = () => {
        setModalOpen(true)
    }

    const closeModal = async (data) => {
        await PostStem(vessel.shipName, vessel.shipType, vessel.id, data, false)
        await recostVoyages(vessel.shipName, vessel.shipType, vessel.id)
        setModalOpen(false)
    }

    return (
        <>
        <button className="bg-white border mb-2 border-ao-blue text-ao-blue rounded-lg hover:bg-ao-blue hover:text-white"
        onClick={handleAddClick}
        >
            Add Stem
        </button>
        <AddStemModal
            vessel= {vessel}
            modalOpen={_modalOpen}
            closeModal={closeModal}
            setStemData={setStemData}
            />
        </>
    )
}