import { refFirstLastDates, voyageOverlapsWithDateRange, daysInRange } from "../../../voyageutils"


export function getEmissionsDataForSelectedPeriod (selectedPeriod, voyageData, grouping) {

    const currentDate = new Date()
    const [rangeOpen, rangeClose] = refFirstLastDates(currentDate, selectedPeriod)
    let keys = new Set([]);

    const emissionsRecord = {}

    for (const voyage of voyageData) {
        
        const isVoyageInDateRange = voyageOverlapsWithDateRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
    
        if (isVoyageInDateRange) {
            const key = grouping === "byVessel" ? voyage.vessel.shipName : "ID: " + voyage.id + " - " + voyage.routeId + " - " + voyage.vessel.shipName
            keys.add(key)
            const voyageDuration = voyage.voyageLength
            const daysInPeriod = daysInRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
            const loadFactorRaw = daysInPeriod/voyageDuration
            const loadFactor = loadFactorRaw > 1 ? 1 : loadFactorRaw

            if (!emissionsRecord[key]) {
                emissionsRecord[key] = {
                    emissionsExpenses: 0,
                    co2: 0,
                    sox: 0,
                    nox: 0,
                    mmBTU: 0
                }
            }

            emissionsRecord[key].emissionsExpenses += voyage.emissionsCost * loadFactor
            emissionsRecord[key].co2 += voyage.carbonConsumption * loadFactor
            emissionsRecord[key].sox += voyage.soxConsumption * loadFactor
            emissionsRecord[key].nox += voyage.noxConsumption * loadFactor
            emissionsRecord[key].mmBTU += voyage.mmbtuConsumption * loadFactor
        }
    }
    const keysArray = [...keys]
    return {emissionsRecord,
            keys: keysArray}
}

export function getEmissionsChartDataForSelectedPeriod (selectedPeriod, voyageData, grouping) {

    const currentDate = new Date()
    const [rangeOpen, rangeClose] = refFirstLastDates(currentDate, selectedPeriod)
    const emissionsByRoute = {}
    for (const voyage of voyageData) {
        const isVoyageInDateRange = voyageOverlapsWithDateRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
    
        if (isVoyageInDateRange) {
            const key = grouping === "byVessel" ? voyage.vessel.shipName : "ID: " + voyage.id + " - " + voyage.routeShortName
            const daysInPeriod = daysInRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
            const loadFactor = daysInPeriod/voyage.voyageLength

            if (!emissionsByRoute[key]) {
                emissionsByRoute[key] = {
                    emissionsExpenses: 0,
                    co2: 0,
                    sox: 0,
                    nox: 0,
                    mmBTU: 0
                }
            }

            emissionsByRoute[key].emissionsExpenses += voyage.emissionsCost * loadFactor
            emissionsByRoute[key].co2 +=  voyage.carbonConsumption * loadFactor
            emissionsByRoute[key].sox +=  voyage.soxConsumption  * loadFactor
            emissionsByRoute[key].nox +=  voyage.noxConsumption * loadFactor
            emissionsByRoute[key].mmBTU += voyage.mmbtuConsumption * loadFactor
        }
    }

    return emissionsByRoute
}