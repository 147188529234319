import React, { useState, useEffect } from "react";
import GeneralTable from "../../../components/GeneralTable";
import {
  dollarFormat
} from "../../../utils/Helpers";
import { getVoyageFinancialsForSelectedPeriod } from "./bunkerModalComponents/calculations/calcVoyageFinancialsPeriod";
import SPGCIDisclaimer from "../../../components/SPGCIDisclaimer";
import { useRecoilValue } from "recoil";
import { userSettings } from "../../../store/ui";
import { DATA_SOURCE } from "../../../utils/Constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ selectedVoyages, grouping }) => {
  const [_selectedOption, setSelectedOption] = useState("all");
  const [_rows, setRows] = useState([]);
  const [_options, setOptions] = useState([])


  useEffect(() => {
    const getData = async () => {

      if (selectedVoyages.length > 0) {

        const dataMonthAll = await getVoyageFinancialsForSelectedPeriod(
          "current",
          selectedVoyages,
          grouping
        );
        const dataMonth = dataMonthAll.financialsByRoute;
        const dataQuarterAll = await getVoyageFinancialsForSelectedPeriod(
          "quarter",
          selectedVoyages,
          grouping
        );
        const dataQuarter = dataQuarterAll.financialsByRoute;
        const dataFutureAll = await getVoyageFinancialsForSelectedPeriod(
          "future",
          selectedVoyages,
          grouping
        );

        const dataFuture = dataFutureAll.financialsByRoute
        const keys = dataFutureAll.keys
        let revenue, ports, emissions, rob, purchased, expectedBunker, expenses, tce, tceCurrent;

        const optionsArray = [
          { key: "all", label: "All" },
          ...keys.map((x) => {
            return { key: x, label: x };
          }),
        ]
        setOptions(optionsArray)

        if (_selectedOption === "all" || !keys.includes(_selectedOption)) {
          let monthRevenues = 0
          let monthPorts = 0
          let monthEmissions = 0
          let monthROB = 0
          let monthPurchasedSum = 0
          let monthMeanSum = 0
          let monthInitialTotalExpenses = 0
          let monthCurrentTotalExpenses = 0
          let monthTCEInitial = 0
          let monthTCECurrent = 0
          let monthDays = 0

          let quarterRevenues = 0
          let quarterPorts = 0
          let quarterEmissions = 0
          let quarterROB = 0
          let quarterPurchasedSum = 0
          let quarterMeanSum = 0
          let quarterInitialTotalExpenses = 0
          let quarterCurrentTotalExpenses = 0
          let quarterTCEInitial = 0
          let quarterTCECurrent = 0
          let quarterDays = 0

          let futureRevenues = 0
          let futurePorts = 0
          let futureEmissions = 0
          let futureROB = 0
          let futurePurchasedSum = 0
          let futureMeanSum = 0
          let futureInitialTotalExpenses = 0
          let futureCurrentTotalExpenses = 0
          let futureTCEInitial = 0
          let futureTCECurrent = 0
          let futureDays = 0

          for (const key in dataMonth) {
            monthRevenues += dataMonth[key].totalRevenues
            monthPorts += dataMonth[key].portExpenses
            monthEmissions += dataMonth[key].emissionsExpenses
            monthROB += dataMonth[key].robSum
            monthPurchasedSum += dataMonth[key].purchasedSum
            monthMeanSum += dataMonth[key].meanCostSum
            monthDays += dataMonth[key].days
            monthInitialTotalExpenses += dataMonth[key].initialTotalExpenses
            monthCurrentTotalExpenses += dataMonth[key].currentTotalExpenses
          }
          monthTCEInitial = (monthRevenues - monthInitialTotalExpenses) / monthDays
          monthTCECurrent = (monthRevenues - monthCurrentTotalExpenses) / monthDays

          for (const key in dataQuarter) {
            quarterRevenues += dataQuarter[key].totalRevenues
            quarterPorts += dataQuarter[key].portExpenses
            quarterEmissions += dataQuarter[key].emissionsExpenses
            quarterROB += dataQuarter[key].robSum
            quarterPurchasedSum += dataQuarter[key].purchasedSum
            quarterMeanSum += dataQuarter[key].meanCostSum
            quarterDays += dataQuarter[key].days
            quarterInitialTotalExpenses += dataQuarter[key].initialTotalExpenses
            quarterCurrentTotalExpenses += dataQuarter[key].currentTotalExpenses
          }
          quarterTCEInitial = (quarterRevenues - quarterInitialTotalExpenses) / quarterDays
          quarterTCECurrent = (quarterRevenues - quarterCurrentTotalExpenses) / quarterDays

          for (const key in dataFuture) {
            futureRevenues += dataFuture[key].totalRevenues
            futurePorts += dataFuture[key].portExpenses
            futureEmissions += dataFuture[key].emissionsExpenses
            futureROB += dataFuture[key].robSum
            futurePurchasedSum += dataFuture[key].purchasedSum
            futureMeanSum += dataFuture[key].meanCostSum
            futureDays += dataFuture[key].days
            futureInitialTotalExpenses += dataFuture[key].initialTotalExpenses
            futureCurrentTotalExpenses += dataFuture[key].currentTotalExpenses
          }
          futureTCEInitial = (futureRevenues - futureInitialTotalExpenses) / futureDays
          futureTCECurrent = (futureRevenues - futureCurrentTotalExpenses) / futureDays

          revenue = [
            "Revenue",
            dollarFormat(monthRevenues),
            dollarFormat(quarterRevenues),
            dollarFormat(futureRevenues)
          ];
          ports = [
            "  - Ports and Canals",
            dollarFormat(monthPorts),
            dollarFormat(quarterPorts),
            dollarFormat(futurePorts)
          ];
          emissions = [
            "  - Emissions",
            dollarFormat(monthEmissions),
            dollarFormat(quarterEmissions),
            dollarFormat(futureEmissions)
          ];
          rob = [
            "  - ROB",
            dollarFormat(monthROB),
            dollarFormat(quarterROB),
            dollarFormat(futureROB)
          ];
          purchased = [
            "  - Purchased Bunkers",
            dollarFormat(monthPurchasedSum),
            dollarFormat(quarterPurchasedSum),
            dollarFormat(futurePurchasedSum)
          ];

          expectedBunker = [
            "  - Required Bunkers",
            dollarFormat(monthMeanSum),
            dollarFormat(quarterMeanSum),
            dollarFormat(futureMeanSum)
          ];

          expenses = [
            "Total Expenses",
            dollarFormat(monthCurrentTotalExpenses),
            dollarFormat(quarterCurrentTotalExpenses),
            dollarFormat(futureCurrentTotalExpenses)
          ];

          tce = [
            "Consolidated Initial TCE",
            dollarFormat(monthTCEInitial),
            dollarFormat(quarterTCEInitial),
            dollarFormat(futureTCEInitial)
          ];
          tceCurrent = [
            "Consolidated Current TCE",
            dollarFormat(monthTCECurrent),
            dollarFormat(quarterTCECurrent),
            dollarFormat(futureTCECurrent),
          ];
        } else {
          revenue = [
            "Revenue",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].totalRevenues
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].totalRevenues
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].totalRevenues
                : 0
            )
          ];
          ports = [
            "  - Ports and Canals",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].portExpenses
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].portExpenses
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].portExpenses
                : 0
            )
          ];
          emissions = [
            "  - Emissions",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].emissionsExpenses
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].emissionsExpenses
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].emissionsExpenses
                : 0
            )
          ];
          expenses = [
            "Total Expenses",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].currentTotalExpenses
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].currentTotalExpenses
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].currentTotalExpenses
                : 0
            )
          ];
          tce = [
            " Consolidated Initial TCE",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].tceInitial
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].tceInitial
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].tceInitial
                : 0
            )
          ];
          tceCurrent = [
            " Consolidated Current TCE",
            dollarFormat(
              dataMonth[_selectedOption]
                ? dataMonth[_selectedOption].tceCurrent
                : 0
            ),
            dollarFormat(
              dataQuarter[_selectedOption]
                ? dataQuarter[_selectedOption].tceCurrent
                : 0
            ),
            dollarFormat(
              dataFuture[_selectedOption]
                ? dataFuture[_selectedOption].tceCurrent
                : 0
            )
          ];
        }
        setRows([revenue, ports, emissions, expenses, tce, tceCurrent]);
      }
    };
    getData().catch(console.error);
  }, [selectedVoyages, _selectedOption, grouping]);

  const cols = ["", "Current Month", "Current Quarter", "All Future Periods"];


  return (
    <>
      <select
        className="p-1 border border-1 rounded-md"
        onChange={(ev) => setSelectedOption(ev.target.value)}
      >
        {_options.map((option) => (
          <option key={"beum" + option.key} value={option.key}>
            {option.label}
          </option>
        ))}
      </select>

      {_rows.length > 0 && <>
        <GeneralTable rows={_rows} cols={cols} />
      </>}
    </>
  );
};
