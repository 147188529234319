import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { availableTCEsFiltered } from "../../store/ui";
import { getRouteParamsIn } from "../../store/api"
import FreightRateSpotChart from "../../components/Charts/FreightRateSpotChart";
import { formatDateWithTime, getDashForZero } from "../../utils/Helpers";
import { DataAttribution } from "./DataAttribution";
import { getRecoilPromise } from "recoil-nexus";
import { getRoutePrices } from "../../store/api";
import { Cell, buildDescription } from "./PriceTable";
import { userSettings } from "../../store/ui";

const BunkerPricingSpotTable = () => {
  const [rowData, setRowData] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [filteredPorts, setFilteredPorts] = useState(0);
  const availableRoutes = useRecoilValue(availableTCEsFiltered)
  const Routes = useRecoilValue(getRouteParamsIn)
  const _userSettings = useRecoilValue(userSettings);
  const [_vol, setVol] = useState(_userSettings.volatility);

  const getTableNameForRoutes = (routeId, provider) => {
    if (routeId in Routes && provider == "SPGCI") {
      return `${Routes[routeId]["description"]} (${(Routes[routeId]?.symbolMT)})`
    } else if (routeId in Routes){
      return `${Routes[routeId]["description"]}`
    }
    return routeId
  }

  const getAxisNameForRoute = (routeId, provider) => {
    if (routeId in Routes && provider == "SPGCI") {
      return `${Routes[routeId]["longName"]}`
    } else if (routeId in Routes){
      return `${Routes[routeId]["longName"]}`
    }
    return routeId
  }

  useEffect(() => {
    (async function(){
      const routesLen = availableRoutes.allRoutes.length
      setFilteredPorts(routesLen)
      const start = pagination * 10
      const end = (start + 10) > routesLen ? routesLen : start + 10
      const promises = availableRoutes.allRoutes.slice(start, end).map(async route => {
        const routeId = route.routeID
        const apiData = await getRecoilPromise(getRoutePrices(routeId, _vol))
        if (!apiData.constructedSpot) {
          const providerData = {
            forwardCurveCode:"",
            forwardCurveCurrency:"",
            forwardCurveName:"",
            forwardMdc:"",
            forwardUom:"",
            spotCurrency: "",
            spotDescr:apiData.providerData.description,
            spotMdc:apiData.providerData.mdc,
            spotSymbol:apiData.providerData.symbolMT,
            spotUom:""
          }
          const isBunkerEx = apiData.dataProvider === "SPGCI" ? false : true
          return {
            route: getTableNameForRoutes(routeId, apiData.dataProvider),
            routeAxis: getAxisNameForRoute(routeId, apiData.dataProvider),
            price: apiData.spotPrice,
            constructedSpot: apiData.constructedSpot,
            constructedCurve: false,
            title: buildDescription(true, apiData.constructedSpot, apiData.dataProvider, providerData, formatDateWithTime(apiData.priceDate)),
            isBunkerEx,
            provider: apiData.dataProvider
          }
        }
      })
      const priceArray = await Promise.all(promises)
      setRowData(priceArray.filter(Boolean))
    })();
  }, [availableRoutes, _vol, pagination]);


  const handlePagination = (action) => {
    if (action === "increase") {
      setPagination(pagination + 1);
    } else if (action === "decrease" && pagination != 0) {
      setPagination(pagination - 1);
    }
  };
  return (
    <div className="flex flex-col">
      <div className="flex flex-row w-full">
        <div className="px-4 sm:px-6 lg:px-8 w-1/2">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="flex flex-col w-full">
                    <TitleRow/>
                    {rowData.map((row) => (
                    <SpotRow row={row} key={row.description}/>
                  ))}
                </div>
                <div
                  className="flex items-center mt-2 w-full justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{pagination === 0 ? 1 : pagination * 10}</span> to{" "}
                      <span className="font-medium">{pagination * 10 + 10 > filteredPorts ? filteredPorts : pagination === 0 ? 10 : pagination * 10 + 10}</span> of{" "}
                      <span className="font-medium">{filteredPorts}</span> results
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <a
                      onClick={() => handlePagination("decrease")}
                      href="#"
                      className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                      Previous
                    </a>
                    <a
                      onClick={() => handlePagination("increase")}
                      href="#"
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    >
                      Next
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <DataAttribution data={rowData} isTCE={false} />
          </div>
        </div>
        <div className="w-1/2">
          <FreightRateSpotChart data={rowData} />
        </div>
      </div>
    </div>
  );
};

export default BunkerPricingSpotTable;

function SpotRow({row}){

  return(
    <div className="grid grid-cols-7 border-t w-full">
      <div className="col-span-6 items-center text-ao-figma-text-gray">
        <span>{row.route}</span>
      </div>
      <div className="col-span-1 items-center">
        <Cell
          value={getDashForZero(row.price)}
          isConstructed={row.constructedSpot}
          title={row.title}
          isSpgci={!row.isBunkerEx}
          topBorder={false}
          />
      </div>
    </div>
  )
}

function TitleRow({}){

  return(
    <div className="grid grid-cols-7 border-t w-full">
      <div className="col-span-6 items-center text-ao-figma-text-gray">
        <p className="font-bold py-2">ROUTE</p>
      </div>
      <div className="col-span-1 items-center">
        <p className="font-bold p-2">RATE</p>
      </div>
    </div>
  )
}

