import React, { useState, useEffect } from "react";
import { getRecoilPromise } from "recoil-nexus";
import PricingsChart from "../../components/Charts/PricingsChart";
import RouteFilter from "../../components/RouteFilter/RouteFilter";
import { useRecoilValue } from "recoil";
import { userSettings } from "../../store/ui";
import FreightPricingSpotTable from "../PricingEngine/FreightPricingSpotTable";
import { selectedRoutes } from "../../store/ui";
import { getRoutePrices } from "../../store/api";
import { truncateForwardCurve } from "../PricingEngine/routeHelper";
import { RoutePriceTable } from "../PricingEngine/RoutePriceTable";
import { formatDateWithTime } from "../../utils/Helpers";
import { replaceItemAtIndex } from "../../utils/Helpers";
import { DataAttribution } from "../PricingEngine/DataAttribution";
import { spgciStatus, balticStatus } from "../../store/integrations";
import { ProviderStatus } from "../../utils/Constants";
import { NoProviders } from "../PricingEngine/NoProviders";

// eslint-disable-next-line import/no-anonymous-default-export
const PEFreightRates = () => {
  const _userSettings = useRecoilValue(userSettings);
  const [_vol, setVol] = useState(_userSettings.volatility);
  const [_liq, setLiq] = useState(_userSettings.liquidity);
  const [isChecked, setIsChecked] = useState(true);
  const _selectedRoutes = useRecoilValue(selectedRoutes);
  const _spgciStatus = useRecoilValue(spgciStatus)
  const _balticStatus = useRecoilValue(balticStatus)
  const [data, setData] = useState([
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
    unavailablePriceData("loading"),
  ]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const spgciForwardRates = {TDUCF00: ["TDUCM01", "TDUCM02", "TDUCM03"]}

  useEffect(() => {
    (async function () {
      _selectedRoutes.forEach(async (route, index) => {
        const apiData = await getRecoilPromise(
          getRoutePrices(route.item, _vol)
        )
        const newData = apiData
          ? {
              spotPrice: apiData.spotPrice,
              spotDate: formatDateWithTime(apiData.priceDate),
              constructedSpot: apiData.constructedSpot,
              forwardCurveData: truncateForwardCurve(apiData.forwardCurve),
              constructedCurve: apiData.constructedCurve,
              isBunkerEx: (apiData.dataProvider !== "SPGCI"),
              providerData: {
                forwardCurveCode:"",
                forwardCurveCurrency:"",
                forwardCurveName:"",
                forwardMdc:"",
                forwardUom:"",
                spotCurrency: "",
                spotDescr:apiData.providerData.description,
                spotMdc:apiData.providerData.mdc,
                spotSymbol:apiData.providerData.symbolMT,
                spotUom:""
              },
              provider: apiData.dataProvider,
              status: 'available'
            }
          : unavailablePriceData("unavailable");
        
          if(newData.providerData.hasOwnProperty("spotSymbol")){
            if(spgciForwardRates.hasOwnProperty(newData.providerData.spotSymbol))
              newData.providerData["forwardSymbols"] = spgciForwardRates[newData.providerData.spotSymbol]
          }
        
        setData((d) => replaceItemAtIndex(d, index, newData));
      });
    })();
  }, [_selectedRoutes]);

  const spgciActive = _spgciStatus === ProviderStatus.ACTIVE
  const balticActive = _balticStatus === ProviderStatus.ACTIVE

  if(!spgciActive && !balticActive){
    return <NoProviders
              spgciFailed={!spgciActive}
              balticFailed={!balticActive}
              page={"freight rate data"}
            />
  }


  return (
    <div className="flex flex-col">
    <div className="flex flex-row">
      <div
        className={`h-full flex flex-col gap-y-2 ${
          !isChecked && "border-r-2 border-gray-300"
        }`}
      >
        <div className="flex flex-row gap-x-2 border-b-1 border-gray-300 align-middle">
          <label className="flex flex-row gap-x-2 cursor-pointer select-none items-center font-semibold">
            <div className="relative">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="sr-only"
              />
              <div className={`block h-8 w-14 rounded-full bg-gray-200`}></div>
              <div
                className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-ao-blue transition ${
                  isChecked ? "translate-x-full" : ""
                }`}
              ></div>
            </div>
            {isChecked ? "Spot View" : "Forwards View"}
          </label>

          <RouteFilter
            isSpotView={isChecked}
            tce={false}
          />
          <button
            type="button"
            className="py-1 px-4 rounded-md border font-semibold border-ao-blue hover:bg-ao-blue hover:text-white"
          >
            Set Alerts
          </button>
        </div>
        {isChecked ? (
          <FreightPricingSpotTable />
        ) : (
          <RoutePriceTable data={data} />
        )}
      </div>
      {!isChecked && (
        <div className="h-full flex-1 w-1/2 pl-5">
          <PricingsChart volatility={_vol} liquidity={_liq} data={data}/>
        </div>
      )}
    </div>
    </div>
  );
};

export default PEFreightRates;

function unavailablePriceData(status) {
  return {
    spotPrice: null,
    spotDate: "N/A",
    constructedSpot: false,
    forwardCurveData: Array.from({ length: 12 }, (_) => ({})),
    constructedCurve: false,
    providerData: {},
    status,
    isBunkerEx: true,
  };
}
