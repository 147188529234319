import React from 'react'
import { Disclosure } from '@headlessui/react'
import {
    ChevronRightIcon
} from '@heroicons/react/24/outline'
import { navRoutes, userRoutes } from '../../routes'
import { Link } from 'react-router-dom/cjs/react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SideNav() {
    return (
        <div className="fixed inset-y-0 z-50 flex w-72 flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-ao-gray px-6">
                <div className="flex flex-col h-18 shrink-0 items-center mt-8 mb-6">
                    <img
                        className="h-auto w-auto"
                        src="/Temp_SideNav_Logo.png"
                        alt="Alpha Ocean"
                    />
                    {/* <span className='text-sm text-gray-500 2xl:-mt-5'>
                        Powered by Michael's Brain
                    </span> */}
                </div>
                <nav className="flex flex-1 flex-col text-left myCustomClass">
                    <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul className="flex flex-1 flex-col gap-y-7">
                                <li>
                                    <ul className="-mx-2 space-y-2">
                                        {navRoutes.map((route) => (
                                            <li key={route.name}>
                                                {!route.subpaths || route?.subpaths.length < 1 ? (
                                                    <Link
                                                        to={route.path}
                                                        className={classNames(
                                                            route.current
                                                                ? 'bg-gray-50 text-ao-blue text-left'
                                                                : 'text-gray-700 hover:text-ao-blue hover:bg-gray-50',
                                                            'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'
                                                        )}
                                                    >
                                                        {route.name}
                                                    </Link>
                                                ) : (
                                                    <Disclosure as="div">
                                                        {({ open }) => (
                                                            <>
                                                                <Disclosure.Button
                                                                    className={classNames(
                                                                        route.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 leading-6 font-semibold text-gray-700 hover:text-ao-blue'
                                                                    )}
                                                                >
                                                                    {route.name}
                                                                    <ChevronRightIcon
                                                                        className={classNames(
                                                                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                            'h-3 w-3 shrink-0 text-black ml-auto'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                </Disclosure.Button>
                                                                <Disclosure.Panel as="ul" className="mt-1 px-2 w-full flex flex-col text-left">
                                                                    {route.subpaths.map((subpath) => (
                                                                        <li key={subpath.name}>
                                                                                <Link to={subpath.path}
                                                                                    className={classNames(
                                                                                        subpath.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                                        'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 hover:text-ao-blue'
                                                                                    )} >{subpath.name}</Link>
                                                                        </li>
                                                                    ))}
                                                                </Disclosure.Panel>
                                                            </>
                                                        )}
                                                    </Disclosure>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="-mx-2 mt-auto">
                            <ul className="flex items-center gap-x-3">

                                <li>
                                    {userRoutes.map((userRoute) => (
                                        <Link
                                            key={userRoute.name}
                                            to={userRoute.path}
                                            className="flex items-center w-full text-left rounded-md p-2 leading-6 font-semibold text-gray-700 hover:text-ao-blue"
                                        >
                                            {userRoute.icon && <userRoute.icon className="w-6 h-6 mr-2" />}
                                            <span className="sr-only">{userRoute.name}</span>
                                            <span aria-hidden="true">{userRoute.name}</span>
                                        </Link>
                                    ))}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}



