import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CopyAllIcon from "@mui/icons-material/CopyAll"
import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton } from "@mui/material"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid"
import React, { useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { layoutGroups, selectedLayoutGroup, useLayoutGroupMutations } from "../../store/ui"
import { DefaultTheme as theme } from "../../theme"
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog"
import { LayoutGroupProperties } from "./LayoutGroupProperties"


// Custom styling to hide the select all/none checkbox in header of datagrid
const useStyles = makeStyles(() => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}))

export function LayoutGroupManageDialog({ onClose }) {
    const [_selectedLayoutGroup, setSelectedLayoutGroup] = useRecoilState(selectedLayoutGroup)
    const _layoutGroups = useRecoilValue(layoutGroups)
    const { saveLayoutGroup, deleteLayoutGroup } = useLayoutGroupMutations()
    const [_edited, setEdited] = useState(_selectedLayoutGroup)
    const [_toDelete, setToDelete] = useState(null)

    const classes = useStyles()
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            hideable: false,
            filterable: false,
            renderCell: (params) => (<>
                <Grid container>

                    <Grid item xs={6}>
                        <IconButton color="primary" component="label"
                            title="Duplicate"
                            onClick={(e) => {
                                e.stopPropagation()
                                duplicate(params.row)
                            }}>
                            <CopyAllIcon />
                        </IconButton>
                    </Grid>
                    {_layoutGroups.length > 1 &&
                        <Grid item xs={6}>
                            <IconButton color="primary" component="label"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setToDelete(params.row)
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </>)
        }
    ]

    return <Dialog open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
    >
        <DialogTitle>Manage layout groups</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Manage layoout groups here.
            </DialogContentText>
            <Grid container>
                <Grid item xs={6}>
                    <ThemeProvider theme={theme}>
                        <div style={{ height: 550, width: "100%", paddingTop: 20, paddingRight: 10 }}>
                            <DataGrid
                                density="compact"
                                className={classes.root}
                                rows={_layoutGroups}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                selectionModel={_edited && _edited.id != null ? [_edited.id] : []}
                                onSelectionModelChange={handleSelection}
                            />
                        </div>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ paddingTop: 20, paddingLeft: 10 }}>
                        <LayoutGroupProperties layoutGroup={_edited} onChange={setEdited}></LayoutGroupProperties>
                    </div>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                onClose()
            }}>Cancel</Button>
            <Button onClick={() => {
                save()
            }}>Apply</Button>
            <Button onClick={() => {
                save()
                onClose()
                _edited && setSelectedLayoutGroup(_edited.id)
            }}>Ok</Button>
        </DialogActions>

        {_toDelete &&
            <ConfirmationDialog message={"Are you sure you want to delete this layout group?"}
                open={_toDelete != null} onYes={() => {
                    del()
                }
                } onClose={() => setToDelete(null)}></ConfirmationDialog>
        }
    </Dialog>

    function handleSelection(sel) {
        setEdited({ ..._layoutGroups.find((l) => l.id === sel[0]) })
    }

    async function save() {
        if (_edited) {
            const saved = await saveLayoutGroup(_edited)

            setEdited(saved)
        }
    }

    async function del() {
        if (_layoutGroups.length === 1) {
            return
        }

        const id = _toDelete.id
        if (_selectedLayoutGroup.id === id) {
            setSelectedLayoutGroup(_layoutGroups.filter((l) => l.id !== _selectedLayoutGroup)[0].id)
        }

        if (_edited.id === _toDelete.id) {
            setEdited(null)
        }

        await deleteLayoutGroup(id)
        setToDelete(null)
    }

    async function duplicate(layoutGroup) {
        const { id, ...rest } = layoutGroup

        const newLayoutGroup = { ...rest, name: `Copy of ${rest.name}` }

        setEdited(newLayoutGroup)
    }
}
// A custom smaller toolbar without the density / export functionality
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    )
}
