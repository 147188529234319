
export const FIELD_CONTAINER_HEIGHTS = {
    1: 'h-[3.1rem]',
    2: 'h-[6.2rem]',
    3: 'h-[9.3rem]',
    4: 'h-[12.4rem]',
    5: 'h-[15.5rem]'
}
export function FieldContainer({ id, label, className, children, height = 1 }) {
    return <div className={`flex flex-col px-2 py-1 ${FIELD_CONTAINER_HEIGHTS[height]} justify-between ${className || ''}`}>
        {label && <label htmlFor={id} className="text-ao-blue text-sm">{label}</label>}
        {children}
    </div>
}