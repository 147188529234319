import React, { useState, useEffect } from "react";
import BunkerChart from "../../components/Charts/BunkerChart";
import BunkerFilter from "../../components/BunkerFilter/BunkerFilter";
import BunkerPricingSpotTable from "../PricingEngine/BunkerPricingSpotTable";
import { BunkerPriceTable } from "../PricingEngine/BunkerPriceTable";
import { selectedBunkers } from "../../store/ui";
import { getRecoilPromise } from "recoil-nexus";
import { bunkerPricesPort } from "../../store/api";
import { useRecoilValue } from "recoil";
import { userSettings } from "../../store/ui";
import { formatDateWithTime } from "../../utils/Helpers";
import { replaceItemAtIndex } from "../../utils/Helpers";
import { DataAttribution } from "../PricingEngine/DataAttribution";
import { spgciStatus, bunkerExStatus } from "../../store/integrations";
import { ProviderStatus } from "../../utils/Constants";
import { NoProviders } from "../PricingEngine/NoProviders";


// eslint-disable-next-line import/no-anonymous-default-export
const PEBunkers = () => {
  const _userSettings = useRecoilValue(userSettings);
  const [_vol, setVol] = useState(_userSettings.volatility);
  const [_liq, setLiq] = useState(_userSettings.liquidity);
  const [isChecked, setIsChecked] = useState(true);
  const _selectedBunkers = useRecoilValue(selectedBunkers);
  const _spgciStatus = useRecoilValue(spgciStatus);
  const _bunkerExStatus = useRecoilValue(bunkerExStatus);
  const [data, setData] = useState([
    unavailableBunkerData('loading'),
    unavailableBunkerData('loading'),
    unavailableBunkerData('loading'),
    unavailableBunkerData('loading'),
  ]);
  
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    (async function () {
      _selectedBunkers.forEach(async (bunker, index) => {
        const provider = bunker.item.isBunkerEx ? "BunkerEx" : "SPGCI";
        const apiData = (
          await getRecoilPromise(
            bunkerPricesPort({
              provider,
              volatility: _vol,
              port: bunker.item.port,
              fuels: bunker.item.fuelType,
            })
          )
        )[0];
        
        const newData = apiData
          ? {
              spotPrice: apiData.spotPrice,
              spotDate: formatDateWithTime(apiData.priceDate),
              constructedSpot: apiData.constructedSpot,
              forwardCurveData: apiData.forwardCurve,
              constructedCurve: apiData.constructedCurve,
              providerData: apiData.providerData,
              port: bunker.item.port,
              fuelType: bunker.item.fuelType,
              provider,
              providerData: apiData.providerData,
              status: 'available'
            }
          : unavailableBunkerData('unavailable');
        
        setData((d) => replaceItemAtIndex(d, index, newData));
      });
    })();
  }, [_selectedBunkers]);

  const spgciActive = _spgciStatus === ProviderStatus.ACTIVE
  const bunkerExActive = _bunkerExStatus === ProviderStatus.ACTIVE


  if (!spgciActive && !bunkerExActive){
    return <NoProviders
              spgciFailed={!spgciActive}
              bunkerExFailed={!bunkerExActive}
              page={"bunker data"}
          />
  }


  return (
    <div className="flex flex-col">
    <div className="flex flex-row">
      <div
        className={`h-full flex flex-col gap-y-2 ${
          !isChecked && "border-r-2 border-gray-300"
        }`}
      >
        <div className="flex flex-row gap-x-4 border-b-1 border-gray-300 align-middle">
          <label className="flex flex-row gap-x-2 cursor-pointer select-none items-center font-semibold">
            <div className="relative">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="sr-only"
              />
              <div className={`block h-8 w-14 rounded-full bg-gray-200`}></div>
              <div
                className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-ao-blue transition ${
                  isChecked ? "translate-x-full" : ""
                }`}
              ></div>
            </div>
            {isChecked ? "Spot View" : "Forwards View"}
          </label>

          <BunkerFilter
            isSpotView={isChecked}
           />

          <button
            type="button"
            className="py-1 px-4 rounded-md border font-semibold border-ao-blue hover:bg-ao-blue hover:text-white"
          >
            Set Alerts
          </button>
        </div>

        {isChecked ? (
          <BunkerPricingSpotTable />
        ) : (
          <BunkerPriceTable data={data} />
        )}
      </div>
      {!isChecked && (
        <div className="h-full w-1/2 pl-5">
          <BunkerChart volatility={_vol} liquidity={_liq} data={data} />
        </div>
        )}
    </div>
    </div>
  );
};

export default PEBunkers;

function unavailableBunkerData(status) {
  return {
    spotPrice: null,
    spotDate: "N/A",
    constructedSpot: false,
    forwardCurveData: Array.from({length: 12}, (_) => ({})),
    constructedCurve: false,
    providerData: {forwardCurveCode: "N/A", spotSymbol: "N/A"},
    status
  };
}
