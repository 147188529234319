export default function BunkerRiskIndicator({ riskLevel }) {
    let riskColor;
  
    if (riskLevel === 'high') {
      riskColor = 'red';
    } else if (riskLevel === 'med') {
      riskColor = '#ebb434'; // Updated to the desired hex color
    } else {
      riskColor = 'green';
    }
  
    return (
      <div className="w-6 h-6 rounded-full flex items-center justify-center">
        <div style={{ backgroundColor: riskColor }} className="w-full h-full rounded-full"></div>
      </div>
    );
  }
  