import TankPurchaseCard from "./TankPurchaseCard"


export default function TankTimeline({queue, isQueue, shipName, fuelType}){

    return(
        <div className="overflow-y-auto h-64 w-full">
            {queue.length > 0 ? (
                queue.map((x, i) => (
                <div className="ml-2" key={x.id}>
                <TankPurchaseCard 
                    purchase={x}
                    isFirstStem={((i===0) && isQueue)}
                    shipName={shipName}
                    fuelType={fuelType}
                />
                </div>
            ))
            ) : (
            <p>No stems to display</p>
            )}
        </div>
    )
}