import React, { useEffect, useState } from "react";
// import { dollarFormat, separatorFormat, percentageFormat} from "../../utils/Helpers";
import { useRecoilState, useRecoilValue } from "recoil";
import { getPortfolioRisk } from "../../../store/api";
import { daysBetweenDates } from "../voyageutils";
import RiskRow from "./PortfolioRisksRow";
import { getRecoilPromise } from "recoil-nexus";
import { voyages } from "../../../store/voyages";
import { dollarFormat, separatorFormat } from "../../../utils/Helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [_selectedVol, setSelectedVol] = useState("MEDIUM")
    const _voyages = useRecoilValue(voyages);
    const [voyageArray, setVoyageArray] = useState([])
    const [activeVoyages, setActiveVoyages] = useState(0)
    const [uncoveredBunkers, setUncoveredBunkers] = useState(0)
    const [expectedBunkerCost, setExpectedBunkerCost] = useState(0)
    const [totalValueAtRisk, setTotalValueAtRisk] = useState(0)
    const [emissionsArray, setEmissionsArray] = useState([])
    const [totalEmissionVar, setTotalEmissionVar] = useState(0)
    console.log("_voyages", _voyages)
    const { currentVoyages, voyageIDs } = filterByDate(_voyages)


      useEffect(() => {
        (async function () {
            const {fuel_info, portfolio_var} = await getRecoilPromise(getPortfolioRisk(_selectedVol))
            setVoyageArray(fuel_info)
            setActiveVoyages(currentVoyages.length)
            setTotalValueAtRisk(portfolio_var)
            setUncoveredBunkers(fuel_info.reduce((sum, x) => sum + x.quantity, 0))
            setExpectedBunkerCost(fuel_info.reduce((sum, x) => sum + x.mean, 0))
            const aggregatedEmissions = aggregateEmissionCosts(currentVoyages, _selectedVol)
            setEmissionsArray(aggregatedEmissions)
            setTotalEmissionVar(aggregatedEmissions.reduce((sum, item) => sum + item.risk, 0))
        })()
      },[_selectedVol, _voyages])



    const cellTitle = (item, last=false) => {
    return (
        <div className={`flex text-l font-bold text-ao-blue ${last ? "" : "border-r border-gray-150"} border-dotted`}>
            <div className={`w-full p-1 ml-2`}>
                {item}
            </div>
        </div>
    )
    }

    const headlineBox = (title, value) => {
        return (
            <div className="flex">
                <div className="flex-1 p-2 pb-4 text-xl flex items-center justify-end">
                    <p className="text-ao-figma-text-gray">{title}</p>
                </div>
                <div className="flex-1 p-2 pb-4 text-2xl flex items-center">
                    <p className="text-ao-figma-text-gray">{value}</p>
                </div>
            </div>
        )
    }



    return (
            <>
                <div className="flex items-center justify-end gap-x-8 -mt-14">
                        <button className="bg-white border border-ao-blue text-ao-blue py-2 px-4 rounded-lg hover:bg-ao-blue hover:text-white">
                            Export Hedges
                        </button>
                </div>
                <div className="bg-gray-100">
                    <div className="bg-gray-100 p-4 mt-4"> 
                    <div className="grid grid-cols-5 gap-4">
                            {headlineBox("Active Voyages", separatorFormat(activeVoyages))}
                            {headlineBox("Uncovered Bunkers", separatorFormat(uncoveredBunkers))}
                            {headlineBox("Expected Bunker Cost", dollarFormat(expectedBunkerCost))}
                            {headlineBox("Bunker Risk", dollarFormat(totalValueAtRisk))}
                            {headlineBox("Emissions Risk", dollarFormat(totalEmissionVar))}
                    </div>
                    </div>
                    <div className="bg-gray-100 p-4"> 
                        <div className="grid grid-flow-col gap-4">
                        <div className="p-4">
                            <label className="block text-gray-700 font-bold mb-2" for="volatility">
                                Volatility
                            </label>
                            <div className="flex">
                                <label className="mr-4">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="volatility"
                                    value="LOW"
                                    onChange={(ev) => setSelectedVol(ev.target.value)}
                                    checked={_selectedVol === "LOW"}
                                />
                                <span className="ml-2">Low</span>
                                </label>
                                <label className="mr-4">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="volatility"
                                    value="MEDIUM"
                                    onChange={(ev) => setSelectedVol(ev.target.value)}
                                    checked={_selectedVol === "MEDIUM"}
                                />
                                <span className="ml-2">Med</span>
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="volatility"
                                    value="HIGH"
                                    onChange={(ev) => setSelectedVol(ev.target.value)}
                                    checked={_selectedVol === "HIGH"}
                                />
                                <span className="ml-2">High</span>
                                </label>
                            </div>
                            </div>
                    </div>

                    <div className="relative overflow-x-auto mt-10">
                        <div className="overflow-x-auto p-2">
                            <div className={`grid grid-cols-7 p-1 text-ao-figma-text-gray`}>
                                {cellTitle("Grade")}
                                {cellTitle("Instrument")}
                                {cellTitle("Exposure")}
                                {cellTitle("Expected Cost")}
                                {cellTitle("Risk")}
                                {cellTitle("Hedgeable Risk")}
                                {cellTitle("Details", true)}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            {voyageArray.map((v) => <RiskRow key={v.id} record={v} haveToggle={true} emissions={false} />)}
                        </div>
                    </div>
                    <div className="relative overflow-x-auto mt-20">
                        <div className="overflow-x-auto p-2">
                            <div className={`grid grid-cols-7 p-1 text-ao-figma-text-gray`}>
                                {cellTitle("Emission")}
                                {cellTitle("Instrument")}
                                {cellTitle("Exposure")}
                                {cellTitle("Expected Cost")}
                                {cellTitle("Risk")}
                                {cellTitle("Hedgeable Risk", true)}
                                {cellTitle("", true)}
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                        {emissionsArray.map((e) => <RiskRow key={e.id} record={e} haveToggle={false} emissions={true} co2={e.emission === "CO2"}/>)}
                        </div>
                        
                    </div>
                </div>
            </div>
            </>
    );
};


function aggregateEmissionCosts(voyages, volatility){

    let record = []
    const emissions = ["CO2", "SOX", "NOX"]
    const instruments = ["MOP-CO2G", "MOP-SO2G", "MOP-NO2G"]
    const today = new Date()

    if (voyages.length === 0){
        return []
    }

    for (let i = 0; i <= 2; i++){

        const temp = {
            emission: emissions[i],
            quantity: 0,
            risk: 0,
            mean: 0,
            instrument: instruments[i],
            risk_reduction: i === 0 ? 1 : 0
        }
        record.push(temp)
    }

    for(const voyage of voyages){

        // Per Ton
        const co2Price = voyage.co2Price
        const daysToEnd = daysBetweenDates(today, voyage.dischargeDate)
        const riskFactor = calcEmissionVarFrac(volatility, co2Price, daysToEnd)
        console.log(riskFactor)
        record[0].quantity += voyage.carbonConsumption
        record[0].mean += voyage.emissionsCost
        record[0].risk += voyage.emissionsCost * (riskFactor - 1)
        record[1].quantity += voyage.soxConsumption
        record[2].quantity += voyage.noxConsumption
    }
    return record
}

function calcEmissionVarFrac(volatility, price, days) {

    let volValue
    if(days < 1){
        return 1
    }

    switch(volatility){
        case "LOW":
            volValue = 25
            break
        case "MEDIUM":
            volValue = 50
            break
        case "HIGH":
            volValue = 75
            break
        default:
            volValue = 50
    }
    const timeToPurchase = days/365
    const varc = (volValue/100)**2 * timeToPurchase
    const CI_upper = price * Math.exp(1.645 * Math.sqrt(varc))
    return (CI_upper / price)
}

function filterByDate(voyages){
    const today = new Date()
    let currentVoyages = voyages.filter((voyage) =>{
        return voyage.dischargeDate > today
    })
    const voyageIDs = new Set(currentVoyages.map((voyage) => voyage.id));
    return {
        currentVoyages,
        voyageIDs
    }
}

function getVoyageFromID(ids, voyages){

    const currentVoyages = voyages.filter((voyage) => {
        return ids.has(voyage.id)
    })

    return currentVoyages
}