import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AoForm } from "../../components/form/AoForm";
import { InputField } from "../../components/form/fields/InputField";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  FetchSpgciSettings,
  SaveSpgciSettings,
} from "../../store/api/services-api";
import { useEffect, useState } from "react";
import { spgciStatus } from "../../store/integrations";
import { useAlert } from "react-alert";

export function ManageSpgci() {
  let history = useHistory();
  const [formData, setFormData] = useState(null);
  const refreshStatus = useRecoilRefresher_UNSTABLE(spgciStatus);
  const alert = useAlert();

  useEffect(() => {
    (async function load() {
      const response = await FetchSpgciSettings();
      setFormData(response.data);
    })();
  }, []);

  const isUnset = formData == null;

  return (
    <AoForm
      initialValues={formData || {}}
      onReset={backToSettings}
      onSubmit={async (settings) => {
        const response = await SaveSpgciSettings(settings);
        if (response.success) {
          refreshStatus();
          backToSettings();
        } else {
          alert.error("The credentials are not valid");
        }
      }}
      className="max-w-sm"
    >
      {({ Column, itemClassName, form }) => {
        return (
          <>
            <Column label="Credentials">
              <InputField
                type="text"
                name="spgciUsername"
                label="Username"
                className={itemClassName}
                required
              />
              <InputField
                type="password"
                name="spgciPassword"
                label="Password"
                className={itemClassName}
                inputClassName="placeholder:italic"
                placeholder={isUnset ? "" : "Confidential"}
                required
              />
              <InputField
                type="text"
                name="spgciApiKey"
                label="API key"
                className={itemClassName}
                inputClassName="placeholder:italic"
                placeholder={isUnset ? "" : "Confidential"}
                required
              />
            </Column>
          </>
        );
      }}
    </AoForm>
  );

  function backToSettings() {
    history.push("/settings");
  }
}
