import { atom, selector } from "recoil"
import { FetchUserInfo, SaveUserInfo } from "./api/aftapi"

export const userInfo = atom({
    key: "userInfo",
    default: selector({
        key: "userInfoLoader",
        get: async () => {
            const fetch = await FetchUserInfo()
            if (fetch.success) {
                return fetch.data
            } else {
                return {}
            }
        }
    }),
    effects: [
        ({ onSet }) => {
            onSet((newValue) => {
                SaveUserInfo(newValue)
            })
        }
    ]
})