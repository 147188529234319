import { DateTime } from "luxon"
import { replaceItemAtIndex } from "../../utils/Helpers"
import { keycloak } from "../../utils/hooks/use-keycloak"

const vesselsResource = "vessels"
export const FetchCustomVessels = fetchMethod(vesselsResource)
export const FetchModelVessels = fetchMethod(`${vesselsResource}/models`)
export const CreateVessel = createMethod(vesselsResource)
export const UpdateVessel = saveMethod(vesselsResource)
export const DeleteVessel = deleteMethod(vesselsResource)

const profileResource = "profile"
export const FetchUserInfo = fetchMethod(profileResource)
export const SaveUserInfo = postMethod(profileResource)

const layoutGroupsResource = "layout-groups"
export const FetchLayoutGroups = fetchMethod(layoutGroupsResource)
export const CreateLayoutGroup = createMethod(layoutGroupsResource)
export const SaveLayoutGroup = saveMethod(layoutGroupsResource)
export const DeleteLayoutGroup = deleteMethod(layoutGroupsResource)

const voyageResourceFactory = resourceFactory("voyages", (voyage) => ({
    ...voyage,
    openDate: DateTime.fromISO(voyage.openDate).toJSDate(),
    laycanStart: DateTime.fromISO(voyage.laycanStart).toJSDate(),
    laycanEnd: DateTime.fromISO(voyage.laycanEnd).toJSDate(),
    dischargeDate: DateTime.fromISO(voyage.dischargeDate).toJSDate(),
    etaBunkerPort: DateTime.fromISO(voyage.etaBunkerPort).toJSDate(),
    etaLoadPort: DateTime.fromISO(voyage.etaLoadPort).toJSDate()

}), (voyage) => ({
    ...voyage,
    openDate: DateTime.fromJSDate(voyage.openDate).toISODate(),
    laycanStart: DateTime.fromJSDate(voyage.laycanStart).toISODate(),
    laycanEnd: DateTime.fromJSDate(voyage.laycanEnd).toISODate(),
    dischargeDate: DateTime.fromJSDate(voyage.dischargeDate).toISODate(),
    etaBunkerPort: DateTime.fromJSDate(voyage.etaBunkerPort).toISODate(),
    etaLoadPort: DateTime.fromJSDate(voyage.etaLoadPort).toISODate()
}))

export const FetchVoyages = voyageResourceFactory.fetch()
// export const FetchVoyages = fetchMethodMock("voyages")
export const CreateVoyage = voyageResourceFactory.create()
// export const CreateVoyage = createMethodMock("voyages")
export const SaveVoyage = voyageResourceFactory.save()
// export const SaveVoyage = saveMethodMock("voyages")
export const DeleteVoyage = voyageResourceFactory.del()

const BunkerPricesFetch = fetchMethod("bunker-prices", async (data, next) => {
    const response = await next(data)

    return response.map((price) => ({
        ...price,
        priceDate: DateTime.fromFormat(price.priceDate, "yyyy-MM-dd").startOf("day").toJSDate(),
        originalPriceDate: DateTime.fromFormat(price.originalPriceDate, "yyyy-MM-dd").startOf("day").toJSDate()
    }))
})
export const FetchBunkerPrices = function () {
    return BunkerPricesFetch({ date: new Date().toISOString() })
}

const voyagePortfolioResourceFactory = resourceFactory("voyage-portfolios")
export const FetchVoyagePortfolios = voyagePortfolioResourceFactory.fetch()
export const CreateVoyagePortfolio = voyagePortfolioResourceFactory.create()
export const SaveVoyagePortfolio = voyagePortfolioResourceFactory.save()
export const DeleteVoyagePortfolio = voyagePortfolioResourceFactory.del()


function resourceFactory(endpoint, mapToJs = (x) => x, mapToJson = (x) => x) {

    const dataProcessorFetch = async (data, next) => {
        const response = await next(data)

        return response.map(mapToJs)
    }

    const dataProcessor = async (data, next) => {
        const jsonData = data != null ? mapToJson(data) : data

        const response = await next(jsonData)

        return mapToJs(response)
    }

    return {
        fetch: () =>
            fetchMethod(endpoint, dataProcessorFetch),
        create: () =>
            createMethod(endpoint, dataProcessor),
        save: () =>
            saveMethod(endpoint, dataProcessor),
        del: () =>
            deleteMethod(endpoint)

    }
}

function fetchMethod(resource, dataProcessor) {
    return async function (params) {
        const data = await get(resource, params, dataProcessor)
        return successResponse(data)
    }
}

function createMethod(resource, dataProcessor) {
    return postMethod(resource, dataProcessor)
}

function postMethod(resource, dataProcessor) {
    return async function (data) {
        const createdData = await post(resource, data, dataProcessor)
        return successResponse(createdData)
    }
}

function saveMethod(resource, dataProcessor) {
    return async function ({ id, ...data }) {
        const updated = await put(`${resource}/${id}`, data, dataProcessor)
        return successResponse(updated)
    }
}

function deleteMethod(resource) {
    return async function (id) {
        await del(`${resource}/${id}`)

        return { success: true }
    }
}

function fetchMethodMock(resource) {
    return async function () {
        const data = JSON.parse(localStorage.getItem(resource) || "[]")
        return successResponse(data)
    }
}

function createMethodMock(resource) {
    return async function (data) {
        const saved = JSON.parse(localStorage.getItem(resource) || "[]")
        const newData = { ...data, id: Math.max(...[...saved.map((s) => s.id), 0]) + 1 }

        localStorage.setItem(resource, JSON.stringify([...saved, newData]))

        return successResponse(newData)
    }
}

function saveMethodMock(resource) {
    return async function (data) {
        const saved = JSON.parse(localStorage.getItem(resource) || "[]")
        const index = saved.findIndex((s) => s.id === data.id)

        localStorage.setItem(resource, JSON.stringify(replaceItemAtIndex(saved, index, data)))

        return successResponse(data)
    }
}

function deleteMethodMock(resource) {
    return async function (id) {
        const saved = JSON.parse(localStorage.getItem(resource) || "[]")

        localStorage.setItem(resource, JSON.stringify(saved.filter((s) => s.id !== id)))

        return { success: true }
    }
}

function get(endpoint, params, dataProcessor) {
    return callWithDataProcessor(dataProcessor, params, (p) => callApi({ endpoint, method: "GET", data: p }))
}

function post(endpoint, data, dataProcessor) {
    return callWithDataProcessor(dataProcessor, data, (d) => callApi({ endpoint, method: "POST", data: d }))
}

function put(endpoint, data, dataProcessor) {
    return callWithDataProcessor(dataProcessor, data, (d) => callApi({ endpoint, method: "PUT", data: d }))
}

function del(endpoint) {
    return callApi({ endpoint, method: "DELETE" })
}

function callWithDataProcessor(dataProcessor, data, apiCall) {
    if (dataProcessor) {
        return dataProcessor(data, (d) => apiCall(d))
    }

    return apiCall(data)
}

async function callApi({ endpoint, method, data }) {
    let query = ""
    if (method === "GET" && data) {
        query = new URLSearchParams(data).toString()
    }

    const response = await fetch(`${process.env.REACT_APP_AFT_API_URL}/${endpoint}/${query ? "?" + query : ""}`, {
        method: method,
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json"
        },
        body: data && method !== "GET" && method !== "DELETE" ? JSON.stringify(data) : undefined
    })

    if (!response.ok) {
        throw new Error(`An error has occurred wile calling ${method} ${endpoint}: ${response.status}`)
    }

    if (response.status === 204) {
        return
    }

    return await response.json()
}

function successResponse(data) {
    if (data) {
        return { success: true, data }
    }

    return { success: true }
}