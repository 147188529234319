import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import React, { useState } from "react"
import { useVoyagesMutations } from "../../../store/voyages"
import { VoyageEngineManagementModal } from "./VoyageEngineManagementModal"


export default function EditVoyageEngineButton({ voy }) {

    const storedVoyage = voy
    const { saveVoyage } = useVoyagesMutations()
    const [voyage, setVoyage] = useState(storedVoyage)
    const [_modalOpen, setModalOpen] = useState(false)

    return (<>
        <Tooltip
            title="Adjust costing variables for this voyage.">
            <Button onClick={() => {
                setModalOpen(true)
            }}
                style={{
                    backgroundColor: "transparent",
                    color: "ao-blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    padding: "0 0px",
                    fontSize: "1rem",
                    fontWeight: "530",
                    textTransform: "none"
                }}
            >
                Details
            </Button>
        </Tooltip>
        <VoyageEngineManagementModal voyageInput={voyage} modalOpen={_modalOpen} closeModal={closeModal} onSave={handleSaveVoyage} isEdit={true} />
    </>)

    function closeModal() {
        setModalOpen(false)
    }

    async function handleSaveVoyage(voyage) {
        await saveVoyage(voyage)
        closeModal()
    }

}