import { Line, Circle } from 'rc-progress';
import { mtFormat } from '../../../utils/Helpers';


export default function TankLevel({level, capacity}){


    return (
        <div className="flex flex-row p-1">
            <div className="flex basis-1/4">
                <div>
                    <p>Level: {mtFormat(level)}</p>
                    <p>Capacity: {mtFormat(capacity)}</p>
                </div>
            </div>
            <div className="flex basis-3/4 items-center mx-auto">
                <Line percent={(level/capacity * 100)} strokeWidth={1} strokeColor="#5C93C0" height={20} width={750} />
            </div>
        </div>
    )

}