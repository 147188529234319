import axios, {isCancel, AxiosError} from 'axios';
import Timeline from "./Optimizations-timeline"
import { realVessels } from "../../../store/vessels"
import { useRecoilState, useRecoilValue} from "recoil";
import { voyageEngineVoyages } from "../../../store/ui";
import { useEffect, useState } from "react";
import { userCustomisedVessels } from "../../../store/vessels";
import { voyages } from "../../../store/voyages";
import OptimizationsHeaderTable from "./Optimizations-Header-Table";
import { mtFormat, formatDateToYYYYMMDD, dollarFormat } from "../../../utils/Helpers";
import PortConstraints from "../../../components/PortConstraints/PortConstraints";
import { keycloak } from '../../../utils/hooks/use-keycloak';

export default function Optimizations() {
    const _realVessels = useRecoilValue(userCustomisedVessels)
    const _voyages = useRecoilValue(voyages)
    const [_selectedShip, setSelectedShip] = useState("VERONICA")
    const [dynamicPlanEvents, setDynamicPlanEvents] = useState([])
    const [optimizedEvents, setOptimizedEvents] = useState([])
    const [dynamicTCE, setDynamicTCE] = useState(0)
    const [dynamicBunker, setDynamicBunker] = useState(0)
    const [optimizedTCE, setOptimizedTCE] = useState(0)
    const [optimizedBunker, setOptimizedBunker] = useState(0)
    const [showSpinner, setShowSpinner] = useState(false)
    const [optimizatonError, setOptimizationError] = useState(false)
    const [enableAcceptPlan, setEnableAcceptPlan] = useState(true)
    const [ifoMinBuy, setIfoMinBuy] = useState(0)
    const [mgoMinBuy, setMgoMinBuy] = useState(0)
    const [portConstraints, setPortConstraints] = useState([])
    const [allowSecondaryBunkering, setSecondaryBunkering] = useState(false)
    



    useEffect(() => {
        (async function () {
            const today = new Date()
            const relevantVoyages = _voyages.filter((v) => v.vessel === _selectedShip && v.laycanEnd > today)
            const dynamicVoyageData = await voyageDataFormatter(relevantVoyages)
            setDynamicPlanEvents(dynamicVoyageData.formatVoyageData)
            setDynamicTCE(dynamicVoyageData.tce)
            setDynamicBunker(dynamicVoyageData.totalBunkers)
            setOptimizedEvents([])
            setOptimizationError(false)
        })()
    }, [_selectedShip, _voyages, _realVessels])

    useEffect(() => {
        (function () {
            setOptimizedEvents([])
            setOptimizedTCE(0)
            setOptimizedBunker(0)
        })()
    }, [_selectedShip, _realVessels])

    const handleOptimizeClick = () => async () => {

        
        const today = new Date()
        const record = _voyages.filter((v) => v.vessel === _selectedShip && v.laycanEnd > today)
        const vessel = _realVessels.filter((x) => x.shipName === _selectedShip)[0]

        setShowSpinner(true)
        const formattedRequest = makeRequest(record, vessel, ifoMinBuy, mgoMinBuy, portConstraints, allowSecondaryBunkering)
        const formattedRecord = await voyageDataFormatter(record)
        const optimizedVoyageData = await getOptimizedVoyages(formattedRequest, formattedRecord.formatVoyageData, vessel, portConstraints)
        const newExpenses = formattedRecord.totalExpenses - formattedRecord.totalBunkers + optimizedVoyageData.totalBunkers + formattedRecord.totalROB
        const optimizedTCE = (formattedRecord.totalRevenues - newExpenses)/formattedRecord.totalDays
        const dTCE = optimizedVoyageData.consolidatedEvents[0]=== "error" ? -1 : optimizedTCE - formattedRecord.tce
        const badOptimization = dTCE < 0 ? true : false
        setOptimizationError(badOptimization)
        setOptimizedTCE(badOptimization ? 0 : dTCE)
        setOptimizedBunker(badOptimization ? 0 : (optimizedVoyageData.totalBunkers + formattedRecord.totalROB))
        setOptimizedEvents(badOptimization ? ["error"] : optimizedVoyageData.consolidatedEvents)
        setEnableAcceptPlan(badOptimization ? false : true)

        setShowSpinner(false)

    }

    const handleIfoSliderChange = (event) => {
        const vessel = _realVessels.filter((x) => x.shipName === _selectedShip)[0]
        const selection = parseFloat(event.target.value)
        const percent = selection / 100

        const minPurchaseIFO = vessel.tankCapacity.ifo * percent

        setIfoMinBuy(minPurchaseIFO)
    }

    const handleMgoSliderChange = (event) => {
        const vessel = _realVessels.filter((x) => x.shipName === _selectedShip)[0]
        const selection = parseFloat(event.target.value)
        const percent = selection / 100

        const minPurchaseMGO = vessel.tankCapacity.mgo * percent
        setMgoMinBuy(minPurchaseMGO)
    }

    const handleSecondaryBunkering = () => {
        setSecondaryBunkering(!allowSecondaryBunkering)
    }

    const checkBox = (label, checked) => {
        return (
            <label className="flex items-center space-x-4">
                <input type="checkbox" className="form-checkbox text-primary text-ao-figma-text-gray left-4 h-4 w-4 rounded border-gray-300" name="fuel[]" value="IFO" checked={checked} readOnly={true}/>
                <span className="inline-block align-middle">{label}</span>
            </label>
            )
    }

    const updatePortConstraints = (option) => {
        if(option && !portConstraints.includes(option.portName)) {
            setPortConstraints([...portConstraints, option.portName])
        }
    }

    const removePortConstraint = (option) => {
        const updatedConstraints = portConstraints.filter((port) => port !== option);
        setPortConstraints(updatedConstraints)
    }

    return (
        <>
        {/* Buttons */}
        <div className="flex items-center justify-end gap-x-8 -mt-14">

        </div>

        {/* Columns */}
        <div className="mt-20">
            <div className="grid grid-cols-3 gap-4">
                <div className="px-4">
                    <div className="text-xl mb-2 font-bold text-ao-figma-text-gray">
                        Setup
                    </div>
                    <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                    <div className="flex items-center space-x-4 mb-5">
                        <div className="text-lg font-semibold text-ao-figma-text-gray"  style={{ width: "200px" }}>
                                Ship
                        </div>
                        <div className="flex flex-col gap-1 w-full">
                            <select
                                className="p-1 border border-1 rounded-md w-full"
                                    onChange={(ev) => setSelectedShip(ev.target.value)}
                                >
                                    {_realVessels.map((x) => (
                                    <option key={x.shipName} value={x.shipName}>
                                        {x.shipName}
                                    </option>
                                    ))}
                            </select>
                            <div className="flex space-x-4 p-1 ml-auto">
                            <label className="flex items-center space-x-4">
                                <span className="inline-block align-middle text-gray-500 text-sm">Allow Two Bunker Stems per Voyage</span>
                                <input type="checkbox" className="form-checkbox text-primary left-4 h-4 w-4 rounded border-gray-500" name="fuel[]" value="IFO" onChange={handleSecondaryBunkering}/>
                            </label>
                            </div>
                        </div>
                    </div>

                <hr className="border-b border-dashed border-ao-gray mb-5"></hr>
                    <div className="flex items-center space-x-4 mb-2">
                        <div className="text-lg font-semibold mr-10 ml-2 text-ao-figma-text-gray"  style={{ width: '200px' }}>
                            Fuels to Optimize
                        </div>
                        <div className="space-y-2">
                            {checkBox("IFO", true)}
                            {checkBox("MFO", true)}
                            {checkBox("LNG", false)}
                            {checkBox("MeOH", false)}
                            {checkBox("NH3", false)}
                            {checkBox("H2", false)}
                            {checkBox("Bio", false)}
                        </div>
                    </div>
                    <hr className="border-b border-dashed border-ao-gray mb-5"></hr>
                    <div className="flex flex-col ">
                        <div className="text-lg font-semibold text-ao-figma-text-gray"  style={{ width: "200px" }}>
                            Minimum Purchase Size
                        </div>
                        <div className="space-y-2 ">
                                <div className="mb-1">
                                    <p>IFO:  {mtFormat(ifoMinBuy)}</p>
                                </div>
                                <input
                                id="discretization-range"
                                type="range"
                                min="1"
                                max="100" 
                                step="1"
                                value="0"
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-ao-blue"
                                onChange={handleIfoSliderChange}
                                />
                                <div className="mb-1">
                                    <p>MGO:  {mtFormat(mgoMinBuy)}</p>
                                </div>
                                <input
                                id="discretization-range"
                                type="range"
                                min="1"
                                max="100" 
                                step="1"
                                value="0"
                                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-ao-blue"
                                onChange={handleMgoSliderChange}
                                />
                        </div>
                        <hr className="border-b border-dashed border-ao-gray my-5"></hr>

                        <PortConstraints updatePortConstraints={updatePortConstraints} removePortConstraint={removePortConstraint} portConstraints={portConstraints} />
                        <hr className="border-b border-dashed border-ao-gray my-5"></hr>
                        <button className="bg-white border border-ao-blue text-ao-blue py-2 px-4 rounded-lg hover:bg-ao-blue hover:text-white" onClick={handleOptimizeClick()}>
                            Optimize Bunkering Schedule
                        </button>
                    </div>
                </div>
                <div className="px-4">
                    <div className="text-xl mb-2 font-bold text-ao-figma-text-gray">
                        Current Plan
                    </div>
                    <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                    <div className="px-4">
                    <OptimizationsHeaderTable 
                        bunkerCost={dynamicBunker}
                        TCE={dynamicTCE}
                        optimized={false}
                    />
                    </div>
                    <hr className="border-b border-ao-figma-text-gray mb-5"></hr>
                    <div className="px-4">
                    <Timeline 
                        timelineData={dynamicPlanEvents}
                    />
                    </div>
                </div>

                <div className="px-4">
                <>  
                <div className="grid grid-cols-2 gap-2 items-center">
                    <div className="text-xl mb-2 font-bold text-ao-blue">
                        Optimized Plan
                    </div>
                    <button className="bg-white border mb-2 border-ao-blue text-ao-blue rounded-lg hover:bg-ao-blue hover:text-white">
                        Accept Plan
                    </button>
                </div>
                    
                    <hr className="border-b border-ao-blue mb-5"></hr>
                    {
                        showSpinner ? (
                            <div className="px-4">
                                <p> Fetching results...</p>
                            </div>
                        ) : (
                            optimizatonError ? (
                                <div className="px-4">
                                <p> Current Schedule is Lowest Cost</p>
                            </div>
                            ) : (
                                <>
                                    <div className="px-4">
                                        <OptimizationsHeaderTable 
                                            bunkerCost={optimizedBunker}
                                            TCE={optimizedTCE}
                                            optimized={true}
                                        />
                                    </div>
                                    <hr className="border-b border-ao-blue mb-5"></hr>
                                    <div className="px-4">
                                        <Timeline 
                                            timelineData={optimizedEvents}
                                        />
                                    </div>
                                </>
                            )
                        )
                    }
                </>
                </div>
            </div>
        </div>
        </>
    )
}


function makeRequest (voyageData, vessel, ifoMinBuy, mgoMinBuy, portConstraints, allowSecondaryBunkering){

    if (voyageData.length === 0) {
        return "empty"
    }

    const foType = vessel.scrubber ? "HSFO" : "LSFO"
    voyageData.sort((a, b) => new Date(a.voyage.openDate) - new Date(b.voyage.openDate))

    const firstBunkerPort = voyageData[0].bunkerPort

    let finalPortConstraints

    if (portConstraints.includes(firstBunkerPort)){
        finalPortConstraints = portConstraints
    } else {
        finalPortConstraints = [...portConstraints, firstBunkerPort]
    }

    let request = {
        voyages: [],
        fo_type: foType,
        min_final_rob_fraction: {FO: 0, GO: 0},
        ballast_consumption: vessel.ballastConsumption,
        laden_consumption: vessel.ladenConsumption,
        ship_type: getShipType(vessel.shipType),
        tank_size:{
            FO: vessel.tankCapacity.ifo,
            GO: vessel.tankCapacity.mgo
        } ,
        rob: { 
            FO: voyageData[0].robCosts.LSFO.rob,
            GO: voyageData[0].robCosts.MGO.rob
        },
        bunker_discretization: 21,
        tank_discretization: 101,
        allow_secondary_bunkering: allowSecondaryBunkering,
        dwt: vessel.dwt,
        emissions_percent_eu: 40,
        emissions_percent_non_eu:0,
        min_fuel_purchase: {
            FO: ifoMinBuy,
            GO: mgoMinBuy
        },
        bunkering_constraints: [{
            bunker1: finalPortConstraints,
            bunker_2: finalPortConstraints
        }],
        constrain_bunkering: (portConstraints.length > 0 ? true : false)
    }

    for (const voyage of voyageData){
        const openDate = new Date(voyage.openDate)
        const openDateString = formatDateToYYYYMMDD(openDate)
        const laycanEndDate = new Date(voyage.laycanEnd)
        const laycanEndString = formatDateToYYYYMMDD(laycanEndDate)

        const temp = {
            open: voyage.openPort,
            bunker_1: voyage.bunkerPort,
            bunker_2: null,
            load: voyage.loadingPort,
            discharge: voyage.dischargePort,
            open_date: openDateString,
            laycan_end: laycanEndString,
            weather_adjustment: voyage.seaMargin/100,
            laden_speed:voyage.ladenSpeed,
            ballast_speed: voyage.ballastSpeed,
            allow_canals: true,
            load_days: voyage.loadDays,
            discharge_days: voyage.dischargeDays,
            bunker_days: 0.5,
            waiting_days: voyage.waitingDays,
            canal_days: voyage.canalDays,
            fuels: voyage.fuels
        }
        request.voyages.push(temp)
    }

    request.rob.FO = voyageData[0].robCosts.LSFO.rob
    request.rob.GO = voyageData[0].robCosts.MGO.rob
    return request
}

async function voyageDataFormatter(voyages){
    let totalRevenues = 0
    let totalExpenses = 0
    let totalBunkers = 0
    let totalDays = 0
    let totalROB = 0
    let totalFuel = 0
    const formatVoyageData = []
    if (voyages.length === 0){
        return {formatVoyageData, tce: 0, totalRevenues, totalExpenses, totalBunkers, totalROB}
    }
    
    for (const voyage of voyages) {
        totalRevenues += voyage.totalRevenues
        totalExpenses += voyage.totalCost
        totalBunkers += voyage.bunkerCost
        totalDays += voyage.voyageLength
        totalROB += voyage.robCosts.cost
        totalFuel += voyage.bunkerCost


        formatVoyageData.push({
            voyageID: voyage.id,
            port: voyage.openPort,
            event: "open",
            vesselScrubber: voyage.vesselData.scrubber,
            cargo: voyage.cargo,
            eventDisplay: "Open",
            date: new Date(voyage.openDate)
        })

        formatVoyageData.push({
            voyageID: voyage.id,
            port: voyage.bunkerPort,
            event: "bunker",
            vesselScrubber: voyage.vesselData.scrubber,
            eventDisplay: "Bunker",
            required: [voyage.fuels.LSFO.requirement, voyage.fuels.MGO.requirement, 0, 0, 0, 0, 0, 0],
            displayReq: [voyage.fuels.LSFO.requirement, voyage.fuels.MGO.requirement, 0, 0, 0, 0, 0, 0],
            purchased: [0, 0, 0, 0, 0, 0, 0, 0],
            robQty: [voyage.robCosts.LSFO.rob, voyage.robCosts.MGO.rob, 0, 0, 0, 0, 0, 0],
            prices: [voyage.fuels.LSFO.averagePrice, voyage.fuels.MGO.averagePrice, 0, 0, 0, 0, 0, 0],
            date: new Date(voyage.etaBunkerPort)
        })

        formatVoyageData.push({
            voyageID: voyage.id,
            port: voyage.loadingPort,
            event: "load",
            vesselScrubber: voyage.vesselData.scrubber,
            eventDisplay: "Load",
            date: new Date(voyage.etaLoadPort),
        })

        formatVoyageData.push({
            voyageID: voyage.id,
            port: voyage.dischargePort,
            event: "discharge",
            vesselScrubber: voyage.vesselData.scrubber,
            eventDisplay: "Discharge",
            date: new Date(voyage.dischargeDate),
            robIFO: 0,
            robMGO: 0
        })

        
    }

    const tce = (totalRevenues - totalExpenses)/totalDays
    return {formatVoyageData, tce, totalRevenues, totalExpenses, totalBunkers, totalDays, totalROB, totalFuel}
}

async function getOptimizedVoyages(request, record, vessel, portConstraints){

    const jsonRequest = JSON.stringify(request)
    let totalBunkers = 0

    const axiosConfig ={
        method: "POST",
        url: `${process.env.REACT_APP_SERVICES_API_URL}/v1/calculations/optimize_bunkers`,
        data: jsonRequest,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${keycloak.token}`
        }
    }

    return axios(axiosConfig)
        .then((response) => {
            const optimizedBunkers = response.data.solutions[0]

            const formattedBunkers = []
      
            for (let i = 0; i < optimizedBunkers.bunker_events.length; i++){
              
              const event = optimizedBunkers.bunker_events[i]  
              const requirements = [event.fo_purchased, event.go_purchased, 0, 0, 0, 0, 0, 0]
              const prices = [event.fo_price, event.go_price, 0, 0, 0, 0, 0, 0]
              const purchased = [0, 0, 0, 0, 0, 0, 0, 0]
      
              const temp = {
                  port: event.bunker_port,
                  event: "bunker",
                  vesselScrubber: vessel.scrubber,
                  eventDisplay: "Bunker",
                  required: requirements,
                  displayReq: requirements,
                  purchased: purchased,
                  prices: prices,
                  date: new Date(event.bunker_date)
              }
              totalBunkers += event.totalCost

              if (i === optimizedBunkers.bunker_events.length - 1){
                totalBunkers -= (optimizedBunkers.rob.FO * event.fo_price + optimizedBunkers.rob.GO * event.go_price)
              }

              formattedBunkers.push(temp)
            }

            const filteredRecord = record.filter((x) => x.event !== "bunker")

            const consolidatedEvents = [...formattedBunkers, ...filteredRecord]
            consolidatedEvents.sort((a, b) => a.date - b.date)

            consolidatedEvents[consolidatedEvents.length - 1]["robIFO"] = optimizedBunkers.rob.FO
            consolidatedEvents[consolidatedEvents.length - 1]["robMGO"] = optimizedBunkers.rob.GO

            return { consolidatedEvents, totalBunkers }
        })
        .catch((error) => {
          return {consolidatedEvents: ["error"], totalBunkers: 0}
        })
}


function getShipType(ship){

    if(ship === "Panamax/LR1"){
        return "Panamax"
    }

    if (ship === "Aframax/LR2"){
        return "Aframax"
    }

    return ship

}