import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import { positions, transitions, Provider as AlertProvider } from "react-alert";
import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { AlertTemplate } from "./components/AlertTemplate/AlertTemplate.js";

const container = document.getElementById("root");
const root = createRoot(container);

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

root.render(
  <RecoilRoot>
    <RecoilNexus />
    <AlertProvider template={AlertTemplate} {...options}>
    <App />
    </AlertProvider>
  </RecoilRoot>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
