import { Grid } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { DatePicker } from "@mui/x-date-pickers"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { useRecoilState, useRecoilValue } from "recoil"
import AIVoyageExtender from "../../components/AIVoyageExtender/AIVoyageExtender"
import BreakdownCashflow from "../../components/Charts/BreakdownCashflow"
import BreakdownCashflowAI from "../../components/Charts/BreakdownCashflowAI"
import EmissionsExposuresChart from "../../components/Charts/EmissionsExposuresChart"
import MonthlyEmissionCostsChart from "../../components/Charts/MonthlyEmissionCostsChart"
import RiskCashflowChart from "../../components/Charts/RiskCashflowChart"
import RiskCashflowChartAI from "../../components/Charts/RiskCashflowChartAI"
import { Gauge } from "../../components/Dials/Gauge"
import EmissionRateSelect from "../../components/EmissionRateSelect/EmissionRateSelect"
import HeadedPaper from "../../components/HeadedPaper/HeadedPaper"
import RouteFilter from "../../components/RouteFilter/RouteFilter"
import { getRouteParamsIn } from "../../store/api"
import { regionAndVesselForAI } from "../../store/vessels"
import { DefaultTheme as theme } from "../../theme"
import { DATE_FORMAT_MUI } from "../../utils/Helpers"
import PricingTable from "../PricingEngine/PricingTable"
import { userSettings } from "../../store/ui"
import { LIQUIDITY, VOLATILITY } from "../../utils/Constants"

const volatility = [
    { "level": "Low", "value": 0.16, "relVal": "low" },
    { "level": "Medium", "value": 0.5, "relVal": "med" },
    { "level": "High", "value": 0.84, "relVal": "high" }]
const hedge = [
    { "level": "OFF", "value": 0, "relVal": 0 },
    { "level": "50%", "value": 0.5, "relVal": 1 },
    { "level": "100%", "value": 1, "relVal": 2 }]
const liquidity = [
    { "level": LIQUIDITY.low.caption, "value": 0.16, "relVal": LIQUIDITY.low.name },
    { "level": LIQUIDITY.med.caption, "value": 0.5, "relVal": LIQUIDITY.med.name },
    { "level": LIQUIDITY.high.caption, "value": 0.84, "relVal": LIQUIDITY.high.name }]
const SubVals = [
    { "level": "OFF", "value": 0, "relVal": 0 },
    { "level": "10", "value": 0.1, "relVal": 1 },
    { "level": "25", "value": 0.25, "relVal": 2 },
    { "level": "50", "value": 0.5, "relVal": 3 }]

const ports = [
    {
        "First": "Rotterdam",
        "Second": "Singapore",
        "Third": "Al Basra Terminal",
        "Fourth": "Ningbo",
        "Fifth": "Al Basra Terminal",
        "Sixth": "Ningbo",
        "Seventh": "Al Basra Terminal"
    },
    {
        "First": "Rotterdam",
        "Second": "Hound Point",
        "Third": "Lanshan",
        "Fourth": "Juaymah Terminal",
        "Fifth": "Onsan",
        "Sixth": "Juaymah Terminal",
        "Seventh": "Onsan"
    },
    {
        "First": "Le Havre",
        "Second": "Puerto Jose Terminal",
        "Third": "Jamnagar Terminal",
        "Fourth": "Al Basra Terminal",
        "Fifth": "Ningbo",
        "Sixth": "Al Basra Terminal",
        "Seventh": "Ningbo"
    }]
const style = {
    underline: {
        "&::after": {
            border: "1px solid red"
        },
        "&::before": {
            border: "none"
        }
    },
    inputlabel: {
        color: "rgba(10,100,100,0.6)",
        fontSize: "16px"
    }
}
export default function CharterTCEarnings() {

    const routeparamsin = useRecoilValue(getRouteParamsIn)
    const _userSettings = useRecoilValue(userSettings)

    const [riskSettings, setRiskSettings] = useState({
        vol: _userSettings.volatility,
        liq: _userSettings.liquidity,
        hedgeRate: 0,
        hedgeBunk: 0,
        hedgeEmiss: 0,
        kwhSub: 0,
        LNGSub: 0,
        NH3Sub: 0,
        MeOHSub: 0,
        H2Sub: 0
    })

    const [_SimulateTrading, setSimulateTrading] = useState(false)
    const [_MaximiseTCE, setMaximiseTCE] = useState(false)
    const [_MinimiseEmissions, setMinimiseEmissions] = useState(false)
    const [_useAIS, setUseAIS] = useState("date")
    const [_aidata, setAIdata] = useState([{
        "ratios": [0.81, 1.44, 1.44],
        "routes": ["Carib_India_VLCC", "AG_China_VLCC", "AG_China_VLCC"],
        "weights": [[0, 1, 0.9572011326666667, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0.04279886733333327, 1, 0.11024022500000008, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0.04279886733333327, 1, 0.11024022500000008, 0, 0, 0, 0, 0, 0, 0, 0]],
        "ports": ["Rotterdam", "Singapore", "Al Basra Terminal", "Ningbo", "Al Basra Terminal", "Ningbo", "Al Basra Terminal"]
    }])
    const [_startDate, setStartDate] = useState(new Date())
    const [_loadDischarge] = useState("load")
    const [_regionVesselAI, setRegionVesselAI] = useRecoilState(regionAndVesselForAI)
    const [_aiRoute, setaiRoute] = useState(1)
    const [_rv, setRV] = useState(0)
    const [_startDateBC, setStartDateBC] = useState(new Date())
    const [_rob, setROB] = useState({ IFO: 0, MGO: 0 })


    return (
        <Grid container direction="row" spacing={8}>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" component="h1" style={{ marginRight: 10, fontSize: 42 }}>
                            Charterer TC Earnings
                        </Typography>
                        <Paper style={{ padding: "5px 10px 10px 10px", marginBottom: 10 }}>
                            <br />

                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Button>All Vessels</Button>
                                <RouteFilter />
                                <EmissionRateSelect />

                            </Stack>

                        </Paper>


                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>

                            

                            </Grid>
                            
                                <Grid item xs={12}>


                                    <Tooltip
                                        title={"This table shows the monthly earnings for a specific ship and voyage, based on forward projections of expected freight rates, bunker costs, emission costs, other earnings and ship parameters.  The mean earnings in the table are categorised by risk level in the risk adjusted earnings plot to the right."}>
                                        <Typography variant="h6" component="h6">
                                            Round Voyage Risk Model: Expected Monthly Earnings
                                        </Typography>
                                    </Tooltip>
                                    <PricingTable graphOption={"cfar"} liquidity={riskSettings.liq}
                                        volatility={riskSettings.vol} />

                                </Grid>


                            <Grid item xs={12}>

                                <Stack direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    spacing={2}>
                                    {(_SimulateTrading) &&
                                        <ThemeProvider theme={theme}>

                                            <FormControl variant="standard" component="fieldset" fullWidth>
                                                <RadioGroup
                                                    aria-label="AIS"
                                                    name="controlled-radio-buttons-group"
                                                    value={_useAIS}
                                                    onChange={(e) => setUseAIS(e.target.value)}
                                                    color="primary"
                                                >
                                                    <FormControlLabel value={"ais"} control={<Radio />}
                                                        label="Start at Current AIS" disabled={true} />
                                                    <FormControlLabel value={"date"} control={<Radio />}
                                                        label="Start Date" />
                                                </RadioGroup>
                                            </FormControl>
                                        </ThemeProvider>}
                                    {(_useAIS === "date" && _SimulateTrading) &&
                                        <ThemeProvider theme={theme}>

                                            <FormControl variant="standard" fullWidth>
                                                <DatePicker
                                                    label="Start Date"
                                                    inputFormat={DATE_FORMAT_MUI}
                                                    value={_startDate}
                                                    disablePast={true}
                                                    onChange={(date) => setStartDate(date.toDate())}
                                                    allowSameDateSelection={true}
                                                    renderInput={(params) => <TextField {...params} />}

                                                    OpenPickerButtonProps={{
                                                        sx: { color: "#fff" }
                                                    }}
                                                />
                                            </FormControl>
                                        </ThemeProvider>
                                    }
                                </Stack>

                            </Grid>

                            {(_SimulateTrading) &&
                                <Grid item xs={12}>
                                    <HeadedPaper title="AI Voyage Pathways">
                                        <Grid row spacing={1} container>
                                            <Grid item xs={12}>

                                                <Stack direction="row"
                                                    divider={<Divider orientation="vertical" flexItem />}
                                                    spacing={2}>
                                                    {(_SimulateTrading) &&
                                                        <ThemeProvider theme={theme}>
                                                            <Typography> Choose AI option: </Typography>
                                                            <FormControl variant="standard" component="fieldset"
                                                                fullWidth>
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="AIS"
                                                                    name="controlled-radio-buttons-group"
                                                                    value={_aiRoute}
                                                                    onChange={(e) => setaiRoute(e.target.value)}
                                                                    color="primary"
                                                                >
                                                                    <FormControlLabel value={1} control={<Radio />}
                                                                        label="Pathway 1" />
                                                                    <FormControlLabel value={2} control={<Radio />}
                                                                        label="Pathway 2" />
                                                                    <FormControlLabel value={3} control={<Radio />}
                                                                        label="Pathway 3" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </ThemeProvider>}
                                                </Stack>

                                            </Grid>
                                            <Grid xs={12} item>
                                                <AIVoyageExtender load={_loadDischarge} region={_regionVesselAI}
                                                    volatility={riskSettings.vol}
                                                    liquidity={riskSettings.liq} setAIdata={setAIdata}
                                                    startDate={_startDate} />
                                            </Grid>
                                        </Grid>
                                    </HeadedPaper>

                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                        <Grid container direction="row" spacing={2} justifyContent="center">
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        Liquidity<br /> Dial
                                    </Typography>
                                    <Tooltip
                                        title="Turn dial to simulate different levels of liquidity risk, with low, medium and high corresponding to available forward contracts (FFAs and bunkers) for hedging 1, 3 and 6 months forward.">
                                        <Gauge values={liquidity} nlevel={20}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, liq: v })} 
                                            init={LIQUIDITY.indexOf(riskSettings.liq)} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        Volatility<br /> Dial
                                    </Typography>
                                    <Tooltip
                                        title="Turn dial to simulate different volatility scenarios (low, medium and high), relative to historical average market volatility">
                                        <Gauge values={volatility}
                                            nlevel={20} setrelVal={(v) => setRiskSettings({ ...riskSettings, vol: v })}
                                            init={VOLATILITY.indexOf(riskSettings.vol)} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        Hedge<br /> Rate
                                    </Typography>
                                    <Tooltip title="adjusts plot for only one type of hedge (rate)">
                                        <Gauge values={hedge} nlevel={3}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, hedgeRate: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        Hedge<br /> Bunkers
                                    </Typography>
                                    <Tooltip title="adjusts plot for only one type of hedge (bunkers)">
                                        <Gauge values={hedge} nlevel={3}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, hedgeBunk: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        Hedge<br /> Emissions
                                    </Typography>
                                    <Tooltip title="adjusts plot for only one type of hedge (emissions)">
                                        <Gauge values={hedge} nlevel={3}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, hedgeEmiss: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={2} justifyContent="center">
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        KwH Sub
                                    </Typography>
                                    <Tooltip title="adjusts plot for subsituting for KwH from sail instead of engine">
                                        <Gauge values={SubVals} nlevel={4}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, kwhSub: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        LNG Sub
                                    </Typography>
                                    <Tooltip title="adjusts plot for subsituting for LNG subsituting">
                                        <Gauge values={SubVals} nlevel={4}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, LNGSub: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        NH3 Sub
                                    </Typography>
                                    <Tooltip title="adjusts plot for subsituting for NH3 subsituting">
                                        <Gauge values={SubVals} nlevel={4}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, NH3Sub: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        MeOH Sub
                                    </Typography>
                                    <Tooltip title="adjusts plot for subsituting for MeOH substiution">
                                        <Gauge values={SubVals} nlevel={4}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, MeOHSub: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Paper>
                                    <Typography variant="body2" style={{ textAlign: "center" }}>
                                        H2 Sub
                                    </Typography>
                                    <Tooltip title="adjusts plot for subsituting for H2 substiution">
                                        <Gauge values={SubVals} nlevel={4}
                                            setrelVal={(v) => setRiskSettings({ ...riskSettings, H2Sub: v })}
                                            init={0} />
                                    </Tooltip>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>
            <Grid item xs={6}>  
                <Grid container direction="column" spacing={2}>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={6}>
                            {(() => {

                                if (!_SimulateTrading) {
                                    return <RiskCashflowChart riskSettings={riskSettings} />
                                } else if (_aidata.length) {
                                    return <RiskCashflowChartAI AIroutes={["Rott_Sing", "AG_China", "AG_China"]}
                                        AIstatus={"load"} volatility={riskSettings.vol}
                                        liquidity={riskSettings.liq}
                                        hedgerate={riskSettings.hedgeRate}
                                        hedgebunkers={riskSettings.hedgeBunk}
                                        hedgeemissions={riskSettings.hedgeEmiss}
                                        aidata={_aidata} pathway={_aiRoute} />
                                }
                            })()}
                        </Grid>
                        <Grid item xs={6}>

                            {(() => {

                                if (!_SimulateTrading) {
                                    return <BreakdownCashflow volatility={riskSettings.vol}
                                        liquidity={riskSettings.liq}
                                        hedgerate={riskSettings.hedgeRate}
                                        hedgebunkers={riskSettings.hedgeBunk}
                                        hedgeemissions={riskSettings.hedgeEmiss} />
                                } else if (_aidata.length) {
                                    return <BreakdownCashflowAI volatility={riskSettings.vol}
                                        liquidity={riskSettings.liq}
                                        hedgerate={riskSettings.hedgeRate}
                                        hedgebunkers={riskSettings.hedgeBunk}
                                        hedgeemissions={riskSettings.hedgeEmiss}
                                        aidata={_aidata} pathway={_aiRoute} />
                                }
                            })()}

                        </Grid>
                        <Grid item xs={6}>
                            <MonthlyEmissionCostsChart volatility={riskSettings.vol} liquidity={riskSettings.liq}/>
                        </Grid>
                        <Grid item xs={6}>
                            <HeadedPaper title="Emission quantities - by vessel">
                                <ThemeProvider theme={theme}>
                                    <EmissionsExposuresChart visible={true} chartHeight={410} presentation="radio"/>
                                </ThemeProvider>
                            </HeadedPaper>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}
