import React, { useState } from "react";



export default function TanksFuelToggle({ setTankType, fuels}) {
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    // Get voyageBunkerObject fields
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    const [selectedFuel, setSelectedFuel] = useState("lsfo")
    const fuelTypes = fuels.map((x) => x.toUpperCase())
    const fuelMap = {
      LSFO: "LSFO",
      HSFO: "HSFO",
      MGO: "MGO",
      LNG: "LNG",
      MeOH: "MeOH",
      BIO: "BIO",
      NH3: "NH3",
      H2: "H2",
      KWH: "KwH",
    };
  
    const clickHandler = (e) => {
        setTankType(e.target.value)
        setSelectedFuel(e.target.value)
    };
    return (
  
      <div className="flex flex-row gap-x-8">
        {Object.keys(fuelMap).map((key) => (
          <div className="flex flex-row gap-x-1 items-center">
            {fuels.includes(fuelMap[key]) && (
              <svg height="10" width="10" className="">
                <circle
                  cx="5"
                  cy="5"
                  r="4"
                  stroke="#5C93C0"
                  strokeWidth="1"
                  fill={key === selectedFuel ? "#5C93C0" : "#FFFFFF"}
                />
              </svg>
            )}
            <button
              onClick={clickHandler}
              disabled={
                !fuels.includes(fuelMap[key])
              }
              value={key}
              className={`text-lg disabled:text-gray-200 disabled:cursor-not-allowed ${
                key === selectedFuel
                  ? "text-ao-blue underline hover:text-black"
                  : "text-gray-500 hover:underline hover:text-ao-blue"
              } `}
            >
              {fuelMap[key]}
            </button>
          </div>
        ))}
      </div>
    );
  }
  