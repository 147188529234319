import { useField } from "formik";

export function CheckBoxField({ name, value, className, children, readonly }) {
  const [field, meta] = useField(name);

  return (
    <label
      className={`${className} flex items-center space-x-4`}
      onClick={(e) => {
        if (readonly) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <input
        type="checkbox"
        className="left-4 h-4 w-4 rounded border-dark-gray-border"
        {...field}
        value={value}
        style={{ pointerEvents: readonly ? "none" : "inherit" }}
        checked={value ? meta.value?.includes(value) : meta.value}
        readOnly={readonly}
        onClick={(e) => {
          if (readonly) {
            e.preventDefault();
            e.stopPropagation();

            return false;
          }
        }}
      />
      <span className="text-base leading-6">{children}</span>
    </label>
  );
}
