import React from "react";
import PortfolioRisks from "./components/PortfolioRisks";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  return (
    <div className="px-4">
        <PortfolioRisks />
   </div>
  );
};
