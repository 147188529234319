import { refFirstLastDates, voyageOverlapsWithDateRange, daysInRange } from "../../../voyageutils"


export function getVoyageFinancialsForSelectedPeriod (selectedPeriod, voyageData, grouping) {
    const currentDate = new Date()
    const [rangeOpen, rangeClose] = refFirstLastDates(currentDate, selectedPeriod)

    let financialsByRoute = {}
    let keys = new Set([])

    for (const voyage of voyageData) {
        
        const isVoyageInDateRange = voyageOverlapsWithDateRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose)
    
        if (isVoyageInDateRange) {
            const key = grouping === "byVessel" ? voyage.vessel.shipName : "ID: " + voyage.routeShortName + " - " + voyage.routeId + " - " + voyage.vessel.shipName
            keys.add(key)
            const voyageDuration = voyage.voyageLength
            const daysInPeriod = Math.round(daysInRange(voyage.openDate, voyage.dischargeDate, rangeOpen, rangeClose))
            const loadFactorRaw = daysInPeriod/voyageDuration
            const loadFactor = loadFactorRaw >= 1 ? 1 : loadFactorRaw
            
            if (!financialsByRoute[key]) {
                financialsByRoute[key] = {
                    totalRevenues: 0,
                    initialTotalExpenses: 0,
                    currentTotalExpenses: 0,
                    tceSum: 0,
                    voyagesInRange: 0,
                    tceInitial: 0,
                    tceDeltaSum: 0,
                    tceCurrent: 0,
                    days: 0,
                    otherCosts: 0,
                    purchasedSum: 0,
                    meanCostSum: 0,
                    portExpenses: 0,
                    emissionsExpenses: 0,
                    robSum: 0,
                    voyagesInRange: 0,
                }
            }

            const robKeys = Object.keys(voyage.robCosts)
            let robSum = 0
            for(const fuelType of robKeys){
                robSum += voyage.robCosts[fuelType].cost
            }
            
            financialsByRoute[key].voyagesInRange += 1
            financialsByRoute[key].totalRevenues += voyage.totalRevenues * loadFactor
            financialsByRoute[key].tceSum += voyage.tce
            financialsByRoute[key].days += voyageDuration * loadFactor
            financialsByRoute[key].portExpenses += voyage.portAndOtherCosts * loadFactor
            financialsByRoute[key].emissionsExpenses += voyage.emissionsCost * loadFactor
            financialsByRoute[key].robSum += robSum
            financialsByRoute[key].initialTotalExpenses += voyage.initialCosting.totalCost * loadFactor
            financialsByRoute[key].otherCosts += (voyage.portAndOtherCosts + voyage.emissionsCost) * loadFactor
            financialsByRoute[key].meanCostSum += voyage.bunkerCost * loadFactor
            financialsByRoute[key].purchasedSum += 0
            
        }
    }

    const keysArray = [...keys]
    financialsByRoute = averageTCEs(financialsByRoute)
    for (const entry in financialsByRoute){
        const record = financialsByRoute[entry]
        const currentTotalExpenses = (record.otherCosts + record.meanCostSum + record.purchasedSum + record.robSum)
        const tceCurrent = (record.totalRevenues - currentTotalExpenses)/record.days
        record.currentTotalExpenses = currentTotalExpenses
        record.tceCurrent = tceCurrent
    }

    return  {
        financialsByRoute: financialsByRoute,
        keys: keysArray
    }

}

export function getVoyageFinancials (voyageData, groupingKey) {

    let costs = {}

    for (const voyage of voyageData) {

        const displayName = (groupingKey=== "byVessel") ? voyage.vessel.shipName : "ID: " + voyage.id + " - " + voyage.routeShortName
        const worstCaseBunkerCost = Object.values(voyage.bunkerCostsWorstCase.med).reduce((a, b) => a + b, 0)
        const bestCaseBunkerCost = Object.values(voyage.bunkerCostsBestCase.med).reduce((a, b) => a + b, 0)
        const robKeys = Object.keys(voyage.robCosts)
        let robSum = 0
        for(const fuelType of robKeys){
            robSum += voyage.robCosts[fuelType].cost
        }
        costs[voyage.id] = {
            vessel: voyage.vessel.shipName,
            worstCaseSum: worstCaseBunkerCost,
            bestCaseSum: bestCaseBunkerCost,
            worstCaseStub: worstCaseBunkerCost - bestCaseBunkerCost,
            meanCostStub: voyage.bunkerCost - worstCaseBunkerCost,
            portExpenses: voyage.portAndOtherCosts,
            emissionsExpenses: voyage.emissionsCost,
            otherCosts: 0,
            robSum: robSum,
            voyageID: voyage.id,
            displayName: displayName,
            byVessel: voyage.vessel.shipName,
            longName: voyage.routeLongName,
            meanCostSum: voyage.bunkerCost,
            revenue: voyage.totalRevenues,
            tceInitial: voyage.initialCosting.tce,
            tceCurrent: voyage.tce,
            voyageDTCE: voyage.deltaTce,
            purchasedSum: 0,
            initialExpenses: voyage.initialCosting.totalCost,
            duration: voyage.voyageLength,
            totalCost: voyage.totalCost
        }
        costs[voyage.id].otherCosts = costs[voyage.id].emissionsExpenses + costs[voyage.id].portExpenses

    }

    return costs
}


const averageTCEs = (financialsByRoute) => {

    for (const key in financialsByRoute){
        financialsByRoute[key].tceInitial = financialsByRoute[key].tceSum/financialsByRoute[key].voyagesInRange
    }
    return financialsByRoute
}