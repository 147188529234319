import SPGCIDisclaimer from "../../components/SPGCIDisclaimer"


export function DataAttribution({data, isTCE}){
    
    let origSPGCI, constrSPGCI, origBunkerEx, constrBunkerEx, origBaltic, constrBaltic = false
    for(const record of data){
        const andConstructed = (record.constructedSpot) && (record.constructedCurve)
        const orConstructed = (record.constructedSpot) || (record.constructedCurve)
        if(record.provider === "SPGCI"){
            origSPGCI = origSPGCI || !andConstructed
            constrSPGCI = constrSPGCI || orConstructed
        } else if (record.provider === "BunkerEx" && isTCE){
            constrBaltic = true
            constrBunkerEx = true
        } else if (record.provider === "BunkerEx"){
            origBunkerEx = origBunkerEx || !andConstructed
            constrBunkerEx = constrBunkerEx || orConstructed
        } else if (record.provider === "BALTIC" && isTCE){
            constrBaltic = true
            constrBunkerEx = true
        } else if (record.provider === "BALTIC") {
            origBaltic = origBaltic || !andConstructed
            constrBaltic = constrBaltic || orConstructed
        }
    }

    let otherData, otherDerived = ""

    if(origBunkerEx && origBaltic){
        otherData = "BunkerEx and Balitc Exchange Data"
    } else if (origBunkerEx){
        otherData = "BunkerEx Data"
    } else if (origBaltic){
        otherData = "Balitc Exchange Data"
    }

    if(constrBunkerEx && constrBaltic){
        otherDerived = "Values derived from BunkerEx and Balitc Exhange data"
    } else if (constrBunkerEx){
        otherDerived = "Values derived from BunkerEx data"
    } else if (constrBaltic){
        otherDerived = "Values derived from Baltic Exchange data"
    }


    return (
        <div className="flex flex-col mt-20 w-full">
            {
                (origSPGCI || constrSPGCI) &&
                (   
                    <div className="flex flex-row items-center">
                        <h4 className="text-xs text-gray-400">
                            {origSPGCI && <span className="text-SPGCI-color font-bold">SPGCI Data</span>}
                            {origSPGCI && <span>. </span>}
                            {constrSPGCI && <span className="text-SPGCI-constructed italic">Values derived from SPGCI Data. </span>}
                            <span className="text-gray">S&P Global Commodity Insights, ©2024 by S&P Global Inc.</span>
                        </h4>
                        <SPGCIDisclaimer/>
                    </div>
                )
            }
            {
                (origBunkerEx || origBaltic || constrBunkerEx || constrBaltic) &&
                (
                    <h4 className="text-xs text-gray-400">
                        {(origBunkerEx || origBaltic) && <span className="text-ao-figma-text-gray font-bold">{otherData}</span>}
                        {(origBunkerEx || origBaltic) && <span>. </span>}
                        {(constrBunkerEx || constrBaltic) && <span className="text-gray-400 italic">{otherDerived}</span>}
                    </h4>
                )
            }
        </div>
    )
}