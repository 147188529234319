import { MenuItem, Select } from "@material-ui/core"
import React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { bunkersFiltered, createSelectedBunkerItem, selectedBunkers } from "../../store/ui"
import { DATA_SOURCE, fuelTypeNames } from "../../utils/Constants"
import { replaceItemAtIndex } from "../../utils/Helpers"


export default function BunkerSelect({ index }) {
    const _bunkersFiltered = useRecoilValue(bunkersFiltered)

    const [_selectedBunkers, setSelectedBunkers] = useRecoilState(selectedBunkers)

    const bunkerexItems = _bunkersFiltered.bunkerex.map((bunker) => createProviderBunkerUiItem(bunker, DATA_SOURCE.BUNKEREX))
        .sort((i1, i2) => i1.caption > i2.caption ? 1 : -1)

    const plattsItems = _bunkersFiltered.platts.map((bunker) => createProviderBunkerUiItem(bunker, DATA_SOURCE.PLATTS))
        .sort((i1, i2) => i1.caption > i2.caption ? 1 : -1)

    const selectedBunker = _selectedBunkers[index]

    const onChangeHandler = (event) => {
        const value = event.target.value
        let newBunker;
        if (value.isBunkerEx) {
            newBunker = bunkerexItems.find((b) => b.captionLong === value.captionLong)
        } else {
            newBunker = plattsItems.find((b) => b.captionLong === value.captionLong);
        }

        const newSelectedBunkers = replaceItemAtIndex(_selectedBunkers, index, {
            ...selectedBunker, item: createSelectedBunkerItem(newBunker, value.isBunkerEx)
        })
        setSelectedBunkers(newSelectedBunkers)
    }

    // If for some reason the selected bunker no longer exists, replace with empty string
    let value = selectedBunker
        ? typeof selectedBunker.item === "string" ? selectedBunker.item : (selectedBunker.item.isBunkerEx
            // find the one form the current bunkerexItems; select component requires reference of an option as value; long captions are unique
            ? bunkerexItems : plattsItems).find((i) => i.portName === selectedBunker.item.port && i.fuelType === selectedBunker.item.fuelType) || selectedBunker.item
        : ""

    return (
        <Select
            onChange={onChangeHandler}
            style={{ "fontSize": 11, paddingLeft: 5 }}
            value={value}
            renderValue={getItemCaption}
            fullWidth
        >
            {plattsItems?.length > 0 && <div className="border-b-2 p-1 border-gray-400 pointer-events-none font-semibold text-lg text-center"> SPGCI Bunkers </div>}
            {plattsItems?.length > 0 &&
                plattsItems.map((item, i) => {
                    return <MenuItem key={item.caption + i} value={item} selected={true}>
                        {`${item.captionLong} (${item.symbol})`}
                    </MenuItem>
                })
            }

            {bunkerexItems?.length > 0 && <div className="border-b-2 p-1 border-gray-400 pointer-events-none font-semibold text-lg text-center"> BunkerEx Bunkers </div>}
            {bunkerexItems?.length > 0 &&
                bunkerexItems.map((item, i) => {
                    return <MenuItem key={item.caption + i} value={item}>
                        {item.captionLong}
                    </MenuItem>
                })
            }
        </Select>
    )
}

function createProviderBunkerUiItem(priceObj, dataSource) {
    const fuelTypeLongName = fuelTypeNames[priceObj.fuelType] || priceObj.fuelType
    return {
        portName: priceObj.portName,
        fuelType: priceObj.fuelType,
        isBunkerEx: DATA_SOURCE.isBunkerex(dataSource.name),
        captionLong: `${priceObj.portName} ${fuelTypeLongName}`,
        caption: `${priceObj.portName} ${priceObj.fuelType}`,
        symbol: priceObj.providerData.spotSymbol ? priceObj.providerData.spotSymbol : "",
        isProviderData: true,
        providerData: priceObj.providerData
    }
}

function getItemCaption(item) {
    return typeof item == 'string' ? item : item.caption
}
