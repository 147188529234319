import axios from 'axios';
import { convertToSentenceCase } from "../../utils/Helpers"

import { keycloak } from "../../utils/hooks/use-keycloak";
import { ProviderStatus } from '../../utils/Constants';

/**
 * A wrapper to fetch data from the Bunker Ex api
 * Calls should return object with success boolean, and error message in error
 * if not successful.
 *
 */
const servicesApiUrl = process.env.REACT_APP_SERVICES_API_URL

export async function FetchBunkerPrices(provider, fuels, port) {
    
    const params = {
        dataProvider: provider,
        fuelType: fuels,
    }
    if(port){
        params["location"] = port
    }

    const headers ={
        "Authorization": `Bearer ${keycloak.token}`
    }
    
    try{
      const response = await axios.get(`${servicesApiUrl}/v1/bunkers/prices`, {params: params, headers: headers})
      .catch(({response: erorResponse}) => {
        if(erorResponse.status === 400 && erorResponse.data.error_code === "DATA_SOURCE_NOT_ACTIVE"){
          return {data: []}
        }
      })

      return {
        success: true, results: response.data
      }
    }
    catch(e) {
      console.log('>>>>>>>>>>>>>>>>>>', e)
      return {success: true, results: []}
    }

}

export async function FetchPlattsEmissionPrices() {

  const headers ={
    "Authorization": `Bearer ${keycloak.token}`
  }
  
  const response = await axios.get(`${servicesApiUrl}/v2/emissions/prices`, {headers: headers})
  .catch(({response: erorResponse}) => {
    if(erorResponse.status === 400 && erorResponse.data.error_code === "DATA_SOURCE_NOT_ACTIVE"){
      return {data: []}
    }
  })

  return {
    success: true, results: response.data
  }
}

export async function FetchTankData(shipName, shipType, shipId){
    console.log(shipName, shipType, shipId)
    const response = await fetch(`${servicesApiUrl}/v1/ship/tank_status?ship_name=${shipName}&ship_type=${shipType}&ship_id=${shipId}`, {
        headers: {
            "Authorization": `Bearer ${keycloak.token}`
        }
    })
    if (!response.ok) {
        throw new Error(`An error has occurred fetching tank data: ${response.status}`)
    }
    const json = await response.json()
    if (json.errorMessage) {
        throw new Error(`An error has occurred fetching tank data: ${json.errorMessage}`)
    }

    return {
        success: true, data: json.data
    }
}

export async function LoadStem(shipName, fuelType){

    const response = await fetch(`${servicesApiUrl}/v1/ship/load_stem?ship_name=${shipName}&fuel_type=${fuelType}`, {
        headers: {
            "Authorization": `Bearer ${keycloak.token}`
        }
    })
    if (!response.ok) {
        throw new Error(`An error has occurred loading the stem: ${response.status}`)
    }
    const json = await response.json()
    if (json.errorMessage) {
        throw new Error(`An error has occurred loading the stem: ${json.errorMessage}`)
    }
    return {
        success: true, data: json.data
    }
}

export async function FetchPortfolioRisk(vol){
    const response = await fetch(`${servicesApiUrl}/v1/voyage_engine/portfolio_risk?volatility=${vol}`, {
        headers: {
            "Authorization": `Bearer ${keycloak.token}`
        }
    })
    if (!response.ok) {
        throw new Error(`An error has occurred fetching portfolio risk: ${response.status}`)
    }
    const json = await response.json()
    if (json.errorMessage) {
        throw new Error(`An error has occurred fetching portfolio risk: ${json.errorMessage}`)
    }
    return {
        success: true, data: json
    }
}

export async function FetchRoutePrices(routeShortName, volatility = "med") {

    if (!routeShortName) {
        return { success: true, prices: {} }
    }
    const response = await fetch(`${servicesApiUrl}/v1/routes/prices?routeId=${routeShortName}&volatility=${volatility}`, {
        headers: {
            "Authorization": `Bearer ${keycloak.token}`
        }
    })

    let routePrices
    if (!response.ok) {
        routePrices = [[]]
    }
    routePrices = await response.json()
        return { success: true, prices: routePrices[0] }
}

export async function FetchRouteTceComps(route, volatility = "med", bunkerDataSource, excludeEmissionsCosts) {
    if (!route) {
        return { success: true, prices: {} }
    }

    const response = await axios.get(`${servicesApiUrl}/v1/tce`, {params: {route, volatility, bunkerDataSource, excludeEmissionsCosts}, headers: {
      "Authorization": `Bearer ${keycloak.token}`
  }})
    .catch(({response: erorResponse}) => {
      if(erorResponse.status === 400 && erorResponse.data.error_code === "DATA_SOURCE_NOT_ACTIVE"){
        return {data: null}
      }
    })

    return {
      success: true, tceComponentCurves: response.data
    }
}

export async function FetchRegionPortList(shipType, region){
    const response = await fetch(`${servicesApiUrl}/v1/voyage_engine/region_port_list?ship_type=${shipType}&region=${convertToSentenceCase(region)}`)
    
    if (!response.ok) {
        throw new Error(`An error has occurred fetching port list: ${response.status}`)
    }
    const json = await response.json()
    if (json.errorMessage) {
        throw new Error(`An error has occurred port list: ${json.errorMessage}`)
    }
    return {
        success: true, ports: json
    }
}

export async function PostStem(shipName, shipType, shipId, data, isEdit){

    const keyConversions = [
        ["quantity", "quantity"],
        ["price", "price"],
        ["shipName", "ship_name"],
        ["fuelType", "fuel_type"],
        ["supplier", "supplier"],
        ["stemDate", "stem_date"],
        ["recordedDate", "recorded_date"],
        ["port", "port"],
        ["id", "id"]
    ]
    const dataPost = {}
    for(const pair of keyConversions){
        dataPost[pair[1]] = data[pair[0]]
    }

    dataPost["ship_name"] = shipName
    dataPost["ship_type"] = shipType
    dataPost["ship_id"] = shipId

    const url = `${servicesApiUrl}/v1/ship/` + (isEdit ? "edit_stem" : "queue_stem" )

    const axiosConfig = {
        method: "POST",
        url: url,
        data: JSON.stringify(dataPost),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${keycloak.token}`
        }
    }
    try {
        const response = await axios(axiosConfig)
        return response
    } catch (error) {
        console.error(error);
    }

}

export async function FetchProviderStatus(provider) {
  try {
    const response = await fetch(
      `${servicesApiUrl}/v1/integrations/data_provider_status?data_provider=${encodeURIComponent(provider)}`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      }
    );
    if (!response.ok) {
      return {
        success: false,
        data: ProviderStatus.ERROR,
      };
    }

    const status = await response.json();

    return {
      success: true,
      data: ProviderStatus[status],
    };
  } catch (error) {
    return {
      success: false,
      data: ProviderStatus.ERROR,
    };
  }
}

export async function FetchSpgciSettings() {
  try {
    const response = await fetch(
      `${servicesApiUrl}/v1/integrations/spgci/settings`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      }
    );
    if (!response.ok) {
      if (response.status === 404) {
        return {
          success: true,
          data: null,
        };
      }

      return {
        success: false,
        data: {},
      };
    }

    const json = await response.json();

    return {
      success: true,
      data: json,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: {},
    };
  }
}

export async function SaveSpgciSettings(settings) {
  try {
    const response = await fetch(
      `${servicesApiUrl}/v1/integrations/spgci/settings`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(settings),
      }
    );
    if (!response.ok) {
      if (response.status === 400) {
        return {
          success: false,
          data: await response.json(),
        };
      }

      return {
        success: false,
        data: {},
      };
    }

    const json = await response.json();

    return {
      success: true,
      data: json,
    };
  } catch (error) {
    return {
      success: false,
      data: {},
    };
  }
}
