import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import React, { Suspense } from "react"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import AppLayout from "./components/AppLayout/AppLayout"
import PrefetchData from "./components/PrefetchData"
import { navRoutes, userRoutes } from "./routes"
import { useAuth, useKeycloak } from "./utils"


export default function App() {

    const { isAuthed } = useAuth()

    const { status: keycloakStatus } = useKeycloak()

    if (keycloakStatus === "LOADING") {
        return null
    }

    const routes = [...navRoutes, ...userRoutes]

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
                <AppLayout routes={routes}>
                    <Switch>
                        {routes.map(({ path, Component, subpaths }) => (
                            !subpaths ?
                            <Route key={path} path={path}>
                                <Suspense fallback={<div>Loading freight data...</div>}>
                                    <PrefetchData />
                                    <Component />
                                </Suspense>
                            </Route>
                            : subpaths.map(({ path, Component}) => (
                                <Route key={path} path={path} exact>
                                <Suspense fallback={<div>Loading freight data...</div>}>
                                    <PrefetchData/>
                                    <Component/>
                                </Suspense>
                            </Route>
                            ) )
                        ))}
                    </Switch>
                </AppLayout>
            </Router>
        </LocalizationProvider>
    )
}
