import { DATA_SOURCE } from "../utils/Constants"

export const availableRoutesFilterDefault = {
    dataSource: DATA_SOURCE.PLATTS.name,
    showConstructedRouteCurves: true,
    showConstructedBunkerCurves: true,

    "clean": true, // Clean / Dirty
    "dirty": true,

    "vlcc": true, // Vessel Type
    "suezmax": true,
    "aframax/LR2": true,
    "panamax/LR1": true,
    "mr": true,
    "handy": true,

    "aus": true, // Regions
    "bal": true,
    "bla": true,
    "car": true,
    "chi": true,
    "eaf": true,
    "emx": true,
    "ind": true,
    "jap": true,
    "med": true,
    "agu": true,
    "nor": true,
    "pac": true,
    "red": true,
    "sea": true,
    "saf": true,
    "sam": true,
    "sko": true,
    "ukc": true,
    "usa": true,
    "usg": true,
    "usw": true,
    "waf": true,
    "far": true,

    // fuel types,
    "lsfo": true,
    "hsfo": true,
    "mgo": true
}

export const availableRoutesFilterRegionsOff = {
    "aus": false, // Regions
    "bal": false,
    "bla": false,
    "car": false,
    "chi": false,
    "eaf": false,
    "emx": false,
    "ind": false,
    "jap": false,
    "med": false,
    "agu": false,
    "nor": false,
    "pac": false,
    "red": false,
    "sea": false,
    "saf": false,
    "sam": false,
    "sko": false,
    "ukc": false,
    "usa": false,
    "usg": false,
    "usw": false,
    "waf": false,
    "far": false
}

export const routesLayoutDefaultBaltic = [{
        item: "TD2",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: true,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Baltic",
        shipType: "VLCC"
    }, {
        item: "TD20",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Baltic",
        shipType: "Suezmax"
    }, {
        item: "TC2",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Baltic",
        shipType: "MR"
    }, {
        item: "TC6",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Baltic",
        shipType: "Handy"
    }]

export const routesLayoutDefaultSPGCI = [{
        item: "AG_Japan_VLCC",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: true,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Platts",
        shipType: "VLCC"
    }, {
        item: "USGC_UKC_LR2",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Platts",
        shipType: "Aframax/LR2"
    }, {
        item: "RedSea_Med_LR1",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Platts",
        shipType: "Panamax/LR1"
    }, {
        item: "Sing_Aus_HaC",
        vesselID: null,
        selectedInPricingEngine: true,
        selectedInRiskEngine: false,
        selectedInEmissionsEngine: true,
        defaultModelParameters: {},
        balticPlatts: "Platts",
        shipType: "Handy"
    }]

export const emissionsLayoutsDefault = [{
    item: "EUAEA00",
    selected: true
}]

export const bunkersLayoutsDefaultSPGCI = [{
        item: {
            fuelType: "HSFO",
            port: "Gibraltar",
            isBunkerEx: false,
        },
        selected: true
    }, {
        item:  {
            port: "Houston",
            fuelType: "LSFO",
            isBunkerEx: false,
        },
        selected: true
    }, {
        item: {
            port: "Rotterdam",
            fuelType: "HSFO",
            isBunkerEx: false,
        },
        selected: true
    },
    {
        item: {
            port: "Singapore",
            fuelType: "HSFO",
            isBunkerEx: false,
        },
        selected: true
    }]

export const bunkersLayoutsDefaultBunkerEx = [{
        item: {
            fuelType: "HSFO",
            port: "Gibraltar",
            isBunkerEx: true,
        },
        selected: true
    }, {
        item:  {
            port: "Houston",
            fuelType: "LSFO",
            isBunkerEx: true,
        },
        selected: true
    }, {
        item: {
            port: "Rotterdam",
            fuelType: "HSFO",
            isBunkerEx: true,
        },
        selected: true
    },
    {
        item: {
            port: "Singapore",
            fuelType: "HSFO",
            isBunkerEx: true,
        },
        selected: true
    }]
    
export const bunkersEmissionsManagementLayoutsDefault = []