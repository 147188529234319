import React from "react";
import Tanks from "./components/Tanks";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  return (
    <div className="px-4">
        <Tanks />
   </div>
  );
};
