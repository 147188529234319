import React, { useState, useEffect } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  DiscreteColorLegend,
} from "react-vis";
import { useRecoilState } from "recoil";
import { availableBunkersFilter } from "../../store/ui";
import SPGCIDisclaimer from "../SPGCIDisclaimer";
import { DATA_SOURCE } from "../../utils/Constants";


const FreightRateSpotChart = ({ data }) => {
  const [_availableBunkersFilter, setAvailableBunkerFilter] = useRecoilState(availableBunkersFilter)
  const seriesData = [];
  data.map((row) => {
    let series = {};
    series.x = row.routeAxis;
    series.y = { Rate: row.price }
    series.provider = row.provider
    seriesData.push(series);
  });
  
  const legendItems = ["Rate"]; 
  
  return (
    <div>
      <XYPlot xType="ordinal" width={700} height={700} xDistance={100} margin={{bottom: 300}}>
        <XAxis tickLabelAngle={-90}/>
        <YAxis />
        {legendItems.map((yKey, index) => (
          <VerticalBarSeries
            key={yKey}
            data={seriesData.map((d) => ({ x: d.x, y: d.y[yKey] }))}
          />
        ))}
      </XYPlot>
    </div>
  );
};

export default FreightRateSpotChart;
