import { selector, selectorFamily } from "recoil"
import { RouteLookup } from "../dataMocks/RouteLookup"
import { getUniqueValuesForKey } from "../utils/Helpers"
// import { FetchBunkerExPrices } from "./api/bunkerex"  
import { FetchPlattsEmissionPrices,
        FetchTankData,
        FetchPortfolioRisk,
        FetchRoutePrices,
        FetchRouteTceComps,
        FetchBunkerPrices,
        FetchRegionPortList } from "./api/services-api"

import {
    FetchBunkerParams,
    FetchBunkers_v1, FetchBunkers_v5,
    FetchPathways, FetchPortListByShipType_v3,
    FetchRouteParamsIn,
    FetchRouteParamsUnpriced,
    FetchSimulations
} from "./api/data"

import { regions } from "../routes/TwVoyageEngine/components/VoyageEngineLogic"

/**
 * The place for front end to grab API api, the rest is abstracted
 * to a wrapper, where the database calling code will reside
 */

/** The list of valid ship types, and route types used throughout the app
 */
export const validVesselTypes = ["vlcc", "suezmax", "aframax/LR2", "panamax/LR1", "mr", "handy"]

export const getRoutePrices = selectorFamily({
    key: "getRoutePrices",
    get: (routeId, volatility) => async () => {
        const response = await FetchRoutePrices(routeId, volatility)
        if (response.success) {
            return response.prices
        } else {
            throw response.error
        }

    }
})

export const getRouteTceComps = selectorFamily({
    key: "getRouteTceComps",
    get: ({route, vol, dataSource, excludeEmissionsCosts }) => async () => {
        const response = await FetchRouteTceComps(route, vol, dataSource, excludeEmissionsCosts)
        if (response.success) {
            return response.tceComponentCurves
        } else {
            throw response.error
        }

    }
})

/**
 * Get abridged version of routes
 */
export const getRouteParamsIn = selector({
    key: "getRouteParamsIn",
    get: async () => {
        const response = await FetchRouteParamsIn()
        if (response.success) {
            return response.data
        } else {
            throw response.error
        }
    }
})

export const getRouteParamsUnpriced = selector({
    key: "getRouteParamsUnpriced",
    get: async () => {
        const response = await FetchRouteParamsUnpriced()
        if (response.success) {
            return response.data
        } else {
            throw response.error
        }
    }
})

/**
 * Get model ship info (e.g. routes, blatic/platts, ship type)
 * TODO: Will this be fetched or be baked in to URL?
 */
export const getRouteLookup = selector({
    key: "GetModelShipInfo",
    get: () => {
        return RouteLookup
    }
})

/**
 * Get all tankers
 */
export const getAllSimulations = selector({
    key: "GetAllTankers",
    get: async () => {
        const response = await FetchSimulations()
        if (response.success) {
            return response.tankers
        } else {
            throw response.error
        }
    }
})

/**
 * Get port list by ship type
 */
export const getPortListByShipType = selector({
    key: "getPortListByShipType",
    get: async () => {
            const response = await FetchPortListByShipType_v3()
            if (response.success) {
                return response.ports
            } else {
                throw response.error
            }
            }
})

export const getDischargingPorts = selectorFamily({
    key: "getDischargingPorts",
    get: (params) => async () => {
        const {shipName, region} = params
        if (regions.includes(region)){
            const response = await FetchRegionPortList(shipName, region)
            if (response.success) {
                return response.ports
            } else {
                throw response.error
            }
        } else {
            return []
        }
    }
})

/**
 * Get all bunkers - new updated structure with day to day values
 */
export const getAllBunkers_v5 = selector({
    key: "getAllBunkers_v5",
    get: async () => {
        const response = await FetchBunkers_v5()
        if (response.success) {
            return response.bunkers
        } else {
            throw response.error
        }
    }
})


/**
 * Get all bunkers - legacy version of file
 */
export const getAllBunkers_v1 = selector({
    key: "getAllBunkers_v1",
    get: async () => {
        const response = await FetchBunkers_v1()
        if (response.success) {
            return response.bunkers
        } else {
            throw response.error
        }
    }
})

/**
 * Get all bunker parameters 
 */
export const getBunkerParams = selector({
    key: "getBunkerParams",
    get: async () => {
        const response = await FetchBunkerParams()
        if (response.success) {
            return response.data
        } else {
            throw response.error
        }
    }
})

/**
 * Get all bunker prices 
 */
export const bunkerPrices = selector({
    key: "bunkerPrices",
    get: async () => {

        const [bunkerexRespIFOMGO, plattsAll] =
        await Promise.all([FetchBunkerPrices("BunkerEx"),
        FetchBunkerPrices("SPGCI")])
        const bunkerexResponse = [...bunkerexRespIFOMGO.results]
        const plattsResponse = [...plattsAll.results]
      
        return {
            bunkerex: bunkerexResponse,
            platts: plattsResponse
        }
    }
})

export const bunkerPricesPort = selectorFamily({
    key: "bunkerPricesPort",

    get: ({ provider, fuels, port }) => async () => {
        const response = await FetchBunkerPrices(provider, fuels, port)
        
        return response.results
    }
})

export const emissionPrices = selector({
    key: "emissionPrices",
    get: async() => {

        const response = await FetchPlattsEmissionPrices()

        return {
            platts: [...response.results]
        }
    }
})

/**
 * Get all emissions
 */
export const getAllEmissions = selector({
    key: "GetAllEmissions",
    get: async () => {
        const response = await FetchPlattsEmissionPrices()
        if (response.success) {
            return response.results[0]
        } else {
            throw response.error
        }
    }
})

export const getPathways = selector({
    key: "getPathways",
    get: async () => {
        const response = await FetchPathways()
        if (response.success) {
            return response.pathways
        } else {
            throw response.error
        }
    }
})

export const getUniquePathways = selector({
    key: "GetUniquePathways",
    get: async (get) => {
        const pathways = get(getPathways)
        return getUniqueValuesForKey(pathways, "originRegion")
    }
})

export const getShipTankData = selectorFamily({
    key: "getShipTankData",
    get: (inputs) => async () => {
        const response = await FetchTankData(inputs.shipName, inputs.shipType, inputs.id)
        if (response.success) {
            return response.data
        } else {
            throw response.error
        }
    }
})

export const getPortfolioRisk = selectorFamily({
    key: "getPortfolioRisk",
    get: (vol) => async () => {
        const response = await FetchPortfolioRisk(vol)
        if (response.success) {
            return response.data
        } else {
            throw response.error
        }
    }
})
