import {useEffect, useState} from "react"

const useTime = (frequency) => {
    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date())
        }, frequency)

        return () => {
            clearInterval(interval)
        }
    }, [frequency])

    return time
}

export default useTime
