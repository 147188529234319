import React, { useState, useEffect } from "react";
import FlexibleChart from "../../../components/Charts/FlexibleChart";
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  DiscreteColorLegend,
} from "react-vis";
import { DefaultAxisStyle } from "../../../utils/Constants";
import { getBunkerDataForSelectedPeriodByFuel } from "./bunkerModalComponents/calculations/calcBunkerChart";
import { useRecoilValue } from "recoil";
import { voyages } from "../../../store/voyages";
const metrics = {
  all: "All",
  current: "Current Month",
  quarter: "Current Quarter",
  historical: "Historical"
};

const chartUnits = {
  current: "$/Mt",
  quarter: "$/Mt",
  all: "$/Mt",
  historical: "$/Mt"
};

const legendCostBreakDown = [
  { title: "ROB Bunkers", color: "blue" },
  { title: "Purchased Bunkers", color: "#ebb434" },
  { title: "Best Case Bunkers", color: "green" },
  { title: "Worst Case Bunkers", color: "red" },
];


function makeSeries(data, grouping, dataPoint) {

  const record = {}
  const dataKeys = Object.keys(data)

  if(grouping === "byFuel"){
    for(const fuel of dataKeys){

      if(record[fuel]){
        record[fuel].y += data[fuel][dataPoint]
      } else{
        record[fuel] = {
          x: fuel,
          y: data[fuel][dataPoint]
        }
      }
    }
    return Object.values(record)
  }

  const groupingKey = grouping==="byVessel" ? "vessel" : "displayName"
  for (const item of dataKeys){
    const voy = data[item]
    const key = voy[groupingKey]

    if(record[key]){
      record[key].y += voy[dataPoint]
    } else{
      
      record[key] = {
        x: key,
        y: voy[dataPoint]
      }
      
    }
  }

  const series = Object.values(record)
  return series
}


export default function BunkerTableGroupedChartFuel({ selectedVoyages, grouping }) {
  const [_metricSelect, setMetricSelect] = useState("All");
  const [_voyageBunkerData, setVoyageBunkerData] = useState({});
  const _voyages = useRecoilValue(voyages)
 
  useEffect(() => {
    const getData = async () => {
        const relevantVoyages = _voyages.filter((v) => selectedVoyages.includes(v.id))
        const { bunkersByGroup } = getBunkerDataForSelectedPeriodByFuel(_metricSelect, relevantVoyages)
        setVoyageBunkerData(bunkersByGroup)
    }
    getData()
  }, [_metricSelect, selectedVoyages, grouping, _voyages])


  return (
    <>
      <select
      className="p-1 border border-1 rounded-md"
        onChange={(ev) => setMetricSelect(ev.target.value)}
      >
        {Object.keys(metrics).map((x) => (
          <option key={x} value={x}>
            {metrics[x]}
          </option>
        ))}
      </select>

      <div className="mt-2">
        {selectedVoyages.length > 0 &&
        Object.keys(_voyageBunkerData).length > 0 ? (
          <div>
          {renderChart(_voyageBunkerData, grouping, _metricSelect)}
          </div>
        ) : (
          <p>No data available for selected voyages.</p>
        )}
      </div>
    </>
  );
}

function renderChart(_voyageBunkerData, grouping, _metricSelect){
    return (
      <>
      <div class="relative overflow-x-auto mt-10">
        <FlexibleChart
          height={400}
          margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
          xType="ordinal"
          stackBy="y"
        >
          <XAxis style={DefaultAxisStyle} tickLabelAngle={-90} />
          <YAxis style={DefaultAxisStyle} title={chartUnits[_metricSelect]} />
          <HorizontalGridLines style={DefaultAxisStyle} />
          <VerticalGridLines style={DefaultAxisStyle} />
          <VerticalBarSeries
            data={makeSeries(_voyageBunkerData, grouping, "bestCaseSum")}
            color="green"
            barWidth={0.3}
          />
          <VerticalBarSeries
            data={makeSeries(_voyageBunkerData, grouping, "worstCaseStub")}
            color="red"
            barWidth={0.3}
          />
        </FlexibleChart>
        </div>
        <DiscreteColorLegend
          items={legendCostBreakDown}
          style={DefaultAxisStyle}
        />
      </>
  )
}