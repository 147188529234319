import React from "react";
import VoyageTable from "./components/VoyageTable";
import { useRecoilValue } from "recoil";
import { userSettings } from "../../store/ui";
import { DATA_SOURCE, ProviderStatus } from "../../utils/Constants";
import { spgciStatus } from "../../store/integrations";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const _spgciStatus = useRecoilValue(spgciStatus);
  const spgciActive = _spgciStatus === ProviderStatus.ACTIVE;

  let noDataSources = 0;
  let bunkerDataSource = DATA_SOURCE[useRecoilValue(userSettings).bunkerPricesSource]
  if (bunkerDataSource === DATA_SOURCE.PLATTS && !spgciActive) {
    bunkerDataSource = DATA_SOURCE.NONE
    ++noDataSources
  }

  let emissionsdataSource = DATA_SOURCE[useRecoilValue(userSettings).emissionsPricesSource]
  if (emissionsdataSource === DATA_SOURCE.PLATTS && !spgciActive) {
    emissionsdataSource = DATA_SOURCE.NONE
    ++noDataSources
  }

  return (
    <div className="px-4">
      <VoyageTable />
      <div className="text-xs ml-2 mt-2 text-gray-500">
        {noDataSources < 2 && `Dynamic TCEs and voyage costs are derived from ${bunkerDataSource !== DATA_SOURCE.NONE ? bunkerDataSource.caption + ' bunker prices ' : ''}
        ${emissionsdataSource && emissionsdataSource !== DATA_SOURCE.NONE
            ? ("and " + emissionsdataSource.caption + " emission prices")
            : ""} using AlphaOcean quantitative models.`}
      </div>
    </div>
  );
};
