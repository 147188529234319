import {getRouteParamsIn} from "../../store/api"
import {getRecoil} from "recoil-nexus"
import {portDistances} from "../../store/port"


const BunkerMultiplier = (route, params) => {

    const routeparamsin = getRecoil(getRouteParamsIn)
    const routeparam = routeparamsin[route]

    if (routeparam === undefined) {
        console.error("Unknown route: " + route)
        return 1
    }

    let nla = 0.49 * (routeparam["ndays"] - routeparam["loadingDays"] - routeparam["dischargingDays"] - routeparam["waitingDays"])
    let nba = 0.51 * (routeparam["ndays"] - routeparam["loadingDays"] - routeparam["dischargingDays"] - routeparam["waitingDays"])

    let totalconsDis = routeparam["dischargingDays"] * routeparam["dischargingConsumption"]
    let totalconsWait = routeparam["waitingDays"] * routeparam["waitingConsumption"]
    let totalconsLoad = routeparam["loadingDays"] * routeparam["loadingConsumption"]
    let totalconsLad = nla * routeparam["ladenConsumption"] * params["ladenMultiplier"]
    let totalconsBal = nba * routeparam["ballastConsumption"] * params["ballastMultiplier"]
    let totalconsR = routeparam["fuelconsFO"] + routeparam["fuelconsGO"]
    let bunkMult = (totalconsLad + totalconsBal + totalconsLoad + totalconsWait + totalconsDis) / totalconsR

    return bunkMult
}

const JourneyTimeMultiplier = (route, params) => {

    const routeparamsin = getRecoil(getRouteParamsIn)
    const routeparam = routeparamsin[route]

    if (routeparam === undefined) {
        console.error("Unknown route: " + route)
        return 1
    }

    const processingDays = routeparam["loadingDays"] + routeparam["dischargingDays"] + routeparam["waitingDays"]
    const daysOutToSea = routeparam["ndays"] - processingDays
    let daysLaden = 0.49 * daysOutToSea
    let daysBallast = 0.51 * daysOutToSea

    if (params && ("modelShipSlowSteam" in params) && params["modelShipSlowSteam"] === 1) {
        daysLaden = daysLaden * 13 / 11.0
        daysBallast = daysBallast * 12.5 / 11.0
    }

    let timeMult = (daysLaden + daysBallast + processingDays) / routeparam["ndays"]
    return timeMult
}

const scrubberFuel = (params) => {
    return params["scrubber"] === 0 ? "bunker" : "bunkerHS"
}

const PortDistanceMultiplier = (voyage) => {
    const _portDistances = getRecoil(portDistances)

    if (voyage.defaultDistance === undefined) {
        console.error("defaultDistance undefined")
        console.log(voyage)
        return 1
    }

    if (voyage.defaultDistance === -1) {
        return 1 // Skip port distance if no default set
    }

    if (voyage.startingPort === undefined || voyage.endPort === undefined) {
        console.error("startingPort or endPort undefined")
        console.log(voyage)
        return 1
    }

    if (!(voyage.startingPort in _portDistances)) {
        console.error("startingPort not found")
        console.log(voyage)
        return 1
    }

    if (!(voyage.endPort in _portDistances[voyage.startingPort])) {
        console.error("startingPort not found")
        console.log(voyage)
        return 1
    }

    const defaultDistance = voyage.defaultDistance
    const newDistance = _portDistances[voyage.startingPort][voyage.endPort]
    return newDistance / defaultDistance
}

export {BunkerMultiplier, JourneyTimeMultiplier, PortDistanceMultiplier, scrubberFuel}