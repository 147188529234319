import React, { useEffect } from "react";
import { getRecoilPromise } from "recoil-nexus";
import { spgciStatus } from "../store/integrations";

const PrefetchData = () => {
  useEffect(() => {
    (async function () {
      getRecoilPromise(spgciStatus);
    })();
  }, []);

  return <></>;
};

export default PrefetchData;
