import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Checkbox from "@material-ui/core/Checkbox"
import TableSortLabel from "@material-ui/core/TableSortLabel"

const useStyles = makeStyles((theme) => ({
    row: {
        backgroundColor: "#e5e7eb"
    },
    tableCell: {
        paddingTop: 4,
        paddingBottom: 4,
        // fontWeight: theme.typography.heading.fontWeight,
        // fontSize: theme.typography.heading.fontSize,
        // color: theme.palette.secondary.heading
    },
    tableSortLabel: {
        fontWeight: "bold",
        lineHeight: 1.2,
        display: "table-cell",
        fontSize: "0.75rem",
        height: 30,
        verticalAlign: "bottom"
    }
}))

const handleRequestSort = (order, orderBy, property, { setOrder, setOrderBy }) => () => {
    if (orderBy === property) {
        setOrder(order === "asc" ? "desc" : "asc")
        return
    }

    setOrder("asc")
    setOrderBy(property)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ columns, rowCount, order, setOrder, orderBy, setOrderBy, selected, setSelectedAll, noSort }) => {
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {setSelectedAll && <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={selected.length > 0 && selected.length < rowCount}
                        checked={selected.length === rowCount}
                        onChange={setSelectedAll}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>}
                {columns.map((headCell) => (
                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align="left"
                        style={headCell.style || {}}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {noSort
                            ? <span className={classes.tableSortLabel}>{headCell.label}</span>
                            : <TableSortLabel
                                classes={{ root: classes.tableSortLabel }}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={handleRequestSort(order, orderBy, headCell.id, { setOrder, setOrderBy })}
                            >
                                {headCell.label}
                            </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    )
}
