import React, { useState, useEffect} from "react";
import { dollarFormat, separatorFormat, percentageFormat, mtFormat, kgFormat } from "../../../utils/Helpers";
import FlexibleChart from "../../../components/Charts/FlexibleChart";
import {
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  DiscreteColorLegend,
} from "react-vis";
import { DefaultAxisStyle } from "../../../utils/Constants";


export default function RiskRow({record, haveToggle, emissions, co2 = false}){

    const [showDetails, setShowDetails] = useState(false);

    const tagKey = emissions ? "emission" : "fuel_type"
    const instmtKey = "instrument"
    const qtyKey = "quantity"
    const meanKey = "mean"
    const riskKey = "risk"
    const riskReducKey = "risk_reduction"



    const portKeys = emissions ? "" : Object.keys(record.ports)
    const cell = (item, bold = false, last=false) => {
        return (
            <div className={`flex ${last ? "" : "border-r border-gray-150"} border-dotted`}>
                <div className={`w-full p-1 ml-2 ${bold ? "font-medium" : ""}`}>
                    {item}
                </div>
            </div>
        )
    }

    return(
        <div className="overflow-x-auto border border-gray-150 rounded bg-white p-2">
            <div className={`grid grid-cols-7 p-1 text-ao-figma-text-gray ${showDetails ? "border-b border-gray-150" : ""}`}>
                    {cell(record[tagKey])}
                    {cell(record[instmtKey])}
                    {cell(mtFormat(record[qtyKey]))}
                    {cell(dollarFormat(record[meanKey]))}
                    {cell(dollarFormat(record[riskKey]))}
                    {cell(percentageFormat(record[riskReducKey], 0))}
                    {haveToggle && (
                        <div className="flex p-1 ml-2">
                        <button onClick={() => setShowDetails(!showDetails)}>{showDetails ? "Hide" : "Show"}</button>
                        {/* <p>Details</p> */}
                    </div>
                    )}
            </div>
            {showDetails && (
                <div className="flex flex-row">
                <div className="flex flex-col p-1 w-1/2 pt-10">
                    <div className="overflow-x-auto p-2">
                        <div className="grid grid-cols-5 p-1 border-b text-ao-figma-text-gray">
                                {cell("Port", true, true)}
                                {cell("Exposure", true, true)}
                                {cell("Cost", true, true)}
                                {cell("Risk", true, true)}
                                {cell("Hedgeable Risk", true, true)}
                        </div>
                    </div>
                    {portKeys.map((key) => {
                        const item = record.ports[key];
                        return (
                            <div className="overflow-x-auto border-b border-gray-150 p-2">
                                <div className="grid grid-cols-5 p-1 text-ao-figma-text-gray">
                                        {cell(key)}
                                        {cell(separatorFormat(item[qtyKey]))}
                                        {cell(dollarFormat(item[meanKey]))}
                                        {cell(dollarFormat(item[riskKey]))}
                                        {cell(percentageFormat(item[riskReducKey], 0), false, true)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex-col p-1 w-1/2 pt-10">
                <FlexibleChart
                    height={450}
                    margin={{ left: 85, bottom: 160, top: 25, right: 100 }}
                    xType="ordinal"
                    stackBy="y"
                >
                    <XAxis style={DefaultAxisStyle}  tickLabelAngle={-90}/>
                    <YAxis style={DefaultAxisStyle} title={"$"} />
                    <HorizontalGridLines style={DefaultAxisStyle} />
                    <VerticalGridLines style={DefaultAxisStyle} />
                    <VerticalBarSeries
                    data={portKeys.map((key) => {
                        const item = record.ports[key];
                        return{
                            x: key,
                            y: item[riskKey]
                        }
                    })}
                    color="red"
                    barWidth={0.3}
                    />
                </FlexibleChart>
                </div>
                </div>
            )}
        </div>
    )

}