import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const buttonStyles = "block rounded-md bg-ao-blue px-3 py-2 text-center font-semibold text-white shadow-md hover:bg-white hover:text-ao-blue"

export function DropdownButton({ text, options, onOptionSelect, link }) {
    const [_dropdownOpen, setDropdownOpen] = useState(false);
    const ref = useRef(null);
    useOutsideClickHandler(ref, () => setDropdownOpen(false), _dropdownOpen)

    return (
        <>
            <div ref={ref} className="relative">

                <button onClick={() => setDropdownOpen(!_dropdownOpen)} className={buttonStyles}>
                    {text}
                </button>

                <div className={`${_dropdownOpen ? '' : 'hidden'} absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                    <ul className="py-1">
                        {options.map((option) => {
                            const optionText = typeof option === 'object' ? <Link to={option.linkTo} className="block">{option.text}</Link> : option
                            const optionKey = typeof option === 'object' ? option.text : option

                            return (
                                <li key={optionKey} className="text-gray-700 block px-4 py-2 text-sm whitespace-nowrap hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect(option)}>{optionText}</li>
                            )
                        })}
                    </ul>
                </div>
            </div >
        </>
    )

    function handleSelect(option) {
        setDropdownOpen(false)
        onOptionSelect && onOptionSelect(option)
    }
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClickHandler(ref, handle, active = true) {
    const handleRef = useRef(null)
    handleRef.current = handle

    useEffect(() => {

        if (active) {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleRef.current()
                }
            }

            document.addEventListener("mousedown", handleClickOutside);

            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }

    }, [ref, active]);
}