import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useRecoilValue } from "recoil";
import {
  selectdVoyagePortfolio,
  selectdVoyagePortfolioId
} from "../../../store/ui";
import { useVoyagePortfolioMutations } from "../../../store/voyagePortfolios";
import { voyages } from "../../../store/voyages";
import {
  dollarFormat,
  separatorFormat
} from "../../../utils/Helpers";
import { formatDateToDDMMYY } from "./BunkerTable";
import EmissionsQuantityChart from "./EmissionsQuantityChart";
import EmissionsQuantityTable from "./EmissionsQuantityTable";
import { VoyagePortfolioManageDialog } from "./VoyagePortfolioManageDialog";
import { VoyagePortfolioSelect } from "./VoyagePortfolioSelect";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const _voyages = useRecoilValue(voyages)

  const [_selectedVoyages, setSelectedVoyages] = useState([]);
  const [_voyageData, setVoyageData] = useState([]);
  const [_rows, setRows] = useState([]);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [_groupingChoice, setGroupingChoice] = useState("byVessel")

  const _selectedPortfolioId = useRecoilValue(selectdVoyagePortfolioId)
  const _selectedPortfolio = useRecoilValue(selectdVoyagePortfolio)
  const { saveVoyagePortfolio } = useVoyagePortfolioMutations()
  const [_adToPortfolioVisible, setAddToPortfolioVisible] = useState(false)
  const [_portfolioModalOpen, setPortfolioModalOpen] = useState(false)


  useLayoutEffect(() => {
    setChecked(
      _voyages.length > 0 &&
      _selectedVoyages.length > 0 &&
      _voyages.length === _selectedVoyages.length
    );
  }, [_selectedVoyages]);

  function toggleAll() {
    handleSelectionChange(checked ? [] : _rows.map((x) => x.id));
    setChecked(!checked);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const getData = () => {
      if (_selectedVoyages.length > 0) {
        setVoyageData(_selectedVoyages);
      }
    };
    getData()
  }, [_selectedVoyages]);

  useEffect(() => {
    const getRows = () => {
      const _voyageDisplay = [];
      const filteredVoyages = _selectedPortfolioId != null ? _voyages.filter((v) => v.portfolios.includes(_selectedPortfolioId)) : _voyages
      for (const voy of filteredVoyages) {
        _voyageDisplay.push({
          ...voy,
          co2: separatorFormat(voy.carbonConsumption),
          nox: separatorFormat(0),
          sox: separatorFormat(0),
          btu: separatorFormat(0),
          deltaTCEEmissions: dollarFormat(voy.tceEffectsEmissions.dynamicTceEffects.grossDelta),
          deltaCo2: separatorFormat(Math.round(voy.initialCosting.carbonConsumption - voy.carbonConsumption)),
          openDate: formatDateToDDMMYY(voy.openDate),
          duration: voy.voyageLength
        })
      }
      setRows(_voyageDisplay)
    }
    getRows()
  }, [_voyages, _selectedPortfolioId])


  useEffect(() => {
    setAddToPortfolioVisible(_selectedPortfolioId == null && _selectedVoyages.length > 0)
  }, [_selectedPortfolioId, _selectedVoyages])

  useEffect(() => {
    if (_selectedPortfolio) {
      handleSelectPortfolio(_selectedPortfolio)
    }
  }, [])

  return (
    <>
      <div className="mt-8 flow-root">
        <div className="flex space-between">
          {_adToPortfolioVisible &&
            <button className="rounded-md px-3 py-2 text-center font-semibold text-ao-blue shadow-md hover:bg-white hover:text-ao-blue" onClick={handleAddToPortfolio}>
              Add to portfolio
            </button>
          }
          <div style={{ flex: "1 0" }}></div>
          <VoyagePortfolioSelect onSelect={handleSelectPortfolio} />
          <VoyagePortfolioManageDialog open={_portfolioModalOpen} onClose={() => setPortfolioModalOpen(false)} voyagesToAdd={_selectedVoyages} onOk={handleAddedToPortfolio}></VoyagePortfolioManageDialog>
        </div>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="relative">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className=" py-3.5 pr-3 text-left text-sm font-semibold text-ao-blue"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Vessel
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Route
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Open Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      CO2 (Mt)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      NOx (Mt)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      SOx (Mt)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      BTU (mmBTU)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Δ-TCE Emissions
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Δ-CO2 (Mt)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      CII
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Dynamic CII
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-ao-blue"
                    >
                      Duration
                    </th>
                  </tr>
                </thead>
                {_rows.length > 0 && (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {_rows.map((voyage) => (
                      <tr
                        key={voyage.id}
                        className={
                          _selectedVoyages.includes(voyage.id)
                            ? "bg-gray-50"
                            : undefined
                        }
                      >
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {_selectedVoyages.includes(voyage.id) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-ao-blue" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-ao-blue focus:ring-ao-blue"
                            checked={_selectedVoyages.includes(voyage.id)}
                            onChange={(e) => {
                              handleSelectionChange(e.target.checked
                                ? [..._selectedVoyages, voyage.id]
                                : _selectedVoyages.filter(
                                  (s) => s !== voyage.id
                                ))
                            }}
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 font-medium",
                            _selectedVoyages.includes(voyage.id)
                              ? "text-ao-blue"
                              : "text-ao-blue"
                          )}
                        >
                          {voyage.id}
                        </td>
                        {/* TODO - Link Vessel to Vessels - individual ship view */}
                        <td className="whitespace-nowrap px-3 py-4 text-ao-blue underline cursor-pointer">
                          {voyage.vessel.shipName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {voyage.routeShortName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {voyage.openDate}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {separatorFormat(voyage.carbonConsumption)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {separatorFormat(voyage.noxConsumption)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {separatorFormat(voyage.soxConsumption)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {separatorFormat(voyage.mmbtuConsumption)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {voyage.deltaTCEEmissions}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {separatorFormat(voyage.deltaCo2)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          B
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          B
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                          {voyage.duration.toFixed(1)}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-3">
                          <Link
                            to="/voyage-engine/optimizations"
                            className="underline"
                          >
                            Optimize Fuel & Emissions
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {_rows.length < 1 && (
                <div className="w-1/2 pt-12 text-ao-figma-text-gray font-semibold text-lg mx-auto text-center">
                  {" "}
                  No Voyages Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


      {_selectedVoyages.length > 0 && (
        <>
        <div class="flex">
              <div class="flex items-center mr-4">
                <input id="inline-radio-vessel" type="radio" value="byVessel" name="inline-radio-group" class="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVessel" } onChange={(ev) => setGroupingChoice(ev.target.value)}>
                </input>
                <label for="inline-radio-vessel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Vessel</label>
              </div>
              <div class="flex items-center mr-4">
                <input id="inline-radio-voyage" type="radio" value="byVoyage" name="inline-radio-group" class="w-4 h-4 text-ao-blue" checked={_groupingChoice === "byVoyage"} onChange={(ev) => setGroupingChoice(ev.target.value)}>
                </input>
                <label for="inline-radio-voyage" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">By Voyage</label>
              </div>
            </div>
        <div className="flex flex-row justify-between mt-16">
          <div className="w-1/2">
            <EmissionsQuantityChart
              selectedVoyages={_voyageData}
              grouping={_groupingChoice}
            />
          </div>
          <div className="w-1/2">
            <EmissionsQuantityTable
              selectedVoyages={_voyageData}
              grouping={_groupingChoice}
            />
          </div>
        </div>
        </>
      )}
    </>
  );

  function handleSelectionChange(newSelection) {
    updateSelectedVoyages(newSelection)

    if (_selectedPortfolio != null) {
      saveVoyagePortfolio({ ..._selectedPortfolio, selectedVoyages: newSelection })
    }
  }

  function handleSelectPortfolio(portfolio) {
    updateSelectedVoyages(portfolio ? portfolio.selectedVoyages || [] : [])
  }

  function handleAddToPortfolio() {
    setPortfolioModalOpen(true)
  }

  function handleAddedToPortfolio(portfolio) {
    handleSelectPortfolio(portfolio)
  }

  function updateSelectedVoyages(newSelection) {
    setSelectedVoyages(newSelection)
  }
};
