import React, { useEffect, useRef, useState } from 'react';
import { ChartLabel, DiscreteColorLegend, HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';

const gridLines = {
    stroke: "#E3E8EE",
};

const axis = {
    line: { stroke: "#8792A2", strokeWidth: 2 },
    ticks: { stroke: "#8792A2" },
    text: { stroke: "none", fill: "#697386" },
    title: { stroke: "none", fill: "#697386" },
};

const line = {
    strokeLinejoin: "round",
    strokeWidth: 2,
    fill: "none",
};

export function SimpleLineChart({ height, yAxisLabel, data, verticalGridLines = true, className }) {
    /*
    * The following code is designed to overcome a bug in react-vis
    * FlexibleWidthXYPlot, which was causing the plot to go to 0 sometimes
    * when changing the data.
    */
    const [_chartWidth, setChartWidth] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        function handleResize() {
            const newWidth = ref.current ? ref.current.offsetWidth : 0;
            setChartWidth(newWidth);
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={`${className} flex flex-col`} >
            <DiscreteColorLegend
                width={_chartWidth}
                height={80}
                orientation="horizontal"
                items={data.map(item => ({ color: item.color, title: <span className="text-ao-light-gray-chart">{item.title}</span>, strokeWidth: 8 }))}
                className="text-sm"
            />
            <div ref={ref} className="h-100 f-100">
                <XYPlot height={height} width={_chartWidth} xType="ordinal" margin={{ left: 100 }}>
                    {verticalGridLines && <VerticalGridLines style={gridLines} />}
                    <HorizontalGridLines style={gridLines} />
                    <XAxis style={axis} />
                    <YAxis style={axis} />
                    <ChartLabel
                        text={yAxisLabel}
                        className="alt-y-label"
                        includeMargin={false}
                        xPercent={-0.1}
                        yPercent={0.6}
                        style={{
                            fill: "#697386",
                        }}
                    />
                    {data.map(item => {
                        return (
                            <LineSeries
                                color={item.color}
                                data={item.data}
                                style={line}
                                key={item.key}
                            />
                        );
                    })}
                </XYPlot>
            </div>
        </ div>
    );
};
