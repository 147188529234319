import React from "react"
import { dollarFormat, euroFormat } from "../../utils/Helpers"


export function PriceTable({data, hasPlattsSource, isEuros = false}) {
    
    let dateCurve = data.find(d => d.status === 'available')?.forwardCurveData || data[0]?.forwardCurveData
    const formatter = isEuros ? euroFormat : dollarFormat
    return (
        <div>
            <div className="grid grid-cols-5">
            {(data.length > 0 ) &&
                <div>
                <DateColum
                spotDate={"Spot"}
                forwardCurveData={dateCurve}
                isDate={true}
                key={"date"}
                />
                </div>
                }

            {data.map((record, i) => (
                <div>
                <PriceColumn
                spotPrice={record.spotPrice}
                spotDate={record.spotDate}
                constructedSpot={record.constructedSpot}
                forwardCurveData={record.forwardCurveData}
                constructedCurve={record.constructedCurve}
                provider={record.provider}
                providerData={record.providerData}
                status={record.status}
                valueFormatter={formatter}
                key={`${i} ${record.spotPrice}`}
                />
                </div>
            ))}
            </div>
        </div>
    )
}

export function PriceColumn({spotPrice, spotDate, constructedSpot, forwardCurveData, constructedCurve, provider, providerData, status, valueFormatter}) {
    return (<div className="flex flex-col px-2 border-l" name={spotPrice}>
                <Cell
                    value={status === 'loading' ? 'Loading...': status ==='unavailable' ? '⚠️' :  valueFormatter(spotPrice, 2)}
                    isConstructed={constructedSpot}
                    isSpgci={(provider === 'SPGCI')}
                    date={spotDate}
                    title={status ==='unavailable' ? 'The data is unavailable. Please check your configuration' : buildDescription(true, constructedSpot, provider, providerData, spotDate, 0)}/>
            {
                forwardCurveData.map((data, i) => (
                    <Cell
                        value={data.price == null ? '\u00A0' : valueFormatter(data.price, 2)}
                        isConstructed={data.constructed}
                        isSpgci={(provider === 'SPGCI')}
                        date={data.yearMonth}
                        title={buildDescription(false, data.constructed, provider, providerData, data.yearMonth, i)}
                        key={`${i} ${data.yearMonth}`}
                        />
                ))
            }
        </div>)

}

export function DateColum({spotDate, forwardCurveData}){
    return (<div className="flex flex-col px-2 border-l" name={spotDate}>
                <Cell
                    value={spotDate}
                    title={spotDate}
                    isDate={true}/>
                {
                    forwardCurveData.map((data, i) => (
                        <Cell
                            value={data.yearMonth ? data.yearMonth : '\u00A0'}
                            title={data.yearMonth}
                            isDate={true}
                            key={`${i} ${data.yearMonth}`}
                            />
                    ))
                }
            </div>)
}


export function Cell({ value, isConstructed, isSpgci, title, isDate, topBorder = true }) {
    let className = "text-gray-400 italic"

    if(isDate){
        className = "text-gray-400"
    } else if (!isConstructed && !isSpgci) {
        className = "text-ao-figma-text-gray font-bold"
    } else if (isConstructed && isSpgci) {
        className = "text-SPGCI-constructed italic"
    } else if (!isConstructed && isSpgci) {
        className = "text-SPGCI-color font-bold"
    }

    return (
        <div>
            <span
            className={`flex items-center ${className} text-sm p-2 ${topBorder ? "border-t" : ""} transition duration-1 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 `}
            data-te-toggle="tooltip"
            title={title}
            >
                {value}
            </span>
        </div>
    )
}


export function buildDescription(isSpot, isConstructed, provider, providerData, date, index){

    if(providerData.spotSymbol === "TDUCF00" && !isSpot && !isConstructed){
        return handleTDUC(providerData, index)
    }

    const spotKeys = {
        symbol: "spotSymbol",
        mdc: "spotMdc",
        desc: "spotDescr",
    }

    const forwardKeys = {
        symbol: "forwardCurveCode",
        mdc: "forwardMdc",
        desc: "forwardCurveName",
    }

    const keySet = isSpot ? spotKeys : forwardKeys
    const symbolTerm = isSpot ? "Symbol" : "Curve Code"

    const symbol = providerData.hasOwnProperty(keySet.symbol) ? providerData[keySet.symbol] : ""
    const desc = providerData.hasOwnProperty(keySet.desc) ? providerData[keySet.desc] : ""
    const mdc = providerData.hasOwnProperty(keySet.mdc) ? providerData[keySet.mdc] : ""

    let title = '';
    const displayProvider = (provider === "BALTIC") ? "Baltic Exchange" : provider

    if ((provider === "SPGCI") && !isConstructed){
        title = `Provider: SPGCI \n${symbolTerm}: ${symbol}\nDescription: ${desc}\nMDC: ${mdc}\nBate: C`
    } else if(!isConstructed){
        title = `Provider: ${displayProvider}\nPrice Date: ${date}`
    } else if(isConstructed){
        title = `Price Date: ${date}\nDerived from ${displayProvider} Values`
    }

    return title
}

function handleTDUC(providerData, index){
    return `Provider: SPGCI \nSymbol: ${providerData.forwardSymbols[index]}\nDescription: ${providerData.spotDescr}\nMDC: ${providerData.spotMdc}\nBate: C`
}