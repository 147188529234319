import React from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
import "react-vis/dist/style.css";

const VoyageManagementRisk = () => {
  // Dummy data
  const data = [
    { x: new Date(2022, 3), y: 0 },
    { x: new Date(2022, 4), y: 0 },
    { x: new Date(2022, 5), y: 0 },
    { x: new Date(2022, 6), y: 0 },
    { x: new Date(2022, 7), y: 0 },
    { x: new Date(2022, 8), y: 0 },
    { x: new Date(2022, 9), y: 0},
    { x: new Date(2022, 10), y: 0 },
    { x: new Date(2022, 11), y: 0 },
    { x: new Date(2022, 12), y: 0 },

  ];


  const tickFormat = (d) => new Date(d).toLocaleString('en-us', { month: 'short' });
  // const numDataPoints = 100;
  // for (let i = 0; i <= numDataPoints; i++) {
  //   const x = i / (numDataPoints / 10);
  //   const slope = -0.5 + Math.random() * 0.05; // Randomized slope to introduce variability
  //   const y = slope * x + 5 + Math.random() * 0.5; // Linear function with some random noise
  //   data.push({ x, y });
  // }

  return (
    <div className="flex flex-col items-start">
     <span className="font-semibold tracking-wide text-xs text-ao-figma-text-gray">RISK OVER TIME</span>
      <XYPlot width={325} height={175} margin={{left:-0}}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title="Time " tickFormat={tickFormat} />
        <YAxis title="$/day" />
        <LineSeries data={data} style={{ stroke: 'transparent' }}/>
      </XYPlot>
    </div>
  );
};

export default VoyageManagementRisk;
