import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { VesselForm } from "./components/VesselForm";
import { useRecoilValue } from "recoil";
import { userCustomisedVessels } from "../../store/vessels";

export function EditVessel() {
    const { vesselId } = useParams()
    const vessels = useRecoilValue(userCustomisedVessels)
    const vessel = vessels.find(v => String(v.id) === vesselId)

    return <VesselForm defaultValues={vessel}></VesselForm>
}