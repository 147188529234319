import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
    primaryHeading: {
       
    }
}))

export default ({children}) => {
    const classes = useStyles()

    return (
        <Typography className={classes.primaryHeading}>{children.toUpperCase()}</Typography>
    )
}
