import { atom, selector, selectorFamily, useRecoilState } from "recoil"
import { getRecoil } from "recoil-nexus"
import RegionsLookup from "../dataMocks/RegionsLookup.json"
import { DATA_SOURCE, FUEL_TYPES, LIQUIDITY, ProviderStatus, VOLATILITY, fuelTypeNames } from "../utils/Constants"
import { replaceItemAtIndex, unique } from "../utils/Helpers"
import ClosestRegions from "./ClosestRegions.json"
import { bunkerPrices, emissionPrices, getRouteLookup, getRouteParamsIn } from "./api"
import { CreateLayoutGroup, DeleteLayoutGroup, FetchLayoutGroups, SaveLayoutGroup } from "./api/aftapi"
import {
    availableRoutesFilterDefault,
    availableRoutesFilterRegionsOff,
    bunkersEmissionsManagementLayoutsDefault, bunkersLayoutsDefaultBunkerEx, bunkersLayoutsDefaultSPGCI, emissionsLayoutsDefault,
    routesLayoutDefaultBaltic,
    routesLayoutDefaultSPGCI
} from "./defaults"
import { userInfo } from "./user"
import { voyagePortFolios } from "./voyagePortfolios"
import { spgciStatus } from "./integrations.js"

//https://medium.com/geekculture/crud-with-recoiljs-and-remote-api-e36581b77168

/**
* This atom stores all of the user interface state for sync with the users account
*
 * THis pattern of using an async selector as the default in an atom
 * sets the atom on first load of the page
 */
export const userInterface = atom({
    key: "userInterface",
    default: selector({
        key: "userInterfaceLoader",
        get: async ({ get }) => {
            const _layoutGroups = await get(layoutGroups)

            return {
                ..._layoutGroups.reduce((ui, layout) => ({
                    customLayouts: { ...ui.customLayouts, [layout.id]: { name: layout.name, filter: layout.filter } },
                    routesLayouts: { ...ui.routesLayouts, [layout.id]: layout.routes },
                    emissionsLayouts: { ...ui.emissionsLayouts, [layout.id]: layout.emissions },
                    bunkersLayouts: { ...ui.bunkersLayouts, [layout.id]: layout.bunkers },
                    bunkersEmissionsManagementLayouts: { ...ui.bunkersEmissionsManagementLayouts, [layout.id]: bunkersEmissionsManagementLayoutsDefault }
                }), {
                    customLayouts: {},
                    routesLayouts: {},
                    emissionsLayouts: {},
                    bunkersLayouts: {},
                    bunkersEmissionsManagementLayouts: {}
                })
            }
        }
    })
})

/**
 * Settings for the UI's emission rates (%)
 */
const _selectedEmissionRate = atom({
    key: "_selectedEmissionRate",
    default: {
        nonEU: 20,
        EU: 20
    }
})
export { _selectedEmissionRate as selectedEmissionRate }

/**
 * Helper function to update a nested property in a path of the current user selected layout group
 * If path2 is specified then the property is nested a further level
 * @param ui - the current ui object
 * @param newValue - the new value to be set
 * @param path - the path with in the ui object (e.g. 'routesLayouts')
 * @param path2 - the sub property in the above path
 */
const updatedUserInterfaceForSelectedLayoutGroup = (ui, layoutGroup, newValue, path, path2 = undefined) => {
    if (path2 === undefined) {

        return {
            ...ui,
            [path]: {
                ...ui[path],
                [layoutGroup]: newValue
            }
        }
    } else {
        return {
            ...ui,
            [path]: {
                ...ui[path],
                [layoutGroup]: {
                    ...ui[path][layoutGroup],
                    [path2]: newValue
                }
            }
        }
    }
}

export function createLayoutGroupForRegion(regionName) {
    const region = RegionsLookup.find((r) => r.shortName === regionName) || RegionsLookup[0]
    const [routes, routeRegions] = createRoutePricingLayoutGroupForRegion(region)
    const [bunkers, bunkerRegions] = createBunkerPricingLayoutGroupForRegion(region)

    return {
        name: regionName,
        filter: { // Set the filter up to only include the selected region
            ...availableRoutesFilterDefault,
            ...availableRoutesFilterRegionsOff,
            [region["codeName"]]: true,
            ...routeRegions.reduce((all, current) => ({ ...all, [current.codeName]: true }), {}),
            ...bunkerRegions.reduce((all, current) => ({ ...all, [current.codeName]: true }), {})
        },
        routes,
        emissions: emissionsLayoutsDefault,
        bunkers,
        liquidity: LIQUIDITY.low.name,
        volatility: VOLATILITY.low.name
    }
}

/**
 * Creates a new layout group with routes from a specific region
 */
const createRoutePricingLayoutGroupForRegion = (region) => {

    const routeLookup = getRecoil(getRouteLookup)

    const routesForRegion = findRoutesForRegion(routeLookup, region)
    const regions = [region]

    if (routesForRegion.length < 4) {
        const closeRegions = ClosestRegions.find((cr) => cr.ourRegion === region.shortName)
        const closestRegion = RegionsLookup.find((r) => closeRegions.closestRegion === r.shortName)

        if (closestRegion) {
            routesForRegion.push(...findRoutesForRegion(routeLookup, closestRegion))
            regions.push(closestRegion)
        }

        if (routesForRegion.length < 4) {
            const nextClosestRegion = RegionsLookup.find((r) => closeRegions.nextClosestRegion === r.shortName)

            routesForRegion.push(...findRoutesForRegion(routeLookup, nextClosestRegion))
            regions.push(nextClosestRegion)
        }

    }
    const items = []
    for (let i = 0; i < 4; i += 1) {
        items.push({
            item: routesForRegion[i % routesForRegion.length].routeID,
            vesselID: null,
            selectedInPricingEngine: true,
            selectedInRiskEngine: (i === 0), //only select first vessel in risk engine
            selectedInEmissionsEngine: true
        })
    }
    return [items, regions]
}

function findRoutesForRegion(modelShipInfo, region) {
    return modelShipInfo.filter((mShip) => {

        const isSource = mShip.sourceRegion === region["shortName"]
        const isDestination = mShip.destination === region["shortName"]

        if (isSource || isDestination) {
            return true
        }

        const isFarEastRegion = ["chi", "jap", "sko"].includes(region["codeName"])
        if (isFarEastRegion && mShip.destination === "ChinaJapanKorea") {
            return true
        }
        return false
    })
}

/**
 * Creates a new layout group with bunkers from a specific region
 */
function createBunkerPricingLayoutGroupForRegion(region) {
    const _bunkerPrices = getRecoil(bunkerPrices)
    const _userSettings = getRecoil(userSettings)

    const closeRegions = ClosestRegions.find((cr) => cr.ourRegion === region.shortName)

    const bunkers = []
    const bunkersItems = []
    if (DATA_SOURCE.isPlatts(_userSettings.bunkerDataSource)) {
        const plattsBunkers = findBunkerPricesForRegion(region, _bunkerPrices.platts, closeRegions)
        bunkersItems.push(...plattsBunkers.map((b) => createSelectedBunkerItem(b, false)))
        bunkers.push(...plattsBunkers)
    }

    if (bunkersItems.length < 4 && DATA_SOURCE.isBunkerex(_userSettings.bunkerDataSource)) {
        const bunkerexBunkers = findBunkerPricesForRegion(region, _bunkerPrices.bunkerex, closeRegions)
        bunkersItems.push(...bunkerexBunkers.map((b) => createSelectedBunkerItem(b, true)))
        bunkers.push(...bunkerexBunkers)
    }

    if (bunkersItems.length === 0) {
        return bunkersLayoutsDefaultSPGCI.custom
    }

    const items = []
    const regions = []
    for (let i = 0; i < 4; i += 1) {
        const bunkerIndex = i % bunkersItems.length
        items.push({
            item: bunkersItems[bunkerIndex],
            selected: true
        })
        regions.push(bunkers[bunkerIndex].region)
    }

    return [items, RegionsLookup.filter((r) => regions.includes(r.shortName))]
}

function findBunkerPricesForRegion(region, bunkerPrices, closeRegions) {
    const bunkersInRegion = bunkerPrices.filter((b) => b.region === region.shortName).sort((b1, b2) => b1.portDisplayOrder - b2.portDisplayOrder)
    const bunkersInCloseRegions = closeRegions ? [
        ...bunkerPrices.filter((b) => b.region === closeRegions.closestRegion).sort((b1, b2) => b1.portDisplayOrder - b2.portDisplayOrder),
        ...bunkerPrices.filter((b) => b.region === closeRegions.nextClosestRegion).sort((b1, b2) => b1.portDisplayOrder - b2.portDisplayOrder)
    ]
        : []

    return [...bunkersInRegion, ...bunkersInCloseRegions]
}

export function createSelectedBunkerItem(priceObj, isBunkerEx) {
    
    const fuelTypeLongName = fuelTypeNames[priceObj.fuelType] || priceObj.fuelType
    return {
        port: priceObj.portName,
        fuelType: priceObj.fuelType,
        isBunkerEx,
        captionLong: `${priceObj.portName} ${fuelTypeLongName}`,
        caption: `${priceObj.portName} ${priceObj.fuelType}`,
        symbol: priceObj.providerData.spotSymbol ? priceObj.providerData.spotSymbol : "",
        isProviderData: true
    }
}



/**
 * Returns or sets the users selected layout group key
 */
export const selectedLayoutGroup = selector({
    key: "selectedLayoutGroup",
    get: async ({ get }) => {
        const _userInfo = get(userInfo)
        const _layoutGroups = get(layoutGroups)

        return _userInfo.selectedLayoutGroup ? _layoutGroups.find((l) => String(l.id) === String(_userInfo.selectedLayoutGroup)) || _layoutGroups[0] : _layoutGroups[0]
    },
    set: ({ get, set }, layoutGroupId) => {
        set(userInfo, {
            ...get(userInfo),
            selectedLayoutGroup: layoutGroupId
        })
    }
})

export const selectdVoyagePortfolioId = atom({
    key: "selectdVoyagePortfolioId",
    default: null
})

export const selectdVoyagePortfolio = selector({
    key: "selectdVoyagePortfolio",
    default: null,
    get: ({ get }) => {
        const _selectdVoyagePortfolioId = get(selectdVoyagePortfolioId)
        if (_selectdVoyagePortfolioId == null) {
            return null
        }

        return get(voyagePortFolios).find((vp) => vp.id === _selectdVoyagePortfolioId)
    },
    set: ({ set }, voyagePortfolio) => {
        set(selectdVoyagePortfolioId, voyagePortfolio.id)
    }
})

/**
 * The route selection can be filtered by various classes of
 * routes and also ship type.  This atom stores the setup for this
 * The available options are selected in the RouteFilter component
 */
export const availableRoutesFilter = selector({
    key: "availableRoutesFilter",
    get({ get }) {
        const _selectedLayoutGroup = get(selectedLayoutGroup)

        return _selectedLayoutGroup.filter || availableRoutesFilterDefault

    },
    set: ({ get, set }, newValue) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _layoutGroups = get(layoutGroups)

        const layoutGroupIndex = _layoutGroups.indexOf(_selectedLayoutGroup)
        const updatedLayoutGroup = { ..._selectedLayoutGroup, filter: newValue }

        SaveLayoutGroup(updatedLayoutGroup)

        set(layoutGroups, replaceItemAtIndex(_layoutGroups, layoutGroupIndex, updatedLayoutGroup))
    }
})

/**
 * The bunker selection can be filtered by reagons and fuel types.
 * This atom stores the setup for this
 * The available options are selected in the Bunker component
 */
export const availableBunkersFilter = selector({
    key: "availableBunkersFilter",
    get: ({ get }) => {
        return get(availableRoutesFilter)
    },
    set: ({ set }, newValue) => {
        const ui = set(availableRoutesFilter, newValue)
    }
})


/**
 * The routes currently selected in GUI, and whether they are shown on graph
 * vesselID property holds a vessel ID from userSelectedVesselObjects for the routeId
 * vesselID property holds a vessel ID from userSelectedVesselObjects for the routeId
 * if null it is the default
 */
export const selectedRoutes = selector({
    key: "selectedRoutes",
    get: ({ get }) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _routesFilter = get(availableRoutesFilter)

        return _selectedLayoutGroup.routes || (DATA_SOURCE.isBaltic(_routesFilter.routeDataSource) ? routesLayoutDefaultBaltic : routesLayoutDefaultSPGCI)
    },
    set: ({ get, set }, newValue) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _layoutGroups = get(layoutGroups)

        const layoutGroupIndex = _layoutGroups.indexOf(_selectedLayoutGroup)
        const updatedLayoutGroup = { ..._selectedLayoutGroup, routes: newValue }

        SaveLayoutGroup(updatedLayoutGroup)

        set(layoutGroups, replaceItemAtIndex(_layoutGroups, layoutGroupIndex, updatedLayoutGroup))
    }
})

/**
 * The emissions currently selected in UI, and whether they are shown on graph
 */
export const selectedEmissions = selector({
    key: "selectedEmissions",
    get: ({ get }) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)

        return _selectedLayoutGroup.emissions || emissionsLayoutsDefault
    },
    set: ({ get, set }, newValue) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _layoutGroups = get(layoutGroups)

        const layoutGroupIndex = _layoutGroups.indexOf(_selectedLayoutGroup)
        const updatedLayoutGroup = { ..._selectedLayoutGroup, emissions: newValue }

        SaveLayoutGroup(updatedLayoutGroup)

        set(layoutGroups, replaceItemAtIndex(_layoutGroups, layoutGroupIndex, updatedLayoutGroup))
    }
})

/**
 * The bunkers currently selected in UI, and whether they are shown on graph
 */
export const selectedBunkers = selector({
    key: "selectedBunkers",
    get: ({ get }) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _availableBunkersFilter = get(availableBunkersFilter)

        return _selectedLayoutGroup.bunkers || (DATA_SOURCE.isBunkerex(_availableBunkersFilter.bunkerDataSource) ? bunkersLayoutsDefaultBunkerEx : bunkersLayoutsDefaultSPGCI)
    },
    set: ({ get, set }, newValue) => {
        const _selectedLayoutGroup = get(selectedLayoutGroup)
        const _layoutGroups = get(layoutGroups)

        const layoutGroupIndex = _layoutGroups.indexOf(_selectedLayoutGroup)
        const updatedLayoutGroup = { ..._selectedLayoutGroup, bunkers: newValue }

        SaveLayoutGroup(updatedLayoutGroup)

        set(layoutGroups, replaceItemAtIndex(_layoutGroups, layoutGroupIndex, updatedLayoutGroup))
    }
})

/**
 * Safely gets modifications for a model vessel (as elected in UI, VesselSelect.js), injecting into defaults
 * @type {(param: SerializableParam) => RecoilValueReadOnly<{[p: string]: *}|{eco: boolean, slowSteam: boolean, scrubber: boolean}>}
 */
export const modelVesselCustomisationForSelectedIndex = selectorFamily({
    key: "modelVesselCustomisationForSelectedIndex",
    get: (index) => ({ get }) => {

        const defaultParams = {
            eco: false,
            scrubber: false,
            slowSteam: false,
            halfLaden: false
        }

        const userSelectedRoutes = get(selectedRoutes)

        if (userSelectedRoutes[index].hasOwnProperty("defaultModelParams")) {
            return { ...defaultParams, ...userSelectedRoutes[index].defaultModelParams }
        } else {
            return defaultParams
        }
    },
    set: (index) => ({ set, get }, newValue) => {

        let userSelectedRoutes = [...get(selectedRoutes)]
        userSelectedRoutes[index] = {
            ...userSelectedRoutes[index],
            defaultModelParams: {
                ...userSelectedRoutes[index].defaultModelParams,
                ...newValue
            }
        }
        set(selectedRoutes, userSelectedRoutes)
    }
})

/**
 * Returns the routes listed in getModelShipInfo filtered by the availableRoutesFilter
 * E.g. for RouteFilter.js to select routes in a filtered context
 */

export const availableRoutesFiltered = selector({
    key: "availableRoutesFiltered",
    get: ({ get }) => {
        const routeLookup = get(getRouteLookup)
        const _routeParamsIn = get(getRouteParamsIn)
        const filter = get(availableRoutesFilter)
        const _spgciStatus = get(spgciStatus);

        const filteredRoutes = routeLookup.filter((route) => {
            // Baltic vs Platts filter
            if (route.balticPlatts.toLowerCase() === "baltic" && !DATA_SOURCE.isBaltic(filter.routeDataSource)) return false
            if (isPLattsRoute(route) && !DATA_SOURCE.isPlatts(filter.routeDataSource)) return false
            if (!filter.showConstructedRouteCurves && (_routeParamsIn[route.routeID].FFAs.toUpperCase() === "N") && !(_routeParamsIn[route.routeID].symbolMT === "TDUCF00")) return false

            // Clean vs Dirty filter
            if (!filter.clean && route.cleanDirty.toLowerCase() === "clean") return false
            if (!filter.dirty && route.cleanDirty.toLowerCase() === "dirty") return false

            // Ship type filter
            if (!filter.vlcc && route.type.toLowerCase() === "vlcc") return false
            if (!filter.suezmax && route.type.toLowerCase() === "suezmax") return false
            if (!filter["aframax/LR2"] && route.type.toLowerCase() === "aframax/lr2") return false
            if (!filter["panamax/LR1"] && route.type.toLowerCase() === "panamax/lr1") return false
            if (!filter.mr && route.type.toLowerCase() === "mr") return false
            if (!filter.handy && route.type.toLowerCase() === "handy") return false

            let isInRegion = false
            for (let regionL of RegionsLookup) {

                const regionIsSelected = filter[regionL["codeName"]]

                if (!regionIsSelected) continue

                const isSource = route.sourceRegion === regionL["shortName"]
                const isDestination = route.destination === regionL["shortName"]

                if (isSource || isDestination) {
                    isInRegion = true
                    break
                }

                const isFarEastRegion = ["chi", "jap", "sko"].includes(regionL["codeName"])
                if (isFarEastRegion && route.destination === "ChinaJapanKorea") {
                    isInRegion = true
                    break
                }
            }

            return isInRegion
        })

        // move platts at the top of the list
        // filteredRoutes.sort((m1, m2) => isPLattsRoute(m1) ? isPLattsRoute(m2) ? 0 : -1 : 1)
        const plattsRoutes = _spgciStatus === ProviderStatus.ACTIVE ? filteredRoutes.filter((r) =>
            r.balticPlatts.toUpperCase() === "PLATTS"
        ) : []

        const balticExRoutes = filteredRoutes.filter((r) =>
            r.balticPlatts.toUpperCase() === "BALTIC"
        )

        return { allRoutes: filteredRoutes, plattsRoutes, balticExRoutes }
    }
})

export const availableTCEsFiltered = selector({
    key: "availableTCEsFiltered",
    get: ({ get }) => {
        const routeLookup = get(getRouteLookup)
        const filter = get(availableRoutesFilter)
        const _spgciStatus = get(spgciStatus);

        const filteredRoutes = routeLookup.filter((route) => {

            // console.log(filter)
            // Baltic vs Platts filter
            if (route.balticPlatts.toLowerCase() === "baltic" && !DATA_SOURCE.isBaltic(filter.routeDataSource)) return false
            if (isPLattsRoute(route) && !DATA_SOURCE.isPlatts(filter.routeDataSource)) return false

            // Clean vs Dirty filter
            if (!filter.clean && route.cleanDirty.toLowerCase() === "clean") return false
            if (!filter.dirty && route.cleanDirty.toLowerCase() === "dirty") return false

            // Ship type filter
            if (!filter.vlcc && route.type.toLowerCase() === "vlcc") return false
            if (!filter.suezmax && route.type.toLowerCase() === "suezmax") return false
            if (!filter["aframax/LR2"] && route.type.toLowerCase() === "aframax/lr2") return false
            if (!filter["panamax/LR1"] && route.type.toLowerCase() === "panamax/lr1") return false
            if (!filter.mr && route.type.toLowerCase() === "mr") return false
            if (!filter.handy && route.type.toLowerCase() === "handy") return false

            let isInRegion = false
            for (let regionL of RegionsLookup) {

                const regionIsSelected = filter[regionL["codeName"]]

                if (!regionIsSelected) continue

                const isSource = route.sourceRegion === regionL["shortName"]
                const isDestination = route.destination === regionL["shortName"]

                if (isSource || isDestination) {
                    isInRegion = true
                    break
                }

                const isFarEastRegion = ["chi", "jap", "sko"].includes(regionL["codeName"])
                if (isFarEastRegion && route.destination === "ChinaJapanKorea") {
                    isInRegion = true
                    break
                }
            }

            return isInRegion
        })

        // move platts at the top of the list
        // filteredRoutes.sort((m1, m2) => isPLattsRoute(m1) ? isPLattsRoute(m2) ? 0 : -1 : 1)
        const plattsRoutes = _spgciStatus === ProviderStatus.ACTIVE ? filteredRoutes.filter((r) =>
            r.balticPlatts.toUpperCase() === "PLATTS"
        ) : []

        const balticExRoutes = filteredRoutes.filter((r) =>
            r.balticPlatts.toUpperCase() === "BALTIC"
        )

        return { allRoutes: filteredRoutes, plattsRoutes, balticExRoutes }
    }
})

function isPLattsRoute(route) {
    return route.balticPlatts.toLowerCase() === "platts"
}


export const availableBunkerPrices = selector({
    key: 'availableBunkerPrices',
    get: async ({ get }) => {
        return getBunkerPricesForDataSource(await get(bunkerPrices), get(userSettings).bunkerPricesSource)
    }
})

export const allBunkerPrices = selector({
    key: 'allBunkerPrices',
    get: async ({ get }) => {
        return get(bunkerPrices)
    }
})

export const availableEmissionPrices = selector({
    key: 'availableEmissionPrices',
    get: async ({ get }) => {
        return getEmissionPricesForDataSource(await get(emissionPrices), get(userSettings).bunkerPricesSource)
    }
})

function getBunkerPricesForDataSource(bunkerPrices, dataSource) {
    return DATA_SOURCE.isPlatts(dataSource)
        ? DATA_SOURCE.isBunkerex(dataSource)
            ? unique(
                [...bunkerPrices.platts, ...bunkerPrices.bunkerex],
                ['port', 'fuelType'])
            : [...bunkerPrices.platts]
        : [...bunkerPrices.bunkerex]
}

function getEmissionPricesForDataSource(emissionPrices, dataSource) {

    return DATA_SOURCE.isPlatts(dataSource)
        ? [...emissionPrices.platts]
        : []
}

/**
 * Returns BunkerEx data filtered by the availableBunkersFilter
 * E.g. for BunkerFilter.js to select bunkers in a filtered context
 */
export const bunkersFiltered = selector({
    key: "bunkersFiltered",
    get: async ({ get }) => {
        const _bunkerPrices = await get(bunkerPrices)
        const filter = get(availableBunkersFilter)

        const selectedRegions = RegionsLookup.filter((r) => !!filter[r.codeName])
        const selectedFuelTypes = Object.keys(fuelTypeNames).map((f) => f.toLowerCase()).filter((f) => !!filter[f])

        let platts = []
        let bunkerex = []
        if (DATA_SOURCE.isPlatts(filter.bunkerDataSource)) {
            platts = filterBunkers(_bunkerPrices.platts)
        }
        if (DATA_SOURCE.isBunkerex(filter.bunkerDataSource)) {
            bunkerex = filterBunkers(_bunkerPrices.bunkerex)
        }
        
        return { platts, bunkerex }

        function filterBunkers(bunkers) {
            return bunkers
                .filter((b) => b.forwardCurve?.length > 0)
                .filter((b) => selectedRegions.findIndex((r) => b.region === r.shortName) >= 0)
                .filter((b) =>
                    selectedFuelTypes.findIndex((ft) => b.fuelType.toLowerCase() === ft) >= 0)
                .filter((b) => !(b.forwardCurve.reduce((acc, e) => acc && e.constructed, true)) || filter.showConstructedBunkerCurves)
        }
    }
})

export const bunkersFilteredSpot = selector({
    key: "bunkersFilteredSpot",
    get: async ({ get }) => {
        const _bunkerPrices = await get(bunkerPrices)
        const filter = get(availableBunkersFilter)

        const selectedRegions = RegionsLookup.filter((r) => !!filter[r.codeName])
        const selectedFuelTypes = Object.keys(fuelTypeNames).map((f) => f.toLowerCase()).filter((f) => !!filter[f])

        let platts = []
        let bunkerex = []
        if (DATA_SOURCE.isPlatts(filter.bunkerDataSource)) {
            platts = filterBunkers(_bunkerPrices.platts)
        }
        if (DATA_SOURCE.isBunkerex(filter.bunkerDataSource)) {
            bunkerex = filterBunkers(_bunkerPrices.bunkerex)
        }
        
        return { platts, bunkerex }

        function filterBunkers(bunkers) {
            return bunkers
                .filter((b) => selectedRegions.findIndex((r) => b.region === r.shortName) >= 0)
                .filter((b) =>
                    selectedFuelTypes.findIndex((ft) => b.fuelType.toLowerCase() === ft) >= 0)
        }
    }
})

export const voyageEngineBunkerPorts = selector({
    key: "voyageEngineBunkerPorts",
    get: async ({ get }) => {
        const _availableBunkerPrices = await get(availableBunkerPrices)

        const portFuelTypes = _availableBunkerPrices.reduce((types, price) => {
            const fuelTypes = types[price.port] || []

            types[price.port] = [...fuelTypes, price.fuelType]
            return types
        }, {})

        return Object.keys(portFuelTypes).filter((port) => {
            const fuelTypes = portFuelTypes[port]
            return fuelTypes.includes(FUEL_TYPES.LSFO.name) &&
                fuelTypes.includes(FUEL_TYPES.HSFO.name) &&
                fuelTypes.includes(FUEL_TYPES.MGO.name)
        })
    }
})

/**
 * The voyages under management in the bunker engine
 */
export const voyagesUnderManagement = selector({
    key: "voyagesUnderManagement",
    get: ({ get }) => {

        const ui = get(userInterface)
        const layoutGroup = get(selectedLayoutGroup).id
        if (ui.hasOwnProperty("bunkersEmissionsManagementLayouts") && ui.bunkersEmissionsManagementLayouts.hasOwnProperty(layoutGroup)) {
            return ui.bunkersEmissionsManagementLayouts[layoutGroup]
        } else {
            return [] // An empty array for the data grid
        }
    },
    set: ({ get, set }, newValue) => {
        const ui = get(userInterface)
        set(userInterface, updatedUserInterfaceForSelectedLayoutGroup(ui, get(selectedLayoutGroup).id, newValue, "bunkersEmissionsManagementLayouts"))
    }
})


/**
 * Global risk settings for the bunker engine
 */
export const bunkerEngineRiskSettings = atom({
    key: "bunkerEngineRiskSettings",
    default: {
        vol: "low",
        liq: "low",
        hedgeRate: 0,
        hedgeBunk: 0,
        hedgeEmiss: 0,
        kwhSub: 0,
        lngSub: 0,
        NH3Sub: 0,
        MeOHSub: 0,
        H2Sub: 0
    }
})

/**
 * Global risk settings for the alpha engine
 */
export const alphaEngineRiskSettings = atom({
    key: "alphaEngineRiskSettings",
    default: {
        vol: "low",
        liq: "low",
        hedgeRate: 0,
        hedgeBunk: 0,
        hedgeEmiss: 0,
        kwhSub: 0,
        lngSub: 0,
        NH3Sub: 0,
        MeOHSub: 0,
        H2Sub: 0
    }
})


/**
 * The graph option selected in the UI
 */
export const graphselect = atom({
    key: "graphoptionselect",
    default: "rate"
})


function convertBunkerDates(bunker) {

    const dateKeys = [
        "voyage_startDate",
        "voyage_endDate"
    ]

    for (const key of dateKeys) {
        const dateObj = bunker[key].toDate()
        bunker[key] = dateObj
    }

    for (const port of bunker["qtyAndCost_atPorts"]) {
        const dateObj = port["date"].toDate()
        port["date"] = dateObj
    }
    return bunker
}

function convertBunkerPurchaseDates(purchase) {
    let purchaseUpdate = purchase
    const dateKeys = [
        "date_bunkering",
        "date_purchase"
    ]

    for (const pur of purchaseUpdate.purchases) {
        for (const key of dateKeys) {
            const dateObj = pur[key].toDate()
            pur[key] = dateObj
        }
    }

    return purchaseUpdate
}

/**
 * Used in voyage engine to suspend ui (e.g. display swirl) when fetching data
 * @type {RecoilState<boolean>}
 */
export const loadingIndicator = atom({
    key: "loadingIndicator",
    default: false
})

export const layoutGroups = atom({
    key: "layoutGroups",
    default: selector({
        key: "layoutGroupsLoader",
        get: async () => {
            const fetch = await FetchLayoutGroups()
            if (fetch.success) {
                if (fetch.data.length > 0) {
                    return fetch.data
                }

                const { data: defaultLayoutGroup } = await CreateLayoutGroup({
                    name: "Custom Layout",
                    filter: availableRoutesFilterDefault,
                    routes: routesLayoutDefaultBaltic,
                    emissions: emissionsLayoutsDefault,
                    bunkers: bunkersLayoutsDefaultBunkerEx,
                    liquidity: LIQUIDITY.low.name,
                    volatility: VOLATILITY.low.name
                })

                return [defaultLayoutGroup]
            } else {
                return {}
            }
        }
    })
})

export function useLayoutGroupMutations() {
    const [_layoutGroups, setLayoutGroups] = useRecoilState(layoutGroups)

    const saveLayoutGroup = async (layoutGroup) => {
        const existingIndex = _layoutGroups.findIndex((l) => l.id === layoutGroup.id)
        const response = await (existingIndex >= 0 ? SaveLayoutGroup(layoutGroup) : CreateLayoutGroup(layoutGroup))

        if (response.success) {
            const savedLayoutGroup = response.data

            setLayoutGroups(existingIndex > -1
                ? replaceItemAtIndex(_layoutGroups, existingIndex, savedLayoutGroup)
                : [..._layoutGroups, savedLayoutGroup])

            return savedLayoutGroup
        } else {
            throw new Error("Couldn't save layout group")
        }
    }

    const deleteLayoutGroup = async (id) => {
        const response = await DeleteLayoutGroup(id)

        if (response.success) {
            setLayoutGroups(_layoutGroups.filter((l) => l.id !== id))
        }
    }

    return { saveLayoutGroup, deleteLayoutGroup }
}

export const voyageErrorMessages = atom({
    key: "voyageErrorMessages",
    default: []
})


export const progressSwapPort = atom({
    key: "progressSwapPort",
    default: 0
})

const defaultUserSettings = {
    frightRatesSource: DATA_SOURCE.BALTIC.name,
    bunkerPricesSource: DATA_SOURCE.BUNKEREX.name,
    newFuels: [],
    dataSources: [DATA_SOURCE.BALTIC.name, DATA_SOURCE.BUNKEREX.name],
    emissionsPricesSource: DATA_SOURCE.NONE.name,
    emissionsRegsEu: 40,
    emissionsRegsNonEu: 0,
    volatility: VOLATILITY.med.name,
    liquidity: LIQUIDITY.med.name,
}

export const userSettings = atom({
    key: "userSettings",
    default: localStorage.getItem("userSettings") ? { ...defaultUserSettings, ...JSON.parse(localStorage.getItem("userSettings")) } : defaultUserSettings,
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((newValue) => {
                localStorage.setItem("userSettings", JSON.stringify(newValue))
            })
        }
    ]
})

export const selectableDataSources = selectorFamily({
    key: "selectableDataSources",
    get: (category) => ({ get }) => {
        const _spgciStatus = get(spgciStatus)

        const selectableValues = DATA_SOURCE.valuesAsSelectOptions(category)

        if(_spgciStatus !== ProviderStatus.ACTIVE){
            return selectableValues.filter((ds) => ds.value !== DATA_SOURCE.PLATTS.name)
        }
        
        return selectableValues;
    }
})



