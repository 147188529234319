/*
This is a node module so not available in browser, commenting out for now
const https = require("https")
*/

const apiCall = ({ apiUrl, method, body, callback }) => {
    fetch(apiUrl, {
        method,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        body: JSON.stringify(body)
        /*agent: new https.Agent({
            rejectUnauthorized: false
        })*/
    })
        .then((res) => {
            return res.status === 200 ? res.json?.() : callback(new Error(`Server responded with status code ${res.status}`))}
        , (err) => callback(err))
        .then((res) => {
            callback(null, res)
        })
}

export default apiCall
