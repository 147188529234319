import { formatDateToYYYYMM } from "../../utils/Helpers"

export function buildForwardCurve(routePrices) {
    return [routePrices.spotPrice, ...routePrices.forwardCurve.map((x) => x.price)]
}

export function buildForwardCurveUpper(routeProces) {
    return [routeProces.spotPrice, ...routeProces.forwardCurveCiUpper.map((x) => x.price)]
}

export function buildForwardCurveLower(routeProces) {
    return [routeProces.spotPrice, ...routeProces.forwardCurveCiLower.map((x) => x.price)]
}

export function convertRawCurve(spot, forwardCurve){
    const truncatedCurve = truncateForwardCurve(forwardCurve)
    return [spot, ...truncatedCurve.map((x) => x.price)]
}

export function truncateForwardCurve(forwardCurve){
    return forwardCurve.slice(0,12)
}
