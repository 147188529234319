import { atom, selector, useRecoilState } from "recoil"
import { replaceItemAtIndex } from "../utils/Helpers"
import { CreateVoyagePortfolio, DeleteVoyagePortfolio, FetchVoyagePortfolios, SaveVoyagePortfolio } from "./api/aftapi"

export const voyagePortFolios = atom({
    key: "voyagePortFolios",
    default: selector({
        key: "voyagePortFoliosLoader",
        get: async () => {
            const portfolios = await FetchVoyagePortfolios()

            if (portfolios.success) {
                return portfolios.data
            }

            return []
        }
    })
})

export function useVoyagePortfolioMutations() {
    const [_portfolios, setPortfolios] = useRecoilState(voyagePortFolios)

    const saveVoyagePortfolio = async (portfolio) => {
        const existingIndex = _portfolios.findIndex((l) => l.id === portfolio.id)
        const response = await (existingIndex >= 0 ? SaveVoyagePortfolio(portfolio) : CreateVoyagePortfolio(portfolio))

        if (response.success) {
            const savedPortfolio = response.data

            setPortfolios(existingIndex > -1
                ? replaceItemAtIndex(_portfolios, existingIndex, savedPortfolio)
                : [..._portfolios, savedPortfolio])

            return savedPortfolio
        } else {
            throw new Error("Couldn't save voyage portfolio")
        }
    }

    const deleteVoyagePortfolio = async (id) => {
        const response = await DeleteVoyagePortfolio(id)

        if (response.success) {
            setPortfolios(_portfolios.filter((l) => l.id !== id))
        }
    }

    return { saveVoyagePortfolio, deleteVoyagePortfolio }
}