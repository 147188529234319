import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormGroup from "@material-ui/core/FormGroup"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CopyAllIcon from "@mui/icons-material/CopyAll"
import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton, Stack } from "@mui/material"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { selectdVoyagePortfolio, selectdVoyagePortfolioId } from "../../../store/ui"
import { useVoyagePortfolioMutations, voyagePortFolios } from "../../../store/voyagePortfolios"
import { DefaultTheme as theme } from "../../../theme"
import { useVoyagesMutations, voyages } from "../../../store/voyages"
import { Chip, FormControl, FormLabel, Input, InputLabel } from "@material-ui/core"
import { ConfirmationDialog } from "../../../components/Dialogs/ConfirmationDialog"
import { formatDate } from "../../../utils/Helpers"


// Custom styling to hide the select all/none checkbox in header of datagrid
const useStyles = makeStyles(() => ({
    root: {
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
            display: "none"
        }
    }
}))

export function VoyagePortfolioManageDialog({ open, onClose, voyagesToAdd, onOk }) {
    const [_selectedPortfolio, setSelectedPortfolio] = useRecoilState(selectdVoyagePortfolio)
    const _portfolios = useRecoilValue(voyagePortFolios)
    const { saveVoyagePortfolio, deleteVoyagePortfolio } = useVoyagePortfolioMutations()
    const { resetVoyages } = useVoyagesMutations()
    const [_voyagesToAdd, setVoyagesToAdd] = useState([])
    const [_edited, setEdited] = useState(null)
    const [_toDelete, setToDelete] = useState(null)

    const classes = useStyles()
    const columns = [
        {
            field: "portfolioName",
            headerName: "Name",
            flex: 1
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            hideable: false,
            filterable: false,
            renderCell: (params) => (<>
                <Grid container>
                    <Grid item xs={6}>
                        <IconButton color="primary" component="label"
                            title="Duplicate"
                            onClick={(e) => {
                                e.stopPropagation()
                                duplicate(params.row)
                            }}>
                            <CopyAllIcon />
                        </IconButton>
                    </Grid>
                    {_portfolios.length > 1 &&
                        <Grid item xs={6}>
                            <IconButton color="primary" component="label"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setToDelete(params.row)
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </>)
        }
    ]

    useEffect(() => {
        setVoyagesToAdd(voyagesToAdd)
    }, [voyagesToAdd])

    useEffect(() => {
        setEdited(_selectedPortfolio)
    }, [_selectedPortfolio])

    return <Dialog open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
    >
        <DialogTitle>Manage voyage portfolios</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Manage voyage portfolios here.
            </DialogContentText>
            <Stack
                sx={{ width: "100%", mb: 1 }}
                direction="row"
                alignItems="flex-start"
                columnGap={1}
            >
                <Button size="small" onClick={handleAddPortfolioClick}>
                    Add voyage portfolio
                </Button>

            </Stack>
            <Grid container>
                <Grid item xs={6}>
                    <ThemeProvider theme={theme}>
                        <div style={{ height: 550, width: "100%", paddingTop: 20, paddingRight: 10 }}>
                            <DataGrid
                                density="compact"
                                className={classes.root}
                                rows={_portfolios}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                components={{
                                    Toolbar: CustomToolbar
                                }}
                                selectionModel={_edited && _edited.id != null ? [_edited.id] : []}
                                onSelectionModelChange={handleSelection}
                            />
                        </div>
                    </ThemeProvider>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ paddingTop: 20, paddingLeft: 10 }}>
                        <VoyagePortfolioProperties voyagePortfoilo={_edited} onChange={setEdited}></VoyagePortfolioProperties>
                    </div>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                handleClose()
            }}>Cancel</Button>
            <Button onClick={() => {
                save()
            }}>Apply</Button>
            <Button onClick={async () => {
                const saved = await save()
                saved && setSelectedPortfolio(saved)
                onOk(saved)
                handleClose()
            }}>Ok</Button>
        </DialogActions>

        {_toDelete &&
            <ConfirmationDialog message={"Are you sure you want to delete this portfolio?"}
                open={_toDelete != null} onYes={() => {
                    del()
                }
                } onClose={() => setToDelete(null)}></ConfirmationDialog>
        }
    </Dialog>

    function handleClose() {
        setEdited(null)
        onClose()
    }

    function handleSelection(sel) {
        setEdited({ ..._portfolios.find((p) => p.id === sel[0]) })
    }

    async function save() {
        if (!_edited) {
            return null
        }

        const saved = await saveVoyagePortfolio({ ..._edited, voyages: [..._edited.voyages, ..._voyagesToAdd] })
        setVoyagesToAdd([])
        setEdited(saved)
        resetVoyages()

        return saved
    }

    async function del() {
        if (_portfolios.length === 1) {
            return
        }

        const id = _toDelete.id
        if (_selectedPortfolio && _selectedPortfolio.id === id) {
            setSelectedPortfolio(null)
        }

        if (_edited && _edited.id === _toDelete.id) {
            setEdited(null)
        }

        await deleteVoyagePortfolio(id)
        setToDelete(null)
    }

    async function duplicate(portfolio) {
        const { id, ...rest } = portfolio

        const newPortfolio = { ...rest, portfolioName: `Copy of ${rest.portfolioName}` }

        setEdited(newPortfolio)
    }

    function handleAddPortfolioClick() {
        let portfolioName = "New portfolio"

        const existingPortfoliosWithNewName = _portfolios.filter((p) => p.portfolioName.startsWith(portfolioName))
        if (existingPortfoliosWithNewName.length > 0) {
            const maxNumberSameName = Math.max(...existingPortfoliosWithNewName.map((p) => p.portfolioName.match(/\((\d+)\)$/)?.[1] || 0).filter((n) => !isNaN(n)).map(Number)) || 0
            portfolioName = `${portfolioName} (${maxNumberSameName + 1})`
        }
        setEdited({
            portfolioName,
            voyages: []
        })
    }
}
// A custom smaller toolbar without the density / export functionality
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    )
}

const usePortfolioVesselsStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0
    },
    formLabel: {
        marginTop: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}))


function VoyagePortfolioProperties({ voyagePortfoilo, onChange }) {
    const classes = usePortfolioVesselsStyles()
    const _voyages = useRecoilValue(voyages)

    const handleChange = (key, value) => {
        const updated = {
            ...voyagePortfoilo,
            [key]: value
        }
        onChange(updated)
    }

    return <Paper>
        {voyagePortfoilo == null && <>
            <Typography>Select or create a portfolio to see it's properties</Typography>
        </>}
        {voyagePortfoilo != null && <>
            <FormGroup>
                <TextField
                    label="Name"
                    value={voyagePortfoilo.portfolioName}
                    size="small"
                    onChange={(event) => handleChange("portfolioName", event.target.value)}
                />
                <FormLabel className={classes.formLabel}>Voyages</FormLabel>
                <ul className={classes.root}>
                    {voyagePortfoilo.voyages
                        .map((v) => _voyages.find((vv) => vv.id === v))
                        .map((v, idx) => <li key={v.id}><Chip label={<><span>{`${v.vessel.shipName} - ${v.routeId} - ${formatDate(v.openDate)} `}</span></>} className={classes.chip} onDelete={() => handleDeleteVoyage(idx)} /></li>)}
                </ul>
            </FormGroup>
        </>}
    </Paper>

    function handleDeleteVoyage(index) {
        handleChange("voyages", voyagePortfoilo.voyages.filter((_, idx) => idx !== index))
    }
}