export function AlertTemplate({ style, options, message, close }) {
  return (
    <div style={style}>
      {options.type === "info" && "!"}
      {options.type === "success" && ":)"}
      {options.type === "error" && errorTpl(options.title, message, close)}
    </div>
  );
}

function errorTpl(title, message, close) {
  return (
    <div
      class="border border-red-400 text-ao-red px-4 py-3 rounded relative"
      role="alert"
    >
      <strong class="font-bold">{title}</strong>
      <span class="block sm:inline pr-8">{message}</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={close}>
        <svg
          class="fill-current h-6 w-6 text-ao-red"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
}
